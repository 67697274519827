import React from 'react';
import importedStyles from "./forbiddenScreen.module.sass";
import MaximizeIcon from "@material-ui/icons/Maximize"
import Button from "@material-ui/core/Button/Button";
import history from "../../helpers/history"
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

class forbiddenScreen extends React.Component {

    state = {};

    componentDidMount() {
    }

    render() {
        return (
            <div style={{height: '100%'}}>
                <Grid container spacing={0} className={importedStyles.container}>
                    <Grid item xs={6} className={importedStyles.forbiddenMessageBox}>
                        <Typography variant={"h1"} className={importedStyles.errorCode}>
                            403
                        </Typography>
                        <MaximizeIcon className={importedStyles.divider}/>
                        <Typography variant={"h5"} className={importedStyles.claim}>
                            Lo sentimos, no tienes permiso para utilizar esta funcionalidad.
                        </Typography>
                        <div id={'fixButtonSize'}>
                            <Button variant={"outlined"} className={importedStyles.returnButton} size={"large"}
                                    onClick={() => history.push('/myRestaurants')}>
                                Volver
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={6} className={importedStyles.forbiddenBackground}/>
                </Grid>
            </div>
        );
    }
}

export default forbiddenScreen;