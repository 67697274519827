import React from "react";
import {withRouter} from "react-router-dom";

class RouterMiddlewareComponent extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
       this.applyFlexPolyfill();
    }

    applyFlexPolyfill() {
        //flexibility(document.body);
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(RouterMiddlewareComponent);