import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

export default function importedStyles() {
    return {
        sendingDataAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -10
        },
        closeModalIcon: {
            position: 'absolute !important',
            right: 5
        },
        dishComplementsBox: {
            display: 'flex',
            flexDirection: 'column'
        },
        chargingAnimationContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: window.innerHeight - 200
        },
        mainActions: {

        },
        content: {
            flexGrow: 1,
            //justifyContent: 'space-around',
            backgroundColor: theme.palette.background.default,
            //padding: theme.spacing(3),
            marginTop: 100,
            justifyContent: 'center'
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        img: {
            height: 300,
            marginLeft: 10,
        },
        imgContainer: {
            display: 'flex',
            overflowX: 'scroll'
        },
        menuStateIcon: {
            marginLeft: 10,
            marginBottom: 5
        },
        containerMenu: {
            width: '65%',
            display: 'flex',
            flexDirection: 'column',
        },
        sectionHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        sectionPanelName: {
            width: '25%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        sectionExpansionPanel: {
            borderRadius: 15,
            '&:first-child': {
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
            },
            '&:not(:first-child)': {
                marginTop: 10
            },
            '&:last-child': {
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
            },
            '&:before': {
                display: 'none',
            },
        },
        sectionExpansionPanelSummary: {
            backgroundColor: '#eaeaea',
            borderRadius: 15,
        },
        menuStateButton: {
            width: '25%',
            alignSelf: 'flex-end',
            marginBottom: -60
        },
        menuStateButton2: {
            width: '25%',
            alignSelf: 'flex-end',
            marginBottom: -60,
            color: '#ffff',
            backgroundColor: '#00c853 !important',
            '&:hover': {
                backgroundColor: '#009624 !important'
            }
        },
        editMenuModalContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            marginTop: 15
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        hiddenQR: {
            display: 'none'
        }
    }
};