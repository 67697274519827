import {CHANGE_ACTIVE_MENU, CHANGE_CURRENT_RESTAURANT, CHANGE_CURRENT_SECTION} from "../../constants/action-types";

export const change_active_menu = (menu) => {
    return ({
        type: CHANGE_ACTIVE_MENU,
        actualMenu: menu
    });
};

export const change_current_restaurant = (restaurantUuid) => {
    return ({
        type: CHANGE_CURRENT_RESTAURANT,
        currentRestaurant: restaurantUuid
    });
};

export const change_current_section = (section) => {
    return ({
        type: CHANGE_CURRENT_SECTION,
        currentSection: section
    });
};

