export default function importedStyles() {
    return {
        menuSeeMoreEvents: {
            fontStyle: 'italic',
            marginLeft: 10,
            color: '#919191',
            '&:hover': {
                cursor: 'pointer',
            }
        },
        menuPrice: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,
            height: 30,
            minWidth: 70,
            borderRadius: 45,
            backgroundColor: '#d3d3d3',
        },
        menuPriceIcon: {
            marginLeft: 2,
            fontSize: 14,
        },
        menuActions: {
            justifyContent: 'flex-end'
        },
        menuEventTitle: {
            color: '#6a6a6a'
        },
        dishContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        fullDisplayFlex: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        displayFlexCenter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        card:{
          marginBottom: '1rem'
        },
        qrCode: {
            width: '25px !important',
            height: '25px !important'
        },
        disabledSectionsBox: {
            webkitFilter: 'opacity(60%)', /* Safari 6.0 - 9.0 */
            filter: 'opacity(60%)',
            backgroundColor: '#e4e4e4',
            borderRadius: 8
        }
    }
}