import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import ItemsCarousel from "react-items-carousel";
import Avatar from "@material-ui/core/Avatar";
import {Typography} from "@material-ui/core";


class DishCarousel extends React.Component {

    state = {
        activeItemIndex: 0,
        autoPlayDelay: 2000,
    };

    componentDidMount() {
        //this.interval = setInterval(this.autoChangeSlider, this.state.autoPlayDelay);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    autoChangeSlider = () => {

        if(this.state.activeItemIndex + 1 > this.props.dishes.length){
            this.setState({activeItemIndex: 0});
        } else {
            this.setState((prevState)=>({activeItemIndex: prevState.activeItemIndex + 1}));
        }
    };

    renderDishAvatar = dish => {
        let avatar = <Avatar  style={{width: 95, height: 95}}>{dish.name.substr(0, 1)}</Avatar>;
        if (dish.photo && dish.photo.length > 0){
            avatar = <Avatar style={{width: 95, height: 95}} src={dish.photo}/>
        }
        return (
            <React.Fragment>{avatar}</React.Fragment>
        );
    };

    renderCarouselItems = () => {
        return this.props.dishes.map((dish, i) =>
            <div
                key={i}
                onClick={() =>this.props.handleGoToSectionTroughDish(dish)}
                style={{
                    height: 180, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                }}
            >
                {this.renderDishAvatar(dish)}
                <Typography variant={"caption"} style={{marginTop: 4}}>{dish.name.length > 18 ? (dish.name.substr(0,18) + '...') : dish.name}</Typography>
            </div>
        );
    };

    handleItemChange = value => {
        this.setState({activeItemIndex: value});
    };

    render() {
        return (
            <div style={{"padding": "0 60px", "maxWidth": 1000, "margin": "0 auto"}}>
                <ItemsCarousel
                    infiniteLoop
                    placeholderItem={
                        <div style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Avatar style={{width: 125, height: 125, backgroundColor: '#EEE'}}>{""}</Avatar>
                        </div>
                    }
                    enablePlaceholder={true}
                    numberOfPlaceholderItems={3}
                    numberOfCards={this.props.show > this.props.dishes.length ? this.props.dishes.length : this.props.show}
                    gutter={5}
                    slidesToScroll={1}
                    showSlither={true}
                    firstAndLastGutter={false}
                    activeItemIndex={this.state.activeItemIndex}
                    requestToChangeActive={value => this.handleItemChange(value)}
                    outsideChevron={true}
                    chevronWidth={60}
                    //rightChevron={'>'}
                    //leftChevron={'<'}
                >
                    {this.renderCarouselItems()}
                </ItemsCarousel>
            </div>
        );
    }
}

DishCarousel.propTypes = {
    dishes: PropTypes.array.isRequired,
    show: PropTypes.number.isRequired,
    handleGoToSectionTroughDish: PropTypes.func.isRequired,
};


const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(DishCarousel);

