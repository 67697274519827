import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

export default function importedStyles() {
    return {
        navIconHide: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        toolbar: theme.mixins.toolbar,
        content: {
            display: 'flex',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 50,
        },
        chargingContent: {
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 300,
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
        },
        chargingAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -10,
        },
        timePickersBox: {
            //width: '75%',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        },
        contentCard: {
            backgroundColor: '#f3f7f8',
        },
        scheduleContainer: {
            alignItems: 'center',
            marginTop: 15,
            backgroundColor: '#eaeaea',
            borderRadius: 15,
            paddingLeft: 10,
            paddingTop: 5,
            paddingBottom: 5,
        },
        scheduleSubContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minHeight: 175,
        },
        listItem: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderLeft: '4px solid #3f51b5',
            marginLeft: 10,
            margin: 5
        },
        list: {
            width: '95%'
        },
        daysBox: {
            margin: 50
        },
        editorBox: {
            marginBottom: 25
        },
        dayHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        dayExpansionPanel: {
            borderRadius: 15,
            '&:not(:first-child)': {
                marginTop: 10
            },
            '&:last-child': {
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
            },
            '&:before': {
                display: 'none',
            },
        },
        dayExpansionPanelSummary: {
            backgroundColor: '#e0efff',
            borderRadius: 15,
            '&:hover': {
                //backgroundColor: '#eaeaea',
                backgroundColor: '#d0deee',
            },
        },
        dayExpansionPanelSummaryContent: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        summaryHoursBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        dividerIcon: {
            marginLeft: 10,
            marginRight: 10
        },
        reservationFrecuencyBox: {
            display: 'flex',
            alignItems: 'center'
        }
    }
}