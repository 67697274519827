import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
//import {importedStyles} from "./LandingStyle";
import importedStyles from "./Landing.module.sass";
import history from '../../../helpers/history';
import Footer from "../../../components/Footer/Footer";
import SearchHeader from "./SearchHeader/SearchHeader";
import NavbarLanding from "./NavbarLanding/NavbarLanding";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import Grid from "@material-ui/core/Grid/Grid";
import GridRestaurantCard from "../components/GridRestaurantCard/GridRestaurantCard";
import Typography from "@material-ui/core/Typography/Typography";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Button} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ReservationIcon from "@material-ui/icons/Loyalty";
import EnjoyIcon from "@material-ui/icons/LocalDining";
import FavoriteIcon from "@material-ui/icons/Favorite";
import supportsWebP from "supports-webp";
import fondoLanding from  "../../../rsc/img/fondo-landing.png";
import fondoContact from  "../../../rsc/img/contact-background.png";


class Landing extends React.Component {

    state = {
        expanded: false,
        restaurants: {},
        loadingRestaurants: false,
        filterRestaurants: false,
        selectedKitchenType: '',
        selectedCity: '',
        restaurantSearched: '',
    };

    componentDidMount() {
        //Fallback for non Webp format supported web browsers
        supportsWebP.then(supported => {
            if (!supported) {
                document.getElementById('searchParentContainer').style.backgroundImage =  `url(${fondoLanding})`;
                document.getElementById('contactParentContainer').style.backgroundImage =  `url(${fondoContact})`;
            }
        });

        this.getAllRestaurants();
    }

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    getAllRestaurants() {
        this.setState({loadingRestaurants: true});
        fetch(apiBaseUrl + 'restaurants/getAllRestaurants', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({})
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    restaurants: response.restaurants,
                    loadingRestaurants: false
                })
            } else {
                this.setState({loadingRestaurants: false});
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({loadingRestaurants: false});
            console.error(error);
        });
    }

    shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    renderRestaurantsGrid() {
        let restaurants = this.state.restaurants;
        let gridItems = [];
        if (restaurants.length > 0) {
            if (this.state.filterRestaurants) {
                Object.keys(restaurants).forEach((restaurant) => {
                    if (restaurants[restaurant].city === this.state.selectedCity) {
                        gridItems.push(
                            <Grid item xs={12} md={4}>
                                <GridRestaurantCard
                                    restaurantName={restaurants[restaurant].name}
                                    restaurantDescription={restaurants[restaurant].description}
                                    restaurantPermissions={restaurants[restaurant].restaurants_role}
                                    logo={restaurants[restaurant].logo}
                                    idRestaurant={restaurants[restaurant].uuid}
                                />
                            </Grid>
                        );
                    }
                })
            } else {
                //shuffle the array and crop it to only 6 restaurants
                restaurants = this.shuffle(restaurants).slice(0, 6);
                Object.keys(restaurants).forEach((restaurant) => {
                    gridItems.push(
                        <Grid key={restaurant} item xs={12} md={4}
                              className={restaurant > 2 && importedStyles.hiddenMobileCard}>
                            <GridRestaurantCard
                                restaurantName={restaurants[restaurant].name}
                                restaurantDescription={restaurants[restaurant].description}
                                logo={restaurants[restaurant].logo}
                                restaurantPermissions={restaurants[restaurant].restaurants_role}
                                idRestaurant={restaurants[restaurant].uuid}
                            />
                        </Grid>
                    );
                })
            }

        } else {
            gridItems.push(
                <Grid key={0} item xs={12}>
                    <Typography variant={'h3'}>
                        Sin restaurantes
                    </Typography>
                </Grid>
            );
        }

        return (
            <React.Fragment>
                {gridItems}
            </React.Fragment>
        )
    }


    render() {
        return (
            <Grid container className={importedStyles.parentContainer}>
                {/*<NavBar style={{marginBottom: 10}} title="ComerEn"/>*/}
                <Grid item xs={12}>
                    <NavbarLanding/>
                </Grid>
                <Grid item xs={12} id={'searchParentContainer'} className={importedStyles.searchParentContainer}
                      style={{height: window.innerHeight}}>
                    <div id={'searchClaim'} className={importedStyles.searchClaim}>
                        <Typography variant={"h6"}>
                            Reserva gratis en los mejores restaurantes de Castellón
                        </Typography>
                    </div>
                    <div id={'searchBox'} className={importedStyles.searchBox}>
                        <SearchHeader
                            onChangeKitchenType={(newValue) => this.setState({selectedKitchenType: newValue})}
                            onChangeCity={(newValue) => this.setState({selectedCity: newValue})}
                            onChangeSearch={(newValue) => this.setState({restaurantSearched: newValue})}
                            setFilterFunction={() => this.setState({filterRestaurants: true})}
                            landing={true}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} className={importedStyles.restaurantsGrid}>
                    <Grid container spacing={32} justify={"center"} alignItems={"center"}>
                        <Grid item xs={12} className={importedStyles.ourFavorites}>
                            <Typography variant={"h2"}>
                                Nuestras recomendaciones
                            </Typography>
                            <FavoriteIcon style={{fontSize: 90}}/>
                        </Grid>
                        {this.state.loadingRestaurants ?
                            <CircularProgress size={120}/> : this.renderRestaurantsGrid()}
                    </Grid>
                </Grid>

                <Grid item xs={12} className={importedStyles.featuresParent} id={'callToActionParent'}>
                    <Grid container spacing={32} className={importedStyles.featuresContainer}>
                        <Grid item xs={12} className={importedStyles.featuresSubtitle}>
                            <Typography variant={"h2"}>
                                ¿Cómo funciona?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} className={importedStyles.featureBox}>
                            <SearchIcon className={importedStyles.featureIcon}/>
                            <Typography variant={"h5"}>
                                Descubre
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} className={importedStyles.featureBox}>
                            <ReservationIcon className={importedStyles.featureIcon}/>
                            <Typography variant={"h5"}>
                                Reserva
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} className={importedStyles.featureBox}>
                            <EnjoyIcon className={importedStyles.featureIcon}/>
                            <Typography variant={"h5"}>
                                Disfruta de la variedad
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={importedStyles.contactParentContainer} id={'contactParentContainer'}>
                    <Grid container spacing={8} className={importedStyles.contactContainer}>
                        <Grid item xs={8} className={importedStyles.contactItem}>
                            <Typography variant={"h5"}>
                                ¿Tienes un restaurante?
                            </Typography>
                            <Typography variant={"subtitle1"}>
                                Únete a la comunidad de comerEn y aumenta las reservas de tú restaurante
                            </Typography>
                        </Grid>
                        <Grid item xs={8} className={importedStyles.contactItem}><Button
                                variant={"outlined"}
                                color={"primary"}
                                size={"large"}
                                className={importedStyles.contactButton}
                                onClick={() => history.push('/')}
                            >
                                Más info
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Footer/>
                </Grid>
            </Grid>
        );
    }
}

Landing.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(importedStyles)(Landing);






