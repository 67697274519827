import React from 'react';
import PropTypes from 'prop-types';
import history from "../../../../helpers/history";
import importedStyles from "./GridRestaurantCard.module.sass";
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import {detail_rte} from "../../../Restaurant/restaurantActions";
import connect from "react-redux/es/connect/connect";

class GridRestaurantCard extends React.Component {

    state = {

    };

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    handleRestaurantClick = () => {
        this.props.dispatch(detail_rte(this.props.idRestaurant, this.props.restaurantName, this.props.logo, this.props.restaurantPermissions));
        history.push('/comerEn/restaurante/' + this.props.idRestaurant);
    };

    render() {

        return (
            <Card className={importedStyles.card} onClick={this.handleRestaurantClick}>
                <CardActionArea className={importedStyles.cardActionArea}>
                    <CardMedia
                        component="img"
                        alt="Restaurant Image"
                        className={importedStyles.cardImage}
                        height="170"
                        image={this.props.logo ? this.props.logo : "https://image.freepik.com/foto-gratis/tabla-madera-sobremesa-vacia-fondo-borroso_1253-1584.jpg"}
                        title="Restaurant Image"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {this.props.restaurantName}
                        </Typography>
                        <Typography component="p">
                            {this.props.restaurantDescription}
                        </Typography>
                    </CardContent>
                </CardActionArea>
               {/* <CardActions>
                    <Button size="small" color="primary">
                        Ver Más
                    </Button>
                    <Button size="small" color="primary">
                        Learn More
                    </Button>
                </CardActions>*/}
            </Card>
        );
    }
}

GridRestaurantCard.propTypes = {
    restaurantDescription: PropTypes.string,
    logo: PropTypes.string,
    idRestaurant: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({

});

export default connect(mapStateToProps)(GridRestaurantCard);