import React from 'react';
import importedStyles from "./Privacy.module.sass";
import Button from "@material-ui/core/Button/Button";
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom';

class Law extends React.Component {

    state = {};

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <div className={importedStyles.privacy}>
                    CONDICIONES GENERALES DE www.comeren.com
                    Por favor, lee con atención<br/>
                    No dudes en consultarnos cualquier duda
                    <hr/>

                    <h2>0. OBJETO</h2>
                    Las presentes Condiciones Generales regulan la adquisición de los productos ofertados en el sitio
                    web comeren.com, del que es titular Sociedad Europea de Verificación de Identidad S.L. (en
                    adelante, SEVI Systems).
                    La adquisición de cualesquiera de los productos conlleva la aceptación plena y sin reservas de todas
                    y cada una de las Condiciones Generales que se indican, sin perjuicio de la aceptación de las
                    Condiciones Particulares que pudieran ser de aplicación al adquirir determinados productos.
                    Estas Condiciones Generales podrán ser modificadas sin notificación previa, por tanto, es
                    recomendable leer atentamente su contenido antes de proceder a la adquisición de cualquiera de los
                    productos ofertados.


                    <h2>1. IDENTIFICACIÓN</h2>
                    SEVI Systems, en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                    Información y de Comercio Electrónico, le informa de que:
                    <ul>
                        <li>Su denominación social es: Sociedad Europea de Verificación de Identidad S.L.</li>
                        <li>Su nombre comercial es: SEVI Systems.</li>
                        <li>Su CIF es: B44503985.</li>
                        <li>Su domicilio social está en: C\Santa Caterina 21,2º 3 - 12540 Vila Real - CASTELLÓN DE LA
                            PLANA (ESPAÑA).
                        </li>
                        <li>
                            Está inscrita en el Registro Mercantil de CASTELLÓN DE LA PLANA  Inscrita en el Registro
                            Mercantil de Castellón, el 26/03/18,
                            en el Tomo 1756 , Folio 86, Inscripción 1, Hoja CS-40883.
                        </li>
                    </ul>

                    <h2>2. COMUNICACIONES</h2>
                    Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos
                    a continuación:
                    <ul>
                        <li>Email: rgpd@sevisl.com</li>
                    </ul>
                    Todas las notificaciones y comunicaciones entre los usuarios y SEVI Systems se considerarán
                    eficaces, a todos los efectos, cuando se realicen a través de cualquier medio de los detallados
                    anteriormente.


                    <h2>3. PRODUCTOS</h2>
                    Los productos ofertados, junto con las características principales de los mismos y su precio
                    aparecen en pantalla.
                    SEVI Systems se reserva el derecho de decidir, en cada momento, los productos y/o servicios que se
                    ofrecen a los Clientes. De este modo, SEVI Systems podrá, en cualquier momento, añadir nuevos
                    productos y/o servicios a los ofertados actualmente. Asimismo SEVI Systems se reserva el derecho a
                    retirar o dejar de ofrecer, en cualquier momento, y sin previo aviso, cualesquiera de los productos
                    ofrecidos.
                    Todo ello sin perjuicio de que la adquisición de alguno de los productos sólo podrá hacerse mediante
                    la suscripción o registro del USUARIO, otorgando por parte de SEVI Systems al interesado un nombre
                    de Usuario y una contraseña, los cuales le identificarán y habilitarán personalmente para poder
                    tener acceso a los productos.
                    Una vez dentro de comeren.com, y para acceder a la contratación de los distintos productos, el
                    Usuario deberá seguir todas las instrucciones indicadas en el proceso de compra, lo cual supondrá la
                    lectura y aceptación de todas las condiciones generales y particulares fijadas en comeren.com.
                    <h3>OBSERVACIONES</h3>
                    Datos médicos opcionales, suministrados por el usuario.


                    <h2>4. DISPONIBILIDAD Y SUSTITUCIONES</h2>
                    <h3>PRODUCTOS</h3>
                    SEVI Systems pretende garantizar la disponibilidad de todos los productos que están publicitados a
                    través de comeren.com. No obstante, en el caso de que cualquier producto solicitado no estuviera
                    disponible o si el mismo se hubiera agotado, se le informará al cliente vía correo electrónico lo
                    antes posible.
                    De no hallarse disponible el producto, y habiendo sido informado de ello el consumidor, SEVI Systems
                    podrá suministrar un producto de similares características sin aumento de precio, en cuyo caso, el
                    consumidor podrá aceptarlo o rechazarlo ejerciendo su derecho de desistimiento y resolución del
                    contrato.
                    En caso de indisponibilidad de la totalidad o parte del pedido, y el rechazo de los productos de
                    sustitución por el cliente, el reembolso previamente abonado, se efectuará en la tarjeta de crédito
                    con la que realizó la compra
                    Si SEVI Systems se retrasara injustificadamente en la devolución de las cantidades abonadas, el
                    consumidor podrá reclamar el doble de la cantidad adeudada.


                    <h2>5. PROCESO DE COMPRA Y/O CONTRATACIÓN</h2>
                    Para realizar cualquier compra en comeren.com, es necesario que el cliente sea mayor de edad.
                    Además será preciso que el cliente se registre en comeren.com a través de un formulario de recogida
                    de datos en el que se proporcione a SEVI Systems la información necesaria para la contratación;
                    datos que en cualquier caso serán veraces, exactos y completos sobre su identidad y que el cliente
                    deberá consentir expresamente mediante la aceptación de la política de privacidad de SEVI Systems.
                    Los pasos a seguir para realizar la compra son:
                    <ol>
                        <li>Registro de correo electrónico.</li>
                        <li>Compra del producto..</li>
                        <li>Envío de mail con las claves para ingresar a la plataforma para cargar los datos
                            personales.
                        </li>
                    </ol>

                    Asimismo es recomendable que el cliente imprima y/o guarde una copia en soporte duradero de las
                    condiciones de venta al realizar su pedido, así como también del comprobante de recepción enviado
                    por SEVI Systems por correo electrónico.
                    <h3>FACTURACIÓN</h3>
                    Junto con su pedido, recibirá la correspondiente factura.
                    A tal efecto, el cliente consiente que dicha factura se le remita en formato electrónico. El cliente
                    la recibirá en su correo electrónico. Podrá, en cualquier momento, revocar este consentimiento si
                    para recibir la factura en papel.


                    <h2>6. DURACIÓN, RENOVACIÓN Y CANCELACIÓN DEL CONTRATO</h2>
                    DURACIÓN
                    La duración es indeterminada, siempre y cuando la empresa esté en funcionamiento.
                    RENOVACIÓN
                    No hay renovación de los servicios.
                    CANCELACIÓN
                    Se puede cancelar previo aviso con quince días de anticipación.


                    <h2>7. ENTREGA</h2>
                    <h3>PRODUCTOS</h3>
                    El pedido será entregado en el domicilio designado por el cliente en el momento de la contratación
                    según la modalidad escogida.
                    El ámbito de entrega recoge las siguientes zonas: todo el territorio español.
                    <h3>FORMAS Y PLAZOS DE ENTREGA</h3>
                    <li> Envío postal:</li>
                    Enviado por el servicio postal que escoja el cliente.
                    El plazo máximo de entrega es de 7 día/s.
                    Dependerá del servicio escogido por el cliente, el producto será puesto a disposición de la agencia
                    a las 48 horas laborales, máximo de realizado el pedido.


                    <h2>8. PRECIOS, GASTOS E IMPUESTOS</h2>
                    Los precios de los productos que se recogen en comeren.com vendrán expresados en Euros salvo que se
                    exprese lo contrario y estarán garantizados durante el tiempo de validez de los mismos, salvo error
                    de impresión y sea cual sea el destino del pedido.
                    <h3>PRODUCTOS</h3>
                    Los precios finales de todos los productos, (y en su caso, los precios finales por unidad de medida,
                    cuando sea obligatorio) junto a sus características, las ofertas, descuentos, etc., se reflejan en
                    cada uno de los artículos que aparecen en el catálogo de comeren.com.
                    <ul>
                        <li>Los productos tienen el IVA incluido.</li>
                        <li>El tipo de IVA es del 21.</li>
                        <li>Gastos de envío: No incluidos, depende de la elección del cliente.</li>
                    </ul>
                    Los precios de los productos pueden variar en cualquier momento. En este caso, el precio a aplicar
                    será el vigente en la fecha de realización del pedido. De cualquier modo, el coste final será
                    comunicado al cliente en el proceso de compra electrónica antes de que éste formalice su aceptación.


                    <h2>9. FORMAS DE PAGO</h2>
                    Ponemos a su disposición estas formas de pago:
                    <ul style={{listStyleType: 'lower-alpha'}}>
                        <li>Por tarjeta de crédito o débito.</li>
                        Puede realizar el pago de manera inmediata a través de la pasarela de pago seguro SSL de nuestro
                        Banco. Si fuera necesario realizar cualquier devolución o cancelación del pedido, el importe se
                        reembolsará a través de la misma tarjeta de pago. Los datos tanto personales como bancarios
                        serán protegidos con todas las medidas de seguridad establecidas por la Ley Orgánica de
                        Protección de Datos 15/1999 y su Reglamento de Desarrollo con total confidencialidad.
                        gastos que repercuta la entidad bancaria en el cliente.
                        <li>Por transferencia bancaria.</li>
                        Mediante ingreso en efectivo o transferencia bancaria a favor de: Entidad: Caixa Rural de Nules.
                        Número de cuenta: ES27 3135 7483 2210 2101 7835. Debiendo indicar en el momento de realizar la
                        transferencia el nombre del cliente
                        y número de pedido. El coste de la transferencia será asumido por el cliente.
                    </ul>
                    <h3>OBSERVACIONES</h3>
                    Si paga mediante tarjeta de crédito, una vez completado el pago, comenzará el proceso para el envío
                    del producto. Si paga mediante transferencia, deberá incluir su mail en la referencia, y una vez
                    comprobado el ingreso, comenzará el proceso para el envío.


                    <h2>10. POLÍTICA DE DEVOLUCIONES Y DESISTIMIENTO</h2>
                    Según establece la normativa, el consumidor y usuario tendrá derecho a DESISTIR del contrato durante
                    un período máximo de 14 días naturales sin necesidad de indicar el motivo y sin incurrir en ningún
                    coste, salvo los previstos en el art. 107.2 y 108 del RD1/2007, de 16 de noviembre, por el que se
                    aprueba el texto referido de la Ley General para la Defensa de los Consumidores y Usuarios.
                    Este derecho de desistimiento no es aplicable entre otros a:
                    <ul>
                        <li>Prestación de servicios ya ejecutados o comenzados.</li>
                        <li>Los contratos de suministro de productos confeccionados conforme a las especificaciones del
                            consumidor y usuario o, claramente personalizados, o que por su naturaleza no puedan ser
                            devueltos o puedan deteriorarse o caducar con rapidez.
                        </li>
                    </ul>

                    <h3>PRODUCTOS</h3>
                    El cliente dispondrá de un plazo máximo de 14 días naturales desde el día de la recepción del bien o
                    producto solicitado para informar a SEVI Systems sobre su deseo de desistir del contrato.
                    El desistimiento implica que, SEVI Systems procederá a la devolución del importe ya abonado por el
                    cliente en un plazo máximo de 14 días naturales, siguiendo el mismo procedimiento elegido por el
                    cliente para su abono (siempre que el producto adquirido no esté dentro de las excepciones indicadas
                    anteriormente).
                    Durante este período, el consumidor comunicará al empresario su decisión de desistir del contrato
                    rellenando para ello el formulario que se encuentra al final de este documento y enviándolo a la
                    dirección de correo electrónico de SEVI Systems.
                    SEVI Systems comunicará al consumidor en soporte duradero un acuse de recibo de dicho desistimiento.
                    Para proceder a la devolución de un producto es imprescindible que el mismo se encuentre en perfecto
                    estado y con su correspondiente envoltorio original, con todos sus accesorios y sin haber sido
                    utilizado.
                    Una vez recibamos su solicitud nos pondremos en contacto con el cliente para indicarle los detalles
                    de la devolución.
                    El cliente soportará los costes de devolución de los bienes.


                    <h2>11. GARANTÍA</h2>
                    Los productos adquiridos a través de comeren.com gozan de un período de garantía de 24 meses desde
                    el momento de su adquisición, que cubren los posibles defectos de fabricación del producto, así como
                    cualquier avería producida durante dicho periodo.
                    Se excluyen de las garantías los productos consumibles, las deficiencias ocasionadas por uso o
                    manipulaciones indebidas, conexión a la red eléctrica diferente a la indicada, instalación
                    incorrecta o los defectos derivados de toda causa exterior.
                    En los productos de naturaleza duradera, el consumidor tendrá derecho a un adecuado servicio técnico
                    y a la existencia de repuestos durante un mínimo de 5 años desde la fecha en que el producto deje de
                    fabricarse, sin que pueda incrementarse el precio de los repuestos al aplicarlos en las
                    reparaciones.
                    Para conocer las gestiones que deben seguirse ante los problemas o las averías en un producto pueden
                    contactar:
                    <ul>
                        <li>Con el fabricante o el SAT en las direcciones que aparecen en el siguiente listado:</li>
                    </ul>
                    <table border="1" align="center" padding="5">
                        <tr>
                            <td>Fabricante</td>
                            <td>Email de asistencia</td>
                            <td>Web del fabricante</td>
                        </tr>
                        <tr>
                            <td>SEVI SYSTEMS S.L.</td>
                            <td>sac@sevisl.com</td>
                            <td>www.sevisl.com</td>
                        </tr>
                    </table>

                    Para evitar problemas con la instalación o el manejo del producto comprado, asegúrese de haber
                    seguido las instrucciones de instalación y funcionamiento contenidas en los manuales de usuario.
                    Si aun así, el producto adquirido no funciona correctamente deberá contactar de inmediato con los
                    servicios de atención al cliente y asistencia técnica del fabricante, o en su caso del representante
                    o distribuidor.
                    Habitualmente los servicios técnicos exigirán que les facilite los siguientes datos:
                    <li>Número de factura.</li>


                    <h2>12. RESPONSABILIDAD Y EXONERACIÓN DE RESPONSABILIDAD</h2>
                    El consumidor y usuario tiene derecho a la reparación del producto, a su sustitución, a la rebaja
                    del precio o a la resolución del contrato, según lo establecido en el Capítulo II Tít. IV Libro II
                    de la Ley General para la Defensa de los Consumidores y Usuarios.
                    Si el producto no fuera conforme con el contrato, el consumidor podrá optar entre la reparación o la
                    sustitución del producto, siendo ambas gratuitas para el consumidor, las cuales se llevarán a cabo
                    en un plazo de tiempo razonable en función de la naturaleza de los productos y la finalidad a la que
                    estuvieran destinadas.
                    Si concluida la reparación y entregado el producto, éste sigue siendo no conforme al contrato, el
                    consumidor podrá exigir su sustitución, la rebaja del precio o la resolución del contrato.
                    <h3>AMPLIACIÓN DE INFORMACIÓN</h3>
                    Los datos que el usuario decida compartir, serán los que puedan visualizar las emergencias o cuerpos
                    de seguridad. La veracidad de dichos datos es de la exclusiva responsabilidad del cliente. En caso
                    de adquirir el producto para un menor de edad, deberá poder demostrar el vínculo.


                    <h2>13. ATENCIÓN AL CLIENTE Y RECLAMACIONES</h2>
                    Para interponer cualquier queja o reclamación ante SEVI Systems el cliente deberá dirigirse al
                    departamento de atención al cliente a través de la dirección de correo electrónico:
                    direccion@sevisl.com.
                    <br/>
                    Según la Resolución de litigios en línea en materia de consumo de la UE y conforme al Art. 14.1 del
                    Reglamento (UE) 524/2013, le informamos que la Comisión Europea facilita a todos los consumidores
                    una plataforma de resolución de litigios en línea que se encuentra disponible en el siguiente
                    enlace: http://ec.europa.eu/consumers/odr/.


                    <h2>14. LEGISLACIÓN APLICABLE</h2>
                    Las condiciones presentes se regirán por la legislación española vigente.
                    <br/>
                    La lengua utilizada será el Castellano.
                    {/*
                    <h2>0. OBJETO</h2>
                    El presente aviso legal regula el uso y utilización del sitio web mipulsid.com, del que es titular
                    Sociedad Europea de Verificación de Identidad S.L. (en adelante, SEVI Systems).
                    La navegación por el sitio web de SEVI Systems le atribuye la condición de USUARIO del mismo y
                    conlleva su aceptación plena y sin reservas de todas y cada una de las condiciones publicadas en
                    este aviso legal, advirtiendo de que dichas condiciones podrán ser modificadas sin notificación
                    previa por parte de SEVI Systems, en cuyo caso se procederá a su publicación y aviso con la máxima
                    antelación posible.
                    Por ello es recomendable leer atentamente su contenido en caso de desear acceder y hacer uso de la
                    información y de los servicios ofrecidos desde este sitio web.
                    El usuario además, se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la
                    buena fe, el orden público, los usos del tráfico y el presente Aviso Legal, y responderá frente a
                    SEVI Systems o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como
                    consecuencia del incumplimiento de dicha obligación.
                    Cualquier utilización distinta a la autorizada está expresamente prohibida, pudiendo SEVI Systems
                    denegar o retirar el acceso y su uso en cualquier momento.


                    <h2>1. IDENTIFICACIÓN</h2>
                    SEVI Systems, en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                    Información y de Comercio Electrónico, le informa de que:
                    • Su denominación social es: Sociedad Europea de Verificación de Identidad S.L..
                    • Su nombre comercial es: SEVI Systems.
                    • Su CIF es: B44503985.
                    • Su domicilio social está en: CSanta Caterina 21,2º 3 - 12540 Vila Real - CASTELLÓN DE LA PLANA
                    (ESPAÑA).
                    • Está inscrita en el Registro Mercantil de CASTELLÓN DE LA PLANA  Inscrita en el Registro
                    Mercantil de Castellón, el 26/03/18, en el Tomo 1756 , Folio 86, Inscripción 1, Hoja CS-40883.


                    <h2> 2. COMUNICACIONES</h2>
                    Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos
                    a continuación:
                    • Email: rgpd@sevisl.com.
                    Todas las notificaciones y comunicaciones entre los usuarios y SEVI Systems se considerarán
                    eficaces, a todos los efectos, cuando se realicen a través de cualquier medio de los detallados
                    anteriormente.


                    <h2>3. CONDICIONES DE ACCESO Y UTILIZACIÓN</h2>
                    El sitio web y sus servicios son de acceso libre y gratuito. No obstante, SEVI Systems puede
                    condicionar la utilización de algunos de los servicios ofrecidos en su web a la previa
                    cumplimentación del correspondiente formulario.
                    El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a SEVI
                    Systems y será el único responsable de las manifestaciones falsas o inexactas que realice.
                    El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de SEVI
                    Systems y a no emplearlos para, entre otros:
                    a) Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de
                    apología del terrorismo o, en general, contrarios a la ley o al orden público.
                    b) Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar,
                    estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas
                    físicos y lógicos de SEVI Systems o de terceras personas; así como obstaculizar el acceso de otros
                    usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a
                    través de los cuales SEVI Systems presta sus servicios.
                    c) Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de
                    los sistemas informáticos de SEVI Systems o de terceros y, en su caso, extraer información.
                    d) Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad
                    de la información de SEVI Systems o de terceros.
                    e) Suplantar la identidad de cualquier otro usuario.
                    f) Reproducir, copiar, distribuir, poner a disposición de, o cualquier otra forma de comunicación
                    pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del
                    titular de los correspondientes derechos o ello resulte legalmente permitido.
                    g) Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y
                    comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud
                    o consentimiento.
                    Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos,
                    tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya
                    propiedad pertenece a SEVI Systems, sin que puedan entenderse cedidos al usuario ninguno de los
                    derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso
                    de la web.
                    En definitiva, los usuarios que accedan a este sitio web pueden visualizar los contenidos y
                    efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean
                    cedidos posteriormente a terceros, ni se instalen a servidores conectados a redes, ni sean objeto de
                    ningún tipo de explotación.
                    Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen
                    en el sitio web son propiedad de SEVI Systems, sin que pueda entenderse que el uso o acceso al mismo
                    atribuya al usuario derecho alguno sobre los mismos.
                    La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto
                    que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan
                    prohibidos.
                    El establecimiento de un hiperenlace no implica en ningún caso la existencia de relaciones entre
                    SEVI Systems y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación
                    por parte de SEVI Systems de sus contenidos o servicios.
                    SEVI Systems no se responsabiliza del uso que cada usuario le dé a los materiales puestos a
                    disposición en este sitio web ni de las actuaciones que realice en base a los mismos.
                    <h3>3.1 EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD EN EL ACCESO Y LA UTILIZACIÓN</h3>
                    El contenido del presente sitio web es de carácter general y tiene una finalidad meramente
                    informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exhaustividad,
                    corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.
                    SEVI Systems excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por
                    los daños y perjuicios de toda naturaleza derivados de:
                    a) La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o
                    actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los
                    contenidos transmitidos, difundidos, almacenados, puestos a disposición, a los que se haya accedido
                    a través del sitio web o de los servicios que se ofrecen.
                    b) La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en
                    los sistemas informáticos, documentos electrónicos o datos de los usuarios.
                    c) El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente
                    aviso legal como consecuencia del uso incorrecto del sitio web. En particular, y a modo
                    ejemplificativo, SEVI Systems no se hace responsable de las actuaciones de terceros que vulneren
                    derechos de propiedad intelectual e industrial, secretos empresariales, derechos al honor, a la
                    intimidad personal y familiar y a la propia imagen, así como la normativa en materia de competencia
                    desleal y publicidad ilícita.
                    Asimismo, SEVI Systems declina cualquier responsabilidad respecto a la información que se halle
                    fuera de esta web y no sea gestionada directamente por nuestro webmaster. La función de los links
                    que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras
                    fuentes susceptibles de ampliar los contenidos que ofrece este sitio web. SEVI Systems no garantiza
                    ni se responsabiliza del funcionamiento o accesibilidad de los sitios enlazados; ni sugiere, invita
                    o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido.
                    SEVI Systems no se responsabiliza del establecimiento de hipervínculos por parte de terceros.
                    <h3>3.2 PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO</h3>
                    En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que
                    revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de
                    cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá enviar
                    una notificación a SEVI Systems identificándose debidamente y especificando las supuestas
                    infracciones.
                    <h3>3.3 PUBLICACIONES</h3>
                    La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de
                    las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados
                    formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único
                    instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio web
                    debe entenderse como una guía sin propósito de validez legal.

                    <h2>4. POLÍTICA DE COOKIES</h2>
                    <p style={{textDecoration: 'underline'}} onClick={() => history.push('/cookies')}>Consulta nuestra
                        política de cookies</p>

                    <h2>5. LEGISLACIÓN APLICABLE</h2>
                    Las condiciones presentes se regirán por la legislación española vigente.
                    La lengua utilizada será el Castellano.
*/}
                    <br/>
                    <br/>
                    <sub>© Sociedad Europea de Verificación S.L. 2019. All Rights Reserved.</sub>
                    <sub>© Documento Protegido por Copyright</sub>
                    <div className={importedStyles.downloadButtons}>
                        <Button variant="contained"  size={"small"} href={'./Formulario_desistimiento_Comeren.pdf'} target={'_blank'}>
                            <DownloadIcon/>
                            Formulario de desestimiento
                        </Button>
                        <Button variant="contained" size={"small"} href={'./Formulario_reclamaciones_Comeren.pdf'} target={'_blank'}>
                            <DownloadIcon/>
                            Formulario de reclamación
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Law;