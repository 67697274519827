import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import EuroIcon from '@material-ui/icons/EuroSymbol';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import importedStyles from "./menuCardStyle";
import importedStylesSass from "./menuCard.module.sass";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";


class MenuCard extends React.Component {
    state = {
        expanded: false,
        anchorEl: null,
        removeMenuModal: false,
        removeDishModal: false,
        removeSectionModal: false,
        menuSelectedId: -1,
        dishSelectedId: -1,
        sectionSelectedId: -1,
        showAllMenuEvents: false,
    };

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleRemoveModal = () => {
        this.setState({removeModal: !this.state.removeModal});
    };

    handleRemoveMenuModal = () => {
        this.setState({removeMenuModal: !this.state.removeMenuModal});
    };
    handleRemoveSectionModal = () => {
        this.setState({removeSectionModal: !this.state.removeSectionModal});
    };
    handleRemoveDishModal = () => {
        this.setState({removeDishModal: !this.state.removeDishModal});
    };

    renderDishes(dishes) {
        const {classes} = this.props;

        return (
            <List style={{width: '100%'}}>
                {Object.keys(dishes).map(value => (
                    <ListItem
                        key={value}
                        role={undefined}
                        dense
                        className={classes.dishContainer}
                    >
                        <ListItemText primary={dishes[value].name}/>
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={() => {
                                this.setState({dishSelectedId: dishes[value].uuid});
                                this.handleRemoveDishModal()
                            }}>
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        );

    }

    removeMenu = () => {
        this.handleRemoveMenuModal()
    };
    removeSection = () => {
        this.handleRemoveSectionModal()

    };
    removeDishFromSection = () => {
        this.handleRemoveDishModal()
    };
    renderPrice = () => {
        const {classes} = this.props;
        if (this.props.price != null && this.props.price > 0){
            return (
                <div className={importedStylesSass.menuPrice}>
                    {this.props.price}
                    <EuroIcon className={classes.menuPriceIcon}/>
                </div>
            )
        }

    };


    handleMenuState = () => {
        if (this.props.active){
            this.props.disableMenu(this.props.id)
        } else {
            this.props.enableMenu(this.props.id)
        }
    };

    render() {
        const {classes} = this.props;
        const menuEvents = this.props.events;
        const eventsList = [];

        if (menuEvents) {
            Object.keys(menuEvents).forEach((event) => {
                eventsList.push(
                    <Typography variant="caption" gutterBottom className={classes.menuEventTitle}>
                        {menuEvents[event].name}
                    </Typography>
                );
            });
        }


        return (
            <React.Fragment>
                <Card className={classes.card}>
                    <CardContent>
                        <div className={importedStylesSass.fullDisplayFlex}>
                            <div className={importedStylesSass.displayFlexCenter}>
                                <Typography gutterBottom>
                                    {this.props.name}
                                </Typography>
                                {this.renderPrice()}
                              {/*  <Tooltip title={'Previsualizar menú'}>
                                    <IconButton href={baseUrl + 'comerEn/cartaDigital/' + this.props.idRestaurant + '/' + this.props.id} target={'_blank'}>
                                        <EyeIcon/>
                                    </IconButton>
                                </Tooltip>*/}
                                {/*<Tooltip title={'Previsualizar menú Móvil'}>
                                    <IconButton href={baseUrl + 'comerEn/cartaDigitalMobile/' + this.props.idRestaurant + '/' + this.props.id} target={'_blank'}>
                                        <EyeIcon/>
                                    </IconButton>
                                </Tooltip>*/}

                            </div>
                            <div className={importedStylesSass.stateSwitchAndPriceContainer}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={this.props.active} onChange={this.handleMenuState} value="disableMenuSwitch" />
                                        }
                                        label={this.props.active ? "Activo" : "Desactivado"}
                                    />
                                {this.props.actions}
                            </div>
                        </div>
                        <div  style={{marginBottom: 15, maxWidth: '70%'}}>
                            {eventsList.length === 1 ? eventsList[0] : null}
                            {eventsList.length > 1 && !this.state.showAllMenuEvents ?
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    {eventsList[0]}
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        className={classes.menuSeeMoreEvents}
                                        onClick={() => this.setState({showAllMenuEvents: true})}
                                    >
                                        ...ver más
                                    </Typography>
                                </div>
                                :
                                null
                            }
                            {this.state.showAllMenuEvents ?
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                    <div>
                                        {eventsList}
                                    </div>
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        className={classes.menuSeeMoreEvents}
                                        onClick={() => this.setState({showAllMenuEvents: false})}
                                    >
                                        ...ver menos
                                    </Typography>
                                </div> : null}
                        </div>
                        <div className={!this.props.active && classes.disabledSectionsBox}>
                            {this.props.newSectionButton}
                            {this.props.sections}
                        </div>
                    </CardContent>
                </Card>

                {/*REMOVE MENU MODAL*/}
                <Dialog
                    open={this.state.removeMenuModal}
                    onClose={this.handleRemoveMenuModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar este menú?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveMenuModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.removeMenu} variant="contained" color="secondary" autoFocus>
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*REMOVE SECTION MODAL*/}
                <Dialog
                    open={this.state.removeSectionModal}
                    onClose={this.handleRemoveSectionModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar esta sección?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveSectionModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.removeSection} variant="contained" color="secondary" autoFocus>
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*REMOVE DISH MODAL*/}
                <Dialog
                    open={this.state.removeDishModal}
                    onClose={this.handleRemoveDishModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar este producto de la sección?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveDishModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.removeDishFromSection} variant="contained" color="secondary" autoFocus>
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }
}

MenuCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(importedStyles())(MenuCard);