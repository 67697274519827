import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

export default function importedStyles() {
    return {
        content: {
            display: 'flex',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 50,
        },
        chargingContent: {
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 300,
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
        },
        progressAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -10,
        },
        nested: {
            paddingLeft: theme.spacing(1),
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        button: {
            margin: theme.spacing(3),
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        newEventModalContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'space-evenly',
            padding: '0px 45px 24px'
        }
    }
};