import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import EuroSymbol from '@material-ui/icons/EuroSymbol';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleIcon from '@material-ui/icons/People';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import importedStyles from './RestaurantCardStyle';

class RestaurantCard extends React.Component {
    state = {
        expanded: false,
    };

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    render() {
        const {classes} = this.props;

        return (
            <div style={{margin: 20}}>
                <Card className={classes.card} key={this.props.id}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="Restaurant" className={classes.avatar} src={this.props.avatar}/>
                        }
                        className={this.props.association ? classes.cardHeaderAssociation : classes.cardHeader}
                        title={this.props.title}
                        subheader={this.props.website}
                        onClick={this.props.functionClick}
                    />
                    {this.props.association &&
                        <CardContent>
                            <List>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={this.props.user.name + ' ' + this.props.user.surname}
                                        secondary={this.props.email}
                                    />
                                </ListItem>
                            </List>
                        </CardContent>
                    }


                    <CardActions className={classes.actions} disableActionSpacing>
                        <IconButton
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                            aria-label="Ver más"
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    </CardActions>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent className={classes.cardContent}>
                            <List
                                component="nav"
                                // subheader={<ListSubheader component="div">Información</ListSubheader>}
                            >
                                <div id="infoListContainer" className={classes.infoListContainer}>
                                    <div id="leftColumn">
                                        <ListItem>
                                            <ListItemIcon>
                                                <ScheduleIcon/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={this.props.schedule} secondary="Horario"/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <EuroSymbol/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={this.props.price + "€"}
                                                          secondary="Precio medio"/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PeopleIcon/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={this.props.capacity} secondary="Capacidad"/>
                                        </ListItem>
                                    </div>
                                    <div id="rightColumn">
                                        <ListItem>
                                            <ListItemIcon>
                                                <PhoneIcon/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={this.props.phone} secondary="Teléfono"/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <EmailIcon/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={this.props.email} secondary="Email"/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <LanguageIcon/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={this.props.website} secondary="Web"/>
                                        </ListItem>
                                    </div>
                                </div>
                            </List>
                        </CardContent>
                    </Collapse>
                </Card>

            </div>
        );
    }
}

RestaurantCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(importedStyles())(RestaurantCard);














