import React from 'react';
import importedStyles from "./ConfirmedReservationScreen.module.sass";
import Button from "@material-ui/core/Button/Button";
import history from "../../../helpers/history"
import NavbarLanding from "../Landing/NavbarLanding/NavbarLanding";
import connect from "react-redux/es/connect/connect";
import Footer from "../../../components/Footer/Footer";
import Lottie from 'react-lottie';
import tick from '../../../rsc/animation/tick'
import HomeIcon from '@material-ui/icons/Home';


class ConfirmedReservationScreen extends React.Component {

    state = {
    };

    componentDidMount() {
        if(this.props){
            // this.setState({id : this.props.match.params.id});
        }
    }

    handleInputChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };

    renderView(){
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: tick,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return(
            <div>
                <h2>Reserva confirmada</h2>
                <p>Te hemos enviado a tu correo la información de la reserva</p>
                <Lottie options={defaultOptions}
                        height={250}
                        width={250}
                    // isStopped={this.state.isStopped}
                    // isPaused={this.state.isPaused}
                />
                <Button className={importedStyles.buttonHome} onClick={() => history.push('/')}>
                    <HomeIcon className={importedStyles.homeIcon}/>
                    Volver al inicio
                </Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <NavbarLanding/>
                <div className={importedStyles.confirmedReservation}>
                    {this.renderView()}
                </div>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({searchReducer}) => {
    return ({
        // filterActive: searchReducer.filterActive,
    });
};

export default connect(mapStateToProps)(ConfirmedReservationScreen);