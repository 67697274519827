import CardContent from "@material-ui/core/CardContent/index";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";
import ListItemIcon from "@material-ui/core/ListItemIcon/index";
import UserIcon from "@material-ui/icons/Person";
import MessageIcon from '@material-ui/icons/Announcement';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleIcon from '@material-ui/icons/Wc';
import ChildIcon from '@material-ui/icons/ChildCare';
import ListItemText from "@material-ui/core/ListItemText/index";
import {formatDate} from "../../../helpers/dateUtils";
import Typography from "@material-ui/core/Typography/index";
import Card from "@material-ui/core/Card/index";
import React from "react";
import importedStyles from "./ConfirmedReservationCard.module.sass";
import TextField from "@material-ui/core/TextField/index";
import {Button} from "@material-ui/core";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import connect from "react-redux/es/connect/connect";
import {showSnack} from "../../../components/Snack/SnackActions";
import Snack from "../../../components/Snack/Snack";


class confirmedReservationCard extends React.Component {

    state = {
        restaurantComment: '',
        restaurantCommentEdited: false,
        editingRestaurantComment: false,
        sendingData: false,
    };

    handleChange = (event) => {
      this.setState({
          restaurantComment: event.target.value,
          restaurantCommentEdited: true,
          editingRestaurantComment: true
      })
    };

    updateRestaurantComment = () =>{
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'reservations/updateRestaurantComment', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                reservationUuid: this.props.reservation.uuid,
                restaurantComment: this.state.restaurantComment
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.props.dispatch(showSnack('Se ha guardado tu observación', 'success'));
                this.setState({sendingData: false, editingRestaurantComment: false});
            } else {
                throw Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error updating restaurant comment on a reservation: ', error);
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelve a intentarlo en unos minutos',  'error'));
            this.setState({sendingData: false});
        });
    };

    render() {
        return (
            <Card className={importedStyles.confirmedReservationCard}>
                <CardContent>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <UserIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={this.props.reservation.customer.name}
                                secondary={this.props.reservation.customer.phone}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ScheduleIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={formatDate(this.props.reservation.date)}
                                secondary={this.props.reservation.hour}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={this.props.reservation.adults}
                                secondary={'Adultos'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ChildIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={this.props.reservation.childs}
                                secondary={'Niños'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <MessageIcon/>
                            </ListItemIcon>
                            {this.props.reservation.comment ?
                                <ListItemText
                                    primary={
                                        <Typography variant={"subtitle2"}>
                                            {this.props.reservation.comment}
                                        </Typography>
                                    }
                                />
                                :
                                <ListItemText
                                    //primary={'---'}
                                    secondary={'Sin peticiones especiales'}
                                />
                            }
                        </ListItem>
                        <ListItem className={importedStyles.restaurantCommentItem}>
                            <TextField
                                label="Observaciones"
                                multiline
                                fullWidth
                                rows="4"
                                value={this.state.restaurantCommentEdited ? this.state.restaurantComment : this.props.reservation.restaurantComment}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                            {this.state.editingRestaurantComment &&
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                size={"medium"}
                                disabled={!this.state.editingRestaurantComment || this.state.sendingData}
                                onClick={this.updateRestaurantComment}
                            >
                                Guardar Observación
                                {this.state.sendingData && <CircularProgress size={25} className={importedStyles.sendingDataAnimation}/>}
                            </Button>}
                        </ListItem>
                    </List>
                </CardContent>
                <Snack/>
            </Card>
        )
    }
}

const mapStateToProps = ({restaurantReducer}) => {
    return ({
        idRestaurant: restaurantReducer.idRestaurant,
    });
};

export default connect(mapStateToProps)(confirmedReservationCard);