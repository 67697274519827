import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
//import './ReactotronConfig';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import 'whatwg-fetch';

if (!('fetch' in window)) {
    console.warn('Fetch API not found on your browser, fallback to polyfill');
}
ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
