import {
    ADD_DISH_TO_CART, DELETE_WHOLE_CART,
    REMOVE_DISH_FROM_CART,
    SET_NEW_CART, SUBTRACT_ONE_DISH_FROM_CART,
    UNDO_LAST_CART_ADDITION
} from "../../../../constants/action-types";


const initialState = {
    cart: [],
};

const cartReducer = (state = initialState, action) => {

    let currentCart = [...state.cart];
    switch (action.type) {
        case SET_NEW_CART:
            return {...state, cart: action.newCart};
        case ADD_DISH_TO_CART:
            return {
                ...state, cart: [...state.cart, action.newDish]
            };
        case UNDO_LAST_CART_ADDITION:
            return {
                ...state, cart: [...state.cart.slice(0, -1)]
            };
        case SUBTRACT_ONE_DISH_FROM_CART:
            let index = currentCart.findIndex(item => item.uuid === action.dishToSubtract);
            currentCart.splice(index, 1);

            return {...state, cart: currentCart};

        case REMOVE_DISH_FROM_CART:
            let filtered = currentCart.filter((item) =>{
                return item.uuid !== action.dishToRemove
            });
            return {...state, cart: filtered};

        case DELETE_WHOLE_CART:
            currentCart.length = 0;
            return {...state, cart: currentCart};
        default:
            return {...state};
    }
};


export default cartReducer;