import React from 'react';
import importedStyles from "./ChipInput.module.sass";
import Chip from "@material-ui/core/Chip/Chip";
import Grid from "@material-ui/core/Grid/Grid";
import InputBase from "@material-ui/core/InputBase/InputBase";
import Typography from "@material-ui/core/Typography/Typography";


class ChipInput extends React.Component {

    state = {
        chipString: '',
        chips: this.props.value,
    };

    handleChange(event) {

        let text = event.target.value;
        let lastChar = text.substr(text.length - 1);
        let newChips = this.state.chips;
        if (newChips.length < this.props.chipLimit) {
            if (this.props.chipKeys.indexOf(lastChar) !== -1) {
                let chipText = text.substr(0, text.length - 1);

                //Test if the new string is already in chips array, if not push it
                let alreadyExists = newChips.indexOf(chipText) === -1;

                if (alreadyExists) {
                    newChips.push(chipText);
                    this.setState({
                        chipString: '',
                        chips: newChips
                    });
                } else {
                    this.setState({chipString: ''});
                }
            } else {
                this.setState({chipString: event.target.value});
            }
        }else{
            this.props.chipLimitAlert();
        }

        this.props.onChange(newChips);
    }

    handleDelete(chip) {
        let newChips = this.state.chips;
        let index = newChips.indexOf(chip);
        //Delete an array item by it's index
        newChips.splice(index, 1);

        this.setState({chips: newChips});

        //pass new chips array to parent component
        this.props.onChange(newChips);
    }

    renderChips() {

        const chips = this.state.chips;
        let chipsItem = [];

        Object.keys(chips).forEach((chip) => {

            chipsItem.push(
                <Chip
                    key={chip}
                    className={importedStyles.chip}
                    label={chips[chip]}
                    onDelete={() => this.handleDelete(chips[chip])}
                />
            )
        });

        return chipsItem;
    }

    addChip(){
        if (this.state.chips.length < this.props.chipLimit) {
            if (this.state.chipString !== '') {

                let newChips = this.state.chips;
                let alreadyExists = newChips.indexOf(this.state.chipString) === -1;

                if (alreadyExists) {
                    newChips.push(this.state.chipString);
                    this.setState({chips: newChips, chipString: ''});
                    this.props.onChange(newChips);
                } else {
                    this.setState({chipString: ''});
                }
            }
        }
    }


    render() {

        return (

            <Grid container spacing={8} alignItems={"flex-start"} className={importedStyles.gridContainer}>
                <Typography variant="subheading" gutterBottom className={importedStyles.label}>
                    {this.props.label}
                </Typography>
                <Grid item xs={12} className={importedStyles.parentGrid}>
                    <div className={importedStyles.chipsContainer}>
                        {this.state.chips.length > 0 ? this.renderChips() : null}
                    </div>
                    <InputBase
                        label={this.props.label}
                        className={importedStyles.textField}
                        value={this.state.chipString}
                        onChange={(event) => this.handleChange(event)}
                        onBlur={()=>this.addChip()}
                    />
                </Grid>
                <Typography variant="caption" gutterBottom className={importedStyles.typography}>
                    {this.props.helperText}
                </Typography>
            </Grid>
        );
    }
}

export default ChipInput;