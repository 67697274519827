function apiBaseUrl() {

    // Develop
   // return 'http://localhost:8000/api/';
   //  return 'https://backtestingcomeren.gihsoft.com/api/'
    //Production
    return 'https://backend.quierocomeren.com/api/';

}

export default apiBaseUrl();