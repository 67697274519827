import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import ReactChartkick from 'react-chartkick'
import Chart from 'chart.js'
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Slide from "@material-ui/core/Slide/Slide";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {formatDate} from "../../helpers/dateUtils";
import importedStyles from "./eventsStyle";
import {InlineDatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import moment from "moment";
import MomentUtils from '@date-io/moment';
import Snack from "../../components/Snack/Snack";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Fab from "@material-ui/core/es/Fab/Fab";

ReactChartkick.addAdapter(Chart);

class eventsScreen extends React.Component {

    state = {
        updateError: false,
        expanded: null,
        gettingData: true,
        newEventModal: false,
        modalDeleteConfirmation: false,
        events: {},
        menus: {},
        newEventTitle: '',
        newEventDescription: '',
        newEventBeginDate: new Date(),
        newEventEndDate: new Date(),
        selectedDays: [],
        eventResume: false,
        sendingData: false,
        sendingStateData: false,
        selectedEvent: null,
    };

    constructor(props) {
        super(props);
        this.createNewEvent = this.createNewEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.disableEvent = this.disableEvent.bind(this);
        this.activateEvent = this.activateEvent.bind(this);
        this.renderNewEventModalContent = this.renderNewEventModalContent.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    componentWillReceiveProps(nextProps) {
        this.getEvents();
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };
    handleSelectChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleInputChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleBeginDateChange = date => {
        this.setState({newEventBeginDate: new Date(date)});
    };
    handleEndDateChange = date => {
        this.setState({newEventEndDate: new Date(date)});
    };


    getEvents() {

        let idAssociation = this.props.user.associationData.uuid;
        this.setState({gettingData: true});

        fetch(apiBaseUrl + 'associations/getAssociationEvents', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    idAssociation: idAssociation
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);

            if (!response.error) {
                this.setState({events: response.events, gettingData: false});
            } else {
                throw new Error();
            }
        }).catch((error) => {
            this.setState({gettingData: false});
            this.props.dispatch(showSnack('Error al cargar los eventos, vuelva a intentarlo', snackTypes.error));
            console.log(error);
        });
    }


    renderEvents() {

        const {classes} = this.props;
        const {expanded} = this.state;
        let events = this.state.events;

        if (events.length > 0) {
            let listItems = [];
            events = [].slice.call(events).sort((a, b) => a.position - b.position);

            Object.keys(events).forEach((event) => {

                listItems.push(
                    <ExpansionPanel
                        expanded={expanded === 'panel' + events[event].uuid}
                        onChange={this.handleChange('panel' + events[event].uuid)}
                        onClick={() => this.setState({selectedEvent: events[event]})}
                        key={events[event].uuid}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography className={classes.heading}>{events[event].name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                size={"small"}
                                disabled={this.state.sendingData}
                                onClick={() => this.setState({modalDeleteConfirmation: true})}
                            >
                                Eliminar
                                <DeleteIcon style={{marginLeft: 10, marginBottom: 2}}/>
                                {this.state.sendingData &&
                                <CircularProgress
                                    size={25}
                                    className={classes.progressAnimation}
                                />}
                            </Button>
                            {events[event].active ?
                                <Button variant={"text"} color={"secondary"} size={"small"} disabled={this.state.sendingStateData} onClick={this.disableEvent}>
                                    Desactivar
                                    <RemoveIcon style={{marginLeft: 10, marginBottom: 2}}/>
                                    {this.state.sendingStateData &&
                                    <CircularProgress
                                        size={25}
                                        className={classes.sendingDataAnimation}
                                    />
                                    }
                                </Button>
                                :
                                <Button variant={"text"} color={"primary"} size={"small"} disabled={this.state.sendingStateData} onClick={this.activateEvent}>
                                    Activar
                                    <CheckIcon style={{marginLeft: 10, marginBottom: 5}}/>
                                    {this.state.sendingStateData &&
                                    <CircularProgress
                                        size={25}
                                        className={classes.sendingDataAnimation}
                                    />
                                    }
                                </Button>
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            });
            return (
                <div>
                    <Typography gutterBottom variant="headline" component="h2">
                        Mis Eventos
                    </Typography>
                    <List>{listItems}</List>
                </div>)
        } else {
            return (
                <div>
                    <Typography gutterBottom variant="headline" component="h2">
                        Sin Eventos
                    </Typography>
                    <Typography component="p">
                        Todavía no tienes ningún evento, cuando crees uno podrás verlo aquí.
                    </Typography>
                </div>
            )
        }
    }

    deleteEvent() {

        let eventId = this.state.selectedEvent.uuid;
        this.setState({sendingData: true});

        fetch(apiBaseUrl + 'events/deleteEvent', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    idEvent: eventId,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);

            if (!response.error) {
                this.getEvents();
                this.setState({modalDeleteConfirmation: false, sendingData: false});
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido eliminar', snackTypes.error));
        });
    }

    disableEvent() {

        let eventId = this.state.selectedEvent.uuid;
        this.setState({sendingStateData: true});
        fetch(apiBaseUrl + 'events/disableEvent', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    idEvent: eventId,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.getEvents();
                const updatedSelectedEvent = this.state.selectedEvent;
                updatedSelectedEvent.active = 0;
                this.setState({selectedEvent: updatedSelectedEvent, sendingStateData: false});
                this.props.dispatch(showSnack('Evento deshabilitado', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingStateData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    }

    activateEvent() {

        let eventId = this.state.selectedEvent.uuid;
        this.setState({sendingStateData: true});
        fetch(apiBaseUrl + 'events/activateEvent', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    idEvent: eventId,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.getEvents();
                const updatedSelectedEvent = this.state.selectedEvent;
                updatedSelectedEvent.active = 1;
                this.setState({selectedEvent: updatedSelectedEvent, sendingStateData: false});
                this.props.dispatch(showSnack('Evento activado', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingStateData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    }

    createNewEvent() {
        let idAssociation = this.props.user.associationData.uuid;
        let newEventTitle = this.state.newEventTitle;
        let newEventDescription = this.state.newEventDescription;
        let newEventBeginDate = this.state.newEventBeginDate;
        let newEventEndDate = this.state.newEventEndDate;

        this.setState({sendingData: true});

        fetch(apiBaseUrl + 'events/createNewEvent', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    associationId: idAssociation,
                    name: newEventTitle,
                    description: newEventDescription,
                    beginDate: newEventBeginDate,
                    endDate: newEventEndDate,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);

            if (!response.error) {
                this.getEvents();
                this.setState({
                    newEventModal: false,
                    sendingData: false,
                    newEventTitle: '',
                    newEventDescription: '',
                    newEventBeginDate: new Date(),
                    newEventEndDate: new Date(),
                });
                this.props.dispatch(showSnack('Evento creado con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Error, no se ha podido crear el evento', snackTypes.error));
        });

    }


    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    renderNewEventModalContent() {

        const {classes} = this.props;
        return (
            <React.Fragment>
                <AppBar style={{position: 'relative', marginBottom: 15}}>
                    <Toolbar>
                        <Typography variant="title" color="inherit" style={{flex: 1, marginLeft: 10}}>
                            Nuevo Evento
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={() => this.setState({
                                newEventModal: false,
                                eventResume: false,
                                newEventTitle: '',
                                newEventDescription: '',
                                newEventBeginDate: new Date(),
                                newEventEndDate: new Date(),
                            })}
                            aria-label="Close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    className={classes.newEventModalContent}
                >
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DialogContentText>
                            Escoge un nombre descriptivo para tu evento:
                        </DialogContentText>
                        <TextField
                            autoFocus
                            value={this.state.newEventTitle}
                            name="newEventTitle"
                            margin="normal"
                            variant={"outlined"}
                            placeholder={"Ej: Feria hostelera de navidad 2018"}
                            type="text"
                            inputProps={{maxLength: 100}}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DialogContentText>
                            Añade una descripción:
                        </DialogContentText>
                        <TextField
                            value={this.state.newEventDescription}
                            name="newEventDescription"
                            margin="normal"
                            variant={"outlined"}
                            placeholder={"Ej: Muestra y concurso de menús innovadores"}
                            type="text"
                            inputProps={{maxLength: 150}}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DialogContentText>
                            Fecha de inicio:
                        </DialogContentText>

                        <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            locale={'es'}
                            moment={moment}
                        >
                            <InlineDatePicker
                                margin="normal"
                                name="newEventBeginDate"
                                variant={"outlined"}
                                value={this.state.newEventBeginDate}
                                onChange={this.handleBeginDateChange}
                                format={"LL"}
                                disablePast={true}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DialogContentText>
                            Fecha de finalización:
                        </DialogContentText>
                        <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            locale={'es'}
                            moment={moment}
                        >
                            <InlineDatePicker
                                margin="normal"
                                value={this.state.newEventEndDate}
                                name="newEventEndDate"
                                variant={"outlined"}
                                onChange={this.handleEndDateChange}
                                format={"LL"}
                                helperText={this.state.newEventBeginDate > this.state.newEventEndDate ?
                                    <span style={{color: 'red'}}>La fecha de finalicación no puede ser menor a la de inicio</span> : null}
                                disablePast={true}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </DialogContent>
                <DialogActions style={{padding: 10}}>
                    <Button
                        onClick={() => this.setState({
                            newEventModal: false,
                            eventResume: false,
                            newEventTitle: '',
                            newEventDescription: '',
                            newEventBeginDate: new Date(),
                            newEventEndDate: new Date(),
                        })}
                        color="secondary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={this.createNewEvent}
                        variant={"contained"}
                        color="primary"
                        disabled={this.state.sendingData || !this.state.newEventTitle > 0 || !this.state.newEventDescription > 0 || !this.state.newEventBeginDate > 0 || !this.state.newEventEndDate > 0 || (this.state.newEventBeginDate > this.state.newEventEndDate)}
                    >
                        Crear Evento
                        {this.state.sendingData &&
                        <CircularProgress
                            size={25}
                            className={classes.progressAnimation}
                        />}
                    </Button>
                </DialogActions>
            </React.Fragment>
        )

    }

    renderEventResume() {

        const {classes} = this.props;
        return (
            <React.Fragment>
                <AppBar style={{position: 'relative', marginBottom: 15}}>
                    <Toolbar>
                        <Typography variant="title" color="inherit" style={{flex: 1, marginLeft: 10}}>
                            Resumen del nuevo evento
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={() => this.setState({newEventModal: false, eventResume: false})}
                            aria-label="Close"
                            style={{padding: 0}}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    style={{
                        padding: '0px 45px 24px',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >

                    <List>
                        <ListSubheader component="div">Título del evento</ListSubheader>
                        <ListItem className={classes.nested}>
                            {
                                this.state.newEventTitle ?
                                    <ListItemText primary={this.state.newEventTitle}/>
                                    :
                                    <ListItemText
                                        secondary={'Debes elegir un título para el evento'}
                                        style={{color: 'red', fontStyle: 'italic'}}
                                    />
                            }

                        </ListItem>
                        <ListSubheader component="div">Fecha de inicio</ListSubheader>
                        <ListItem className={classes.nested}>
                            {
                                this.state.newEventBeginDate ?
                                    <ListItemText primary={formatDate(this.state.newEventBeginDate)}/>
                                    :
                                    <ListItemText
                                        secondary={'Debes asignar una fecha de inicio para continuar'}
                                        style={{color: 'red', fontStyle: 'italic'}}
                                    />
                            }
                        </ListItem>
                        <ListSubheader component="div">Fecha limite</ListSubheader>
                        <ListItem className={classes.nested}>
                            {
                                this.state.newEventEndDate ?
                                    <ListItemText primary={formatDate(this.state.newEventEndDate)}/>
                                    :
                                    <ListItemText
                                        secondary={'Debes asignar una fecha limite para continuar'}
                                        style={{color: 'red', fontStyle: 'italic'}}
                                    />
                            }

                        </ListItem>
                    </List>

                </DialogContent>
                <DialogActions style={{padding: 10}}>
                    <Button onClick={() => this.setState({eventResume: false})} color="secondary">
                        <ArrowBackIcon style={{marginLeft: 10}}/> Volver para editar evento
                    </Button>
                    <Button
                        onClick={this.createNewEvent}
                        variant={"contained"}
                        color="primary"
                        disabled={this.state.creatingEvent || !(this.state.newEventTitle && this.state.newEventBeginDate > 0 && this.state.newEventEndDate > 0)}
                    >
                        Crear Evento
                        {this.state.sendingData &&
                        <CircularProgress
                            size={25}
                            className={classes.progressAnimation}
                        />
                        }
                    </Button>
                </DialogActions>
            </React.Fragment>

        )
    }


    renderEventDetails() {
        const {classes} = this.props;
        const event = this.state.selectedEvent;

        if (event) {

            const formatedBeginDate = formatDate(event.beginDate);
            const formatedEndDate = formatDate(event.endDate);


            return (
                <List>
                    <ListItem>
                        <ListItemText primary={'Fecha de inicio'}/>
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <ListItemText secondary={formatedBeginDate}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Fecha de finalización'}/>
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <ListItemText secondary={formatedEndDate}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'¿Evento vigente?'}/>
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <ListItemText secondary={event.active ? 'Sí' : 'No'}/>
                    </ListItem>
                </List>
            );
        } else {
            return (
                <Typography> Haz click en el evento que desees y podrás ver sus detalles aquí.</Typography>
            );
        }


    }


    render() {
        const classes = this.props.classes;

        return (
            <div style={{display: 'flex'}}>
                <MiniDrawer title={"Mis eventos"}/>
                {this.state.gettingData ? <div className={classes.chargingContent}><CircularProgress/></div> :
                    <div className={classes.content}>
                        <Grid container
                              direction="row"
                              justify="space-evenly"
                              spacing={8}
                              alignItems="stretch"
                              style={{marginTop: 15}}
                        >
                            <Grid item xs={12} md={4}>
                                <Card style={{backgroundColor: '#f3f7f8'}}>
                                    <CardContent>
                                        {this.renderEvents()}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Card style={{backgroundColor: '#f3f7f8'}}>
                                    <CardContent>
                                        {this.renderEventDetails()}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Fab variant="extended"
                                aria-label="Add event"
                                size="large" color={"primary"}
                                className={classes.button}
                                onClick={() => this.setState({newEventModal: true})}
                        >
                            <AddIcon style={{marginRight: 5}}/>
                            Nuevo Evento
                        </Fab>


                        {/* MODAL delete event */}
                        <Dialog
                            open={this.state.modalDeleteConfirmation}
                            onClose={() => this.setState({modalDeleteConfirmation: false})}
                            aria-labelledby="form-dialog-title"
                            scroll={"paper"}
                        >

                            <DialogTitle id="form-dialog-title">Eliminación de evento</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    Si eliminas este evento no podrás recuperarlo más tarde, ¿estás seguro?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => this.setState({modalDeleteConfirmation: false})}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    variant="raised"
                                    color="secondary"
                                    className={classes.submit}
                                    disabled={this.state.sendingData}
                                    onClick={() => this.deleteEvent()}
                                >
                                    Eliminar
                                    {this.state.sendingData &&
                                    <CircularProgress
                                        size={25}
                                        className={classes.progressAnimation}
                                    />}
                                </Button>
                            </DialogActions>
                        </Dialog>


                        {/* MODAL NEW event */}
                        <Dialog
                            fullScreen
                            open={this.state.newEventModal}
                            onClose={() => this.setState({newEventModal: false, eventResume: true})}
                            aria-labelledby="form-dialog-title"
                            scroll={"paper"}
                            TransitionComponent={this.Transition}
                        >
                            {this.renderNewEventModalContent()}
                        </Dialog>
                        <Snack/>
                    </div>
                }
            </div>
        );
    }
}

eventsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        user: authReducer.credentials[0],
        idRestaurant: restaurantReducer.idRestaurant,
        nameRestaurant: restaurantReducer.nameRestaurant
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles())(eventsScreen));


