import connect from "react-redux/es/connect/connect";
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import React from "react";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Avatar from "@material-ui/core/Avatar/Avatar";
import TextField from "@material-ui/core/TextField/TextField";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import PhoneIcon from '@material-ui/icons/Phone';
import FolderIcon from '@material-ui/icons/FolderOpen';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoIcon from '@material-ui/icons/Photo';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button/Button";
import {updateUser} from '../../modules/Auth/authActions';
import apiBaseUrl from "../../helpers/apiBaseUrl";
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Typography from "@material-ui/core/Typography/Typography";
import importedStyles from './profileStyle';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Snack from "../../components/Snack/Snack";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";

class profileScreen extends React.Component {

    state = {
        menus: [],
        dishes: [],
        editing: false,
        newName: this.props.user.name,
        newSurname: this.props.user.surname,
        newPassword: null,
        newEmail: this.props.user.email,
        newPhone: this.props.user.phone,
        newPhone2: this.props.user.phone2,
        newAssociationName: this.props.user.associationData ? this.props.user.associationData.name : null,
        newAssociationEmail: this.props.user.associationData ? this.props.user.associationData.email : null,
        newAssociationPhone: this.props.user.associationData ? this.props.user.associationData.phone : null,
        newAvatar: null,
        newAssociationAvatar: null,
        newAvatarPreview: undefined,
        sendingData: false,
    };

    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    updateProfileData() {

        let form = new FormData();

        const newName = this.state.newName ? this.state.newName : this.props.user.name;
        const newSurname = this.state.newSurname ? this.state.newSurname : this.props.user.surname;
        const newPassword = this.state.newPassword ? this.state.newPassword : null;
        const newEmail = this.state.newEmail ? this.state.newEmail : this.props.user.email;
        const newPhone = this.state.newPhone ? this.state.newPhone : this.props.user.phone;
        const newPhone2 = this.state.newPhone2 ? this.state.newPhone2 : '';
        const newAvatar = this.state.newAvatar ? this.state.newAvatar : false;

        form.append("idUser", this.props.user.uuid);
        form.append("name", newName);
        if (newPassword) {
            form.append("password", newPassword);
        }
        form.append("surname", newSurname);
        form.append("email", newEmail);
        form.append("phone", newPhone);
        form.append("phone2", newPhone2);
        form.append("newAvatar", newAvatar);

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'users/updateProfileData', {
            method: 'post',
            body: form
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (!response.updateError) {
                this.props.dispatch(updateUser(response));
                this.setState({editing: false, sendingData: false});
                this.props.dispatch(showSnack('Datos editados con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido editar', snackTypes.error));
        });
    }

    updateAssociationProfileData() {

        let form = new FormData();

        const newName = this.state.newName ? this.state.newName : this.props.user.name;
        const newSurname = this.state.newSurname ? this.state.newSurname : this.props.user.surname;
        const newPassword = this.state.newPassword ? this.state.newPassword : null;
        const newEmail = this.state.newEmail ? this.state.newEmail : this.props.user.email;
        const newPhone = this.state.newPhone ? this.state.newPhone : this.props.user.phone;
        const newPhone2 = this.state.newPhone2 ? this.state.newPhone2 : null;

        const newAssociationName = this.state.newAssociationName ? this.state.newAssociationName : this.props.user.associationData.name;
        const newAssociationEmail = this.state.newAssociationEmail ? this.state.newAssociationEmail : this.props.user.associationData.email;
        const newAssociationPhone = this.state.newAssociationPhone ? this.state.newAssociationPhone : this.props.user.associationData.phone;
        const newAvatar = this.state.newAssociationAvatar ? this.state.newAssociationAvatar : false;

        form.append("uuidUser", this.props.user.uuid);
        form.append("name", newName);
        if (newPassword) {
            form.append("password", newPassword);
        }
        if (newPhone) {
            form.append("phone2", newPhone2);
        }
        form.append("surname", newSurname);
        form.append("email", newEmail);
        form.append("phone", newPhone);
        form.append("newAvatar", newAvatar);

        form.append("uuidAssociation", this.props.user.associationData.uuid);
        form.append("newAssociationName", newAssociationName);
        form.append("newAssociationEmail", newAssociationEmail);
        form.append("newAssociationPhone", newAssociationPhone);

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'associations/updateAssociationProfileData', {
            method: 'post',
            body: form
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (!response.updateError) {
                this.props.dispatch(updateUser(response));
                this.setState({editing: false, sendingData: false});
                this.props.dispatch(showSnack('Datos editados con éxito', snackTypes.success));

            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido editar', snackTypes.error));
        });
    }

    handleImg(e) {
        let file = e.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onload = (ev) => {
                this.setState({newAvatarPreview: ev.target.result});
            };
            reader.readAsDataURL(file);

            if (this.props.user.association) {
                this.setState({newAssociationAvatar: file});
            } else {
                this.setState({newAvatar: file});
            }
        }
    };

    renderImageUploader() {
        const {classes} = this.props;
        let newAvatarName = '';
        let avatar = (
            <Avatar
                className={classes.avatar}
                src={this.props.user.association ? this.props.user.associationData.avatar : this.props.user.avatar}
            />
        );
        if (this.state.newAvatar) {
            let fileName = this.state.newAvatar.name;
            newAvatarName = fileName.substring(0, 6) + '... .' + fileName.substr((fileName.lastIndexOf('.') + 1));
            avatar = (
                <Avatar
                    className={classes.avatar}
                    src={this.state.newAvatarPreview}
                />
            );
        }
        return (
            <React.Fragment>
                <div className={classes.selectFileBox}>
                    {avatar}
                    {this.state.newAvatar &&
                    <List>
                        <ListItem className={classes.iconLeft}>
                            <ListItemAvatar>
                                <PhotoIcon/>
                            </ListItemAvatar>
                            <ListItemText primary={newAvatarName}/>
                            <ListItemSecondaryAction className={classes.iconRight}>
                                <IconButton onClick={() => this.setState({newAvatar: null})}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    }
                    <Button
                        variant="contained"
                        component="label"
                        size={"small"}
                        className={classes.fileUploadButton}
                    >
                        <FolderIcon className={classes.iconLeft}/>
                            Cambiar Avatar
                        <input
                            type="file"
                            accept="image/*"
                            id='documentFile'
                            onChange={(e) => this.handleImg(e)}
                            style={{display: "none"}}
                        />
                    </Button>

                </div>

            </React.Fragment>
        )
    }

    renderEditAssociationFields() {
        return (
            <React.Fragment>
                <Typography variant="title" gutterBottom>
                    Datos de asociación
                </Typography>
                <TextField
                    variant='outlined'
                    margin="normal"
                    id="name"
                    name="associationName"
                    label="Nombre"
                    type="text"
                    inputProps={{maxLength: 50}}
                    defaultValue={this.props.user.associationData.name}
                    fullWidth
                    onChange={this.handleInputChange}
                />
                <TextField
                    variant='outlined'
                    margin="normal"
                    id="name"
                    name="associationEmail"
                    label="Correo"
                    type="text"
                    inputProps={{maxLength: 50}}
                    defaultValue={this.props.user.associationData.email}
                    fullWidth
                    onChange={this.handleInputChange}
                />
                <TextField
                    variant='outlined'
                    margin="normal"
                    id="name"
                    name="associationPhone"
                    label="Teléfono"
                    type="text"
                    inputProps={{maxLength: 9}}
                    defaultValue={this.props.user.associationData.phone}
                    fullWidth
                    onChange={this.handleInputChange}
                />
            </React.Fragment>
        )
    }

    renderEditForm() {
        const classes = this.props.classes;
        return (
            <Card>
                <CardContent>
                <ListItem className={classes.avatarContainer}>
                    {this.renderImageUploader()}
                </ListItem>

                    <form>
                        <TextField
                            autoFocus
                            variant='outlined'
                            margin="normal"
                            id="name"
                            name="newName"
                            label="Nombre"
                            type="text"
                            inputProps={{maxLength: 50}}
                            defaultValue={this.props.user.name}
                            fullWidth
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            variant='outlined'
                            margin="normal"
                            id="surname"
                            name="newSurname"
                            label="Apellidos"
                            type="text"
                            defaultValue={this.props.user.surname}
                            fullWidth
                            inputProps={{maxLength: 100}}
                            onChange={this.handleInputChange}
                        />

                        <TextField
                            margin="normal"
                            variant='outlined'
                            id="email"
                            name="newEmail"
                            label="Correo"
                            type="email"
                            defaultValue={this.props.user.email}
                            fullWidth
                            inputProps={{maxLength: 50}}
                            onChange={this.handleInputChange}
                            disabled

                        />
                        <TextField
                            margin="normal"
                            variant='outlined'
                            id="password"
                            name="newPassword"
                            label="Nueva contraseña"
                            autoComplete="new-password"
                            helperText="Rellena este campo para editar tu contraseña"
                            type="password"
                            fullWidth
                            onChange={this.handleInputChange}

                        />
                        <TextField
                            margin="normal"
                            variant='outlined'
                            id="phone"
                            name="newPhone"
                            label="Teléfono"
                            type="number"
                            inputProps={{maxLength: 9}}
                            defaultValue={this.props.user.phone}
                            fullWidth
                            onChange={this.handleInputChange}

                        />
                        <TextField
                            margin="normal"
                            variant='outlined'
                            id="phone2"
                            name="newPhone2"
                            label="Teléfono 2"
                            helperText="Opcional"
                            type="number"
                            defaultValue={this.props.user.phone2 ? this.props.user.phone2 : ''}
                            fullWidth
                            inputProps={{maxLength: 9}}
                            onChange={this.handleInputChange}

                        />

                        {this.props.user.association ? this.renderEditAssociationFields() : null}

                    </form>
                    <div className={classes.actionButtonsBox}>
                        <Button
                            variant='contained'
                            color={"secondary"}
                            className={classes.cancelEditionButton}
                            onClick={() => this.setState({editing: false})}
                        >
                            Cancelar edición
                        </Button>
                        <Button
                            variant='contained'
                            color={"primary"}
                            onClick={() => this.props.user.association ? this.updateAssociationProfileData() : this.updateProfileData()}
                            disabled={this.state.sendingData || this.state.newName.length <= 0 || this.state.newSurname.length <= 0 || this.state.newPhone.length <= 0 || this.state.newEmail.length <= 0}
                        >
                            Modificar datos
                            {this.state.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.chargingAnimation}
                            />
                            }
                        </Button>
                    </div>
                </CardContent>
            </Card>
        )
    }

    renderUserInfo() {

        const user = this.props.user;
        const classes = this.props.classes;

        return (
            <Card>
                <CardHeader
                    avatar={
                        <Avatar
                            className={classes.avatar}
                            src={this.props.user.avatar}
                        />
                    }
                />
                <CardContent>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TextFormatIcon/>
                            </ListItemIcon>
                            <ListItemText inset primary={user.name} secondary="Nombre"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon/>
                            </ListItemIcon>
                            <ListItemText inset primary={user.phone} secondary="Teléfono"/>
                        </ListItem>
                        {user.phone2 ? (
                                <ListItem>
                                    <ListItemIcon>
                                        <PhoneIcon/>
                                    </ListItemIcon>
                                    <ListItemText inset primary={user.phone2} secondary="Teléfono"/>
                                </ListItem>)
                            : null
                        }
                        <ListItem>
                            <ListItemIcon>
                                <EmailIcon/>
                            </ListItemIcon>
                            <ListItemText inset primary={user.email} secondary="Email"/>
                        </ListItem>
                        {this.props.user.association ? this.renderAssociationFields() : null}
                    </List>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <IconButton onClick={() => this.setState({editing: true})}>
                        <EditIcon/>
                    </IconButton>
                </CardActions>
            </Card>
        )
    }

    renderAssociationFields() {
        const association = this.props.user.associationData;
        return (
            <React.Fragment>
                <ListItem>
                    <Typography variant="title" gutterBottom>
                        Datos de asociación
                    </Typography>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <TextFormatIcon/>
                    </ListItemIcon>
                    <ListItemText inset primary={association.name} secondary="Nombre"/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PhoneIcon/>
                    </ListItemIcon>
                    <ListItemText inset primary={association.phone} secondary="Teléfono"/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <EmailIcon/>
                    </ListItemIcon>
                    <ListItemText inset primary={association.email} secondary="Email"/>
                </ListItem>

            </React.Fragment>
        )
    }


    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <MiniDrawer title={'Mi perfil'}/>
                <div className={classes.content}>
                    {this.state.editing ? this.renderEditForm() : this.renderUserInfo()}
                </div>
                <Snack/>
            </div>
        )


    }
}

profileScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        user: authReducer.credentials[0],
        idRestaurant: restaurantReducer.idRestaurant,
        nameRestaurant: restaurantReducer.nameRestaurant
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles())(profileScreen));