import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import Container from "@material-ui/core/Container";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";
import IngredientsIcon from "@material-ui/icons/Layers";
import AllergensIcon from "@material-ui/icons/Warning";
import WarningIcon from "@material-ui/icons/Warning";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import Grid from "@material-ui/core/Grid";
import importedStyles from "./SectionContentBox.module.sass";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {Badge, Chip, Snackbar} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {add_dish_to_cart, subtract_one_dish_from_cart, undo_last_cart_addition} from "../Cart/cartActions";
import {addDishEvent} from "../../../../helpers/addDishEvent";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {ThemeProvider} from "@material-ui/styles";
import {darken, hexToRgbA, lighten} from "../../../../helpers/manageColors";


class SectionContentBox extends React.Component {

    state = {
        expanded: null,
    };


    componentDidMount() {
        if (this.props.currentDish && this.props.currentDish.length > 0) {
            this.setState({expanded: this.props.currentDish});
            let currentPanel = document.getElementById(this.props.currentDish);

            if (currentPanel) { //Security check to avoid 'null' exception behavior
                setTimeout(() => {
                    window.scrollTo({top: currentPanel.offsetTop - 150, behavior: 'smooth', block: 'end'});//moves the newly opened panel up offset
                }, 250);
            }


        }

    }

    renderDishAllergens = (allergens) => {
        let items = [];
        Object.keys(allergens).forEach((key) => {
            let allergen = allergens[key];
            items.push(
                <React.Fragment key={key}>
                    <Chip className={importedStyles.allergenChip}
                          avatar={<Avatar style={{height: 50, width: 50}} alt={allergen.name} src={allergen.logo}/>}
                          label={allergen.name}/>
                </React.Fragment>
            );
        });

        return (<React.Fragment>{items}</React.Fragment>);
    };

    renderAllergenSection = (dish) => {
        if (dish.allergens && dish.allergens.length > 0) {
            return (
                <React.Fragment>
                    <Divider variant="inset" component="li"/>
                    <ListItem alignItems="flex-start" disableGutters>
                        <ListItemAvatar className={importedStyles.dishInfoIconContainer}>
                            <AllergensIcon
                                // className={importedStyles.dishInfoIcons}
                                style={{color: this.props.primaryColor}}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant={"h5"}>Alérgenos</Typography>}
                            secondary={this.renderDishAllergens(dish.allergens)}
                        />
                    </ListItem>
                </React.Fragment>
            );
        }
    };

    showAddedToCartSnack = (dishName) => {
        this.setState({dishToAdd: dishName, addedToCartSnack: true});
    };

    handleAddDishToCart = (dish, event) => {
        event.stopPropagation(); //Prevent expansion panel to expand

        if (this.props.firstAdditionToCart) { //show 'tuto' dialog if first addition to cart and turn flag to false
            this.props.handleFirstAdditionToCart();
        }
        addDishEvent(dish.uuid);
        this.props.dispatch(add_dish_to_cart(dish));
        // this.showAddedToCartSnack(dish.name);
    };

    handleSubstract = (dish, event) => {
        event.stopPropagation(); //Prevent expansion panel to expand
        this.props.dispatch(subtract_one_dish_from_cart(dish.uuid));
    };
    undoCartAddition = () => {
        this.props.dispatch(undo_last_cart_addition());
        this.setState({addedToCartSnack: false});
    };

    handleExpansionPanelChange = dish => (event, expanded) => {
        this.setState({
            expanded: expanded ? dish.uuid : false,
        });
        let currentPanel = document.getElementById(dish.uuid);

        if (expanded && currentPanel) { //Security check for currentPanel to avoid 'null' exception behavior
            setTimeout(() => {
                window.scrollTo({top: currentPanel.offsetTop - 150, behavior: 'smooth', block: 'end'});//moves the newly opened panel up offset
            }, 250);
        }

    };

    renderNoDetailsMessage = dish => {

        if ((!dish.description || dish.description === 'null') && (!dish.ingredients || dish.ingredients === 'null') && dish.allergens.length < 1) {
            return (
                <ThemeProvider theme={this.props.theme}>
                    <ListItem alignItems="flex-start" disableGutters>
                        <ListItemAvatar className={importedStyles.dishInfoIconContainer}>
                            <WarningIcon
                                color={"secondary"}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            secondary={<span className={importedStyles.secondaryListItemText}>Este producto no contiene información detallada</span>}/>
                    </ListItem>
                </ThemeProvider>

            )
        }
    };
    renderDescription = dish => {
        if (dish.description && dish.description !== 'null') {
            return (
                <React.Fragment>
                    <ThemeProvider theme={this.props.theme}>

                        <ListItem alignItems="flex-start" disableGutters>
                            <ListItemAvatar className={importedStyles.dishInfoIconContainer}>
                                <DescriptionIcon style={{color: this.props.primaryColor}}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography variant={"h5"}>Descripción</Typography>}
                                secondary={<span
                                    className={importedStyles.secondaryListItemText}>{dish.description}</span>}
                            />
                        </ListItem>
                        < Divider variant="inset" component="li"/>
                    </ThemeProvider>
                </React.Fragment>
            )
        }
    };
    renderIngredients = dish => {
        if (dish.ingredients && dish.ingredients !== 'null') {
            return (
                <ThemeProvider theme={this.props.theme}>
                    <ListItem alignItems="flex-start" disableGutters>
                        <ListItemAvatar className={importedStyles.dishInfoIconContainer}>
                            <IngredientsIcon style={{color: this.props.primaryColor}}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant={"h5"}>Ingredientes</Typography>}
                            secondary={<span
                                className={importedStyles.secondaryListItemText}>{dish.ingredients}</span>}
                        />
                    </ListItem>
                </ThemeProvider>
            )
        }
    };

    renderDishes = () => {

        let items = [];
        let dishes = this.props.selectedSection.dishes;
        const {expanded} = this.state;

        Object.keys(dishes).forEach((key) => {
            let dish = dishes[key];
            let numberOfEqualDishesOnCart = this.props.cart.filter(item => (item.uuid === dish.uuid)).length;

            let isExpanded = expanded === dish.uuid;

            items.push(
                <ThemeProvider theme={this.props.theme}>

                    <ExpansionPanel key={dish.uuid} id={dish.uuid} expanded={isExpanded}
                                    onChange={this.handleExpansionPanelChange(dish)}
                                    className={isExpanded ? importedStyles.expandedPanel : ''}
                                    style={isExpanded ? {borderTop: '12px solid' + this.props.secondaryColor, backgroundColor: lighten(this.props.secondaryColor,95)} : {borderTop: '2px', backgroundColor: lighten(this.props.secondaryColor,95) }}


                    >
                        <ExpansionPanelSummary
                            className={isExpanded ? importedStyles.expansionPanelSummaryExpanded : importedStyles.expansionPanelSummary}
                            expandIcon={<ExpandMoreIcon
                                style={{backgroundColor:this.props.primaryColor, color: this.props.secondaryColor, borderRadius: 45}}
                            />}>
                            <Grid container   >
                                <Grid item xs={7}>
                                    <Typography style={{color: this.props.primaryColor}} variant={"subtitle1"}>{dish.name}</Typography>
                                    <Typography style={{color: this.props.primaryColor}} variant={"subtitle2"}
                                                className={importedStyles.dishPrice}>{dish.price}€</Typography>
                                </Grid>
                                <Grid item xs={5} className={importedStyles.panelSummaryItem}>

                                    <IconButton onClick={this.handleSubstract.bind(this, dish)}>
                                        <MinusIcon style={{fontSize: 16, color: darken(this.props.primaryColor,20)}}/>
                                    </IconButton>
                                    <Badge badgeContent={numberOfEqualDishesOnCart}
                                        // classes={{badge: importedStyles.dishQuantityBadge}}
                                           style={{color: darken(this.props.primaryColor,20)}}
                                    >
                                        <CartIcon style={{color: darken(this.props.primaryColor,20)}} onClick={this.handleAddDishToCart.bind(this, dish)}/>
                                    </Badge>
                                    <IconButton onClick={this.handleAddDishToCart.bind(this, dish)}>
                                        <AddIcon style={{fontSize: 16, color: darken(this.props.primaryColor,20)}}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <List disablePadding>
                                        {this.renderNoDetailsMessage(dish)}
                                        {this.renderDescription(dish)}
                                        {this.renderIngredients(dish)}
                                        {this.renderAllergenSection(dish)}
                                    </List>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </ThemeProvider>
            );
        });

        return (<React.Fragment>{items}</React.Fragment>);
    };


    renderNextSectionsLinks = () => {
        let nextSections = this.props.sections.filter((item) => {
            return item.uuid !== this.props.selectedSection.uuid
        });

        let nextSectionsLinks = [];

        Object.keys(nextSections).forEach((key) => {
            let nextSection = nextSections[key];
            nextSectionsLinks.push(
                <ListItem key={key} button onClick={this.props.handleSectionChange(nextSection)}>
                    <ListItemText primary={'Ver "' + nextSection.name + '"'} style={{fontStyle: 'oblique'}}/>
                </ListItem>
            );
        });

        return (
            <List
                className={importedStyles.sectionLinks}>
                {nextSectionsLinks}
            </List>
        );
    };


    render() {


        return (
            <Container maxWidth="lg" style={{'paddingBottom': '7rem'}}>
                <Typography variant={'h5'} style={{
                    marginBottom: '1rem',
                    color: darken(this.props.primaryColor,30),
                    maxWidth: '100%',
                    whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                }}>{this.props.selectedSection.name }</Typography>
                {this.renderDishes()}
                {/*{this.renderNextSectionsLinks()}*/}
                <Snackbar
                    open={this.state.addedToCartSnack}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    autoHideDuration={6000}
                    onClose={() => this.setState({addedToCartSnack: false})}
                    message={'"' + this.state.dishToAdd + '" ha sido añadido al pedido'}
                    action={<Button variant={'outlined'} key="undo" color="secondary" size="small"
                                    onClick={this.undoCartAddition}><span>Deshacer</span></Button>}
                />
            </Container>
        );
    }
}

SectionContentBox.propTypes = {
    sections: PropTypes.array.isRequired,
    selectedSection: PropTypes.object.isRequired,
    handleSectionChange: PropTypes.func.isRequired,
    handleFirstAdditionToCart: PropTypes.func.isRequired,
    firstAdditionToCart: PropTypes.bool.isRequired,
    currentDish: PropTypes.string
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        currentMenu: digitalMenuReducer.actualMenu,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(SectionContentBox);

