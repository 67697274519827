import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {Typography} from "@material-ui/core";
import List from "@material-ui/core/List";
import {add_dish_to_cart, delete_whole_cart, remove_dish_from_cart, subtract_one_dish_from_cart} from "./cartActions";
import IconButton from "@material-ui/core/IconButton";
import importedStyles from "./CartMobile.module.sass";
import MinusIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"
import VerifiedIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import Card from "@material-ui/core/Card";
import {addDishEvent} from './../../../../helpers/addDishEvent'
import {ThemeProvider} from "@material-ui/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {darken, hexToRgbA, lighten} from "../../../../helpers/manageColors";

class CartMobile extends React.Component {

    state = {
        deleteWholeCartConfirmationDialog: false,
        finishOrderConfirmationDialog: false,
    };

    componentDidMount() {

    }

    removeItemFromCart = (dishUuid) => () => {
        this.props.dispatch(remove_dish_from_cart(dishUuid));
    };

    handleAddDishToCart = (dish) => () => {
        this.props.dispatch(add_dish_to_cart(dish));
        addDishEvent(dish.uuid);
    };

    handleSubstract = (dish) => () => {
        this.props.dispatch(subtract_one_dish_from_cart(dish.uuid));
    };

    handleClearCart = () => {
        this.props.dispatch(delete_whole_cart());
        this.handleDeleteWholeCartConfirmationDialog(); //close delete confirmation modal
        window.location.reload(); // Reload page
    };
    handleDeleteWholeCartConfirmationDialog = () => {
        this.setState((prevState, props) => ({
            deleteWholeCartConfirmationDialog: !prevState.deleteWholeCartConfirmationDialog
        }));
    };

    handleFinishOrderConfirmationDialog = () => {
        this.setState((prevState, props) => ({
            finishOrderConfirmationDialog: !prevState.finishOrderConfirmationDialog
        }));
    };

    renderDishAvatar = dish => {
        let avatar = <Avatar style={{width: 45, height: 45}}>{dish.name.substr(0, 1)}</Avatar>;
        if (dish.photo && dish.photo.length > 0) {
            avatar = <Avatar style={{width: 45, height: 45}} src={dish.photo}/>
        }
        return (
            <React.Fragment>{avatar}</React.Fragment>
        );
    };

    renderCartItems = () => {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.props.primaryColor ? this.props.primaryColor : '#008EEF',
                    // main:'#FF0000',
                },
                secondary: {
                    main: this.props.secondaryColor ? this.props.secondaryColor : '#fff',
                },
            },
        });
        let currentCart = this.props.cart;
        let cartItems = [];
        let cartItemsAlreadyAdded = [];
        Object.keys(currentCart).forEach((key) => {
            let dish = currentCart[key];
            let numberOfEqualDishesOnCart = currentCart.filter(item => (item.uuid === dish.uuid)).length;
            let alreadyOnCart = cartItemsAlreadyAdded.filter(item => (item.uuid === dish.uuid)).length > 0;

            if (!alreadyOnCart) {
                cartItemsAlreadyAdded.push(dish);
                cartItems.push(
                    <ThemeProvider theme={theme}>

                        <Card key={key} elevation={3} className={importedStyles.listItemCard}>
                            <div className={importedStyles.cartItemActions} style={{    border: "1px solid " + this.props.primaryColor}}>
                                <IconButton onClick={this.handleSubstract(dish)} style={{padding: 8}}>
                                    <MinusIcon  style={{fontSize: 16, color: this.props.primaryColor}}/>
                                </IconButton>
                                {numberOfEqualDishesOnCart}
                                <IconButton onClick={this.handleAddDishToCart(dish)} style={{padding: 8}}>
                                    <AddIcon   style={{fontSize: 16, color: this.props.primaryColor}}/>
                                </IconButton>
                            </div>
                            <ListItem alignItems="flex-start" disableGutters>
                                {/* <ListItemAvatar style={{marginRight: 10}}>
                                {this.renderDishAvatar(dish)}
                            </ListItemAvatar>*/}
                                <ListItemText
                                    primary={<Typography variant={"subtitle2"} style={{color: darken(this.props.primaryColor,30)}}
                                                         className={importedStyles.cartItemTitle}>{dish.name}</Typography>}
                                    secondary={ <span style={{color: darken(this.props.primaryColor,20)}} >
                                        {dish.price * numberOfEqualDishesOnCart + '€'}
                                        </span>
                                    }

                                />
                            </ListItem>
                            <IconButton onClick={this.removeItemFromCart(dish.uuid)} style={{padding: 8}}
                                        className={importedStyles.removeIconButton}>
                                <CloseIcon
                                    color={"primary"}
                                    className={importedStyles.removeIcon}/>
                            </IconButton>
                            {/*<Divider variant="inset" component="li"/>*/}
                        </Card>
                    </ThemeProvider>
                );
            }

        });

        return (
            <React.Fragment>
                {cartItems}
            </React.Fragment>
        );
    };

    renderTotalPrice = () => {
        let cart = this.props.cart;
        let totalPrice = 0;
        cart.forEach((item) => {
            totalPrice = totalPrice + item.price;
        });
        return totalPrice.toFixed(2);
    };

    renderEmptyCartMessage = () => {
        return (
            <ListItem>
                <ListItemText primary={'Todavía no tienes ningún plato en tu pedido'}/>
            </ListItem>
        );
    };


    render() {
        return (
            <React.Fragment>
                <Dialog open={this.props.cartOpened} onClose={this.props.onClose} fullScreen>
                    <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close"
                                style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}>
                        <CloseIcon style={{color: this.props.primaryColor}}/>
                    </IconButton>
                    <DialogTitle style={{backgroundColor: hexToRgbA(this.props.secondaryColor, 20), color: this.props.primaryColor}} id="cart-title">Mi pedido</DialogTitle>
                    <DialogContent style={{minHeight: '60vh', backgroundColor: hexToRgbA(this.props.secondaryColor, 10)}}>
                        <DialogContentText style={{color:this.props.primaryColor}} id="alert-dialog-description" className={importedStyles.dialogContentText}>
                            Aquí puedes guardar los diferentes platos y/o bebidas que vas a pedir para poder recordarlos
                            y
                            enseñarselo al camarero cuando te atienda.
                        </DialogContentText>
                        <List>
                            {this.props.cart.length > 0 ? this.renderCartItems() : this.renderEmptyCartMessage()}
                        </List>
                        {/* <div className={importedStyles.checkoutButtonContainer}>
                            <Button variant={"contained"} onClick={this.handleFinishOrderConfirmationDialog}
                                    className={importedStyles.checkoutButton} fullWidth size={"large"}>
                                <VerifiedIcon style={{marginRight: 5}}/>
                                Confirmar pedido
                            </Button>
                        </div>*/}
                    </DialogContent>
                    <DialogActions style={{backgroundColor: hexToRgbA(this.props.secondaryColor, 20)}} className={importedStyles.dialogActions}>
                        <Typography variant={"subtitle2"} style={{color:this.props.primaryColor, marginBottom: '0.5rem', textAlign: 'center'}}>
                            <span>Total: {this.renderTotalPrice()}€</span>
                        </Typography>
                        <div className={importedStyles.checkoutButtonContainer}  >
                            <Button variant={"contained"}
                                    onClick={this.handleFinishOrderConfirmationDialog}
                                    className={importedStyles.checkoutButton}
                                    fullWidth size={"large"}
                                    style={{background: "linear-gradient(87deg, "+this.props.primaryColor+", "+lighten(this.props.primaryColor,30)+" 66%)"}}
                                    disabled={this.props.cart.length <= 0}>
                                <VerifiedIcon style={{marginRight: 5, color: this.props.secondaryColor}}/>
                                <span style={{color: this.props.secondaryColor}}>Confirmar pedido</span>
                            </Button>
                        </div>
                        {/*     <Button variant={"outlined"} color="secondary"
                                onClick={this.handleDeleteWholeCartConfirmationDialog}>
                            Borrar pedido
                        </Button>*/}
                    </DialogActions>
                </Dialog>

                {/*DELETE WHOLE CART CONFIRMATION DIALOG */}
                <Dialog open={this.state.deleteWholeCartConfirmationDialog}
                        onClose={this.handleDeleteWholeCartConfirmationDialog} maxWidth="sm" fullWidth>
                    <DialogTitle id="cart-title">Confirmar borrado</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={importedStyles.dialogContentText}>
                            Esta acción eliminará todos los platos/artículos del pedido <br/> ¿Estás seguro?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={importedStyles.dialogActions}>
                        <Button variant={"outlined"} color="primary"
                                onClick={this.handleDeleteWholeCartConfirmationDialog}>
                            Cancelar
                        </Button>
                        <Button variant={"contained"} color="secondary" onClick={this.handleClearCart}>
                            Confirmar borrado
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* FINISH ORDER CONFIRMATION DIALOG */}
                <Dialog open={this.state.finishOrderConfirmationDialog}
                        onClose={this.handleFinishOrderConfirmationDialog} maxWidth="sm" fullWidth>
                    <IconButton color="inherit" onClick={this.handleFinishOrderConfirmationDialog} aria-label="Close"
                                style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}>
                        <CloseIcon style={{color:this.props.primaryColor}}/>
                    </IconButton>
                    <DialogTitle id="cart-title">Confirmar pedido</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={importedStyles.dialogContentText}>
                            ¡GENIAL! <br/> Ahora puedes avisar al camarero/a más cercano para que tome nota de tu
                            pedido.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"secondary"} fullWidth
                                onClick={this.handleFinishOrderConfirmationDialog}>
                            Entendido
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

CartMobile.propTypes = {
    cartOpened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(CartMobile);

