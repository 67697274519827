export default class ChipsQuestion {

    constructor(question) {
        this.title = question.title;
        this.answers = question.answers;
        this.type = question.type;
        this.position = question.position;
        this.skippable = question.skippable;
        this.possibleAnswers = question.possibleAnswers;
    }

    getAnswersStatistics() {
        let answers = this.answers;
        let totalAnswers = answers.length;
        let possibleAnswers = this.possibleAnswers.split(', ');

        let answer1TotalVotes = 0;
        let answer2TotalVotes = 0;
        let answer3TotalVotes = 0;
        let answer4TotalVotes = 0;

        Object.keys(answers).forEach((key) => {
            let answerObject = answers[key];
            let selectedAnswer = possibleAnswers.indexOf(answerObject.answer);

            switch (selectedAnswer) {
                case 0:
                    answer1TotalVotes++;
                    break;
                case 1:
                    answer2TotalVotes++;
                    break;
                case 2:
                    answer3TotalVotes++;
                    break;
                case 3:
                    answer4TotalVotes++;
                    break;
                default:
                    //do nothing
                    break;
            }
        });

        let answerStatistics = {
            answer1TotalVotes: answer1TotalVotes,
            answer2TotalVotes: answer2TotalVotes,
            answer3TotalVotes: answer3TotalVotes,
            answer4TotalVotes: answer4TotalVotes,
            totalAnswers: totalAnswers,
            possibleAnswers: possibleAnswers,
        };

        return answerStatistics;
    }

}