import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import CartIcon from "@material-ui/icons/ShoppingCart";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import importedStyles from "./DigitalMenuNavBarMobile.module.sass";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Cart from "../Cart/CartMobile";
import Badge from "@material-ui/core/Badge";


import ChevronRightIcon from "@material-ui/icons/ChevronRightRounded";
import {ListItemSecondaryAction} from "@material-ui/core";
import {lighten} from "../../../../helpers/manageColors";
import {ThemeProvider} from "@material-ui/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import Typography from "@material-ui/core/Typography";


class DigitalMenuNavBarMobile extends React.Component {

    state = {
        cartOpened: false,
        totalCartItems: 0
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cart.length !== this.props.cart.length) {
            this.setState({totalCartItems: this.props.cart.length})
        }
    }

    renderSelectSectionItems = () => {

        if (this.props.currentMenu.menu_sections) {
            let sections = this.props.currentMenu.menu_sections;
            let items = [];
            Object.keys(sections).forEach((key) => {
                let section = sections[key];
                items.push(
                    <MenuItem key={key} value={section} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{section.name}</MenuItem>
                );
            });
            return (items);
        }
    };

    onSelectChange = (e) => {
        this.props.handleSectionChange(e.target.value);
    }

    handleCart = () => {
        this.setState((prevState, props) => ({
            cartOpened: !prevState.cartOpened
        }));
    };


    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.props.primaryColor ? this.props.primaryColor : '#008EEF',
                },
                secondary: {
                    main: this.props.secondaryColor ? this.props.secondaryColor : '#000',
                },
            },
        });
        return (
            <ThemeProvider theme={theme}>


                <AppBar position="fixed"
                        className={importedStyles.root}
                        style={{backgroundImage: 'linear-gradient(87deg,' + this.props.primaryColor + ', ' + lighten(this.props.primaryColor, 10) + ' 33%)'}}

                >
                    <Grid container spacing={0} className={importedStyles.gridContainerNavBar}>
                        <Grid item sm={1} className={importedStyles.gridItem} style={{marginLeft: '1rem'}}>
                            <IconButton edge="start" color="inherit" aria-label="back" onClick={this.props.handleBack}>
                                <BackIcon style={{color: this.props.secondaryColor}}
                                          className={importedStyles.backIcon}/>
                            </IconButton>
                        </Grid>
                        <Grid item sm={5} className={importedStyles.gridItem} style={{marginLeft: 'auto'}}>
                            {
                                this.props.navBarTitle ? <Typography style={{color: this.props.secondaryColor}} variant={"subtitle2"}> {this.props.navBarTitle} </Typography> :
                                    <FormControl className={importedStyles.formControlSectionSelect}>
                                        <InputLabel htmlFor="section"
                                                    className={importedStyles.sectionSelectLabel}
                                                    style={{color: this.props.secondaryColor}}>Sección actual</InputLabel>
                                        <Select
                                            value={this.props.currentSection}
                                            onChange={this.onSelectChange}
                                            name={'selectedSection'}
                                            disableUnderline
                                            style={{color: this.props.secondaryColor, whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}
                                            // renderValue={value => `${value}`}
                                            className={importedStyles.sectionSelectInput}
                                        >
                                            <MenuItem value={''} key={'000'} disabled>
                                                Selecciona una sección
                                            </MenuItem>
                                            {this.renderSelectSectionItems()}
                                        </Select>
                                    </FormControl>
                            }

                        </Grid>
                        <Grid item xs={1} style={{marginLeft: 'auto', paddingRight: '3.75rem'}}>
                            <Badge badgeContent={this.props.cart.length > 0 ? this.props.cart.length : '0'}
                                   color={"secondary"} style={{color: this.props.secondaryColor}}>
                                <IconButton onClick={this.handleCart} style={{padding: '0'}}><CartIcon
                                    style={{color: this.props.secondaryColor}}/></IconButton>
                            </Badge>
                        </Grid>

                        {/* <div id={'languageIcon'}>
                        <img style={{height: 25, position: 'absolute', bottom: '0.5rem', right: '0.9rem'}}
                             src={require('../../../../rsc/img/espana.png')} alt={'bandera española'}/>
                    </div>*/}
                    </Grid>
                    {/*    <div className={importedStyles.cartAccessor}>
                    <Badge badgeContent={this.props.cart.length} classes={{badge: importedStyles.badgeColor}}>
                        <IconButton onClick={this.handleCart} style={{padding: '0'}}><CartIcon/></IconButton>
                    </Badge>
                </div>*/}
                    <Cart primaryColor={this.props.primaryColor} secondaryColor={this.props.secondaryColor}
                          classes={{badge: importedStyles.badgeColor}}
                          cartOpened={this.state.cartOpened} onClose={this.handleCart}/>
                </AppBar>
            </ThemeProvider>
        );
    }
}

DigitalMenuNavBarMobile.propTypes = {
    classes: PropTypes.object,
    restaurantLogo: PropTypes.string.isRequired,
    restaurantName: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleSectionChange: PropTypes.func.isRequired,
    currentSection: PropTypes.object.isRequired,
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        selectedSection: digitalMenuReducer.selectedSection,
        // currentMenu: digitalMenuReducer.actualMenu,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(DigitalMenuNavBarMobile);

