import React from 'react';
import importedStyles from "./DetailRestaurantScreen.module.sass";
import Button from "@material-ui/core/Button/Button";
import history from "../../../helpers/history"
import NavbarLanding from "../Landing/NavbarLanding/NavbarLanding";
import connect from "react-redux/es/connect/connect";
import Footer from "../../../components/Footer/Footer";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
import {
    EuroSymbol as EuroIcon,
    NavigateBefore,
    NavigateNext,
    Phone as PhoneIcon,
    PhotoCamera as PhotoCameraIcon,
    RoomService as RoomServiceIcon,
    Star as StarIcon
} from '@material-ui/icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DetailCard from "../../Restaurant/components/DetailCard";
import importedStylesSass from "../../Restaurant/DetalleRestaurante/DetalleRestaurante.module.sass";
import {getFormattedSchedule} from "../../../helpers/dateUtils";


class NextButton extends React.Component {
    render() {
        return (
            <button type="button" className={importedStyles.slickNext} onClick={this.props.onClick}><NavigateNext/>
            </button>
        )
    }
}

class PrevButton extends React.Component {
    render() {
        return (
            <button type="button" className={importedStyles.slickPrev} onClick={this.props.onClick}><NavigateBefore/>
            </button>
        )
    }
}

class DetailRestaurantScreen extends React.Component {

    state = {
        loadingRestaurant: true,
    };

    componentDidMount() {
        if (this.props) {
            this.setState({id: this.props.match.params.id});
        }
        this.getInfo();
        this.getImages();
        this.getFeatures();
        this.getRestaurantSchedule();
    }

    getInfo() {
        fetch(apiBaseUrl + 'restaurants/getRestaurant', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.match.params.id
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    restaurant: response.restaurant,
                    loadingRestaurant: false
                })
            } else {
                this.setState({loadingRestaurant: false});
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({loadingRestaurant: false});
            console.error(error);
        });

    }

    getImages() {
        let form = new FormData();
        form.append("uuidRestaurant", this.props.match.params.id);

        fetch(apiBaseUrl + 'restaurants/getRestaurantImages', {
                method: 'post',
                body: form
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({imgUrls: response.imgUrls});
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    getFeatures() {
        fetch(apiBaseUrl + 'restaurants/restaurantFeatures', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: this.props.match.params.id,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            this.setState({features: JSON.parse(recurso)});
            this.generateFeatureList();
        }).catch((error) => {
            console.error(error);
        });
    }

    getRestaurantSchedule() {
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'restaurants/getRestaurantSchedules', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.match.params.id,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                let schedules = response.schedules;
                this.setState({schedule: schedules});

                Object.keys(schedules).forEach((schedule) => {
                    switch (schedules[schedule].dayUuid) {
                        case '1':
                            this.setState({mondaySchedule: schedules[schedule]});
                            break;
                        case '2':
                            this.setState({tuesdaySchedule: schedules[schedule]});
                            break;
                        case '3':
                            this.setState({wednesdaySchedule: schedules[schedule]});
                            break;
                        case '4':
                            this.setState({thursdaySchedule: schedules[schedule]});
                            break;
                        case '5':
                            this.setState({fridaySchedule: schedules[schedule]});
                            break;
                        case '6':
                            this.setState({saturdaySchedule: schedules[schedule]});
                            break;
                        case '7':
                            this.setState({sundaySchedule: schedules[schedule]});
                            break;
                        default:
                            console.error('no schedules available to show');
                    }
                });
                this.setState({gettingData: false});
            } else {
                console.error('Error getting schedule: ', response.errorMessage);
                this.setState({gettingData: false});
            }
        }).catch((error) => {
            console.error(error);
            this.setState({gettingData: false});
        });

    }

    generatePicsRte() {
        const classes = importedStyles;
        const images = this.state.imgUrls;
        const imageCollection = [];

        if (images) {
            Object.keys(images).forEach((image) => {
                imageCollection.push(
                    <div key={image}>
                        <img src={images[image].image} className={classes.img} alt=' '/>
                    </div>
                )
            });
            return imageCollection;
        }
    }

    /*
    Usamos https://github.com/akiran/react-slick para generar el slide
     */
    generatePicsSlider() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            nextArrow: <NextButton/>,
            prevArrow: <PrevButton/>
        };
        const images = this.state.imgUrls;

        if (images) {
            return (
                <div>
                    <div className={importedStyles.imagesTitleContainer}>
                        <PhotoCameraIcon/>
                        <h3>Imágenes</h3>
                    </div>
                    <Slider {...settings}>
                        {this.generatePicsRte()}
                    </Slider>
                </div>
            );
        } else {
            return (<div/>);
        }
    }

    generateFeatureList() {

        let featuresList = [];
        const features = this.state.features;

        if (features && features.length > 0) {
            Object.keys(features).forEach((feature) => {
                featuresList.push(
                    <ListItem key={feature}>
                        <ListItemIcon>
                            <img alt={features[feature].description}
                                 src={"/featureIcons/" + features[feature].logo + ".svg"}
                                 className={importedStylesSass.featureIcon}/>
                        </ListItemIcon>
                        <ListItemText inset primary={features[feature].name}/>
                    </ListItem>
                )
            });
        } else {
            featuresList.push(
                <ListItem key={0}>
                    <ListItemText primary={"Este restaurante no ofrece ningún servicio extra"}/>
                </ListItem>
            )
        }

        return (<List>{featuresList}</List>)
    }

    renderSchedule() {
        let scheduleDinners = [];
        let scheduleLunchs = [];
        if (this.state.schedule) {
            Object.keys(this.state.schedule).forEach((day) => {
                const scheduleDay = this.state.schedule[day];
                const allDay = scheduleDay.allDay;
                let dinnerTime = '-';
                let lunchTime = '-';

                if (!allDay) {
                    if (scheduleDay.lunchStartTime) {
                        let startTime = getFormattedSchedule(scheduleDay.lunchStartTime);
                        let endTime = getFormattedSchedule(scheduleDay.lunchEndTime);
                        lunchTime = startTime + ' - ' + endTime;
                    }

                    if (scheduleDay.dinnerStartTime) {
                        let startTime = getFormattedSchedule(scheduleDay.dinnerStartTime);
                        let endTime = getFormattedSchedule(scheduleDay.dinnerEndTime);
                        dinnerTime = startTime + ' - ' + endTime;
                    }
                    scheduleLunchs.push(
                        <td key={day}>
                            {lunchTime}
                        </td>
                    );
                    scheduleDinners.push(
                        <td key={day}>
                            {dinnerTime}
                        </td>
                    );
                } else {

                    scheduleLunchs.push(
                        <td key={day} rowSpan={2} className={importedStyles.allDayCell}>
                            24h
                        </td>
                    );
                }

            });
        }
        return (
            <div className={importedStyles.scheduleContainer}>
                <table className={importedStyles.scheduleTable} cellSpacing={25}>
                    <tbody>
                    <tr className={importedStyles.scheduleDays}>
                        <th>Lunes</th>
                        <th>Martes</th>
                        <th>Míercoles</th>
                        <th>Jueves</th>
                        <th>Viernes</th>
                        <th>Sábado</th>
                        <th>Domingo</th>
                    </tr>
                    <tr>
                        {scheduleLunchs}
                    </tr>
                    <tr>
                        {scheduleDinners}
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    renderReservationButton = () => {
        if (this.props.restaurantPermissions.reservations) {
            return (
                <Button className={importedStyles.buttonReserve}
                        onClick={() => history.push('/comerEn/reserva/' + this.state.id)}>Reservar</Button>
            );
        } else {
            return (
                <div className={importedStyles.disabledButtonReserveBox}>
                    <Button className={importedStyles.disabledButtonReserve} disabled={true}>Reservar</Button>
                    <Typography variant={"caption"}>
                        Es necesario reservar por teléfono
                    </Typography>
                </div>
            );
        }
    };

    renderRestaurantView() {
        const restaurant = this.state.restaurant;
        if (restaurant) {
            return (
                
                <Card classes={{
                    root: importedStyles.restaurantCard
                }}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="Recipe" className={importedStyles.avatar} src={restaurant.logo}/>
                        }
                        action={this.renderReservationButton()}
                        title={restaurant.name}
                        subheader={restaurant.address + ', ' + restaurant.city}
                    />
                    <CardContent className={importedStyles.restaurantCard}>
                        <div>
                            <Grid container spacing={8} className={importedStyles.dataContainer}>
                                <Grid item xs={12} md={4} className={importedStyles.dataInfoContainer}>
                                    <List>
                                        {this.state.restaurant.description &&
                                        <ListItem>
                                            <ListItemIcon>
                                                <StarIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={this.state.restaurant.description}/>
                                        </ListItem>
                                        }
                                        <ListItem>
                                            <ListItemIcon>
                                                <EuroIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Precio medio: ' + this.state.restaurant.price + '€'}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PhoneIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={this.state.restaurant.phone}
                                                secondary={this.state.restaurant.phone2 ? this.state.restaurant.phone2 : ''}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {this.renderSchedule()}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DetailCard
                                        title="Servicios"
                                        avatar={<RoomServiceIcon/>}
                                        data={this.generateFeatureList()}
                                    />
                                </Grid>
                            </Grid>
                            {this.generatePicsSlider()}
                        </div>
                    </CardContent>
                </Card>
            );
        } else {
            return (<p>Problema al cargar los datos, inténtelo de nuevo</p>)
        }
    }

    render() {
        return (
            <div>
                <NavbarLanding style={{marginBottom: 10}}/>
                <div className={importedStyles.detailRestaurant}>
                    {this.state.loadingRestaurant ? <CircularProgress className={importedStyles.circularProgress}
                                                                      size={120}/> : this.renderRestaurantView()}
                </div>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({restaurantReducer}) => {
    return ({
        restaurantPermissions: restaurantReducer.permissions,
    });
};

export default connect(mapStateToProps)(DetailRestaurantScreen);