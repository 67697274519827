import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import connect from "react-redux/es/connect/connect";
import history from "../../helpers/history";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PeopleIcon from '@material-ui/icons/Wc';
import CloseIcon from '@material-ui/icons/Close';
import ChildIcon from '@material-ui/icons/ChildCare';
import UserIcon from '@material-ui/icons/Person';
import MessageIcon from '@material-ui/icons/Announcement';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Slide from "@material-ui/core/Slide/Slide";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import CardActions from "@material-ui/core/CardActions/CardActions";
import importedStyle from "./reservationStyle";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import List from "@material-ui/core/List/List";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import {formatDate} from "../../helpers/dateUtils";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import {DatePicker} from 'material-ui-pickers';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import ConfirmedReservationCard from "./components/ConfirmedReservationCard";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LaunchIcon from '@material-ui/icons/Launch';

class reservationScreen extends React.Component {

    state = {
        sendingData: false,
        gettingData: false,
        selectedDay: new Date(),
        pendingReservations: [],
        rejectedReservations: [],
        reservations: [],
        filterActivated: false,
        deleteConfirmationSnack: false,
        deleteErrorSnack: false,
        createConfirmationSnack: false,
        createErrorSnack: false,
        modalAcceptReservation: false,
        modalRejectReservation: false,
        viewRejectedReservations: false,
        rejectedComment: null,
        actualTab: 0
    };

    componentDidMount() {
        // Si no tiene permisos...
        if (!this.props.restaurantPermissions.reservations) {
            history.push('/forbiddenScreen');
        } else {
            this.getRestaurantReservations(this.props.idRestaurant);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.restaurantPermissions.reservations) {
            history.push('/forbiddenScreen');
        } else if (this.props.idRestaurant !== nextProps.idRestaurant){
            this.getRestaurantReservations(nextProps.idRestaurant);
        }
    }

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    getRestaurantReservations(idRestaurant) {
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'reservations/getRestaurantReservations', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    reservations: response.reservations,
                    pendingReservations: response.pendingReservations,
                    rejectedReservations: response.rejectedReservations,
                    gettingData: false
                });
            } else {
                throw Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error getting reservations: ', error);
            this.setState({gettingData: false});
        });

    }

    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    acceptReservation() {
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'reservations/acceptReservation', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                reservationUuid: this.state.selectedReservation.uuid
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    reservations: response.reservations,
                    pendingReservations: response.pendingReservations,
                    rejectedReservations: response.rejectedReservations,
                    sendingData: false,
                    modalAcceptReservation: false,
                });
                this.props.dispatch(showSnack('Reserva aceptada con éxito', snackTypes.success));
            } else {
                throw Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error accepting the reservation: ', error);
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
            this.setState({sendingData: false, modalAcceptReservation: false});
        });
    }

    rejectReservation() {
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'reservations/rejectReservation', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                reservationUuid: this.state.selectedReservation.uuid,
                rejectedComment: this.state.rejectedComment
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    pendingReservations: response.pendingReservations,
                    rejectedReservations: response.rejectedReservations,
                    sendingData: false,
                    modalRejectReservation: false,
                });
                this.props.dispatch(showSnack('Reserva rechazada, se notificará al usuario', snackTypes.success));
            } else {
                throw Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error rejecting the reservation: ', error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    renderPendingReservations() {
        const {classes} = this.props;
        const reservations = this.state.pendingReservations;
        let listItems = [];

        if (reservations && reservations.length > 0) {
            Object.keys(reservations).forEach((reservation) => {
                listItems.push(
                    <ListItem key={reservation}>
                        <Card className={classes.pendingReservationBox}>
                            <CardContent>
                                <List>
                                    <ListItem key={reservation}>
                                        <ListItemIcon>
                                            <UserIcon/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={reservations[reservation].customer.name}
                                            secondary={reservations[reservation].customer.phone}
                                        />
                                    </ListItem>
                                    <ListItem key={reservation}>
                                        <ListItemIcon>
                                            <ScheduleIcon/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={formatDate(reservations[reservation].date)}
                                            secondary={reservations[reservation].hour}
                                        />
                                    </ListItem>
                                    <ListItem key={reservation}>
                                        <ListItemIcon>
                                            <PeopleIcon/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={reservations[reservation].adults}
                                            secondary={'Adultos'}
                                        />
                                    </ListItem>
                                    {/*<ListItem key={reservation}>*/}
                                    {/*<ListItemIcon>*/}
                                    {/*<ChildIcon/>*/}
                                    {/*</ListItemIcon>*/}
                                    {/*<ListItemText*/}
                                    {/*primary={reservations[reservation].childs}*/}
                                    {/*secondary={'Niños'}*/}
                                    {/*/>*/}
                                    {/*</ListItem>*/}
                                    <ListItem key={reservation}>
                                        <ListItemIcon>
                                            <MessageIcon/>
                                        </ListItemIcon>
                                        {reservations[reservation].comment ?
                                            <ListItemText
                                                primary={
                                                    <Typography variant={"subtitle2"}>
                                                        {reservations[reservation].comment}
                                                    </Typography>
                                                }
                                            />
                                            :
                                            <ListItemText
                                                //primary={'---'}
                                                secondary={'Sin peticiones especiales'}
                                            />
                                        }
                                    </ListItem>
                                </List>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Button
                                    size={"small"}
                                    color={"secondary"}
                                    disabled={this.state.sendingData}
                                    onClick={() => this.setState({
                                        modalRejectReservation: true,
                                        selectedReservation: reservations[reservation]
                                    })}
                                >
                                    Rechazar
                                </Button>
                                <Button
                                    size={"small"}
                                    color={"primary"}
                                    variant={"contained"}
                                    disabled={this.state.sendingData}
                                    onClick={() => this.setState({
                                        modalAcceptReservation: true,
                                        selectedReservation: reservations[reservation]
                                    })}
                                >
                                    Aceptar
                                </Button>
                            </CardActions>
                        </Card>
                    </ListItem>
                );
            });

        } else {

            listItems.push(
                <ListItem key={0}>
                    <Card className={classes.pendingReservationBox}>
                        <CardContent>
                            <ListItem className={classes.list} key={1}>
                                <ListItemText secondary='No hay reservas pendientes'/>
                            </ListItem>
                        </CardContent>
                    </Card>
                </ListItem>
            )
        }

        return (
            <List className={classes.list}>
                {listItems}
            </List>
        )
    }

    renderConfirmedReservations() {
        const {classes} = this.props;
        const reservations = this.state.reservations;
        let listItems = [];

        if (reservations && reservations.length > 0) {

            Object.keys(reservations).forEach((reservation) => {
                let reservationDate = formatDate(new Date(reservations[reservation].date));
                let selectedDate = formatDate(this.state.selectedDay);
                if (reservationDate === selectedDate) {
                    listItems.push(
                        <ListItem key={reservation}>
                            <ConfirmedReservationCard
                                reservation={reservations[reservation]}
                            />
                        </ListItem>
                    );
                }
            });

            //If after filter by date there is no reservations, inform it
            if (!listItems.length > 0) {
                listItems.push(
                    <ListItem key={0}>
                        <Card className={classes.confirmedReservationBox}>
                            <CardContent>
                                <ListItem className={classes.list} key={1}>
                                    <ListItemText secondary='Sin reservas'/>
                                </ListItem>
                            </CardContent>
                        </Card>
                    </ListItem>
                )
            }

        } else {
            listItems.push(
                <ListItem key={0}>
                    <Card className={classes.confirmedReservationBox}>
                        <CardContent>
                            <ListItem className={classes.list} key={1}>
                                <ListItemText secondary='Sin reservas'/>
                            </ListItem>
                        </CardContent>
                    </Card>
                </ListItem>
            )
        }

        return (
            <List className={classes.list}>
                {listItems}
            </List>
        )
    }

    renderRejectedReservations() {
        const {classes} = this.props;
        const reservations = this.state.rejectedReservations;
        let listItems = [];

        if (reservations && reservations.length > 0) {
            Object.keys(reservations).forEach((reservation) => {
                let reservationDate = formatDate(new Date(reservations[reservation].date));
                let selectedDate = formatDate(this.state.selectedDay);
                if (reservationDate === selectedDate) {
                    listItems.push(
                        <ListItem key={reservation}>
                            <Card className={classes.rejectedReservationBox}>
                                <CardContent>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <UserIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={reservations[reservation].customer.name}
                                                secondary={reservations[reservation].customer.phone}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ScheduleIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={formatDate(reservations[reservation].date)}
                                                secondary={reservations[reservation].hour}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PeopleIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={reservations[reservation].adults}
                                                secondary={'Adultos'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ChildIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={reservations[reservation].childs}
                                                secondary={'Niños'}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <MessageIcon/>
                                            </ListItemIcon>
                                            {reservations[reservation].comment ?
                                                <ListItemText
                                                    primary={
                                                        <Typography variant={"subtitle2"}>
                                                            {reservations[reservation].comment}
                                                        </Typography>
                                                    }
                                                />
                                                :
                                                <ListItemText
                                                    //primary={'---'}
                                                    secondary={'Sin peticiones especiales'}
                                                />
                                            }
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={'Motivo del rechazo:'}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CloseIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography variant={"subtitle2"}>
                                                        {reservations[reservation].rejectedComment ? reservations[reservation].rejectedComment : 'Sin definir'}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </ListItem>
                    );
                }

            });

            //If after filter by date there is no reservations, inform it
            if (!listItems.length > 0) {
                listItems.push(
                    <ListItem key={0}>
                        <Card className={classes.rejectedReservationBox}>
                            <CardContent>
                                <ListItem className={classes.list} key={1}>
                                    <ListItemText secondary='Sin reservas'/>
                                </ListItem>
                            </CardContent>
                        </Card>
                    </ListItem>
                )
            }

        } else {
            listItems.push(
                <ListItem key={0}>
                    <Card className={classes.rejectedReservationBox}>
                        <CardContent>
                            <ListItem className={classes.list} key={1}>
                                <ListItemText secondary='Sin reservas'/>
                            </ListItem>
                        </CardContent>
                    </Card>
                </ListItem>
            )
        }

        return (
            <List className={classes.list}>
                {listItems}
            </List>
        )
    }

    handleReservationsViewChange = () => {
        this.setState((prevState) => ({
            viewRejectedReservations: !prevState.viewRejectedReservations
        }));
    };

    handleTabChange = (event, value) => {
        this.setState({actualTab: value, viewRejectedReservations: value});
    };

    renderTabs() {
        let {classes} = this.props;
        return (
            <Tabs
                value={this.state.actualTab}
                onChange={this.handleTabChange}
                variant="centered"
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabsContainer}
            >
                <Tab label="Confirmadas"/>
                <Tab label="Rechazadas"/>
            </Tabs>
        )
    }


    render() {
        const classes = this.props.classes;

        return (
            <div style={{display: 'flex'}}>
                <MiniDrawer title={"Reservas"} idRestaurant={this.props.idRestaurant}/>

                {this.state.gettingData ?
                    <div className={classes.chargingContent}><CircularProgress/></div>
                    :
                    <React.Fragment>
                        <div className={classes.content}>
                            <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                spacing={32}
                                alignItems="stretch"
                                style={{marginTop: 15}}
                            >

                                <Grid item xs={12} md={6}>
                                    <Typography variant={"h5"}>
                                        Reservas pendientes
                                    </Typography>
                                    <div className={classes.parentBox}>
                                        {this.renderPendingReservations()}
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className={classes.datePicker}>
                                        <DatePicker
                                            keyboard
                                            variant="outlined"
                                            placeholder={'Ej: 20/02/2019'}
                                            format={'DD/MM/YYYY'}
                                            invalidDateMessage={'Formato de fecha inválido'}
                                            maxDateMessage={'Fecha fuera de rango'}
                                            minDateMessage={'Fecha fuera de rango'}
                                            label="Seleccionar dia"
                                            showTodayButton={true}
                                            todayLabel={'Hoy'}
                                            cancelLabel={'Cancelar'}
                                            okLabel={'Aceptar'}
                                            value={this.state.selectedDay}
                                            onChange={(date) => this.setState({selectedDay: new Date(date)})}
                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        />
                                        <Button
                                            variant={"outlined"}
                                            size={"small"}
                                            onClick={() => this.setState({selectedDay: new Date()})}
                                            disabled={formatDate(this.state.selectedDay) === formatDate(new Date())}
                                        >
                                            Ver dia actual
                                        </Button>
                                    </div>
                                    {this.renderTabs()}
                                    <div className={classes.parentBox}>
                                        {this.state.viewRejectedReservations ? this.renderRejectedReservations() : this.renderConfirmedReservations()}
                                    </div>
                                </Grid>

                            </Grid>
                            <div className={classes.iframeInfoWrapper}>

                                <Accordion className={classes.iframeInfo}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <HelpOutlineIcon/>
                                        <Typography className={classes.iframeText}>
                                            Pincha
                                            <a href={"/app/reserva/" + this.props.slugRestaurant} target="_blank" className={classes.iframeLink}>aquí</a>
                                            para acceder al enlace de reservas para tus clientes.
                                            </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <React.Fragment>
                                            <div >
                                                <Typography style={{wordWrap: "break-word"}}>
                                                    Para introducir el sistema de reservas en tu página web introduce el
                                                    siguiente componente en el html de tu página:
                                                    <div className={classes.iframeIframeText}>
                                                        <span >&lt;iframe src="https://quierocomeren.com/comerEn/reservationFrame/{this.props.slugRestaurant}" style="width:100%;height:600px;"&gt;&lt;/iframe&gt;</span>
                                                    </div>
                                                </Typography>
                                            </div>
                                        </React.Fragment>
                                    </AccordionDetails>
                                </Accordion>
                                {/*<span className={classes.iframeText}>Inserte este código en su web para usar el módulo de reservas:</span>*/}
                            </div>


                            {/* accept reservation Modal */}
                            <Dialog
                                open={this.state.modalAcceptReservation}
                                onClose={() => this.setState({modalAcceptReservation: false})}
                                aria-labelledby="form-dialog-title"
                                scroll={"paper"}
                            >
                                <DialogTitle id="form-dialog-title">Confirmar reserva</DialogTitle>
                                <DialogContent>

                                    {this.state.selectedReservation &&
                                    <Typography>
                                        ¿Seguro que quieres confirmar la reserva
                                        de {this.state.selectedReservation.customer.name} para
                                        el {formatDate(this.state.selectedReservation.date)} a
                                        las {this.state.selectedReservation.hour}h?
                                    </Typography>
                                    }

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        variant={"text"}
                                        color="secondary"
                                        className={classes.submit}
                                        onClick={() => this.setState({modalAcceptReservation: false})}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.acceptReservation()}
                                    >
                                        Confirmar
                                        {this.state.sendingData &&
                                        <CircularProgress
                                            size={25}
                                            className={classes.chargingAnimation}
                                        />
                                        }
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {/* reject reservation Modal */}
                            <Dialog
                                open={this.state.modalRejectReservation}
                                onClose={() => this.setState({modalRejectReservation: false})}
                                aria-labelledby="form-dialog-title"
                                scroll={"paper"}
                            >
                                <DialogTitle id="form-dialog-title">Rechazar reserva</DialogTitle>
                                <DialogContent>

                                    {this.state.selectedReservation &&
                                    <Typography>
                                        ¿Seguro que quieres rechazar la reserva
                                        de {this.state.selectedReservation.customer.name} para
                                        el {formatDate(this.state.selectedReservation.date)} a
                                        las {this.state.selectedReservation.hour}h?
                                    </Typography>
                                    }
                                    <br/>
                                    <Typography>
                                        Introduce un breve comentario para el cliente indicando el motivo:
                                    </Typography>
                                    <TextField
                                        label="Motivo de la cancelación"
                                        value={this.state.rejectedComment}
                                        onChange={(e) => this.setState({rejectedComment: e.target.value})}
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                    />

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        variant={"text"}
                                        color="secondary"
                                        className={classes.submit}
                                        onClick={() => this.setState({modalRejectReservation: false})}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.rejectReservation()}
                                    >
                                        Confirmar
                                        {this.state.sendingData &&
                                        <CircularProgress
                                            size={25}
                                            className={classes.chargingAnimation}
                                        />
                                        }
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </div>

                    </React.Fragment>

                }
            </div>
        );
    }
}

reservationScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        user: authReducer.credentials[0],
        idRestaurant: restaurantReducer.idRestaurant,
        slugRestaurant: restaurantReducer.slugRestaurant,
        nameRestaurant: restaurantReducer.nameRestaurant,
        restaurantPermissions: restaurantReducer.permissions
    });
};

export default connect(mapStateToProps)(withStyles(importedStyle(), {withTheme: true})(reservationScreen));


