import {LOGIN_SUCCESS, LOGOUT, UPDATE_USER} from "../../constants/action-types";


const initialState = {
    credentials : [{
        association: 0
    }],
    auth: false,
};

const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOGIN_SUCCESS:
            return {state, credentials: [Object.assign(state, action.user)], auth: true};
        case LOGOUT:
            return {initialState};
        case UPDATE_USER:
            return{state, credentials: [Object.assign(state, action.updatedUser)], auth: true};
        default:
            return state;
    }
};


export default authReducer;