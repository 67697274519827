import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

export default function importedStyles() {
    return {
        menuItem: {
            display: 'flex',
            alignItems: 'center'
        },
        newQuestionsContainer: {
            marginTop: 15,
            backgroundColor: '#eaeaea',
            borderRadius: 15,
            paddingLeft: 10,
            paddingTop: 5,
            paddingBottom: 5,
        },
        newQuestionsBox: {
            borderLeft: '4px solid #3f51b5'
        },
        newPollTitleContainer: {
            marginTop: 50,
            marginBottom: 50,
            [theme.breakpoints.down('md')]: {
                marginBottom: 25,
            }
        },
        redoIconBox: {
            marginTop: 25,
            marginBottom: 10
        },
        redoIcon: {
            [theme.breakpoints.down('md')]: {
                transform: 'rotate(45deg)'
            }
        },
        addQuestionsBox: {
            backgroundColor: '#eaeaea',
            borderRadius: 20,
            padding: '20px !important'
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 50,
        },
        dialog: {
            minWidth: 500
        },
        chargingContent: {
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 300,
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        button: {
            margin: theme.spacing(3),
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        chargingAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        timeSpendBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
}