import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import ReactChartkick from 'react-chartkick'
import Chart from 'chart.js'
import Paper from "@material-ui/core/Paper/Paper";
import Card from "@material-ui/core/Card/Card";
import MobileStepper from "@material-ui/core/MobileStepper/MobileStepper";
import KeyboardArrowLeft from "@material-ui/core/internal/svg-icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/core/internal/svg-icons/KeyboardArrowRight";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import DoneIcon from '@material-ui/icons/Done';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import PollIcon from '@material-ui/icons/Assignment';
import Chip from "@material-ui/core/Chip/Chip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Picker from 'react-mobile-picker-scroll';
import Rating from "react-rating";
import {Transition} from "react-spring";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import importedStyles from "./frontPollsStyle";
import importedStylesSass from "./frontPollsScreen.module.sass";
import Snack from "../../components/Snack/Snack";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {getMinutesDifference} from "../../helpers/dateUtils";
import {change_active_poll, change_current_restaurant} from "./frontPollsActions";
import {IconButton, Tooltip} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ReactCodeInput from "react-code-input";
import Grid from "@material-ui/core/Grid";
import finishedPoll from "../../../src/rsc/animation/finishedPoll";
import Lottie from 'react-lottie'
import screenfull from "screenfull";


ReactChartkick.addAdapter(Chart);

class frontPollsScreen extends React.Component {

    state = {
        restaurantPolls: {},
        restaurantLogo: '',
        restaurantName: '',
        poll: {},
        steps: 0,
        activeStep: 0,
        questions: [],
        existPoll: false,
        startPoll: false,
        answers: {},
        valueGroups: {},
        showFingers: false,
        questionAnswered: false,
        gettingData: false,
        pollStarted: false,
        startTime: null,
        endTime: null,
        startedPollRecordUuid: '',
        isPollSelected: false,
        securityPinModal: false,
        previewMode: false,
    };

    enableFullScreen() {
        if (screenfull.enabled) {
            screenfull.toggle().then((data) => {
           //
            }).catch(exception => {
                console.error('Error fullscreen: ', exception);
            });
        }
    }


    componentDidMount() {
        this.getRestaurantLogo();

        const pollUuid = this.props.match.params.pollUuid;
        //If there is a param pollUuid, you came from comerenManager, so directly get that poll and set a ´preview mode´ flag
        if (pollUuid) {
            /*
                If there is an actual poll (check it by checking if uuid exists) on redux and its the same uuid as pollUuid on url,
                 copy it on 'poll' object at state and set existPoll & isPollSelected flags to true
                 If there is not a poll on redux, or it's different from pollUuid at url, then fetch poll data
             */
            // this.setState({previewMode: true});
            // if (this.props.actualPoll.uuid && (this.props.actualPoll.uuid === this.props.match.params.pollUuid)) {
            //     this.setState({poll: this.props.actualPoll, existPoll: true, isPollSelected: true})
            // } else {
            //     this.getPollData();
            // }
            this.getPollData();

        } else {
            /*
                If there is an actual poll (check it by checking if uuid exists) on redux, then copy it on 'poll' object at state
                and set existPoll & isPollSelected flags to true
             */

            this.setState({previewMode: true});

            this.getRestaurantPolls();
        }


    }

    checkIfRestaurantChanged = () => {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        const lastRestaurantOnRedux = this.props.currentRestaurant;

        let restaurantChanged = lastRestaurantOnRedux !== restaurantUuid;

        if (restaurantChanged) {
            //If restaurant changed, then save current restaurant uuid on redux
            this.props.dispatch(change_current_restaurant(restaurantUuid));
        }

        return restaurantChanged;
    };

    /* HANDLE STEPS NAVIGATION */
    handleNext = () => {
        if ((this.state.activeStep + 1) === 1 && !this.state.startTime) {
            this.setState(state => ({
                activeStep: state.activeStep + 1,
                questionAnswered: false,
                startTime: new Date(),
            }));

            if (!this.state.previewMode) {
                //Only send start poll record if you're not on previewMode
                this.sendStartPollRecord();
            }

        } else {
            this.setState(state => ({
                activeStep: state.activeStep + 1,
                questionAnswered: false
            }));
        }

    };
    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };
    handleReset = () => {
        this.setState({
            activeStep: 0,
            startTime: null,
            endTime: null
        });
    };
    /////////////////////////////

    /*  HANDLE ANSWER INPUTS  */
    handleAnswerChange = (booleanAnswer, questionId) => {

        let answersArray = this.state.answers;
        answersArray[questionId] = {
            'answer': booleanAnswer
        };

        this.setState({[questionId]: booleanAnswer, answers: answersArray, questionAnswered: true});

        //Pass to next question (if its not the last one) with a little delay to improve visual performance
        if (this.state.activeStep !== this.state.poll.questions.length - 1) {
            setTimeout(() => {
                    this.handleNext();
                }, 250
            );
        }


    };

    handleRate(rating, questionId) {
        let answersArray = this.state.answers;

        answersArray[questionId] = {
            'answer': rating
        };
        this.setState({[questionId]: rating, answers: answersArray, questionAnswered: true});
    };

    handleChipClick = (answer, questionId) => {
        let answersArray = this.state.answers;
        answersArray[questionId] = {
            'answer': answer
        };
        this.setState({[questionId]: answer, answers: answersArray, questionAnswered: true});

        //Pass to next question with a little delay to improve visual performance
        if (this.state.activeStep !== this.state.poll.questions.length - 1) {
            setTimeout(() => {
                    this.handleNext();
                }, 250
            );
        }
    };

    handlePickerChange = (name, value, questionId) => {

        let answersArray = this.state.answers;

        answersArray[questionId] = {
            'answer': value
        };
        this.setState({[questionId]: value, answers: answersArray, questionAnswered: true});
        this.setState(({valueGroups}) => ({
            valueGroups: {
                ...valueGroups,
                [name]: value
            }
        }));

    };
    /////////////////////////////

    /* FETCH RESTAURANT DATA */
    getRestaurantLogo() {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'restaurants/getRestaurantLogo', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: restaurantUuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    gettingData: false,
                    restaurantLogo: response.logo,
                    restaurantName: response.restaurantName
                });
            } else {
                throw new Error('Error retrieving logo from restaurant');
            }
        }).catch((error) => {
            this.setState({gettingData: false});
            console.log(error);
        });
    }

    getRestaurantPolls() {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'restaurants/getRestaurantPolls', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: restaurantUuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    gettingData: false,
                    restaurantPolls: response.polls
                });

            } else {
                throw new Error('Error retrieving polls from restaurant');
            }
        }).catch((error) => {
            this.setState({gettingData: false});
            console.log(error);
        });
    }

    getPollData() {
        const pollUuid = this.props.match.params.pollUuid;
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'polls/getPollData', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    idPoll: pollUuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    poll: response.poll,
                    questions: response.poll.questions,
                    existPoll: true,
                    isPollSelected: true,
                    steps: response.poll.questions.length,
                    gettingData: false
                });
                this.props.dispatch(change_active_poll(response));
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            this.setState({gettingData: false});
            console.log(error);
        });

    }

    /////////////////////////////

    /* POLL SELECTOR LOGICS*/
    handlePollSelection(selectedPoll) {
        this.props.dispatch(change_active_poll(selectedPoll));
        this.setState({poll: selectedPoll, isPollSelected: true, existPoll: true});
    }

    renderPollSelector() {
        let polls = this.state.restaurantPolls;
        let listItems = [];
        Object.keys(polls).forEach((key) => {
            let poll = polls[key];
            listItems.push(
                <Chip key={key} label={poll.title} onClick={() => this.handlePollSelection(poll)}
                      className={importedStylesSass.pollChip}/>
            );
        });

        return (
            <div>
                <Typography variant={'h6'} className={importedStylesSass.pollSelectorTitle}>
                    Elige que encuesta deseas mostrar
                </Typography>
                <Card className={importedStylesSass.card}>
                    <CardContent className={importedStylesSass.pollSelectorCardContent}>
                        {listItems}
                    </CardContent>
                </Card>
            </div>
        )
    }

    renderFullScreenIconButton() {
        return (
            <div className={importedStylesSass.fullscreenButton}>
                <IconButton onClick={this.enableFullScreen}>
                    <FullScreenIcon/>
                </IconButton>
            </div>
        )
    }

    renderPollSelectorTriggerButton() {
        const pollUuid = this.props.match.params.pollUuid;

        // If there is a pollUuid on url, you came from comerenManager, so this is a preview, not rendering the change poll button.
        if (pollUuid) {
            // do nothing
        } else {
            return (
                <div className={importedStylesSass.pollSelectorButton}>
                    <Tooltip title={'Cambiar encuesta'} placement={"left"}>
                        <IconButton onClick={() => this.setState({securityPinModal: true})}>
                            <PollIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }

    }

    async checkPinIsValid() {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        const introducedPin = this.state.introducedPin;
        let pinIsValid = false;

        try {
            this.setState({gettingData: true});
            const response = await fetch(apiBaseUrl + 'restaurants/checkPinIsValid', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        uuidRestaurant: restaurantUuid,
                        introducedPin: introducedPin,
                    }),
                }
            );
            const jsonData = await response.json();

            if (!jsonData.error) {
                this.setState({gettingData: false});
                pinIsValid = jsonData.pinIsValid
            } else {
                throw new Error('Error checking security pin');
            }
        } catch (error) {
            console.error(error);
            this.setState({gettingData: false});
        }

        //Should return false if pin is not valid
        return pinIsValid;
    };

    cleanReduxAndRefresh = () => {
        this.setState({introducedPin: ''});

        this.checkPinIsValid().then((pinIsValid) => {
            if (pinIsValid) {
                //Set poll object to empty one on redux and refresh page to show poll selector
                this.setState({securityPinModal: false});
                this.props.dispatch(change_active_poll({}));
                document.location.reload();
            } else {
                // Close pin input modal and show snack error
                this.setState({securityPinModal: false});
                this.props.dispatch(showSnack('El PIN introducido no es correcto', 'error'));
                console.error('PIN no válido');
            }
        });


    };
    /////////////////////////////

    /* RENDER POLL CONTENTS */
    renderRestaurantInfo() {

        return (
            <Transition
                items={true}
                from={{opacity: 0}}
                enter={{opacity: 1}}
                leave={{opacity: 0}}>
                {show =>
                    show && (props =>
                        <div style={props} className={importedStylesSass.restaurantInfoContent}>
                            <Avatar src={this.state.restaurantLogo} className={importedStylesSass.restaurantLogo}/>
                            <Typography variant={'h5'} className={importedStylesSass.restaurantName}>
                                {this.state.restaurantName}
                            </Typography>
                        </div>)
                }
            </Transition>
        )
    }

    renderContent() {
        if (this.state.isPollSelected) {
            if (this.state.startPoll) {
                return (
                    <React.Fragment>
                        {this.renderPollContent()}
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        {this.renderWelcomeScreen()}
                    </React.Fragment>
                )
            }
        } else {
            return (
                <React.Fragment>
                    {this.renderPollSelector()}
                </React.Fragment>
            )
        }

    }

    renderWelcomeScreen() {
        let {classes} = this.props;

        if (this.state.existPoll) {
            return (
                <Paper>
                    <Transition
                        items={true}
                        from={{opacity: 0, fontSize: 10}}
                        enter={{opacity: 1, fontSize: 15}}
                        leave={{opacity: 0, fontSize: 10}}>
                        {show =>
                            show && (props =>
                                <div style={props}>
                                    <div className={importedStylesSass.card}>
                                        <Typography component="h2" variant="display1" align="center" color={"primary"}>
                                            ¿Puedes ayudarnos a mejorar?
                                        </Typography>
                                        <Typography component="h3" variant="headline" align="center" color="secondary">
                                            ¡Prometemos no ser muy pesados!
                                        </Typography>

                                        <Button size={"large"} color={"primary"} variant={"outlined"}
                                                onClick={() => this.setState({startPoll: true})}>
                                            Empezar
                                        </Button>
                                    </div>
                                </div>)
                        }
                    </Transition>
                </Paper>
            )
        } else {
            return (
                <Card className={classes.error404}>
                    <CardContent>
                        <Typography variant={"subtitle1"}>
                            La encuesta seleccionada no existe o no se encuentran sus datos
                        </Typography>
                        <br/>
                        <Typography variant={"h1"} className={classes.error404text}>
                            404
                        </Typography>
                        <br/>
                        🤔🤔🤔🤔🤔🤔<span role={'img'} aria-label={':('}>
                            Disculpe las molestias 🤔🤔🤔🤔🤔🤔😥
                        </span>
                    </CardContent>
                </Card>
            )
        }


    }

    renderAnswerType(question) {

        let type = question.type;
        let questionId = question.uuid;

        switch (parseInt(type, 0)) {

            case 1:
                return (
                    <Rating
                        stop={5}
                        onClick={(event) => this.handleRate(event, questionId)}
                        fractions={2}
                        initialRating={this.state.answers[questionId] ? this.state.answers[questionId].answer : null}
                        emptySymbol={<StarBorderIcon fontSize={"large"} className={importedStylesSass.starSelector}/>}
                        fullSymbol={<StarIcon color={"primary"} fontSize={"large"}
                                              className={importedStylesSass.starSelector}/>}
                    />
                );

            case 2:
                return (
                    <React.Fragment>
                        <Button
                            className={importedStylesSass.answerChip}
                            variant={this.state.answers[questionId] && (this.state.answers[questionId].answer === true) ? "contained" : "outlined"}
                            color={this.state.answers[questionId] && (this.state.answers[questionId].answer === true) ? "primary" : "default"}
                            onClick={() => this.handleAnswerChange(true, questionId)}
                        >
                            {this.state.answers[questionId] && (this.state.answers[questionId].answer === true) ?
                                <DoneIcon className={importedStylesSass.checkedAnswerIcon}/> : null}
                            Sí
                        </Button>

                        <Button
                            className={importedStylesSass.answerChip}
                            variant={this.state.answers[questionId] && (this.state.answers[questionId].answer === false) ? "contained" : "outlined"}
                            color={this.state.answers[questionId] && (this.state.answers[questionId].answer === false) ? "primary" : "default"}
                            onClick={() => this.handleAnswerChange(false, questionId)}
                        >
                            {this.state.answers[questionId] && (this.state.answers[questionId].answer === false) ?
                                <DoneIcon className={importedStylesSass.checkedAnswerIcon}/> : null}
                            No
                        </Button>
                    </React.Fragment>
                    /*<FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup
                            aria-label="True/false"
                            className={classes.group}
                            value={this.state.answers[questionId] ? this.state.answers[questionId].answer : null}
                            onChange={(event) => this.handleAnswerChange(event, questionId)}
                        >
                            <FormControlLabel value='true' control={<Radio/>} label="Sí"/>
                            <FormControlLabel value='false' control={<Radio/>} label="No"/>
                        </RadioGroup>
                    </FormControl>
                    */
                );

            case 3:
                let minVal = question.minVal;
                let maxVal = question.maxVal;
                const optionGroups = {number: []};
                const valueGroups = this.state.valueGroups;
                for (let i = minVal; i <= maxVal; i++) {
                    optionGroups.number.push(i);
                }
                return (
                    <Picker
                        optionGroups={optionGroups}
                        valueGroups={valueGroups}
                        height={150}
                        itemHeight={45}
                        className={importedStylesSass.numericPicker}
                        onChange={(name, value) => this.handlePickerChange(name, value, questionId)}
                    />
                );

            case 4:
                let answers = question.possibleAnswers.split(', ');
                const keys = Object.keys(answers);
                let chips = [];

                for (const key of keys) {
                    let chipLabel = answers[key];
                    chips.push(
                        /*
                        <Chip
                            key={key}
                            label={chipLabel}
                            onClick={() => this.handleChipClick(chipLabel, questionId)}
                            color={this.state.answers[questionId] && (this.state.answers[questionId].answer === chipLabel) ? "primary" : "default"}
                            icon={this.state.answers[questionId] && (this.state.answers[questionId].answer === chipLabel) ? <DoneIcon/> : null}
                            className={importedStylesSass.answerChip}
                         />
                        */
                        <Button
                            key={key}
                            className={importedStylesSass.answerChip}
                            variant={this.state.answers[questionId] && (this.state.answers[questionId].answer === chipLabel) ? "contained" : "outlined"}
                            color={this.state.answers[questionId] && (this.state.answers[questionId].answer === chipLabel) ? "primary" : "default"}
                            onClick={() => this.handleChipClick(chipLabel, questionId)}
                        >
                            {this.state.answers[questionId] && (this.state.answers[questionId].answer === chipLabel) ?
                                <DoneIcon className={importedStylesSass.checkedAnswerIcon}/> : null}
                            {chipLabel}
                        </Button>
                    )
                }
                return (
                    <div id={'chipsContainer'} className={importedStylesSass.chipsContainer}>
                        {chips}
                    </div>
                );

            default:
                break;
        }//fin de switch
    }

    renderStepContent(question) {
        const {classes} = this.props;

        if (question) {
            if (question.type === 4) {
                return (
                    <div className={classes.answerBox}>
                        <Typography variant="h5" className={importedStylesSass.questionTitle} gutterBottom>
                            {question.title}
                        </Typography>
                        {this.renderAnswerType(question)}
                    </div>
                )
            } else {
                return (
                    <div className={importedStylesSass.answersContainer}>
                        <Typography variant="h5" className={importedStylesSass.questionTitle} gutterBottom>
                            {question.title}
                        </Typography>
                        {this.renderAnswerType(question)}
                    </div>
                )
            }
        }
    }

    renderPollContent() {
        let questions = this.state.poll.questions;
        let steps = questions.length;
        let {activeStep, showFingers} = this.state;

        if (activeStep === questions.length && !showFingers) {
            this.setState({showFingers: true});
        }

        return (
            <React.Fragment>
                {activeStep === questions.length ?
                    <Card className={importedStylesSass.card}>
                        <div style={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            flexWrap: "wrap",
                            justifyContent: 'space-evenly'
                        }}>
                            <Transition
                                items={this.state.showFingers}
                                from={{opacity: 0, fontSize: 10}}
                                enter={{opacity: 1, fontSize: 15}}
                                leave={{opacity: 0, fontSize: 10}}>
                                {show =>
                                    show && (props =>
                                        <div style={props} className={importedStylesSass.finishedPollClaimBox}>
                                            <Typography variant="h3" gutterBottom>
                                                ¡Gracias por realizar la encuesta!
                                            </Typography>
                                            <Typography variant="h6">
                                                Tu opinión nos ayuda a mejorar cada día nuestro servicio
                                                para que nuestros clientes siempre se sientan como en casa.
                                            </Typography>
                                            {this.renderFinishedPollAnimation()}
                                        </div>)
                                }
                            </Transition>
                        </div>
                    </Card>
                    :
                    <Card className={importedStylesSass.card}>
                        {this.state.existPoll && this.renderStepContent(questions[activeStep])}
                        <MobileStepper
                            classes={{
                                progress: importedStylesSass.stepperProgress
                            }}
                            variant="progress"
                            steps={steps}
                            position={"static"}
                            activeStep={this.state.activeStep}
                            nextButton={
                                <Button size="small" onClick={this.handleNext}
                                        style={{fontSize: 14}}
                                        className={this.state.activeStep === questions.length - 1 && importedStylesSass.invisibleButton}
                                        disabled={((!questions[activeStep].skippable && !this.state.questionAnswered) || activeStep === questions.length - 1)}>
                                    <span style={{fontSize: '14px !important'}}>Seguir</span>
                                    <KeyboardArrowRight/>
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={this.handleBack}
                                        className={this.state.activeStep === 0 && importedStylesSass.invisibleButton}
                                        disabled={this.state.activeStep === 0}>
                                    <KeyboardArrowLeft/>
                                    <span style={{fontSize: '14px !important'}}>Atrás</span>
                                </Button>
                            }
                        />
                        {activeStep === questions.length - 1 &&
                        <Button color={"primary"} variant={"contained"} onClick={this.handlePollFinish}
                                className={importedStylesSass.endPollButton}
                                disabled={!questions[activeStep].skippable && !this.state.questionAnswered}>
                            Finalizar Encuesta
                        </Button>
                        }
                    </Card>
                }
            </React.Fragment>
        )
    }

    /////////////////////////////

    /* STATISTICS */
    sendStartPollRecord() {
        const uuidPoll = this.state.poll.uuid;
        const startTime = new Date();

        fetch(apiBaseUrl + 'polls/saveStartPollRecord', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidPoll: uuidPoll,
                    startDate: startTime
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    startedPollRecordUuid: response.startedPollRecordUuid
                });
                console.info('Started poll at ' + new Date(response.startedAt) + ', record saved on DB');
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    updatePollStadistics() {
        const startedPollRecordUuid = this.state.startedPollRecordUuid;
        let startTime = this.state.startTime;
        let endTime = new Date();
        let timeSpend = getMinutesDifference(startTime, endTime);

        fetch(apiBaseUrl + 'polls/updatePollStadistics', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    startedPollRecordUuid: startedPollRecordUuid,
                    endTime: endTime,
                    timeSpend: timeSpend,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);

            if (!response.error) {
                console.info('Updated poll stadistics record succesfully');
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    /////////////////////////////

    /* FINISH POLL LOGIC */
    savePollResults() {

        const uuidPoll = this.state.poll.uuid;
        const answers = this.state.answers;

        fetch(apiBaseUrl + 'polls/savePollResults', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidPoll: uuidPoll,
                    answers: answers,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);

            if (!response.error) {
                this.props.dispatch(showSnack('Genial, hemos recibido tus respuestas. ¡Vuelve pronto!', snackTypes.success));
                // Pasamos a la pantalla de agradecimiento.
                this.handleNext();
                // Esperamos 10s y reiniciamos la encuesta
                setTimeout(() => {
                    document.location.reload();
                }, 10000);
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            console.log(error);
            this.props.dispatch(showSnack('Oops parece que no hemos podido guardar tus respuestas, vuelve a intentarlo en unos segundos', snackTypes.error));
        });

    }

    handlePollFinish = () => {
        // Only do normal flux if you're not in previewMode, if so, then simulate beahvior without sending any registers to backend
        if (!this.state.previewMode) {
            //This function is only a trigger for the next functions:
            this.savePollResults();
            this.updatePollStadistics();
        } else {
            this.props.dispatch(showSnack('Genial, hemos recibido tus respuestas. ¡Vuelve pronto!', snackTypes.success));
            // Pasamos a la pantalla de agradecimiento.
            this.handleNext();
            // Esperamos 10s y reiniciamos la encuesta
            setTimeout(() => {
                document.location.reload();
            }, 10000);
        }
    };

    renderFinishedPollAnimation() {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: finishedPoll,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={250}
                    width={250}
            />
        )

    }

    /////////////////////////////

    renderPinInput() {
        return (
            <ReactCodeInput value={this.state.introducedPin} type='number' fields={4} autoFocus={true}
                            inputMode={'numeric'}
                            onChange={(value) => this.setState({introducedPin: value})}
                            className={importedStylesSass.pinInput}/>
        )
    }

    renderPreviewModeAlert() {
        return (
            <div className={importedStylesSass.previewModeAlertContainer}>
                <Typography variant="h5" gutterBottom>
                    Estás viendo esta encuesta en modo "Previsualización"
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    Tus respuestas y tiempo de realización no se tendran en cuenta ni se guardarán
                </Typography>
            </div>
        );
    }

    render() {
        const {fullScreen} = this.props;

        return (
            <React.Fragment>
                <Grid container spacing={0} id={'container'} className={importedStylesSass.parentContainer}>
                    {this.state.previewMode && this.renderPreviewModeAlert()}
                    <Grid item xs={12} className={importedStylesSass.restaurantLogoContainer}>
                        {this.renderRestaurantInfo()}
                    </Grid>

                    <Grid item xs={12} md={6} className={importedStylesSass.content}>
                        {this.state.gettingData ?
                            <Grid item xs={12} className={importedStylesSass.chargingContent}>
                                <CircularProgress color={"primary"} size={120}/>
                            </Grid>
                            :
                            this.renderContent()
                        }
                        {/*        {this.state.isPollSelected && this.renderPollSelectorTriggerButton()}
                        {this.state.isPollSelected && this.renderFullScreenIconButton()}*/}
                    </Grid>

                    <Snack/>

                    <Dialog
                        fullScreen={fullScreen}
                        open={this.state.securityPinModal}
                        onClose={() => this.setState({securityPinModal: false})}
                        aria-labelledby="security pin required"
                    >
                        <DialogTitle>Cambiar encuesta</DialogTitle>
                        <DialogContent className={importedStylesSass.securityPinModalContent}>
                            <DialogContentText>
                                Esta acción requiere la introducción del PIN de seguridad
                            </DialogContentText>
                            {this.renderPinInput()}
                        </DialogContent>
                        <DialogActions>
                            <Button size={"small"} color="secondary"
                                    onClick={() => this.setState({securityPinModal: false})}>
                                Cancelar
                            </Button>
                            <Button color="primary" variant={"contained"} onClick={this.cleanReduxAndRefresh} autoFocus>
                                Comprobar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </React.Fragment>
        );
    }
}

frontPollsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({frontPollsReducer}) => {
    return ({
        actualPoll: frontPollsReducer.actualPoll,
        currentRestaurant: frontPollsReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles(), {withTheme: true})(frontPollsScreen));
