import {DRAWER_OPENED, SET_NESTED_COLLAPSABLES, SET_PARENT_COLLAPSABLE} from "../../constants/action-types";
import {SET_COLLAPSABLES} from "../../constants/action-types";

export const drawerOpened = (isOpened) => {

    return ({
        type: DRAWER_OPENED,
        drawerOpen: isOpened
    });

};
export const setCollapseAllNested = (collapseAllNested) => {
    return ({
        type: SET_NESTED_COLLAPSABLES,
        collapseAllNested: collapseAllNested
    });
};
export const setParentCollapse = (collapseOpen) => {
    return ({
        type: SET_PARENT_COLLAPSABLE,
        collapseOpen: collapseOpen
    });
};
export const setCollapsablesState = (collapsables) => {

    return ({
        type: SET_COLLAPSABLES,
        collapsables: collapsables
    });

};