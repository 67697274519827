import {
    SET_NEW_CART,
    ADD_DISH_TO_CART,
    REMOVE_DISH_FROM_CART,
    UNDO_LAST_CART_ADDITION,
    SUBTRACT_ONE_DISH_FROM_CART,
    DELETE_WHOLE_CART
} from "../../../../constants/action-types";

export const set_new_cart = (cart) => {
    return ({
        type: SET_NEW_CART,
        newCart: cart
    });
};

export const add_dish_to_cart = (dish) => {
    return ({
        type: ADD_DISH_TO_CART,
        newDish: dish
    });
};

export const remove_dish_from_cart = (dishUuid) => {
    return ({
        type: REMOVE_DISH_FROM_CART,
        dishToRemove: dishUuid
    });
};

export const subtract_one_dish_from_cart = (dishUuid) => {
    return ({
        type: SUBTRACT_ONE_DISH_FROM_CART,
        dishToSubtract: dishUuid
    });
};

export const delete_whole_cart = () => {
    return ({
        type: DELETE_WHOLE_CART,
    });
};

export const undo_last_cart_addition = () => {
    return ({
        type: UNDO_LAST_CART_ADDITION,
    });
};




