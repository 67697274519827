import {SET_FILTER_STATE, RESET_FILTER_STATE} from "../../../../constants/action-types";

export const set_filter_state = (filterActive, searchedRestaurant, searchedKitchenType, searchedCity) => {
    return ({
        type: SET_FILTER_STATE,
        filterActive: filterActive,
        searchedRestaurant: searchedRestaurant,
        searchedKitchenType: searchedKitchenType,
        searchedCity: searchedCity
    });
};

export const reset_filter_state = () => {
    return ({
        type: RESET_FILTER_STATE,
    });
};
