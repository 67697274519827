import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, MoreHoriz} from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import connect from "react-redux/es/connect/connect";
import importedStyles from './DetailCardStyle';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

class DetailCard extends React.Component {
    state = {
        expanded: false,
        anchorEl: null,
        removeModal: false,
        editing: this.props.editing,
    };

    handleRemoveModal = () => {
        this.setState({removeModal: !this.state.removeModal});
    };

    removeRestaurant = () => {
        this.setState({removeConfirmationSnack: !this.state.removeConfirmationSnack});
        this.handleRemoveModal();
    };

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    renderSeeMoreIcon() {
        return (
            <Tooltip title="Ver más" placement="right">
                <IconButton onClick={this.handleExpandClick}>
                    <MoreHoriz/>
                </IconButton>
            </Tooltip>
        )
    }

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Card className={classes.card}>
                    <CardHeader
                        avatar={this.props.avatar}
                        title={this.props.title}
                        subheader={this.props.website}
                    />
                    <CardContent className={this.props.centered && classes.centeredContainer}>
                        {(this.props.collapsableCard && this.state.expanded) ? this.props.collapsedContent : this.props.data}
                        {(this.props.collapsableCard && !this.state.expanded) && this.renderSeeMoreIcon()}

                    </CardContent>
                    <CardActions className={classes.actions} disableActionSpacing>
                        {this.props.actions}

                        {this.props.collapsableCard &&
                        <IconButton
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                            aria-label="Ver más"
                        >
                            {this.state.expanded &&
                            <Tooltip title="Ver menos" placement="right">
                                <ExpandLessIcon/>
                            </Tooltip>}
                            {!this.state.expanded &&
                            <Tooltip title="Ver más" placement="right">
                                <ExpandMoreIcon/>
                            </Tooltip>
                            }
                        </IconButton>
                        }
                    </CardActions>

                </Card>


                {/* REMOVE RESTAURANT CONFIRMATION MODAL */}
                <Dialog
                    open={this.state.removeModal}
                    onClose={this.handleRemoveModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title">{"¿Seguro que quieres eliminar este restaurante?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si borras el restaurante no podrás recuperar sus datos más tarde, piensalo bien.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.removeRestaurant} variant="contained" color="secondary" autoFocus>
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

DetailCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        idRestaurant: restaurantReducer.idRestaurant
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles())(DetailCard));