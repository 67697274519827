export default class BooleanQuestion {

    constructor(question) {
        this.title = question.title;
        this.answers = question.answers;
        this.type = question.type;
        this.position = question.position;
        this.skippable = question.skippable;
    }

    getAnswersStatistics() {
        let answers = this.answers;
        let totalAnswers = answers.length;
        let yesAnswers = 0;
        let noAnswers = 0;

        Object.keys(answers).forEach((key) => {
            let answerObject = answers[key];
            if (answerObject.answer) {
                yesAnswers++
            } else {
                noAnswers++
            }
        });

        let answerStatistics = {
            yesAnswers: yesAnswers,
            noAnswers: noAnswers,
            totalAnswers: totalAnswers
        };

        return answerStatistics;

    }

}