import React from 'react';
import importedStyles from "./notFound.module.sass";
import Button from "@material-ui/core/Button/Button";
import history from "../../helpers/history"

class notFound extends React.Component {

    state = {};

    componentDidMount() {
    }

    render() {
        return (
            <div>
                {/*<Navbar path={window.location.pathname} hidden={this.state.hidden} className={importedStyles.navBar} style={{backgroundColor: '#4FA9B3'}}/>*/}
                <div className={importedStyles.notFound}>
                    <div className={importedStyles.numContainer}>
                        <h1>404</h1>
                        {/*<img*/}
                            {/*src={require('../../img/bracelet.png')}*/}
                            {/*alt={'pulsera'}*/}
                        {/*/>*/}

                    </div>
                    <div className={importedStyles.comeBackContainer}>
                        <h2>Oops, parece que esta página no existe</h2>
                        <Button onClick={() => history.push('/')}>Volver al inicio</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default notFound;