import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import Grid from "@material-ui/core/Grid";
import importedStyles from "./SectionContentBox.module.sass";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {Snackbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import imageDefault from "../../../../../src/rsc/img/image-default.png"
import {add_dish_to_cart, subtract_one_dish_from_cart, undo_last_cart_addition} from "../Cart/cartActions";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import {handleImgLoadError} from "../../../../helpers/utils";
import LazyCardImage from "../LazyCardImage/LazyCardImage";
import {LazyLoadImage, trackWindowScroll} from "react-lazy-load-image-component";
import Skeleton from "@material-ui/lab/Skeleton";
import Fade from "@material-ui/core/Fade";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {addDishEvent} from "../../../../helpers/addDishEvent";
import {change_current_product} from "../../digitalMenuActions";
import {darken} from "../../../../helpers/manageColors";

class SectionContentBox extends React.Component {

    state = {
        expanded: null,
        scrolling: false,
    };

    onScroll = () => {
        this.setState({scrolling: true})
    };
    componentDidMount(): void {
        window.addEventListener("scroll", this.onScroll);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

    }

    showAddedToCartSnack = (dishName) => {
        this.setState({dishToAdd: dishName, addedToCartSnack: true});
    };

    handleAddDishToCart = (dish) => () => {
        if (this.props.firstAdditionToCart) { //show 'tuto' dialog if first addition to cart and turn flag to false
            this.props.handleFirstAdditionToCart();
        }
        addDishEvent(dish.uuid);

        this.props.dispatch(add_dish_to_cart(dish));
        // this.showAddedToCartSnack(dish.name);
    };

    handleSubstract = (dish) => () => {
        this.props.dispatch(subtract_one_dish_from_cart(dish.uuid));
    };
    undoCartAddition = () => {
        this.props.dispatch(undo_last_cart_addition());
        this.setState({addedToCartSnack: false});
    };

    renderDishActions = dish => {
        let numberOfEqualDishesOnCart = this.props.cart.filter(item => (item.uuid === dish.uuid)).length;

        return (
            <div style={{backgroundColor: this.props.primaryColor}} className={importedStyles.productActions}>
                <IconButton onClick={this.handleSubstract(dish)}>
                    <MinusIcon style={{ color: this.props.secondaryColor }}/>
                </IconButton>
                <span style={{ color: this.props.secondaryColor }}>
                    {numberOfEqualDishesOnCart}
                </span>
                {/*<Badge badgeContent={numberOfEqualDishesOnCart}
                       classes={{badge: importedStyles.dishQuantityBadge}}>
                    <CartIcon/>
                </Badge>*/}
                <IconButton onClick={this.handleAddDishToCart(dish)}>
                    <AddIcon style={{ color: this.props.secondaryColor }} />
                </IconButton>
            </div>
        )
    };

    renderDishes = () => {

        let items = [];
        let dishes = this.props.selectedSection.dishes;
        /*Object.keys(dishes).forEach((key) => {
            let dish = dishes[key];
            items.push(
                <Grow
                    key={key}
                    in={true}
                    style={{transformOrigin: '0 0 0'}}
                    {...{timeout: (key * 200) + 700}}
                    appear={true}
                >

                </Grow>
            );
        });*/

        return (
            <Grid container style={{padding: 8}}>
                {dishes.map((value, index) => (
                        <Grid item xs={6} className={importedStyles.dishContainer} key={index}>
                            <Card elevation={4}  style={{backgroundColor: this.props.primaryColor}} className={importedStyles.dishCard}>
                                <CardActionArea
                                    onClick={this.props.handleProductSelection(value)}
                                    // onClick={() => console.info(value)}

                                >
                                    <LazyLoadImage
                                        className={importedStyles.dishPhoto}
                                        src={value.photo ? value.photo : imageDefault}
                                        alt={value.name}
                                        //threshold={400} // amount of px before element that triggers image load
                                        scrollPosition={this.props.scrollPosition}
                                        effect={'blur'}
                                        onError={handleImgLoadError}
                                        placeholderSrc={imageDefault}
                                        wrapperClassName={importedStyles.dishPhoto}
                                    />
                                    <Avatar style={{backgroundColor: this.props.primaryColor, color: this.props.secondaryColor}} className={importedStyles.dishPrice}>{value.price}€</Avatar>
                                </CardActionArea>
                                {this.renderDishActions(value)}
                            </Card>
                            <p style={{color: this.props.primaryColor}}
                                className={importedStyles.dishTitle}
                               onClick={this.props.handleProductSelection(value)}>{value.name}</p>
                        </Grid>
                ))
                }
            </Grid>
        );
    };

    renderNextSectionsLinks = () => {
        let nextSections = this.props.sections.filter((item) => {
            return item.uuid !== this.props.selectedSection.uuid
        });

        let nextSectionsLinks = [];

        Object.keys(nextSections).forEach((key) => {
            let nextSection = nextSections[key];
            nextSectionsLinks.push(
                <Grow
                    key={key}
                    in={true}
                    style={{transformOrigin: '0 0 0'}}
                    {...{timeout: (key * 200) + 700}}
                >
                    <ListItem button onClick={() => this.props.handleSectionChange(nextSection)}>
                    {/*<ListItem button onClick={()=> console.info(JSON.stringify(nextSection))}>*/}
                        <ListItemText primary={'Ver "' + nextSection.name + '"'} style={{fontStyle: 'oblique'}}/>
                    </ListItem>
                </Grow>
            );
        });

        return (

            <List className={importedStyles.sectionLinks}>
                {nextSectionsLinks}
            </List>
        );
    };


    render() {


        return (
            <React.Fragment>

                {this.renderDishes()}
                {this.renderNextSectionsLinks()}

                <Snackbar
                    open={this.state.addedToCartSnack}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    autoHideDuration={6000}
                    onClose={() => this.setState({addedToCartSnack: false})}
                    message={'"' + this.state.dishToAdd + '" ha sido añadido al pedido'}
                    action={<Button variant={'outlined'} key="undo" color="secondary" size="small"
                                    onClick={this.undoCartAddition}><span>Deshacer</span></Button>}
                />
            </React.Fragment>

        );
    }
}

SectionContentBox.propTypes = {
    sections: PropTypes.array.isRequired,
    selectedSection: PropTypes.object.isRequired,
    handleSectionChange: PropTypes.func.isRequired,
    handleProductSelection: PropTypes.func.isRequired,
    handleFirstAdditionToCart: PropTypes.func.isRequired,
    firstAdditionToCart: PropTypes.bool.isRequired,
    currentDish: PropTypes.string
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        currentMenu: digitalMenuReducer.actualMenu,
        currentSection: digitalMenuReducer.currentSection,
        currentProduct: digitalMenuReducer.currentProduct,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(trackWindowScroll(SectionContentBox));

