import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import EventIcon from '@material-ui/icons/Event';
import EventAvailable from '@material-ui/icons/EventAvailable';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FastFood from '@material-ui/icons/Fastfood';
import EuroIcon from '@material-ui/icons/EuroSymbol';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import RemoveIcon from '@material-ui/icons/Remove';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import history from "../../helpers/history";
import Button from "@material-ui/core/Button/Button";
import connect from "react-redux/es/connect/connect";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import {logout} from "../../modules/Auth/authActions";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Collapse from "@material-ui/core/Collapse/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {detail_rte} from "../../modules/Restaurant/restaurantActions";
import ExitIcon from "@material-ui/icons/ExitToApp";
import {drawerOpened, setCollapsablesState, setParentCollapse} from "./MiniDrawerActions";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import importedStyles from "./MiniDrawerStyle";

class MiniDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            user: {},
            restaurants: {},
            anchorEl: null,
            loginOpen: false,
            registerOpen: false,
            userIsAssociation: false,
            email: '',
            password: '',
            logoutModal: false,
            collapseOpen: false,
            collapsables: {},
            collapseAllNested: !this.props.drawerOpen,
            gettingData: false,
        };

        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        this.setState({
            user: this.props.user,
            collapsables: this.props.drawer.collapsables,
            collapseOpen: this.props.drawer.collapseOpen,
            //collapseAllNested: this.props.drawer.collapseAllNested
        });
        this.getRestaurants(this.props.user.uuid);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({user: nextProps.user});
        this.getRestaurants(nextProps.user.uuid);
    }

    componentWillUnmount() {
        //this.props.dispatch(setCollapseAllNested(this.state.collapseAllNested));
        this.props.dispatch(setCollapsablesState(this.state.collapsables));
        this.props.dispatch(setParentCollapse(this.state.collapseOpen));
    }

    getRestaurants(idUser) {
        this.setState({gettingData: true});
        let apiEndPoint = '';
        let data = {};
        if (this.props.user.association) {
            apiEndPoint = 'associations/listRestaurants';
            data = {associationId: this.props.user.associationData.uuid}
        } else {
            apiEndPoint = 'users/listRestaurants';
            data = {owner: idUser}
        }
        fetch(apiBaseUrl + apiEndPoint, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            this.setState({restaurants: response.restaurants, gettingData: false})
        }).catch((error) => {
            this.setState({gettingData: false});
            console.error(error);
        });

    }

    handleDrawerOpen = () => {
        this.props.dispatch(drawerOpened(true));
        this.setState({collapseAllNested: false});
    };

    handleDrawerClose = () => {
        this.props.dispatch(drawerOpened(false));
        this.setState({collapseAllNested: true});
    };

    handleInputChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };

    handleAssociationCheckbox = () => {

        this.setState({userIsAssociation: !this.state.userIsAssociation})
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };


    closeLoginModal = () => {
        this.setState({loginOpen: false});
    };

    openLoginModal = () => {
        this.setState({loginOpen: true, registerOpen: false});
    };

    closeRegisterModal = () => {
        this.setState({registerOpen: false});
    };

    openRegisterModal = () => {
        this.setState({loginOpen: false, registerOpen: true});
    };

    handleLogoutModal = () => {
        this.setState({logoutModal: !this.state.logoutModal});
    };

    handleLogout() {
        localStorage.removeItem('userIsAssociation');
        this.props.dispatch(logout());
        history.push('/');
    }

    handleParentCollapsable = (e, restaurant) => {
        console.log(restaurant);
        e.stopPropagation();
        e.preventDefault();
        this.props.dispatch(detail_rte(restaurant.uuid, restaurant.name, restaurant.logo, restaurant.restaurants_role, restaurant.url_slug));
        history.push('/detalleRestaurante');
    };

    handleRestaurantCollapsable = (e, restaurant) => {
        e.stopPropagation();
        e.preventDefault();
        let collapsables = this.state.collapsables;
        collapsables[restaurant.uuid] = !this.state.collapsables[restaurant.uuid];
        this.setState({collapsables});
    };

    handleNestedCollapsable = (e, restaurant, redirectTo) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.dispatch(detail_rte(restaurant.uuid, restaurant.name, restaurant.logo, restaurant.restaurants_role, restaurant.url_slug));
        history.push(redirectTo);
    };

    renderRestaurantsList() {
        const {classes} = this.props;
        const restaurants = this.state.restaurants;
        const restaurantItems = [];

        if (restaurants) {
            Object.keys(restaurants).forEach((key) => {
                let restaurant = restaurants[key];
                let restaurant_permissions = restaurant.restaurants_role;

                let menusPermission = restaurant_permissions ? restaurant_permissions.menus : false;
                let offersPermission = restaurant_permissions ? restaurant_permissions.offers : false;

                if (!menusPermission) {
                    //Fix para el caso de tener permisos para ver ofertas pero no menús (no deberia ocurrir)
                    offersPermission = false;
                }
                let pollsPermission = restaurant_permissions ? restaurant_permissions.polls : false;
                let reservationsPermission = restaurant_permissions ? restaurant_permissions.reservations : false;

                // let onlyRestaurantViewPermission = !(menusPermission && offersPermission && pollsPermission && reservationsPermission);

                restaurantItems.push(
                    <Collapse
                        in={this.state.collapseOpen && !this.state.collapseAllNested}
                        timeout="auto"
                        key={key}
                        onClick={(e) => this.handleParentCollapsable(e, restaurant)}
                    >
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <RemoveIcon/>
                                </ListItemIcon>
                                <ListItemText primary={restaurant.name}/>
                                <ListItemSecondaryAction>
                                    <IconButton onClick={(e) => this.handleRestaurantCollapsable(e, restaurant)}>
                                        {this.state.collapsables && this.state.collapsables[restaurant.uuid] && !this.state.collapseAllNested ?
                                                <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>
                                        }
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Collapse
                                in={this.state.collapsables[restaurant.uuid] && !this.state.collapseAllNested}
                                timeout="auto"
                            >
                                <List component="div" disablePadding>
                                    {menusPermission &&
                                    <ListItem
                                        button
                                        className={classes.doubleNested}
                                        onClick={(e) => this.handleNestedCollapsable(e, restaurant, '/menus')}
                                    >
                                        <ListItemIcon>
                                            <FastFood/>
                                        </ListItemIcon>
                                        <ListItemText primary="Cartas"/>
                                    </ListItem>
                                    }
                                    {pollsPermission &&
                                    <ListItem
                                        button
                                        className={classes.doubleNested}
                                        onClick={(e) => this.handleNestedCollapsable(e, restaurant, '/polls')}
                                    >
                                        <ListItemIcon>
                                            <AssignmentIcon/>
                                        </ListItemIcon>
                                        <ListItemText  primary="Encuestas"/>
                                    </ListItem>
                                    }
                                    {offersPermission &&
                                    <ListItem
                                        button
                                        className={classes.doubleNested}
                                        onClick={(e) => this.handleNestedCollapsable(e, restaurant, '/offers')}
                                    >
                                        <ListItemIcon>
                                            <EuroIcon/>
                                        </ListItemIcon>
                                        <ListItemText  primary="Ofertas"/>
                                    </ListItem>
                                    }
                                    {reservationsPermission &&
                                    <ListItem
                                        button
                                        className={classes.doubleNested}
                                        onClick={(e) => this.handleNestedCollapsable(e, restaurant, '/reservations')}
                                    >
                                        <ListItemIcon>
                                            <EventAvailable/>
                                        </ListItemIcon>
                                        <ListItemText  primary="Reservas"/>
                                    </ListItem>
                                    }
                                </List>
                            </Collapse>
                        </List>
                    </Collapse>
                )
            })
        }
        return <React.Fragment>{restaurantItems}</React.Fragment>;
    }

    renderRestaurantsListLimited() {
        const {classes} = this.props;
        const restaurants = this.state.restaurants;
        const restaurantItems = [];

        if (restaurants) {
            Object.keys(restaurants).forEach((restaurant) => {
                restaurantItems.push(
                    <Collapse
                        in={this.state.collapseOpen && !this.state.collapseAllNested}
                        timeout="auto"
                        key={restaurant}
                    >
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <RemoveIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={<span>{restaurants[restaurant].name}</span>}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                )
            })
        }
        return <React.Fragment>{restaurantItems}</React.Fragment>;
    }


    render() {
        const {classes, theme} = this.props;
        const auth = this.props.auth;

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: this.props.drawerOpen,
                    })}
                >
                    <Toolbar disableGutters={!this.props.drawerOpen}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, {
                                [classes.hide]: this.props.drawerOpen,
                            })}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Typography
                            variant={"h6"} color="inherit" noWrap
                        >
                            {this.props.title}
                        </Typography>
                        {!auth &&
                        (<Button
                            variant="contained" color="primary"
                            style={{backgroundColor: 'black', position: 'absolute', right: 10}}
                            onClick={this.openLoginModal}
                        >
                            <AccountCircle/> Acceder
                        </Button>)
                        }
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant={typeof window.orientation !== 'undefined' ? '' : 'permanent'}
                    anchor="left"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.props.drawerOpen,
                        [classes.drawerClose]: !this.props.drawerOpen,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: this.props.drawerOpen,
                            [classes.drawerClose]: !this.props.drawerOpen,
                        }),
                    }}
                    open={this.props.drawerOpen}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </div>

                    <List>
                        <ListItem style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                            <Avatar
                                src={this.props.user.avatar}
                                className={classes.avatar}
                                onClick={() => {
                                    history.push('/profile');
                                }}
                            />
                            {this.props.drawerOpen && (
                                <React.Fragment>
                                    <Typography
                                        variant="caption" gutterBottom
                                        style={{marginTop: 10}}
                                    >
                                        {this.props.user.association ? this.props.user.associationData.name : this.props.user.name}
                                    </Typography>
                                    <Button
                                        color={"primary"}
                                        size={"small"}
                                        onClick={() => history.push('/profile')}
                                    >Mi Perfil</Button>
                                </React.Fragment>
                            )}
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        <ListItem button onClick={() => history.push('/myRestaurants')}>
                            <ListItemIcon>
                                <RestaurantIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={'Mis restaurantes'}
                            />
                            <ListItemIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({collapseOpen: !this.state.collapseOpen})
                                }}>
                                {this.state.gettingData ? <CircularProgress size={25}/> : (this.state.collapseOpen ?
                                    <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>)}
                            </ListItemIcon>
                        </ListItem>
                        {
                            this.props.user.association ? this.renderRestaurantsListLimited() : this.renderRestaurantsList()
                        }
                        {this.props.user.association &&
                        <ListItem button onClick={() => history.push('/events')}>
                            <ListItemIcon>
                                <EventIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={"Eventos"}
                            />
                        </ListItem>
                        }
                    </List>

                    <List style={{marginTop: 'auto'}}>
                        <Divider/>
                        <ListItem button onClick={() => this.setState({logoutModal: true})}>
                            <ListItemIcon>
                                <ExitIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={"Cerrar sesión"}
                            />
                        </ListItem>
                    </List>
                </Drawer>

                {/* LOGOUT CONFIRMATION MODAL */}
                <Dialog
                    open={this.state.logoutModal}
                    onClose={this.handleLogoutModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className={classes.logoutDialog}>
                        <DialogTitle id="alert-dialog-title">{"Salir"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ¿Seguro desea salir?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleLogoutModal} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={this.handleLogout} variant="contained" color="secondary" autoFocus>
                                Salir
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        );
    }
}

MiniDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, MiniDrawerReducer, restaurantReducer}) => ({
    auth: authReducer.auth,
    user: authReducer.credentials[0],
    drawerOpen: MiniDrawerReducer.drawerOpen,
    drawer: MiniDrawerReducer,
    restaurantPermissions: restaurantReducer.permissions
});

export default connect(mapStateToProps)(withStyles(importedStyles(), {withTheme: true})(MiniDrawer));
