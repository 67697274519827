import React from 'react';
import importedStyles from "./SearchHeader.module.sass";
import ClearIcon from "@material-ui/icons/Clear";
import Select from 'react-select'
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/es/Button/Button";
import SearchBar from 'material-ui-search-bar';
import history from "../../../../helpers/history";
import Typography from "@material-ui/core/Typography/Typography";
import connect from "react-redux/es/connect/connect";
import {reset_filter_state, set_filter_state} from "./searchActions";


const customStyles = {

    control: (provided, state) => ({
        ...provided,
        borderRadius: 15
    }),
};

class SearchHeader extends React.Component {

    state = {
        searchedRestaurant: '',
        searchedKitchenType: '',
        searchedCity: '',
        noFiltersSelected: false,
    };

    componentDidMount() {

        this.setState({
            searchedRestaurant: this.props.searchReducer.searchedRestaurant,
            searchedKitchenType: this.props.searchReducer.searchedKitchenType,
            searchedCity: this.props.searchReducer.searchedCity,
        })
    }


    handleSearch = () => {
        if (this.state.searchedRestaurant.length > 0 || this.state.searchedKitchenType.length > 0 || this.state.searchedCity.length > 0) {
            this.props.dispatch(set_filter_state(true, this.state.searchedRestaurant, this.state.selectedKitchenType, this.state.selectedCity));
            history.push('/comerEn/restaurantes/')
        } else {
            this.setState({noFiltersSelected: true})
        }
    };
    resetSearch = () => {
        this.setState({
            searchedRestaurant: '',
            searchedKitchenType: '',
            searchedCity: '',
            noFiltersSelected: false
        });
        this.props.dispatch(reset_filter_state());
    };

    render() {
        const cityOptions = [
            {value: 'Onda', label: 'Onda'},
            {value: 'Castellón de la Plana', label: 'Castellón'},
            {value: 'Zaragoza', label: 'Zaragoza'}
        ];
        const kitchenTypeOptions = [
            {value: 'asiatica', label: 'Asiatica'},
            {value: 'Italiana', label: 'Italiana'},
            {value: 'Española', label: 'Española'}
        ];
        return (
            <div className={importedStyles.fixGridBox}>
                <Grid container spacing={16} className={this.props.landing ? importedStyles.bodyBoxLanding : importedStyles.bodyBox}>
                    <Grid item xs={12} className={importedStyles.searchBarBox}>
                        <Grid item xs={12} md={8}>
                            <SearchBar
                                value={this.props.searchReducer.searchedRestaurant}
                                onChange={(newValue) => this.setState({searchedRestaurant: newValue})}
                                className={importedStyles.searchInput}
                                closeIcon={<ClearIcon className={importedStyles.clearSearchIcon}/>}
                                placeholder={'Introduce tu búsqueda'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <Select placeholder={'Tipo de cocina'} styles={customStyles} options={kitchenTypeOptions}
                                onChange={(selectedOption) => this.setState({searchedKitchenType: selectedOption.value})}/>
                    </Grid>
                    <Grid item xs={10} md={3}>
                        <Select placeholder={'Población'} styles={customStyles} options={cityOptions}
                                onChange={(selectedOption) => this.setState({searchedCity: selectedOption.value})}/>
                    </Grid>
                    <Grid item xs={12} className={importedStyles.searchButtonBox}>
                        <Grid item xs={8} md={3}>
                            <Button variant={"contained"} className={importedStyles.searchRestaurantButton} fullWidth
                                    onClick={this.handleSearch}>
                                Buscar Restaurante
                            </Button>
                        </Grid>
                    </Grid>
                    {this.state.noFiltersSelected &&
                    <Grid item xs={12} className={importedStyles.clearSearchBox}>
                        <Typography className={importedStyles.noFiltersText}>
                            Debes introducir un nombre, tipo de cocina o ciudad
                        </Typography>
                    </Grid>
                    }
                    {this.props.filterActive &&
                    <Grid item xs={4} className={importedStyles.clearSearchBox}>
                        <Button variant={"text"} onClick={this.resetSearch}>
                            <ClearIcon className={importedStyles.clearSearchButtonIcon}/>
                            <Typography className={importedStyles.clearSearchButton}>Limpiar búsqueda</Typography>
                        </Button>
                    </Grid>
                    }
                </Grid>
            </div>
        );
    }
}
const mapStateToProps = ({searchReducer}) => {
    return ({
        searchReducer: searchReducer,
        filterActive: searchReducer.filterActive
    });
};

export default connect(mapStateToProps) (SearchHeader);