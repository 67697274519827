import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import CartIcon from "@material-ui/icons/ShoppingCart";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import Avatar from "@material-ui/core/Avatar";
import importedStyles from "./DigitalMenuNavBar.module.sass";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Cart from "../Cart/Cart";
import {Tooltip} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";


class DigitalMenuNavBar extends React.Component {

    state = {
        cartOpened: false,
        totalCartItems: 0
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cart.length !== this.props.cart.length) {
            this.setState({totalCartItems: this.props.cart.length})
        }
    }

    renderSelectSectionItems = () => {

        if (this.props.currentMenu.menu_sections) {
            let sections = this.props.currentMenu.menu_sections;
            let items = [];
            Object.keys(sections).forEach((key) => {
                let section = sections[key];
                items.push(
                    <MenuItem value={section}>{section.name}</MenuItem>
                );
            });
            return (items);
        }
    };

    handleCart = () => {
        this.setState((prevState, props) => ({
            cartOpened: !prevState.cartOpened
        }));
    };



    render() {
        return (
            <AppBar position="fixed" className={importedStyles.root}>
                <Grid container spacing={0}>
                    <Grid item sm={1} className={importedStyles.gridItem}>
                        {this.props.currentSection &&
                        <IconButton edge="start" color="inherit" aria-label="back" onClick={this.props.handleBack}>
                            <BackIcon className={importedStyles.backIcon}/>
                        </IconButton>
                        }
                    </Grid>
                    <Grid item sm={2} className={[importedStyles.gridItem, importedStyles.restaurantLogoGridItem]}>
                        <Avatar alt="Restaurant Logo" src={this.props.restaurantLogo}
                             className={importedStyles.restaurantLogo} onClick={this.props.handleBack}/>
                    </Grid>
                    <Grid item sm={5} className={importedStyles.gridItem}>
                        <FormControl className={importedStyles.formControlSectionSelect}>
                            <InputLabel htmlFor="section"
                                        className={importedStyles.sectionSelectLabel}>Sección</InputLabel>
                            <Select
                                value={this.props.currentSection}
                                onChange={this.props.handleSectionSelect}
                                name={'selectedSection'}
                               // renderValue={value => `${value}`}
                               // className={importedStyles.sectionSelectInput}
                            >
                                <MenuItem value={''} key={'000'} disabled>
                                    Selecciona una sección
                                </MenuItem>
                                {this.renderSelectSectionItems()}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sm={4} className={importedStyles.gridItem}>
                        {/*     <Tooltip title={'Ver mi pedido'}>
                                <Badge badgeContent={this.props.cart.length} color="primary">
                                    <IconButton onClick={this.handleCart}><CartIcon/></IconButton>
                                </Badge>
                            </Tooltip>*/}
                        <Tooltip title={'Ver mi pedido'}>
                            <Badge badgeContent={this.state.totalCartItems} color="primary">
                                <Button variant={"outlined"} onClick={this.handleCart} color={"primary"}>Mi
                                    pedido <CartIcon style={{marginLeft: '0.5em'}}/></Button>
                            </Badge>
                        </Tooltip>
                    </Grid>
                    <div id={'languageIcon'}>
                        <img style={{height: 25, position: 'absolute', bottom: '0.5rem', right: '0.9rem'}} src={require('../../../../rsc/img/espana.png')} alt={'bandera española'}/>
                    </div>
                </Grid>
                <Cart cartOpened={this.state.cartOpened} onClose={this.handleCart}/>
            </AppBar>
        );
    }
}

DigitalMenuNavBar.propTypes = {
    classes: PropTypes.object.isRequired,
    restaurantLogo: PropTypes.string.isRequired,
    restaurantName: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleSectionSelect: PropTypes.func.isRequired,
    currentSection: PropTypes.object.isRequired,
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        selectedSection: digitalMenuReducer.selectedSection,
        currentMenu: digitalMenuReducer.actualMenu,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(DigitalMenuNavBar);

