import React from 'react';
import importedStyles from "./ReservationIframe.module.sass";
import Button from "@material-ui/core/Button/Button";
import UserIcon from "@material-ui/icons/Person";
import ClockIcon from "@material-ui/icons/Schedule";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import connect from "react-redux/es/connect/connect";
import apiBaseUrl from "../../../../helpers/apiBaseUrl";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {showSnack, snackTypes} from "../../../../components/Snack/SnackActions";
import {Card, CardContent, Divider, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Calendar from "react-calendar";
import {
    formatReservationResumeDate,
    getFormattedReservationHours,
    getFormattedSchedule
} from "../../../../helpers/dateUtils";
import moment from "moment";
import Snack from "../../../../components/Snack/Snack";
import tick from "../../../../rsc/animation/tick";
import Lottie from 'react-lottie';
import baseUrl from "../../../../helpers/baseUrl";


class ReservationIframe extends React.Component {

    constructor(props) {
        super(props);
        this.selectDateRef = React.createRef();
    }

    state = {
        loadingRestaurant: true,
        formError: false,
        emailError: false,
        sendingData: false,
        reservationEmail: '',
        reservationName: '',
        reservationNote: '',
        reservationPhone: '',
        reservationAdults: 1,
        reservationKids: 0,
        peopleSelected: false,
        allowNotifications: false,
        reservationHour: null,
        reservationDate: new Date(),
        confirmedReservation: false,
        restaurantHaveReservationsModule: false,
    };

    componentDidMount() {
        if (this.props) {
            this.setState({restaurantId: this.props.match.params.id});
            this.getInfo();
        }
    }

    getInfo() {
        fetch(apiBaseUrl + 'restaurants/getRestaurant', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.match.params.id
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    restaurant: response.restaurant,
                    loadingRestaurant: false,
                    restaurantHaveReservationsModule: response.restaurant.restaurants_role.reservations
                });
            } else {
                this.setState({loadingRestaurant: false});
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({loadingRestaurant: false});
            console.error(error);
        });
    }

    handleInputChange = (e) => {
        let alphaRegex = new RegExp("^[a-zA-Z ]*$");
        let emailRegex = new RegExp("^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");

        switch (e.target.name) {
            case 'reservationName':
                if (alphaRegex.test(e.target.value)) {
                    this.setState({[e.target.name]: e.target.value});
                }
                break;
            case 'reservationAdults':
                if (e.target.value <= 50) {
                    this.setState({[e.target.name]: e.target.value});
                }
                break;
            case 'reservationKids':
                if (e.target.value <= 50) {
                    this.setState({[e.target.name]: e.target.value});
                }
                break;
            case 'reservationPhone':
                if (e.target.value.length <= 9) {
                    this.setState({[e.target.name]: e.target.value});
                }
                break;
            case 'reservationEmail':
                if (emailRegex.test(e.target.value)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailError: false
                    })
                } else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailError: true
                    });
                }
                break;
            default:
                this.setState({[e.target.name]: e.target.value});
                break;
        }
    };
    handleInputDateChange = (date) => {
        let newValue = {reservationDate: date};
        this.setState(newValue);
        //scroll to bottom:
        //window.scrollTo({top: 0, behavior: 'smooth'});
        window.scrollTo(0, document.body.scrollHeight);

    };

    handleInputHourChange = (hour) => {
        let change = {reservationHour: hour};
        this.setState(change);
        //scroll to top
        window.scrollTo({top: 0, behavior: 'smooth'});
        document.getElementById('firstStepFather').style.display = 'none';

    };


    handleBackButton = () => {
        document.getElementById('firstStepFather').style.display = 'flex';
        window.scrollTo({top: document.getElementById('selectDate').offsetTop, behavior: 'smooth'});
        this.setState({reservationHour: null})
    };

    handlePeopleChange(newValue) {
        this.setState({adults: newValue, peopleSelected: true});

        //Set date-hour selector visible and scroll to it
        document.getElementById('selectDate').style.display = 'flex';
        window.scrollTo({top: document.getElementById('selectDate').offsetTop, behavior: 'smooth'});
    };

    renderHowManyPeopleSection() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={8} className={importedStyles.sectionTitle}>
                    <UserIcon className={importedStyles.sectionPeopleIcon}/>
                    <Typography variant={"h5"}>Número de personas</Typography>
                </Grid>
                <Grid item xs={12} md={8} className={importedStyles.howManyPeopleBox}>
                    <Card className={importedStyles.howManyPeopleCard}>
                        <CardContent className={importedStyles.howManyPeopleCardContent}>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 1 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(1)}>1</Button>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 2 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(2)}>2</Button>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 3 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(3)}>3</Button>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 4 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(4)}>4</Button>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 5 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(5)}>5</Button>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 6 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(6)}>6</Button>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 7 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(7)}>7</Button>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 8 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(8)}>8</Button>
                            <Button variant={"outlined"} size={"small"}
                                    className={this.state.adults === 9 ? importedStyles.howManyPeopleButtonActive : importedStyles.howManyPeopleButton}
                                    onClick={() => this.handlePeopleChange(9)}>9</Button>
                        </CardContent>
                        <CardContent className={importedStyles.howManyPeopleCardContentGroup}>
                            <Typography variant={"subtitle1"} className={importedStyles.howManyPeopleGroupClaim}>
                                Somos un grupo de: </Typography>
                            <TextField
                                value={this.state.adults}
                                onChange={(e) => this.handlePeopleChange(e.target.value)}
                                type="number"
                                InputLabelProps={{shrink: true}}
                                inputProps={{
                                    style: {textAlign: "center"}
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </React.Fragment>
        )
    }

    setMaxDate() {
        let actualDate = new Date();
        actualDate.setMonth(actualDate.getMonth() + 2);
        return actualDate;
    }

    setDisabledDays() {
        const schedules = this.state.restaurant.schedules;
        let disabledDays = [];
        Object.keys(schedules).forEach((key) => {
            let schedule = schedules[key];
            let allDay = schedules[key].allDay;
            if ((!schedule.lunchStartTime && !schedule.dinnerStartTime) && !allDay) {
                disabledDays.push(parseInt(schedule.dayUuid));
            }
        });
        return disabledDays;
    }

    setDisabledCalendarTiles = ({date}) => {
        let disabledDates = this.setDisabledDays();
        let daySelected = date.getDay() === 0 ? 7 : date.getDay();
        return disabledDates.some(disabledDate => daySelected === disabledDate);
    };

    renderAllDayHours(schedule) {
        let hours = [];
        let reservationFrecuency = parseInt(schedule.reservationFrecuency);
        let startTime = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
        let endHour = moment().set({hour: 23, minute: 59, second: 59, millisecond: 0}).locale('es').format("HH");
        let reservationHour = new Date(startTime)
        console.info("ALLDAY: " + new Date(startTime) + endHour + reservationHour);

        //Push first hour
        let selected = getFormattedReservationHours(new Date(reservationHour)) === getFormattedReservationHours(new Date(this.state.reservationHour));
        let buttonValue = new Date(reservationHour);
        hours.push(
            <Button variant={"outlined"} size={"small"} key={reservationHour}
                    className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                    onClick={() => this.handleInputHourChange(buttonValue)}>
                {getFormattedReservationHours(reservationHour)}
            </Button>
        );

        while (reservationHour.getHours() != endHour) {
            console.info(reservationHour.getHours())
            console.info(endHour)
            reservationHour.setMinutes(reservationHour.getMinutes() + reservationFrecuency);
            let selected = getFormattedReservationHours(new Date(reservationHour)) === getFormattedReservationHours(new Date(this.state.reservationHour));

            // check if condition continues true after adding interval minutes to hour
            if (reservationHour.getHours() < endHour) {
                /*   IMPORTANT!!! - Alex Lopez
                    Must create a bypass variable in order to pass right value to handleInputHour function.
                    If we pass directly new Date(reservationHour) at the arrow function, it got the last value, not the one at the moment we push button element to array.
                    This is because arrow functions always recall a new fresh function with fresh values and can cause unexpected data coercion
                */
                let buttonValue = new Date(reservationHour);
                hours.push(
                    <Button variant={"outlined"} size={"small"} key={reservationHour}
                            className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                            onClick={() => this.handleInputHourChange(buttonValue)}>
                        {getFormattedReservationHours(reservationHour)}
                    </Button>
                );
            } else if (reservationHour.getMinutes() === 0) {
                let buttonValue = new Date(reservationHour);
                hours.push(
                    <Button variant={"outlined"} size={"small"} key={reservationHour}
                            className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                            onClick={() => this.handleInputHourChange(buttonValue)}>
                        {getFormattedReservationHours(reservationHour)}
                    </Button>
                );
            }
        }

        return hours;
    }

    renderDinnerHours(schedule) {
        let hours = [];
        if (schedule.dinnerStartTime) {
            let reservationFrecuency = parseInt(schedule.reservationFrecuency);
            let startTime = schedule.dinnerStartTime;
            let endHour = new Date(schedule.dinnerEndTime).getHours();
            let reservationHour = new Date(startTime);
            console.info("DINNER" + startTime + " ENDHOUR " + endHour + " HORA DE RESERVA " + reservationHour)
            //Push first hour
            let selected = getFormattedReservationHours(new Date(reservationHour)) === getFormattedReservationHours(new Date(this.state.reservationHour));
            let buttonValue = new Date(reservationHour);
            hours.push(
                <Button variant={"outlined"} size={"small"} key={reservationHour}
                        className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                        onClick={() => this.handleInputHourChange(buttonValue)}>
                    {getFormattedReservationHours(reservationHour)}
                </Button>
            );

            while (reservationHour.getHours() !== endHour) {
                reservationHour.setMinutes(reservationHour.getMinutes() + reservationFrecuency);
                let selected = getFormattedReservationHours(new Date(reservationHour)) === getFormattedReservationHours(new Date(this.state.reservationHour));

                // check if condition continues true after adding interval minutes to hour
                if (reservationHour.getHours() !== endHour) {
                    /*   IMPORTANT!!! - Alex Lopez
                        Must create a bypass variable in order to pass right value to handleInputHour function.
                        If we pass directly new Date(reservationHour) at the arrow function, it got the last value, not the one at the moment we push button element to array.
                        This is because arrow functions always recall a new fresh function with fresh values and can cause unexpected data coercion
                    */
                    let buttonValue = new Date(reservationHour);
                    hours.push(
                        <Button variant={"outlined"} size={"small"} key={reservationHour}
                                className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                                onClick={() => this.handleInputHourChange(buttonValue)}>
                            {getFormattedReservationHours(reservationHour)}
                        </Button>
                    );
                } else if (reservationHour.getMinutes() === 0) {
                    let buttonValue = new Date(reservationHour);
                    hours.push(
                        <Button variant={"outlined"} size={"small"} key={reservationHour}
                                className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                                onClick={() => this.handleInputHourChange(buttonValue)}>
                            {getFormattedReservationHours(reservationHour)}
                        </Button>
                    );
                }
            }
        }
        return hours;
    }

    renderLunchHours(schedule) {
        let hours = [];

        if (schedule.lunchStartTime) {
            console.info("LUNCH: " + schedule.lunchStartTime);
            let reservationFrecuency = parseInt(schedule.reservationFrecuency);
            let startTime = schedule.lunchStartTime;
            let endHour = new Date(schedule.lunchEndTime).getHours();
            let reservationHour = new Date(startTime);

            //Push first hour
            let selected = getFormattedReservationHours(new Date(reservationHour)) === getFormattedReservationHours(new Date(this.state.reservationHour));
            let buttonValue = new Date(reservationHour);
            hours.push(
                <Button variant={"outlined"} size={"small"} key={reservationHour}
                        className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                        onClick={() => this.handleInputHourChange(buttonValue)}>
                    {getFormattedReservationHours(reservationHour)}
                </Button>
            );

            while (reservationHour.getHours() < endHour) {
                reservationHour.setMinutes(reservationHour.getMinutes() + reservationFrecuency);
                let selected = getFormattedReservationHours(new Date(reservationHour)) === getFormattedReservationHours(new Date(this.state.reservationHour));

                // check if condition continues true after adding interval minutes to hour
                if (reservationHour.getHours() < endHour) {
                    /*   IMPORTANT!!! - Alex Lopez
                        Must create a bypass variable in order to pass right value to handleInputHour function.
                        If we pass directly new Date(reservationHour) at the arrow function, it got the last value, not the one at the moment we push button element to array.
                        This is because arrow functions always recall a new fresh function with fresh values and can cause unexpected data coercion
                    */
                    let buttonValue = new Date(reservationHour);
                    hours.push(
                        <Button variant={"outlined"} size={"small"} key={reservationHour}
                                className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                                onClick={() => this.handleInputHourChange(buttonValue)}>
                            {getFormattedReservationHours(reservationHour)}
                        </Button>
                    );
                } else if (reservationHour.getMinutes() === 0) {
                    let buttonValue = new Date(reservationHour);
                    hours.push(
                        <Button variant={"outlined"} size={"small"} key={reservationHour}
                                className={selected ? importedStyles.hourButtonActive : importedStyles.hourButton}
                                onClick={() => this.handleInputHourChange(buttonValue)}>
                            {getFormattedReservationHours(reservationHour)}
                        </Button>
                    );
                }
            }
        }
        return hours;
    }

    renderAvailableHours() {
        //Si el dia elegido es 0 significa que es el domingo, asi que seria el indice 6 del array de schedules
        // si no, a getDay() se le resta 1 a fin de cuadrar este numero con el hecho de que los array empiezan en 0
        let daySelected = this.state.reservationDate.getDay() === 0 ? 6 : (this.state.reservationDate.getDay() - 1);
        const schedule = this.state.restaurant.schedules[daySelected];
        const lunchHours = this.renderLunchHours(schedule);
        const dinnerHours = this.renderDinnerHours(schedule);
        console.info(schedule)
        const allDayHours = this.renderAllDayHours(schedule);

        return (
            <React.Fragment>
                {schedule.allDay ?
                    <div className={importedStyles.hoursContainer}>
                        {allDayHours}
                    </div>
                    :
                    <div>
                        {lunchHours.length > 0 && <Typography variant={"caption"}>Comida</Typography>}
                        <div className={importedStyles.hoursContainer}>
                            {lunchHours}
                        </div>

                        {schedule.allDay ? <h1>TODO EL DIA</h1> : null}
                        {(lunchHours.length > 0 && dinnerHours.length > 0) &&
                        <Divider className={importedStyles.divider}/>}
                        {dinnerHours.length > 0 && <Typography variant={"caption"}>Cena</Typography>}
                        <div className={importedStyles.hoursContainer}>
                            {dinnerHours}
                        </div>
                        {/* Fallback message */}
                        {!schedule.lunchStartTime && !schedule.dinnerStartTime ?
                            <Typography variant={"subtitle1"}>Selecciona un día para ver su horario</Typography> : null}
                    </div>}

            </React.Fragment>
        );
    }

    renderCalendarAndHours() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={8} className={importedStyles.calendarHoursContainer} ref={this.selectDateRef}
                      id={'selectDate'}>
                    <Grid item xs={11} md={5}>
                        <Grid container spacing={32}>
                            <Grid item xs={12} className={importedStyles.sectionTitle}>
                                <CalendarIcon className={importedStyles.sectionTitleIcon}/>
                                <Typography variant={"h5"}>Selecciona una fecha</Typography>
                            </Grid>
                            <Grid item xs={12} className={importedStyles.calendarBox}>
                                <Calendar
                                    className={importedStyles.calendar}
                                    value={this.state.reservationDate}
                                    minDate={new Date()}
                                    maxDate={this.setMaxDate()}
                                    minDetail={'month'}
                                    tileDisabled={({date}) => this.setDisabledCalendarTiles({date})}
                                    onChange={(date) => this.handleInputDateChange(date)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={11} md={5} className={importedStyles.hoursParentContainer}>
                        <Grid container spacing={32}>
                            <Grid item xs={12} className={importedStyles.sectionTitle}>
                                <ClockIcon className={importedStyles.sectionTitleIcon}/>
                                <Typography variant={"h5"}>Selecciona una hora</Typography>
                            </Grid>
                            <Grid item xs={12} className={importedStyles.calendarBox}>
                                <Card className={importedStyles.hoursCard}>
                                    <CardContent>
                                        {this.renderAvailableHours()}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    renderAllowNotificationsCheck() {
        return (
            <React.Fragment>
                <Grid item xs={12} className={importedStyles.allowNotificationsBox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.allowNotifications}
                                onChange={() => this.setState((prevState) => ({allowNotifications: !prevState.allowNotifications}))}
                            />
                        }
                        label="Quiero recibir notificaciones sobre novedades y promociones del restaurante"
                    />
                </Grid>
            </React.Fragment>
        )
    }

    renderFirstStep() {
        return (

            <Grid container id={'firstStepFather'} spacing={0} className={importedStyles.firstStepFather}>
                {this.renderHowManyPeopleSection()}
                {this.renderCalendarAndHours()}
            </Grid>
        )
    }
    renderSecondStep() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={8} className={importedStyles.backButton}>
                    <Button variant={"outlined"} onClick={this.handleBackButton} disabled={this.state.sendingData} >
                        <ArrowBack className={importedStyles.buttonBackIcon}/> Volver
                    </Button>
                </Grid>
                <Grid item xs={12} md={8} className={importedStyles.reservationResume}>
                    <Grid item xs={12} className={importedStyles.resumeWrapper}>
                        <Grid item xs={12} md={4} className={importedStyles.reservationResumeItem}>
                            <CalendarIcon className={importedStyles.reservationResumeItemIcon}/>
                            {formatReservationResumeDate(this.state.reservationDate)}
                        </Grid>
                        <Grid item xs={12} md={4} className={importedStyles.reservationResumeItem}>
                            <ClockIcon className={importedStyles.reservationResumeItemIcon}/>
                            {getFormattedSchedule(this.state.reservationHour)}
                        </Grid>
                        <Grid item xs={12} md={4} className={importedStyles.reservationResumeItem}>
                            <UserIcon className={importedStyles.reservationResumeItemIcon}/>
                            {this.state.adults}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8} className={importedStyles.reservationForm} style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
                    <TextField
                        error={this.state.formError || this.state.reservationName.length <= 0}
                        required
                        margin="normal"
                        name="reservationName"
                        label="Nombre"
                        type="text"
                        variant="outlined"
                        value={this.state.reservationName}
                        onChange={this.handleInputChange}
                        fullWidth
                        inputProps={{maxLength: 50}}
                    />
                    <TextField
                        margin="normal"
                        required
                        error={this.state.emailError || this.state.reservationEmail.length <= 0}
                        variant="outlined"
                        name="reservationEmail"
                        label="Correo de contacto"
                        type="email"
                        value={this.state.reservationEmail}
                        onChange={this.handleInputChange}
                        fullWidth
                        inputProps={{maxLength: 50}}
                    />
                    <TextField
                        margin="normal"
                        required
                        error={this.state.formError || this.state.reservationPhone.length < 9}
                        name="reservationPhone"
                        label="Teléfono"
                        variant="outlined"
                        type="number"
                        value={this.state.reservationPhone}
                        onChange={this.handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        name="reservationNote"
                        label="Comentario"
                        type="text"
                        variant="outlined"
                        value={this.state.reservationNote}
                        onChange={this.handleInputChange}
                        fullWidth
                        helperText="Añade un comentario sobre la reserva de manera opcional"
                        multiline
                        inputProps={{maxLength: 150}}
                    />
                    <br/><br/>
                    <Typography variant={"subtitle2"}>
                        * Los campos marcados con asterisco son obligatorios.
                    </Typography>

                    {this.renderAllowNotificationsCheck()}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.makeReservation}
                        className={importedStyles.reservationButton}
                        disabled={
                            this.state.sendingData ||
                            !this.state.reservationName.length > 0 || !this.state.reservationEmail.length > 0 ||
                            !this.state.reservationPhone.length > 0 || !this.state.reservationAdults > 0 || this.state.emailError
                        }
                    >
                        {this.state.sendingData ? <CircularProgress size={25}/> : 'Reservar'}
                    </Button>
                    <Typography variant={"caption"} className={importedStyles.legalClause}>
                        Haciendo click en « Reservar », usted acepta la <a href={baseUrl + 'privacy'} target={'_blank'}> Politica de privacidad</a>
                        y las <a href={baseUrl + 'law'} target={'_blank'}>Condiciones legales </a> de ComerEn.
                        ComerEn y el restaurante, en calidad de responsables del tratamiento de los datos, procesan los datos personales
                        con el fin de tramitar reservas y gestionar las relaciones con los clientes.
                        Con arreglo a la legislación aplicable, tiene derecho a acceder, corregir, eliminar y transferir sus datos,
                        así como a oponerse a que sus datos sean procesados, en especial, si esto se lleva a cabo con fines de marketing.
                        Estos derechos los podrá ejercitar enviando un mensaje de correo electrónico a info@sevisl.com o poniéndose en contacto
                        con el restaurante. Puede trasladar una queja a la autoridad reguladora del uso de datos personales
                        si cree que sus datos personales no se han utilizado de conformidad con lo estipulado en la ley.
                        Si desea obtener más información, consulte la <a href={baseUrl + 'privacy'} target={'_blank'}>Política de privacidad</a> de ComerEn.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderDetail() {
        if (this.state.restaurant) {
            if (this.state.confirmedReservation) {
                return (
                    <React.Fragment>
                        {this.renderReservationConfirmedMessage()}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <Grid item xs={12} className={importedStyles.restaurantName}>
                            <Typography variant={"h4"}
                                        className={importedStyles.howManyPeopleGroupClaim}>Reserva
                                en {this.state.restaurant.name}</Typography>
                        </Grid>

                        {this.renderFirstStep()}

                        {(this.state.reservationHour && this.state.reservationDate && this.state.adults) && this.renderSecondStep()}
                    </React.Fragment>
                );
            }
        }
    }

    makeReservation = () => {
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'reservations/createReservationIframe', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.match.params.id,
                email: this.state.reservationEmail,
                name: this.state.reservationName,
                phone: this.state.reservationPhone,
                date: moment(this.state.reservationDate).format('Y-MM-DD'),
                hour: moment(this.state.reservationHour).locale('es').format('LT'),
                adults: this.state.adults,
                allowNotifications: this.state.allowNotifications,
                childs: 0,
                comment: this.state.reservationNote
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({sendingData: false, confirmedReservation: true});
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.props.dispatch(showSnack('Ha ocurrido un error al procesar tu reserva, inténtalo en unos minutos', snackTypes.error));
            this.setState({sendingData: false});
            console.error(error);
        });
    };

    renderReservationConfirmedMessage() {

        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: tick,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div className={importedStyles.confirmedReservation}>
                <Typography variant={"h5"} className={importedStyles.confirmedReservationMessage}>
                    Gracias por tu solicitud, en breve recibirás un correo del restaurante confirmando tu reserva
                </Typography>
                <Lottie options={defaultOptions}
                        height={250}
                        width={250}
                />
            </div>
        );
    }

    renderPermissionError() {
        return (
            <Grid item xs={12} className={importedStyles.restaurantName}>
                <Typography>
                    No tienes permisos para acceder a este módulo
                </Typography>
            </Grid>
        );
    }


    render() {
        if (this.state.adults > 0) {
            let selectDateBox = document.getElementById('selectDate');
            if (selectDateBox) {
                selectDateBox.style.display = 'flex';
            }
        }

        return (
            <div className={importedStyles.parentBox}>
                <Grid container spacing={32} className={importedStyles.gridContainer}>
                    {this.state.loadingRestaurant ?
                        <Grid item xs={12} className={importedStyles.progressAnimation}>
                            <CircularProgress size={60}/>
                        </Grid>
                        :
                        (this.state.restaurantHaveReservationsModule ? this.renderDetail() : this.renderPermissionError())
                    }

                </Grid>
                <Snack/>
            </div>
        );
    }
}

const mapStateToProps = ({searchReducer}) => {
    return ({
        // filterActive: searchReducer.filterActive,
    });
};

export default connect(mapStateToProps)(ReservationIframe);