import React from 'react';
import importedStyles from "./NavbarLanding.module.sass";
import history from '../../../../helpers/history'
import Logo from '../../../../rsc/img/logo-comerEn.png';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class NavbarLanding extends React.Component {

    state = {
        drawerOpen: false,
    };

    handleDrawerOpen = () => {
      this.setState({drawerOpen: !this.state.drawerOpen})
    };

    componentDidMount() {
        if(window.innerWidth > 760)
            this.setState({drawerOpen: true})
    }

    renderMenu(){

        let navbarClasses = [
            !this.state.drawerOpen ? importedStyles.navbarSlideIn : importedStyles.navbarSlideOut,
            importedStyles.navbarContainer
        ].join(' ');

            return (
                <div className={navbarClasses}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.handleDrawerOpen}
                        className={importedStyles.handleButton}
                    >
                        <ChevronRightIcon/>
                    </IconButton>
                    <img className={importedStyles.logo} onClick={() => history.push('/')} src={Logo} alt={'logo'}/>
                    <div className={importedStyles.buttonsContainer}>
                        <button className={importedStyles.buttonItem}
                                onClick={() => history.push('/comerEn/restaurantes')}>Restaurantes
                        </button>
                        <button className={importedStyles.buttonItem}
                                onClick={() => history.push('/comerEn/eventos')}>Eventos
                        </button>
                        <button className={importedStyles.buttonItem}
                                onClick={() => history.push('/comerEn/contacto')}>Contacta
                        </button>
                    </div>
                </div>
            );
    }
    render() {

        return (
            <div className={importedStyles.navbar}>
                <div className={importedStyles.navbarContainerMobile}>
                    <img  onClick={()=> history.push('/')} src={Logo} alt={'logo'}/>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.handleDrawerOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </div>
                {this.renderMenu()}
            </div>);
    }
}

export default NavbarLanding;