import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import RestaurantCard from "../Restaurant/components/RestaurantCard";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Typography from "@material-ui/core/Typography/Typography";
import Slide from "@material-ui/core/Slide/Slide";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import connect from "react-redux/es/connect/connect";
import history from "../../helpers/history";
import {detail_rte} from "./restaurantActions";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import importedStyle from './restaurantStyle';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Snack from "../../components/Snack/Snack";
import Fab from "@material-ui/core/es/Fab/Fab";
import Avatar from "@material-ui/core/Avatar/Avatar";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import FolderIcon from '@material-ui/icons/FolderOpen';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
// told Material UI to use new Typography(v2) avoiding deprecated ones
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class myRestaurantScreen extends React.Component {

    state = {
        expanded: false,
        loadingRestaurants: false,
        restaurants: {},
        formError: false,
        addRestaurantModalOpen: false,
        assignRestaurantToUserModal: false,
        newRestaurantLogo: undefined,
        newRestaurantLogoPreview: '',
        newRestaurantName: '',
        newRestaurantDescription: '',
        newRestaurantPhone: '',
        newRestaurantPhone2: '',
        newRestaurantCity: '',
        newRestaurantAddress: '',
        newRestaurantWeb: '',
        newRestaurantCapacity: '',
        newRestaurantPrice: '',
        newRestaurantEmail: '',
        newRestaurantOwnerName: '',
        newRestaurantOwnerSurname: '',
        newRestaurantOwnerEmail: '',
        newRestaurantOwnerPassword: '',
        newRestaurantOwnerPhone: '',
        user: {},
        associations: null,
        selectedAssociations: [],
        refreshDrawerInfo: false,
    };


    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({user: nextProps.user});
        // controlar

        this.getRestaurants(nextProps.user.uuid);
        this.getAssociations();

    }

    componentDidMount() {
        if (this.props.user && this.props.user.uuid) {
            this.getRestaurants(this.props.user.uuid);
            this.getAssociations();
        }
    }

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    handleAddRestaurantModal = () => {
        if (this.state.addRestaurantModalOpen) {
            this.setState({
                newRestaurantLogo: '',
                newRestaurantName: '',
                newRestaurantDescription: '',
                newRestaurantPhone: '',
                newRestaurantPhone2: '',
                newRestaurantCity: '',
                newRestaurantAddress: '',
                newRestaurantWeb: '',
                newRestaurantCapacity: '',
                newRestaurantPrice: '',
                newRestaurantEmail: '',
                addRestaurantModalOpen: false
            })
        } else {
            this.setState(state => ({addRestaurantModalOpen: !state.addRestaurantModalOpen}));
        }
    };
    handleAssignRestaurantModal = () => {
        if (this.state.assignRestaurantToUserModal) {
            this.setState({
                newRestaurantOwnerName: '',
                newRestaurantOwnerSurname: '',
                newRestaurantOwnerEmail: '',
                newRestaurantOwnerPassword: '',
                newRestaurantOwnerPhone: '',
                assignRestaurantToUserModal: false
            })
        } else {
            this.setState(state => ({assignRestaurantToUserModal: !state.assignRestaurantToUserModal}));
        }
    };

    handleCityChange = city => {
        let comaPosition = city.indexOf(',');
        if (comaPosition !== -1) {
            city = city.substring(0, comaPosition);
        }
        this.setState({newRestaurantCity: city});
    };

    renderAutocompleteCityInput() {

        const searchOptions = {
            componentRestrictions: {country: 'es'},
            types: ["(cities)"]
        };

        return (
            <PlacesAutocomplete
                value={this.state.newRestaurantCity}
                onChange={this.handleCityChange}
                searchOptions={searchOptions}
            >
                {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <div>
                        <TextField
                            variant={"outlined"}
                            error={this.state.formError && this.state.newRestaurantCity.length <= 0}
                            margin="normal"
                            name={"city"}
                            label="Ciudad"
                            type="text"
                            fullWidth
                            {...getInputProps({
                                autoComplete: "nope"
                            })}
                        />

                        <div>
                            {loading && <div>Generando sugerencias...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? {backgroundColor: '#c4c4c4', cursor: 'pointer', marginBottom: 5}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer', marginBottom: 5};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        )
    }

    getRestaurants(idUser) {
        this.setState({loadingRestaurants: true});
        let apiEndPoint = '';
        let data = {};
        if (this.props.user.association) {
            apiEndPoint = 'associations/listRestaurants';
            data = {associationId: this.props.user.associationData.uuid}
        } else {
            apiEndPoint = 'users/listRestaurants';
            data = {owner: idUser}
        }
        fetch(apiBaseUrl + apiEndPoint, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({restaurants: response.restaurants, loadingRestaurants: false})
            } else {
                this.setState({loadingRestaurants: false});
                console.error(response.errorMessage);
            }

        }).catch((error) => {
            this.setState({loadingRestaurants: false});
            console.error(error);
        });

    }

    renderRestaurants() {

        const restaurants = this.state.restaurants;
        let restaurantCards = [];

        Object.keys(restaurants).forEach((restaurant) => {

            restaurantCards.push(
                <RestaurantCard
                    key={restaurants[restaurant].uuid}
                    id={restaurants[restaurant].uuid}
                    title={restaurants[restaurant].name}
                    description={restaurants[restaurant].description}
                    website={restaurants[restaurant].website}
                    phone={restaurants[restaurant].phone}
                    email={restaurants[restaurant].email}
                    avatar={restaurants[restaurant].logo}
                    capacity={restaurants[restaurant].capacity}
                    schedule={restaurants[restaurant].schedule}
                    price={restaurants[restaurant].price}
                    association={this.props.user.association}
                    user={restaurants[restaurant].user ? restaurants[restaurant].user : ''}
                    functionClick={() => {
                        if (this.props && !this.props.user.association) {
                            this.props.dispatch(detail_rte(restaurants[restaurant].uuid, restaurants[restaurant].name, restaurants[restaurant].logo, restaurants[restaurant].restaurants_role, restaurants[restaurant].url_slug ));
                            history.push('/detalleRestaurante');
                        }
                    }}
                />)
        });
        if (restaurantCards.length === 0) {
            return (
                <p style={{margin: 20}}>Crea tu primer restaurante...</p>
            );
        } else {
            return restaurantCards;
        }
    }

    handleInputChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };
    handleChange = event => {
        this.setState({selectedAssociations: event.target.value});
    };

    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    createNewRestaurant() {

        const name = this.state.newRestaurantName;
        const description = this.state.newRestaurantDescription;
        const email = this.state.newRestaurantEmail;
        const phone = this.state.newRestaurantPhone;
        const phone2 = this.state.newRestaurantPhone2.length > 0 ? this.state.newRestaurantPhone2 : null;
        const web = this.state.newRestaurantWeb.length > 0 ? this.state.newRestaurantWeb : null;
        const capacity = this.state.newRestaurantCapacity;
        const price = this.state.newRestaurantPrice;
        const city = this.state.newRestaurantCity;
        const address = this.state.newRestaurantAddress;

        /*
        let associations = [];

       if (this.state.selectedAssociations.length > 0) {
            const selectedAssociations = this.state.selectedAssociations;
            associations = [];
            Object.keys(selectedAssociations).forEach((association) => {
                associations.push(selectedAssociations[association].uuid);
            });
        }*/

        if (name.length <= 0 || email.length <= 0 || phone.length <= 0 || capacity.length <= 0 || city.length <= 0 || address.length <= 0) {
            this.setState({formError: true})
        } else {
            let formData = new FormData();

            this.state.newRestaurantLogo && formData.append('avatar', this.state.newRestaurantLogo);

            formData.append('name', name);
            formData.append('description', description);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('phone2', phone2);
            formData.append('website', web);
            formData.append('capacity', capacity);
            formData.append('price', price);
            formData.append('city', city);
            formData.append('address', address);
            formData.append('businessTypeUuid', '2');
            formData.append('cityUuid', '1');

            formData.append('user', this.props.user.uuid);
            /*const newRestaurant = {
                'name': name,
                'description': description,
                'email': email,
                'phone': phone,
                'phone2': phone2,
                'website': web,
                'capacity': capacity,
                'city': city,
                'address': address,
                'businessTypeUuid': '2',
                'cityUuid': '1',
                'associations': associations,
                'user': this.props.user.uuid,
            };*/
            this.setState({sendingData: true});
            fetch(apiBaseUrl + 'restaurants/createRestaurant', {
                method: 'post',
                body: formData,
            }).then((responseJSON) => {
                return responseJSON.text();
            }).then((respuesta) => {
                respuesta = JSON.parse(respuesta);
                if (!respuesta.error) {
                    this.setState({
                        formError: false,
                        selectedAssociations: [],
                        sendingData: false,
                        refreshDrawerInfo: !this.state.refreshDrawerInfo
                    });
                    this.props.dispatch(showSnack('Restaurante creado con éxito', snackTypes.success));
                    this.getRestaurants(this.props.user.uuid);
                    this.handleAddRestaurantModal();
                } else {
                    throw new Error();
                }
            }).catch((error) => {
                console.error(error);
                this.setState({sendingData: false});
                this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
            });
        }
    }

    createNewRestaurantAndAssignToUser() {

        const name = this.state.newRestaurantName;
        const description = this.state.newRestaurantDescription;
        const email = this.state.newRestaurantEmail;
        const phone = this.state.newRestaurantPhone;
        const phone2 = this.state.newRestaurantPhone2.length > 0 ? this.state.newRestaurantPhone2 : null;
        const web = this.state.newRestaurantWeb.length > 0 ? this.state.newRestaurantWeb : null;
        const capacity = this.state.newRestaurantCapacity;
        const price = this.state.newRestaurantPrice;
        const city = this.state.newRestaurantCity;
        const address = this.state.newRestaurantAddress;

        const userName = this.state.newRestaurantOwnerName;
        const userSurname = this.state.newRestaurantOwnerSurname;
        const userEmail = this.state.newRestaurantOwnerEmail;
        const userPassword = this.state.newRestaurantOwnerPassword;
        const userPhone = this.state.newRestaurantOwnerPhone;




        if (name.length <= 0 || email.length <= 0 || phone.length <= 0 || capacity.length <= 0 || city.length <= 0 || address.length <= 0
            || userName.length <= 0 || userSurname.length <= 0 || userEmail.length <= 0 || userPassword.length <= 0 || userPhone.length <= 0) {
            this.setState({formError: true})
        } else {
            this.setState({sendingData: true});
            fetch(apiBaseUrl + 'restaurants/createRestaurantAndUser', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    'associationUuid': this.props.user.associationData.uuid,
                    'name': name,
                    'description': description,
                    'email': email,
                    'phone': phone,
                    'phone2': phone2,
                    'website': web,
                    'capacity': capacity,
                    'price': price,
                    'city': city,
                    'address': address,
                    'businessTypeUuid': '2',
                    'cityUuid': '1',
                    'userName': userName,
                    'userSurname': userSurname,
                    'userEmail': userEmail,
                    'userPassword': userPassword,
                    'userPhone': userPhone,
                }),
            }).then((responseJSON) => {
                return responseJSON.text();
            }).then((respuesta) => {
                respuesta = JSON.parse(respuesta);
                if (!respuesta.error) {
                    this.setState({
                        formError: false,
                        sendingData: false,
                        refreshDrawerInfo: !this.state.refreshDrawerInfo
                    });
                    this.props.dispatch(showSnack('Restaurante creado con éxito', snackTypes.success));

                    this.getRestaurants(this.props.user.uuid);

                    this.handleAddRestaurantModal();
                    this.handleAssignRestaurantModal();
                } else {
                    throw new Error();
                }
            }).catch((error) => {
                console.error(error);
                this.setState({sendingData: false});
                this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido crear', snackTypes.error));
            });
        }
    }

    getStyles(association, that) {
        return {
            fontWeight:
                that.state.selectedAssociations.indexOf(association) === -1
                    ? that.props.theme.typography.fontWeightRegular
                    : that.props.theme.typography.fontWeightMedium,
        };
    }

    getAssociations() {
        fetch(apiBaseUrl + 'associations', {
            method: 'get',
            headers: {'Content-Type': 'application/json'},
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            this.setState({associations: JSON.parse(recurso)})
        }).catch((error) => {
            console.error(error);
        });

    }

    renderAssociationSelect() {
        const {classes} = this.props;
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        return (
            <React.Fragment>
                <Typography
                    variant="subheading"
                    className={classes.textAsociation}
                    color={"primary"}
                >
                    Si tu restaurante pertenece a alguna asociación, puedes seleccionarlas aquí:
                </Typography>

                <Select
                    multiple
                    displayEmpty
                    variant={"outlined"}
                    margin="normal"
                    fullWidth
                    name="selectedAssociations"
                    value={this.state.selectedAssociations}
                    renderValue={selected => {

                        if (selected.length === 0) {
                            return <em style={{fontStyle: 'italic'}}>-- Asociaciones --</em>;
                        } else {
                            let selectedAssociationsNames = [];
                            this.state.selectedAssociations.forEach(association => {
                                selectedAssociationsNames.push(association.name);
                            });
                            return selectedAssociationsNames.join(', ');
                        }
                    }}
                    onChange={this.handleChange}
                    input={<OutlinedInput labelWidth={0} id="select-multiple-associations"/>}
                    MenuProps={MenuProps}
                    style={{marginBottom: 15}}
                >
                    <MenuItem disabled value="">
                        <em>--Asociaciones--</em>
                    </MenuItem>
                    {this.state.associations &&
                    this.state.associations.map(association => (
                        <MenuItem key={association.uuid} value={association}
                                  style={this.getStyles(association.uuid, this)}>
                            <Checkbox checked={this.state.selectedAssociations.indexOf(association) > -1}/>
                            <ListItemText primary={association.name}/>
                        </MenuItem>
                    ))
                    }
                </Select>
            </React.Fragment>
        );
    }

    getAddressGeopoint = address => {

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };

    handleImg(e) {
        let file = e.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onload = (ev) => {
                this.setState({newRestaurantLogoPreview: ev.target.result});
            };
            reader.readAsDataURL(file);
            this.setState({newRestaurantLogo: file});
        }
    };

    renderImageUploader() {
        const {classes} = this.props;
        let newLogoName = '';
        let avatar = (
            <Avatar
                className={classes.avatar}
                src={'https://image.freepik.com/foto-gratis/fondo-interior-tienda-restaurante-borrosa_1203-4031.jpg'}
            />
        );
        if (this.state.newRestaurantLogo) {
            let fileName = this.state.newRestaurantLogo.name;
            newLogoName = fileName.substring(0, 6) + '... .' + fileName.substr((fileName.lastIndexOf('.') + 1));
            avatar = (
                <Avatar
                    className={classes.avatar}
                    src={this.state.newRestaurantLogoPreview}
                />
            );
        }
        return (
            <React.Fragment>
                <div className={classes.selectFileBox}>
                    <label className={classes.selectFileLabel}>
                        Logo
                    </label>
                    <div className={classes.selectFileActions}>
                        {avatar}
                        {this.state.newRestaurantLogo ?
                            <List>
                                <ListItem className={classes.iconLeft}>
                                    <ListItemText primary={newLogoName}/>
                                    <ListItemSecondaryAction className={classes.iconRight}>
                                        <IconButton onClick={() => this.setState({newRestaurantLogo: undefined})}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                            :
                            <List>
                                <ListItem className={classes.iconLeft}>
                                    <ListItemText primary={'Logo predeterminado'}/>
                                </ListItem>
                            </List>
                        }
                        <Button
                            variant="contained"
                            component="label"
                            size={"small"}
                            className={classes.fileUploadButton}
                        >
                            <FolderIcon className={classes.iconLeft}/>
                            Seleccionar imagen
                            <input
                                type="file"
                                accept="image/*"
                                id='documentFile'
                                onChange={(e) => this.handleImg(e)}
                                style={{display: "none"}}
                            />
                        </Button>
                    </div>
                </div>

            </React.Fragment>
        )
    }


    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <MiniDrawer title={"Mis restaurantes"} resfresh={this.state.refreshDrawerInfo}/>
                {/* <NavBar style={{marginBottom: 10}} title="eMenu"/> */}
                <div className={classes.content}>

                    {this.state.loadingRestaurants ?
                        <div className={classes.chargingAnimationContainer}>
                            <CircularProgress size={65}/>
                        </div>
                        :
                        this.renderRestaurants()
                    }

                </div>
                <Fab variant="extended" color="primary" aria-label="Add" className={classes.button}
                     onClick={this.handleAddRestaurantModal}>
                    <AddIcon style={{marginRight: 5}}/>
                    Nuevo Restaurante
                </Fab>


                {/********** Add new restaurant modal *********/}
                <Dialog
                    fullScreen
                    open={this.state.addRestaurantModalOpen}
                    onClose={this.handleAddRestaurantModal}
                >

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="title" color="inherit" className={classes.flex}>
                                Nuevo Restaurante
                            </Typography>
                            <Typography variant="subheading" className={classes.flex}
                                        style={{color: '#ffb38d', marginLeft: 15, fontStyle: 'italic'}}>

                                {this.state.formError ? '* Debes rellenar los campos señalados en rojo' : null}
                            </Typography>
                            <IconButton color="inherit" onClick={this.handleAddRestaurantModal} aria-label="Close"
                                        style={{position: 'absolute', right: 5}}>
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogTitle id="form-dialog-title">Nuevo Restaurante</DialogTitle>
                    <DialogContent style={{marginTop: 15, display: 'flex', flexDirection: 'column'}}>
                        <form>
                            <TextField
                                autoFocus
                                error={this.state.formError && this.state.newRestaurantName.length <= 0}
                                margin="normal"
                                name="newRestaurantName"
                                label="Nombre del negocio"
                                type="text"
                                variant="outlined"
                                onChange={this.handleInputChange}
                                fullWidth
                                helperText="Ej: Gastrobar el Menú"
                                inputProps={{maxLength: 50}}
                            />
                            <TextField
                                name="newRestaurantDescription"
                                label="Descripción"
                                error={this.state.formError && this.state.newRestaurantDescription.length <= 0}
                                variant="outlined"
                                multiline
                                rowsMax="4"
                                onChange={this.handleInputChange}
                                margin="normal"
                                helperText="Ej: La mejor cocina de la provincia, ven a probar nuestros platos"
                                fullWidth
                                inputProps={{maxLength: 150}}

                            />

                            <TextField
                                margin="normal"
                                error={this.state.formError && this.state.newRestaurantPhone.length <= 0}
                                name="newRestaurantPhone"
                                label="Teléfono"
                                variant="outlined"
                                type="number"
                                onChange={this.handleInputChange}
                                fullWidth
                                inputProps={{maxLength: 9}}
                                helperText="Ej: 625761224"
                            />

                            <TextField
                                margin="normal"
                                variant="outlined"
                                name="newRestaurantPhone2"
                                label="2º Teléfono"
                                type="number"
                                onChange={this.handleInputChange}
                                fullWidth
                                inputProps={{maxLength: 9}}
                                helperText="Opcional"
                            />
                            <TextField
                                margin="normal"
                                error={this.state.formError && this.state.newRestaurantEmail.length <= 0}
                                variant="outlined"
                                name="newRestaurantEmail"
                                label="Correo de contacto"
                                type="email"
                                onChange={this.handleInputChange}
                                fullWidth
                                helperText="Ej: hola@comeren.com"
                                inputProps={{maxLength: 50}}
                            />
                            <TextField
                                margin="normal"
                                variant="outlined"
                                name="newRestaurantWeb"
                                label="Web"
                                type="text"
                                onChange={this.handleInputChange}
                                fullWidth
                                inputProps={{maxLength: 500}}
                                helperText="Opcional"

                            />

                            <TextField
                                margin="normal"
                                variant="outlined"
                                error={this.state.formError && this.state.newRestaurantCapacity.length <= 0}
                                name="newRestaurantCapacity"
                                label="Aforo"
                                type="number"
                                onChange={this.handleInputChange}
                                fullWidth
                                helperText="Opcional"
                            />
                            <TextField
                                margin="normal"
                                variant="outlined"
                                // error={this.state.formError && this.state.newRestaurantPrice.length <= 0}
                                name="newRestaurantPrice"
                                label="Precio medio"
                                type="number"
                                onChange={this.handleInputChange}
                                fullWidth
                                helperText="Opcional"
                            />
                            {this.renderAutocompleteCityInput()}

                            <TextField
                                margin="normal"
                                variant="outlined"
                                error={this.state.formError && this.state.newRestaurantAddress.length <= 0}
                                name="newRestaurantAddress"
                                label="Dirección"
                                type="text"
                                onChange={this.handleInputChange}
                                fullWidth
                                helperText="Ej: C/ Chef de Papillón 3"
                                inputProps={{maxLength: 50}}
                                style={{marginBottom: 15}}
                            />

                            {this.props.user.association ? null : this.renderAssociationSelect}

                            {this.renderImageUploader()}
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.props.user.association ?
                                    this.setState({
                                        assignRestaurantToUserModal: true
                                    }) : this.createNewRestaurant()
                            }}
                            disabled={
                                this.state.sendingData || !this.state.newRestaurantAddress.length > 0 ||
                                !this.state.newRestaurantName.length > 0 || !this.state.newRestaurantEmail.length > 0 ||
                                !this.state.newRestaurantAddress.length > 0 || !this.state.newRestaurantDescription.length > 0 ||
                                !this.state.newRestaurantPhone.length > 0 || !this.state.newRestaurantCapacity.length > 0
                            }
                        >
                            {this.props.user.association ? 'Siguiente' : 'Crear Restaurante'}
                            {this.state.sendingData &&
                            <CircularProgress size={25} className={classes.chargingAnimation}/>}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/********** Assign new restaurant to user modal *********/}
                <Dialog
                    fullScreen
                    open={this.state.assignRestaurantToUserModal}
                    onClose={this.handleAssignRestaurantModal}
                >

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit"
                                        onClick={() => this.setState({assignRestaurantToUserModal: false})}
                                        aria-label="Close"
                                        style={{marginRight: 5}}>
                                <ArrowBack/>
                            </IconButton>
                            <Typography variant="title" color="inherit" className={classes.flex}>
                                Asignar restaurante
                            </Typography>
                            <Typography variant="subheading" className={classes.flex}
                                        style={{color: '#ffb38d', marginLeft: 15, fontStyle: 'italic'}}>
                                {this.state.formError ? '* Debes rellenar los campos señalados en rojo' : null}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogTitle id="form-dialog-title" style={{paddingTop: 115}}>Necesitamos vincular este nuevo
                        restaurante a un usuario, rellena sus datos</DialogTitle>
                    <DialogContent style={{marginTop: 15, display: 'flex', flexDirection: 'column'}}>
                        <form>
                            <TextField
                                autoFocus
                                error={this.state.formError && this.state.newRestaurantOwnerName.length <= 0 ? true : null}
                                margin="normal"
                                name="newRestaurantOwnerName"
                                label="Nombre del dueño"
                                type="text"
                                variant="outlined"
                                onChange={this.handleInputChange}
                                fullWidth
                                helperText="Ej: Peter"
                                inputProps={{maxLength: 50}}
                            />
                            <TextField
                                name="newRestaurantOwnerSurname"
                                label="Apellidos"
                                error={this.state.formError && this.state.newRestaurantOwnerSurname.length <= 0 ? true : null}
                                variant="outlined"
                                type={'text'}
                                onChange={this.handleInputChange}
                                margin="dense"
                                helperText="Ej: Parker Sánchez"
                                fullWidth
                                inputProps={{maxLength: 150}}
                            />
                            <TextField
                                margin="normal"
                                error={this.state.formError && this.state.newRestaurantOwnerEmail.length <= 0 ? true : null}
                                name="newRestaurantOwnerEmail"
                                label="Correo"
                                variant="outlined"
                                type="email"
                                onChange={this.handleInputChange}
                                fullWidth
                                inputProps={{maxLength: 50}}
                                helperText="Ej: pit@gmail.com"
                            />
                            <TextField
                                margin="normal"
                                error={this.state.formError && this.state.newRestaurantOwnerPassword.length <= 0 ? true : null}
                                name="newRestaurantOwnerPassword"
                                label="Contraseña"
                                variant="outlined"
                                type="password"
                                onChange={this.handleInputChange}
                                fullWidth
                                inputProps={{maxLength: 50}}
                            />
                            <TextField
                                margin="normal"
                                error={this.state.formError && this.state.newRestaurantOwnerPhone.length <= 0 ? true : null}
                                variant="outlined"
                                name="newRestaurantOwnerPhone"
                                label="Teléfono"
                                type="number"
                                onChange={this.handleInputChange}
                                fullWidth
                                helperText="Ej: 612345678"
                                inputProps={{maxLength: 9}}
                            />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => this.createNewRestaurantAndAssignToUser()}
                            disabled={
                                this.state.sendingData || !this.state.newRestaurantOwnerName.length > 0 ||
                                !this.state.newRestaurantOwnerSurname.length > 0 || !this.state.newRestaurantOwnerEmail.length > 0 ||
                                !this.state.newRestaurantOwnerPassword.length > 0 || !this.state.newRestaurantOwnerPhone.length > 0
                            }
                        >
                            Crear Restaurante
                            {this.state.sendingData &&
                            <CircularProgress size={25} className={classes.chargingAnimation}/>}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snack/>
            </div>

        );
    }
}

myRestaurantScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = ({authReducer}) => {
    return ({user: authReducer.credentials[0]});
};

export default connect(mapStateToProps)(withStyles(importedStyle(), {withTheme: true})(myRestaurantScreen));






