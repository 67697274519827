import React from "react";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card/Card";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import importedStyles from "./digitalMenuScreenMobileWithImages.module.sass";
import Snack from "../../components/Snack/Snack";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ChevronRightIcon from "@material-ui/icons/ChevronRightRounded";
import {IconButton, List, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {
    change_active_menu,
    change_current_product,
    change_current_restaurant,
    change_current_section
} from "./digitalMenuActions";
import DigitalMenuNavBar from "./components/DigitalMenuNavBar/DigitalMenuNavBarMobile";
import SectionContentBox from "./components/SectionContentBox/SectionContentBox";
import CloseIcon from "@material-ui/icons/Close";
import ListItem from "@material-ui/core/ListItem";
import CardMedia from "@material-ui/core/CardMedia";
import Fade from "@material-ui/core/Fade";
import ProductDetail from "./components/ProductDetail/ProductDetail";
import Grow from "@material-ui/core/Grow";
import imageDefault from "../../../src/rsc/img/image-default.png"
import CardActionArea from "@material-ui/core/CardActionArea";
import Skeleton from "@material-ui/lab/Skeleton";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {handleImgLoadError} from "../../helpers/utils";
import CartNotFound from "../CardNotFound/CartNotFound";
import {darken, hexToRgbA, lighten} from "../../helpers/manageColors";
import history from "../../helpers/history";


class digitalMenuScreenMobileWithImages extends React.Component {

    state = {
        restaurant: {},
        restaurantMenus: [],
        restaurantLogo: '',
        restaurantName: '',
        restaurantRoles: '',
        navbarTitle: '',
        menu: {},
        cart: [],
        selectedSection: '',
        selectedProduct: null,
        existMenu: false,
        gettingData: true,
        isMenuSelected: false,
        securityPinModal: false,
        idleRefreshDialog: false,
        previewMode: false,
        firstAdditionToCart: true,
        firstAdditionToCartTutorialDialog: false,
        currentDish: null,
        primaryColor: null,
        secondaryColor: null,
    };

    async componentDidMount() {
        this.getStyles();
        await this.getRestaurantInfo();
        //let restaurantChanged = this.checkIfRestaurantChanged();
    }

    getStyles() {
        this.setState({gettingData: true});
        let restaurantUuid = this.props.match.params.restaurantUuid;
        fetch(apiBaseUrl + 'restaurants/styles/getStyles', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: restaurantUuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.error) {
                this.setState({});
            } else {
                this.setState({
                    primaryColor: response.data.primaryColor,
                    secondaryColor: response.data.secondaryColor,
                });
            }
        }).catch((error) => {
            console.error('Error: ' + error);
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    getRestaurantInfo = () => {
        let restaurantUuid = this.props.match.params.restaurantUuid;
        fetch(apiBaseUrl + 'restaurants/getRestaurant', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: restaurantUuid
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    restaurant: response.restaurant,
                    restaurantName: response.restaurant.name,
                    restaurantMenus: response.restaurant.menus,
                    restaurantRoles: response.restaurant.restaurants_role,
                    gettingData: false
                });
            } else {
                throw new Error('Error: ' + response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.props.dispatch(showSnack('Lo sentimos, parece que hay algun problema cargando la información, prueba de nuevo en unos minutos', 'error'))
        });
    };


    handleFirstAdditionToCart = () => {
        if (this.state.firstAdditionToCart) {
            this.props.dispatch(showSnack('¡Acabas de añadir tu primer plato al pedido! \n' +
                'Recuerda que podrás ver tu pedido pulsando el icono de carrito en la barra superior.', 'success'));
            this.setState({firstAdditionToCart: false});
        }
    };
    handleFirstAdditionToCartTutorialDialog = () => {
        this.setState((prevState, props) => ({
            firstAdditionToCartTutorialDialog: !prevState.firstAdditionToCartTutorialDialog,
        }));
    };

    handleSectionSelect = e => {
        this.props.dispatch(change_current_section(e.target.value)); //save currentSection to redux, if page refreshed then we can retrieve it from there
        this.setState({selectedSection: e.target.value});
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    handleSectionChange = (nextSection) => {
        this.props.dispatch(change_current_section(nextSection));
        this.setState({selectedSection: nextSection});
        window.scrollTo({top: 0, behavior: 'smooth'});
        history.push(`/app/menuPro/${this.props.match.params.restaurantUuid}/${this.props.match.params.menuUuid}/${nextSection.url_slug}`);
    };

    handleBack = () => {
        // history.goBack();
        let restaurantParam = this.props.match.params.restaurantUuid ? this.props.match.params.restaurantUuid : "";
        let menuParam = this.props.match.params.menuUuid ? this.props.match.params.menuUuid : "";
        let sectionParam = this.props.match.params.sectionUuid ? this.props.match.params.sectionUuid : "";
        let productParam = this.props.match.params.productUuid ? this.props.match.params.productUuid : "";

        if (productParam) {
            history.push(`/app/menuPro/${restaurantParam}/${this.props.match.params.menuUuid}/${this.props.match.params.sectionUuid}`);
        } else if (sectionParam) {
            history.push(`/app/menuPro/${restaurantParam}/${this.props.match.params.menuUuid}`);
        } else if (menuParam) {
            history.push(`/app/menuPro/${restaurantParam}`);
        } else {
            history.push(`/app/menuPro/${restaurantParam}`);

        }
    };

    checkIfRestaurantChanged = () => {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        const lastRestaurantOnRedux = this.props.currentRestaurant;

        let restaurantChanged = lastRestaurantOnRedux !== restaurantUuid;

        if (restaurantChanged) {
            //If restaurant changed, then save current restaurant uuid on redux
            this.props.dispatch(change_current_restaurant(restaurantUuid));
        }

        return restaurantChanged;
    };

    /////////////////////////////

    /* Menu SELECTOR LOGICS*/
    handleMenuSelection = (selectedMenu) => {
        this.props.dispatch(change_active_menu(selectedMenu));
        this.setState({menu: selectedMenu, isMenuSelected: true, existMenu: true});
        history.push(`/app/menuPro/${this.props.match.params.restaurantUuid}/${selectedMenu.url_slug}`)
    };

    handleProductSelection = (selectedProduct) => () => {
        // console.info(selectedProduct.uuid);
        this.props.dispatch(change_current_product(selectedProduct));
        this.setState({selectedProduct: selectedProduct});
        // window.scrollTo({top: 0, behavior: 'smooth'});
        history.push(`/app/menuPro/${this.props.match.params.restaurantUuid}/${this.props.match.params.menuUuid}/${this.props.match.params.sectionUuid}/${selectedProduct.url_slug}`);

    };
    //////////// RENDERS //////////////

    renderFirstScreen = () => {
        let menus = this.state.restaurant.menus;
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : "#000000"
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : "#DCB061"
        if (menus && menus.length > 0 && this.state.restaurantRoles.menusWithImages) {
            let menusList = menus.map((menu, key) => {
                if (menu.active) {
                    return (
                        <Grow key={key} in={true} appear={true}
                              style={{transformOrigin: '0 0 0'}}  {...{timeout: (key * 200) + 900}}>
                            <Card elevation={2} style={{marginBottom: '1rem'}}
                                  onClick={() => this.handleMenuSelection(menu)}>
                                <ListItem button className={importedStyles.menuListItem}
                                          style={{backgroundColor: primaryColor}}>
                                    <ListItemText primary={menu.name} style={{color: secondaryColor}}/>
                                    <ListItemSecondaryAction>
                                        <ChevronRightIcon
                                            style={{
                                                backgroundColor: secondaryColor,
                                                color: primaryColor,
                                                borderRadius: 45
                                            }}/>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Card>
                        </Grow>
                    )
                }
            });

            return (
                <Fade in={true} timeout={800} mountOnEnter unmountOnExit>
                    <div>
                        {/*<Typography variant={'subtitle1'} className={importedStyles.restaurantName}>{this.state.restaurant.name}</Typography>*/}
                        <img alt="Restaurant Logo" src={this.state.restaurant.logo}
                             className={importedStyles.restaurantLogoHeader}/>
                        <List className={importedStyles.menusList}>
                            {menusList}
                        </List>
                    </div>
                </Fade>
            );
        } else {
            return <CartNotFound/>;
        }
    };

    renderContent() {
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : "#000000"
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : "#DCB061"
        let restaurantParam = this.props.match.params.restaurantUuid;
        let menuParam = this.props.match.params.menuUuid;
        let sectionParam = this.props.match.params.sectionUuid;
        let productParam = this.props.match.params.productUuid;
        if (productParam) {
            let sections = this.state.restaurantMenus.find(menu => menu.uuid == menuParam);
            if(sections === undefined){
                sections = this.state.restaurantMenus.find(menu => menu.url_slug == menuParam).menu_sections;
            }else{
                sections = sections.menu_sections
            }

            let section = sections.find(section => section.uuid == sectionParam);
            if(section === undefined){
                section = sections.find(section => section.url_slug == sectionParam);
            }
            let product = section.dishes.find(product => product.uuid == this.props.match.params.productUuid);
            if(product === undefined){
                product = section.dishes.find(product => product.url_slug == this.props.match.params.productUuid);
            }

            return (
                <ProductDetail
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    firstAdditionToCart={this.state.firstAdditionToCart}
                    handleFirstAdditionToCart={this.handleFirstAdditionToCart}
                    product={this.state.selectedProduct ? this.state.selectedProduct : product}
                />
            );
        } else if (sectionParam) {
            let sections = this.state.restaurantMenus.find(menu => menu.uuid == menuParam);
            if (sections === undefined) {
                sections = this.state.restaurantMenus.find(menu => menu.url_slug == menuParam).menu_sections;
            } else {
                sections = sections.menu_sections
            }
            let section = sections.find(section => section.uuid == sectionParam);
            if (section === undefined) {
                section = sections.find(section => section.url_slug == sectionParam);
            }
            return (
                <React.Fragment>
                    <SectionContentBox
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        selectedSection={section}
                        sections={sections}
                        handleSectionChange={this.handleSectionChange}
                        handleProductSelection={this.handleProductSelection}
                        handleFirstAdditionToCart={this.handleFirstAdditionToCart}
                        firstAdditionToCart={this.state.firstAdditionToCart}
                        currentDish={this.state.currentDish}
                    />
                </React.Fragment>
            );

        } else if (menuParam) {

            return (
                <React.Fragment>
                    {this.renderWelcomeScreen()}
                </React.Fragment>
            );

        } else {
            return (
                <React.Fragment>

                    {
                        this.renderFirstScreen()
                    }
                </React.Fragment>);
        }
    }

    renderNavBarTitle = () => {


        if (this.props.match.params.productUuid) {
            let sections = this.state.restaurantMenus.find(menu => menu.uuid === this.props.match.params.menuUuid);
            if (sections === undefined) {
                sections = this.state.restaurantMenus.find(menu => menu.url_slug === this.props.match.params.menuUuid).menu_sections;
            } else {
                sections = sections.menu_sections;
            }
            let section = sections.find(section => section.uuid === this.props.match.params.sectionUuid);
            if (section === undefined) {
                section = sections.find(section => section.url_slug === this.props.match.params.sectionUuid);
            }
            let product = section.dishes.find(product => product.uuid === this.props.match.params.productUuid);
            if (product === undefined) {
                product = section.dishes.find(product => product.url_slug === this.props.match.params.productUuid);
            }

            return product.name;
        } else if (this.props.match.params.sectionUuid) {
            let sections = this.state.restaurantMenus.find(menu => menu.uuid == this.props.match.params.menuUuid);
            if (sections === undefined) {
                sections = this.state.restaurantMenus.find(menu => menu.url_slug == this.props.match.params.menuUuid).menu_sections
            } else {
                sections = sections.menu_sections;
            }
            let section = sections.find(section => section.uuid === this.props.match.params.sectionUuid);
            if (section === undefined) {
                section = sections.find(section => section.url_slug === this.props.match.params.sectionUuid);
            }
            return section.name;
        } else if (this.props.match.params.menuUuid) {
            let menu = this.state.restaurantMenus.find(menu => menu.uuid == this.props.match.params.menuUuid);
            if (menu === undefined) {
                menu = this.state.restaurantMenus.find(menu => menu.url_slug == this.props.match.params.menuUuid);
            }

            return menu.name;
        } else {
            return this.state.restaurantName;
        }
    }

    renderNavBar = () => {
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : "#000000"
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : "#DCB061"
        return (

            <DigitalMenuNavBar
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                className={importedStyles.container}
                handleBack={this.handleBack}
                restaurantLogo={this.state.restaurantLogo}
                hideBackButton={!this.props.match.params.menuUuid}
                navbarTitle={this.renderNavBarTitle()}
                currentSection={this.state.selectedSection}
                handleSectionSelect={this.handleSectionSelect}
            />
        )
    };

    renderInitialSectionList = () => {
        let sections = this.state.restaurantMenus.find(menu => menu.uuid == this.props.match.params.menuUuid);
        if (sections === undefined) {
            sections = this.state.restaurantMenus.find(menu => menu.url_slug == this.props.match.params.menuUuid).menu_sections
        } else {
            sections = sections.menu_sections;
        }
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : "#000000"
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : "#DCB061"

        return sections.map((section, key) => {
            return (
                <Fade key={key} in={true} timeout={800}>
                    <Grid item xs={6} className={importedStyles.sectionContainer}>
                        <Card elevation={6} onClick={() => this.handleSectionChange(section)}
                              className={importedStyles.sectionCard}>
                            <CardActionArea>
                                <LazyLoadImage
                                    className={importedStyles.sectionImage}
                                    src={section.photo ? section.photo : imageDefault}
                                    alt={section.name}
                                    //threshold={400} // amount of px before element that triggers image load
                                    scrollPosition={this.props.scrollPosition}
                                    effect={'blur'}
                                    onError={handleImgLoadError}
                                    placeholderSrc={imageDefault}
                                    wrapperClassName={importedStyles.sectionImage}
                                />
                                {/* <CardMedia
                                    className={importedStyles.sectionImage}
                                    image={section.photo ? section.photo : imageDefault}
                                    title={section.name}
                                />*/}
                            </CardActionArea>
                        </Card>
                        <p style={{color: primaryColor, whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} className={importedStyles.sectionTitle}>{section.name}</p>
                    </Grid>
                </Fade>
            )
        });

    };

    renderWelcomeScreen = () => {
        return (
            <Fade in={true} timeout={800}>
                {/* <Typography variant={'subtitle1'} className={importedStyles.restaurantName}>{this.state.restaurant.name}</Typography>*/}
                {/*
                <img alt="Restaurant Logo" src={this.state.restaurant.logo} className={importedStyles.restaurantLogoHeader}/>
*/}
                <Grid container style={{padding: 8}}>
                    {this.renderInitialSectionList()}
                </Grid>
            </Fade>
        )
    };

    renderMainSkeleton = () => {
        return (
            <Skeleton variant="rect" className={importedStyles.mainSkeleton}/>
        )
    };

    ////////

    getContainerStyles = () => {
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : "#000000"
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : "#DCB061"

        let menuParam = this.props.match.params.menuUuid;
        let sectionParam = this.props.match.params.sectionUuid;
        let productParam = this.props.match.params.productUuid;

        let styles = {
            backgroundColor: hexToRgbA(secondaryColor, 10),
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            minHeight: '100vh'
        };


        return styles;
    }


    render() {

        return (
            <React.Fragment>
                {this.state.restaurantRoles.menusWithImages ? this.renderNavBar() : <React.Fragment/>}
                <Grid container spacing={0} id={'container'}
                    // className={importedStyles.parentContainer}
                      style={this.getContainerStyles()}
                >
                    <div
                        className={this.state.restaurantRoles.menusWithImages ? importedStyles.content : importedStyles.contentNotFound}>
                        {this.state.gettingData ? this.renderMainSkeleton() : this.renderContent()}
                    </div>
                </Grid>
                <Snack/>

                <Dialog
                    open={this.state.firstAdditionToCartTutorialDialog}
                    onClose={this.handleFirstAdditionToCartTutorialDialog}
                    aria-labelledby="first addition to cart tutorial"
                    disableBackdropClick
                >
                    <IconButton color="inherit" onClick={this.handleFirstAdditionToCartTutorialDialog}
                                aria-label="Close" style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle>Información de uso</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={importedStyles.dialogContentText}>
                            Acabas de añadir tu primer plato al pedido,
                            recuerda que puedes quitar o añadir platos/articulos a la lista y que te ayudará a
                            recordarlos a la hora de realizar tu pedido al camarero/a.
                            <br/><br/>
                            ¡Gracias!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"primary"}
                                onClick={this.handleFirstAdditionToCartTutorialDialog}>
                            Entendido
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

digitalMenuScreenMobileWithImages.propTypes = {};

const mapStateToProps = ({digitalMenuReducer}) => {
    return ({
        actualMenu: digitalMenuReducer.actualMenu,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
        currentProduct: digitalMenuReducer.currentProduct,
    });
};

export default connect(mapStateToProps)(digitalMenuScreenMobileWithImages);
