import React from "react";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import importedStyles from "./digitalMenuScreenMobile.module.sass";
import Snack from "../../components/Snack/Snack";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ChevronRightIcon from "@material-ui/icons/ChevronRightRounded";
import {Container, IconButton, List, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {change_active_menu, change_current_restaurant, change_current_section} from "./digitalMenuActions";
import DigitalMenuNavBar from "./components/DigitalMenuNavBar/DigitalMenuNavBarMobile";
import SectionContentBox from "./components/SectionContentBox/SectionContentBox";
import CloseIcon from "@material-ui/icons/Close";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import notFound from "../notFound/notFound";
import history from "../../helpers/history";
import CartNotFound from "../CardNotFound/CartNotFound";
import useTheme from "@material-ui/core/styles/useTheme";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {ThemeProvider} from "@material-ui/styles";
import {darken, hexToRgbA} from "../../helpers/manageColors";
import Box from "@material-ui/core/Box";

class digitalMenuScreenMobile extends React.Component {

    state = {
        restaurant: {},
        restaurantMenus: [],
        restaurantLogo: '',
        restaurantName: '',
        restaurantRoles: '',
        menu: {},
        cart: [],
        selectedSection: '',
        existMenu: false,
        gettingData: true,
        isMenuSelected: false,
        securityPinModal: false,
        idleRefreshDialog: false,
        previewMode: false,
        firstAdditionToCart: true,
        firstAdditionToCartTutorialDialog: false,
        currentDish: null,
        primaryColor: null,
        secondaryColor: null,
    };


    async componentDidMount() {

        await this.getRestaurantInfo();
        await this.getStyles();

        // this.checkUrlParams();

        //let restaurantChanged = this.checkIfRestaurantChanged();
    }

    async getStyles() {
        let restaurantUuid = this.props.match.params.restaurantUuid;
        await fetch(apiBaseUrl + 'restaurants/styles/getStyles', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: restaurantUuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.error) {
                this.setState({
                    gettingData: false
                });
            } else {
                this.setState({
                    primaryColor: response.data.primaryColor,
                    secondaryColor: response.data.secondaryColor,
                    gettingData: false
                });
            }
        }).catch((error) => {
            this.setState({gettingData: false});
            console.error('Error: ' + error);
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    getRestaurantInfo = async () => {
        let restaurantUuid = this.props.match.params.restaurantUuid;
        this.setState({gettingData: true});
        await fetch(apiBaseUrl + 'restaurants/getRestaurant', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: restaurantUuid
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    restaurant: response.restaurant,
                    restaurantId: response.restaurant.uuidRestaurant,
                    restaurantMenus: response.restaurant.menus,
                    restaurantRoles: response.restaurant.restaurants_role,
                    // gettingData: false
                });
            } else {
                throw new Error('Error: ' + response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.props.dispatch(showSnack('Lo sentimos, parece que hay algun problema cargando la información, prueba de nuevo en unos minutos', 'error'))
        });
    };


    handleFirstAdditionToCart = () => {
        if (this.state.firstAdditionToCart) {
            this.props.dispatch(showSnack('¡Acabas de añadir tu primer plato al pedido! \n' +
                'Recuerda que podrás ver tu pedido pulsando el icono de carrito en la barra superior.', 'success'));
            this.setState({firstAdditionToCart: false});
        }
    };
    handleFirstAdditionToCartTutorialDialog = () => {
        this.setState((prevState, props) => ({
            firstAdditionToCartTutorialDialog: !prevState.firstAdditionToCartTutorialDialog,
        }));
    };

    handleBack = () => {
        let restaurantParam = this.props.match.params.restaurantUuid ? this.props.match.params.restaurantUuid : "" ;
        let menuParam = this.props.match.params.menuUuid ? this.props.match.params.menuUuid : "";
        let sectionParam = this.props.match.params.sectionUuid ? this.props.match.params.sectionUuid : "";

        if(sectionParam){
            history.push(`/app/menu/${restaurantParam}/${this.props.match.params.menuUuid}`);
        }else if(menuParam){
            history.push(`/app/menu/${restaurantParam}`);
        }else{
            history.push(`/app/menu/${restaurantParam}`);

        }


    }




    handleSectionChange = (nextSection) => {
        this.props.dispatch(change_current_section(nextSection.uuid));
        this.setState({selectedSection: nextSection});
        window.scrollTo({top: 0, behavior: 'smooth'});
        history.push(`/app/menu/${this.props.match.params.restaurantUuid}/${this.props.match.params.menuUuid}/${nextSection.url_slug}`);
    };


    checkIfRestaurantChanged = () => {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        const lastRestaurantOnRedux = this.props.currentRestaurant;

        let restaurantChanged = lastRestaurantOnRedux !== restaurantUuid;

        if (restaurantChanged) {
            //If restaurant changed, then save current restaurant uuid on redux
            this.props.dispatch(change_current_restaurant(restaurantUuid));
        }

        return restaurantChanged;
    };


    /////////////////////////////

    /* Menu SELECTOR LOGICS*/
    handleMenuSelection = (selectedMenu) => {
        this.props.dispatch(change_active_menu(selectedMenu.uuid));
        this.setState({menu: selectedMenu, isMenuSelected: true, existMenu: true});
        history.push(`/app/menu/${this.props.match.params.restaurantUuid}/${selectedMenu.url_slug}`)
    };


    //////////// RENDERS //////////////

    renderMenusList = () => {
        let menus = this.state.restaurant.menus;
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : '#fff';
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : '#008EEF';
        if (menus && menus.length > 0 && this.state.restaurantRoles.menus) {
            let menusList = menus.map((menu, key) => {
                if(menu.active) {
                    return (
                        <Card key={key} elevation={2} style={{marginBottom: '1rem'}}
                              onClick={() => this.handleMenuSelection(menu)}
                        >
                            <ListItem style={{backgroundColor: primaryColor}} button>
                                <ListItemText style={{color: secondaryColor}} primary={menu.name}/>
                                <ListItemSecondaryAction>
                                    <ChevronRightIcon
                                        style={{
                                            backgroundColor: secondaryColor,
                                            color: primaryColor,
                                            borderRadius: 45
                                        }}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Card>
                    )
                }
            });

            return (
                <Container>
                    <Avatar alt="Restaurant Logo" src={this.state.restaurant.logo}
                            className={importedStyles.restaurantLogo}
                    />
                    <Typography variant={'h4'}
                                style={{
                                    color: darken(primaryColor, 30)
                                }}
                                className={importedStyles.restaurantName}
                    >{this.state.restaurant.name}</Typography>
                    <List>
                        {menusList}
                    </List>
                </Container>
            );
        } else {
            return (
                <CartNotFound/>
            );
        }
    };

    renderContent() {
        const restaurantParam = this.props.match.params.restaurantUuid;
        const menuParam = this.props.match.params.menuUuid;
        const sectionParam = this.props.match.params.sectionUuid;
        // if (this.state.isMenuSelected) {
        //     if (this.state.selectedSection) {
        //         return (
        //             <React.Fragment>
        //                 <SectionContentBox
        //                     primaryColor={this.state.primaryColor ? this.state.primaryColor : '#008EEF'}
        //                     secondaryColor={this.state.secondaryColor ? this.state.secondaryColor : '#fff'}
        //                     selectedSection={this.state.selectedSection}
        //                     sections={this.state.menu.menu_sections}
        //                     handleSectionChange={() => this.handleSectionChange}
        //                     handleFirstAdditionToCart={this.handleFirstAdditionToCart}
        //                     firstAdditionToCart={this.state.firstAdditionToCart}
        //                     currentDish={this.state.currentDish}
        //                 />
        //             </React.Fragment>
        //         );
        //     } else {
        //         return (
        //             <React.Fragment>
        //                 {this.renderWelcomeScreen()}
        //             </React.Fragment>
        //         );
        //     }
        // } else {
        //     return (
        //         <React.Fragment>
        //             {this.renderMenusList()}
        //         </React.Fragment>
        //     );
        // }

        if (sectionParam) {
            let sections = this.state.restaurantMenus.find(menu => menu.uuid == this.props.match.params.menuUuid)
            if(sections === undefined) {
                sections = this.state.restaurantMenus.find(menu => menu.url_slug == this.props.match.params.menuUuid).menu_sections;
            }else{
                sections = sections.menu_sections;
            }
            let section = sections.find(section => section.uuid == this.props.match.params.sectionUuid);
            if(section === undefined){
                section = sections.find(section => section.url_slug == this.props.match.params.sectionUuid);
            }
            return (
                <React.Fragment>
                    <SectionContentBox
                        primaryColor={this.state.primaryColor ? this.state.primaryColor : '#008EEF'}
                        secondaryColor={this.state.secondaryColor ? this.state.secondaryColor : '#fff'}
                        selectedSection={section}
                        sections={sections}
                        handleSectionChange={() => this.handleSectionChange}
                        handleFirstAdditionToCart={this.handleFirstAdditionToCart}
                        firstAdditionToCart={this.state.firstAdditionToCart}
                        currentDish={this.state.currentDish}
                    />
                </React.Fragment>
            );
        } else if (menuParam) {
            return (
                <React.Fragment>
                    {
                        this.renderWelcomeScreen()
                    }
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>

                    {
                        this.renderMenusList()
                    }
                </React.Fragment>
            );
        }
    }
    renderNavBarTitle = () => {

        if (this.props.match.params.menuUuid && !this.props.match.params.sectionUuid) {
            let menu = this.state.restaurantMenus.find(menu => menu.uuid === this.props.match.params.menuUuid)
            if(menu===undefined){
                menu=this.state.restaurantMenus.find(menu => menu.url_slug === this.props.match.params.menuUuid)
            }
            return menu.name;
        } else {
            return false
        }
    }

    renderNavBar = () => {


        let menu = this.state.restaurantMenus.find(menu => menu.uuid == this.props.match.params.menuUuid);
        if(menu===undefined){
            menu = this.state.restaurantMenus.find(menu => menu.url_slug == this.props.match.params.menuUuid);
        }
        let sections = menu.menu_sections;
        let section = sections.find(section => section.uuid == this.props.match.params.sectionUuid);
        if(section===undefined){
            section = sections.find(section => section.url_slug == this.props.match.params.sectionUuid);
        }
        let currentMenu = this.state.restaurantMenus.find(menu => menu.uuid == this.props.match.params.menuUuid);
        if(currentMenu === undefined){
            currentMenu = this.state.restaurantMenus.find(menu => menu.url_slug == this.props.match.params.menuUuid);
        }

        return (
            <DigitalMenuNavBar
                primaryColor={this.state.primaryColor ? this.state.primaryColor : '#fff'}
                currentSection={section}
                currentMenu={currentMenu}
                sections={sections}
                navBarTitle={this.renderNavBarTitle()}
                secondaryColor={this.state.secondaryColor ? this.state.secondaryColor : '#008EEF'}
                handleBack={this.handleBack} restaurantLogo={this.state.restaurantLogo}
                restaurantName={this.state.restaurantName}
                handleSectionChange={this.handleSectionChange}
            />
        )
    };

    renderInitialSectionList = () => {
        // let sections = this.state.menu.menu_sections;
        let sections = this.state.restaurantMenus.find(menu => menu.uuid == this.props.match.params.menuUuid)
        if(sections === undefined){
            sections = this.state.restaurantMenus.find(menu => menu.url_slug == this.props.match.params.menuUuid).menu_sections;
        }else{
            sections = sections.menu_sections;
        }
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : '#fff';
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : '#008EEF';

        return sections.map((section, key) => {
            return (
                <Card key={key} elevation={2} style={{marginBottom: '1rem'}}
                      onClick={() => this.handleSectionChange(section)}>
                    <ListItem button style={{backgroundColor: primaryColor}} className={importedStyles.initialScreenSectionButton}>
                        <ListItemText style={{color: secondaryColor}} primary={section.name}/>
                        <ListItemSecondaryAction>
                            <ChevronRightIcon
                                style={{backgroundColor: secondaryColor, color: primaryColor, borderRadius: 45}}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                </Card>
            )
        });

    };

    renderWelcomeScreen = () => {
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : '#fff';
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : '#008EEF';

        return (

            <Container>
                <Avatar alt="Restaurant Logo" src={this.state.restaurant.logo}
                        className={importedStyles.restaurantLogo}/>
                <Typography variant={'h4'}
                            className={importedStyles.restaurantName}>{this.state.restaurantName}</Typography>
                <Typography variant={'subtitle2'}
                            style={{
                                textAlign: 'center',
                                fontStyle: 'italic',
                                marginBottom: '1rem',
                                color: darken(primaryColor, 30)
                            }}>
                    Echa un vistazo a la carta que <br/>hemos preparado para ti
                </Typography>
                <List>
                    {this.renderInitialSectionList()}
                </List>
            </Container>
        )
    };




    ////////
    getContainerStyles = () => {
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : "#000000"
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : "#DCB061"

        let menuParam = this.props.match.params.menuUuid;
        let sectionParam = this.props.match.params.sectionUuid;
        let productParam = this.props.match.params.productUuid;

        let styles = {
            backgroundColor: hexToRgbA(secondaryColor, 10),
            paddingTop: '1rem',
            minHeight: '100vh'
        };
        return styles;
    }

    render() {

        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.state.primaryColor ? this.state.primaryColor : '#fff',
                },
                secondary: {
                    main: this.state.secondaryColor ? this.state.secondaryColor : '#008EEF',
                },
            },
        });
        let primaryColor = this.state.primaryColor ? this.state.primaryColor : '#fff';
        let secondaryColor = this.state.secondaryColor ? this.state.secondaryColor : '#008EEF';
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    {this.props.match.params.menuUuid && !this.state.gettingData && this.renderNavBar()}
                    <Grid container spacing={0} id={'container'}
                          style={this.getContainerStyles()}
                    >
                        <Grid item xs={12} md={12}
                              // style={{height: "100%"}}
                        >
                            {this.state.gettingData ?
                                <Grid item xs={12} className={importedStyles.chargingContent}>
                                    <CircularProgress size={75} style={{color: '#3ba7e1'}}/>
                                </Grid>
                                :
                                this.renderContent()
                            }
                        </Grid>

                        <Snack/>

                        <Dialog
                            open={this.state.firstAdditionToCartTutorialDialog}
                            onClose={this.handleFirstAdditionToCartTutorialDialog}
                            aria-labelledby="first addition to cart tutorial"
                            disableBackdropClick
                        >
                            <IconButton color="inherit" onClick={this.handleFirstAdditionToCartTutorialDialog}
                                        aria-label="Close"
                                        style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}>
                                <CloseIcon/>
                            </IconButton>
                            <DialogTitle>Información de uso</DialogTitle>
                            <DialogContent>
                                <DialogContentText className={importedStyles.dialogContentText}>
                                    Acabas de añadir tu primer plato al pedido,
                                    recuerda que puedes quitar o añadir platos/articulos a la lista y que te ayudará
                                    a
                                    recordarlos a la hora de realizar tu pedido al camarero/a.
                                    <br/><br/>
                                    ¡Gracias!
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant={"contained"} color={"primary"}
                                        onClick={this.handleFirstAdditionToCartTutorialDialog}>
                                    Entendido
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>

                </ThemeProvider>

            </React.Fragment>
        );
    }
}

digitalMenuScreenMobile.propTypes = {};

const mapStateToProps = ({digitalMenuReducer}) => {
    return ({
        actualMenu: digitalMenuReducer.actualMenu,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(digitalMenuScreenMobile);
