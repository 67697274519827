import React from "react";
import importedStyles from "./CartNotFound.module.sass";
import Button from "@material-ui/core/Button";
import history from "../../helpers/history";
import Logo from '../../rsc/img/logo-comerEn.png';
import {Typography} from "@material-ui/core";

class CartNotFound extends React.Component {
    render(){
        return(
            <div className={importedStyles.notFound}>
                <img className={importedStyles.img} src={Logo} />
                <div className={importedStyles.containerText}>

                    <Typography display="inline" align="justify" variant='h4' className={importedStyles.h1}>
                        <span className={importedStyles.oops}>Oops</span>
                        , parece que no se ha podido encontrar ninguna carta
                        disponible. Contacta con el restaurante
                    </Typography>
                </div>


                <Button className={importedStyles.button} variant="contained" color='inherit' onClick={() => history.push('/')}>Ir a ComerEn</Button>
            </div>);

    }

}

export default (CartNotFound);
