import {CHANGE_ACTIVE_POLL, CHANGE_CURRENT_RESTAURANT} from "../../constants/action-types";


const initialState = {
    actualPoll : {},
    currentRestaurant: null
};

const frontPollsReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHANGE_ACTIVE_POLL:
            return {...state, actualPoll: action.actualPoll};
        case CHANGE_CURRENT_RESTAURANT:
            return {...state, currentRestaurant: action.currentRestaurant};
        default:
            return state;
    }
};


export default frontPollsReducer;