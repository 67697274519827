import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import importedStyles from "./AllRestaurantsScreen.module.sass";
import Grid from "@material-ui/core/Grid/Grid";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import Typography from "@material-ui/core/Typography/Typography";
import GridRestaurantCard from "../components/GridRestaurantCard/GridRestaurantCard";
import Footer from "../../../components/Footer/Footer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import NavbarLanding from "../Landing/NavbarLanding/NavbarLanding";
import Fuse from "fuse.js";
import SearchHeader from "../Landing/SearchHeader/SearchHeader";
import connect from "react-redux/es/connect/connect";


class AllRestaurantsScreen extends React.Component {

    state = {
        loadingRestaurants: false,
        restaurants: {},
        searchedRestaurant: '',
        searchedKitchenType: '',
        searchedCity: '',
    };

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    componentDidMount() {
        this.getAllRestaurants();
    }

    getAllRestaurants() {
        this.setState({loadingRestaurants: true});
        fetch(apiBaseUrl + 'restaurants/getAllRestaurants', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({})
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    restaurants: response.restaurants,
                    loadingRestaurants: false
                })
            } else {
                this.setState({loadingRestaurants: false});
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({loadingRestaurants: false});
            console.error(error);
        });
    }

    renderRestaurantsGrid() {
        const restaurants = this.state.restaurants;
        let gridItems = [];

        if (restaurants.length > 0) {
            Object.keys(restaurants).forEach((restaurant) => {

                gridItems.push(
                    <Grid key={restaurant} item xs={12} md={3}>
                        <GridRestaurantCard
                            restaurantName={restaurants[restaurant].name}
                            restaurantDescription={restaurants[restaurant].description}
                            logo={restaurants[restaurant].logo}
                            idRestaurant={restaurants[restaurant].uuid}
                        />
                    </Grid>
                );

            })
        } else {
            gridItems.push(
                <Grid key={0} item xs={12}>
                    <Typography variant={'h3'}>
                        Sin restaurantes
                    </Typography>
                </Grid>
            );
        }

        return (
            <React.Fragment>
                {gridItems}
            </React.Fragment>
        )
    }

    applyFilter() {
        const restaurants = this.state.restaurants;
        let selectedKeys = [];
        let searchedTerms = '';
        console.log(this.props.searchedKitchenType);
        let filterByName = this.props.searchedRestaurant.length > 0;
        let filterByKitchenType = this.props.searchedKitchenType.length > 0;
        let filterByCity = this.props.searchedCity.length > 0;

        console.log(this.props.searchedKitchenType);

        if (filterByName) {
            selectedKeys.push('name');
            searchedTerms += this.props.searchedRestaurant;
        }
        if (filterByKitchenType) {
            selectedKeys.push('kitchen_types.name');
            searchedTerms += ' ' + this.props.searchedKitchenType;
        }
        if (filterByCity) {
            selectedKeys.push('city');
            searchedTerms += ' ' + this.props.searchedCity;
        }

        let options = {
            keys: selectedKeys,
            shouldSort: true,
            minMatchCharLength: 3,
            threshold: 0.6,
            location: 0,
            distance: 100,
            includeScore: true,
        };
        let fuse = new Fuse(restaurants, options);
        return fuse.search(searchedTerms);
    }

    renderFilteredGrid() {
        let gridItems = [];
        const filteredRestaurants = this.applyFilter();

        if (filteredRestaurants.length > 0) {
            Object.keys(filteredRestaurants).forEach((key) => {
                let restaurant = filteredRestaurants[key].item;
                gridItems.push(
                    <Grid item xs={12} md={3}>
                        <GridRestaurantCard
                            restaurantName={restaurant.name}
                            restaurantDescription={restaurant.description}
                            logo={restaurant.logo}
                            idRestaurant={restaurant.uuid}
                        />
                    </Grid>
                );
            })
        } else {
            gridItems.push(
                <Grid item xs={12}>
                    <Typography variant={'h3'}>
                        No existen restaurantes que coincidan con tu búsqueda
                    </Typography>
                </Grid>
            );
        }

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant={'h3'}>
                        Tu búsqueda:
                    </Typography>
                </Grid>
                {gridItems}
            </React.Fragment>
        )
    }


    render() {
        return (
            <div>
                <NavbarLanding/>
                <Grid container spacing={32} className={importedStyles.restaurantsGridContainer}>
                    <Grid item xs={12}>
                        <SearchHeader/>
                    </Grid>
                    {this.state.loadingRestaurants ? <CircularProgress size={120}/> :
                        this.props.filterActive ? this.renderFilteredGrid() : this.renderRestaurantsGrid()}
                </Grid>
                <Footer/>
            </div>
        );
    }
}

AllRestaurantsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = ({searchReducer}) => {
    return ({
        filterActive: searchReducer.filterActive,
        searchedRestaurant: searchReducer.searchedRestaurant ? searchReducer.searchedRestaurant : '',
        searchedKitchenType: searchReducer.searchedKitchenType ? searchReducer.searchedKitchenType : '',
        searchedCity: searchReducer.searchedCity ? searchReducer.searchedCity : '',

    });
};

export default connect(mapStateToProps)(withStyles(importedStyles)(AllRestaurantsScreen));






