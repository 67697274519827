import React from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import importedStyles from "../menusManagerStyle";
import importedStylesSass from "./SectionModals.module.sass";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import TextField from "@material-ui/core/es/TextField/TextField";
import Button from "@material-ui/core/es/Button/Button";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
 import {FilePond, registerPlugin} from "react-filepond";
// import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
 import 'filepond/dist/filepond.min.css'
 import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
 import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Create";

 registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class SectionModals extends React.Component {

    state = {
        editingCurrentSectionImage: false,
    };

    componentDidMount(): void {


    }

    triggerSectionImageEdit = () => {
        console.info("triggerSectionImageEdit");
        this.props.handleEditSectionImage();
        this.setState({editingCurrentSectionImage: true});
    };
    renderCurrentImage = () => {
        return (
            <div className={importedStylesSass.currentSectionImageContainer}>
                <img src={this.props.editSectionImage} className={importedStylesSass.currentSectionImage} alt={'current section'}/>
                <Tooltip title="Editar" placement="top">
                    <IconButton onClick={this.triggerSectionImageEdit} className={importedStylesSass.editSectionImageButton}><EditIcon/></IconButton>
                </Tooltip>
            </div>
        )
    };
    renderFilePond = () => {
        return (
            <FilePond
                maxFileSize={'20MB'}
                imagePreviewMaxFileSize={'20MB'}
                allowImagePreview={true}
                imagePreviewUpscale={true}
                labelIdle={'Haz click para seleccionar una NUEVA imagen de sección. Si no eliges ninguna, se conservará la imagen anterior.'}
                labelFileTypeNotAllowed={'Lo sentimos, ese tipo de archivo no está permitido'}
                fileValidateTypeLabelExpectedTypes={'Lo sentimos, ese tipo de archivo no está permitido'}
                acceptedFileTypes={['image/*']}
                ref={ref => this.pond = ref}
                allowImageResize={true}
                imageResizeUpscale={true}
                onupdatefiles={fileItems => this.props.handleEditSectionImage(fileItems)}
            />
        );
    };

    closeEditModal = () => {
        this.props.closeEditSectionModal();
        this.setState({editingCurrentSectionImage: false});
    };

    render(){
        const classes = this.props.classes;
        const fullScreenModals = window.matchMedia("(max-width: 768px)").matches;

        return(
            <div>
                {/*MODAL CREAR SECTION */}
                <Dialog
                    fullWidth
                    maxWidth={'sm'}
                    open={this.props.newSectionModal}
                    onClose={this.props.closeNewSectionModal}
                    aria-labelledby="form-dialog-title"
                >
                    <IconButton color="inherit" onClick={this.props.closeNewSectionModal} aria-label="Close"
                                style={{position: 'absolute', right: 2, top: 2}}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle id="form-dialog-title">
                        Nueva sección
                    </DialogTitle>
                    <DialogContent>
                        <FilePond
                            maxFileSize={'20MB'}
                            imagePreviewMaxFileSize={'20MB'}
                            allowImageExifOrientation={true}
                            allowImagePreview={true}
                            imagePreviewUpscale={true}
                            labelIdle={'Haz click para seleccionar una imagen de sección. También puedes arrastrarla hasta aquí directamente.'}
                            labelFileTypeNotAllowed={'Lo sentimos, ese tipo de archivo no está permitido'}
                            fileValidateTypeLabelExpectedTypes={'Lo sentimos, ese tipo de archivo no está permitido'}
                            acceptedFileTypes={['image/*']}
                            ref={ref => this.pond = ref}
                            allowImageResize={true}
                            imageResizeUpscale={true}
                            onupdatefiles={fileItems => this.props.handleNewSectionImage(fileItems)}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            name="newSectionName"
                            label="Nombre de sección"
                            inputProps={{maxLength: 50}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.props.addSection}
                            disabled={this.props.newSectionName === '' || this.props.sendingData}
                        >
                            Crear
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />
                            }
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*MODAL EDIT SECTION */}
                <Dialog
                    fullWidth
                    maxWidth={'sm'}
                    open={this.props.editSectionModal}
                    onClose={this.closeEditModal}
                    aria-labelledby="form-dialog-title"
                >
                    <IconButton color="inherit" onClick={this.closeEditModal} aria-label="Close"
                                style={{position: 'absolute', right: 2, top: 2}}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle id="form-dialog-title">
                        Editar sección
                    </DialogTitle>
                    <DialogContent>
                        {(this.props.editSectionImage && !this.state.editingCurrentSectionImage) ? this.renderCurrentImage() : this.renderFilePond()}

                        <TextField
                            value={this.props.editSectionName}
                            margin="dense"
                            name="editSectionName"
                            label="Nombre de sección"
                            inputProps={{maxLength: 50}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => this.props.editSection(this.props.actualEditingSection)}
                            disabled={this.props.editSectionName === '' || this.props.sendingData}
                        >
                            Editar
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />
                            }
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*REMOVE SECTION MODAL*/}
                <Dialog
                    fullScreen={fullScreenModals}
                    fullWidth
                    maxWidth={'sm'}
                    open={this.props.removeSectionModal}
                    onClose={this.props.handleRemoveSectionModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar esta sección?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleRemoveSectionModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.props.deleteSection} variant="contained" color="secondary" autoFocus
                                disabled={this.props.sendingData}>
                            Eliminar
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
SectionModals.propTypes = {
    classes: PropTypes.object,
    sendingData: PropTypes.bool.isRequired,
    handleInputChange: PropTypes.func.isRequired,

    //Add section
    newSectionModal:  PropTypes.bool.isRequired,
    closeNewSectionModal: PropTypes.func.isRequired,
    addSection: PropTypes.func.isRequired,
    newSectionName: PropTypes.string.isRequired,

    //Edit section
    editSectionModal:  PropTypes.bool.isRequired,
    closeEditSectionModal: PropTypes.func.isRequired,
    editSection: PropTypes.func.isRequired,
    editSectionName: PropTypes.string.isRequired,
    editSectionImage: PropTypes.string.isRequired,
    handleEditSectionImage: PropTypes.func.isRequired,

    //Remove section
    removeSectionModal: PropTypes.bool.isRequired,
    handleRemoveSectionModal: PropTypes.func.isRequired,
    deleteSection: PropTypes.func.isRequired,
};

export default withStyles(importedStyles())(SectionModals);

