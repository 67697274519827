import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    }});
const drawerWidth = 300;

export default function importedStyles() {
    return {
        root: {

            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginLeft: 12,
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'unset',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        doubleNested: {
            paddingLeft: theme.spacing(8),
        },
        avatar: {
            '&:hover': {
                cursor: 'pointer',
            }
        },
        logoutDialog: {
            width: 333
        }
        //CUSTOM SCROLLBAR
      /*
        body::-webkit-scrollbar {
            width: 0.5em;
        }

        body::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
        }

        body::-webkit-scrollbar-thumb {
            background-color: #83b1fc;
            border-radius: 25px;
            height: 1em;
            outline: 1px solid blue;
        }
        */
    };
};

