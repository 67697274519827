import React from 'react';
import {Typography} from "@material-ui/core";
import {Label, ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from "recharts";

import importedStyles from "./Statistics.module.sass";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";


class StatisticCardArea extends React.Component {

    state = {
        title: "RATATA",
        x: [],
        y: [],
        color: "#8884d8",
    }


    componentDidMount() {

    }


    render() {




        const {title, statistics, emptyText} = this.props;

        return (


            <React.Fragment>
                <Typography className={importedStyles.cardTitle} variant="h5">{title}</Typography>



                {(statistics.length != 0) ?

                    <AreaChart className={importedStyles.graphicsArea}
                               width={400}
                               height={300}
                               data={statistics}
                               isAnimationActive={true}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis>
                            <Label>Día</Label>

                        </XAxis>
                        <YAxis
                            label={{value: 'Interacciones', angle: -90, position: 'insideLeft', textAnchor: 'middle'}}/>
                        <Tooltip/>
                        <Area type="monotone" dataKey="count" data={statistics} stroke="#8884d8" fill="#FFD54F"/>
                    </AreaChart>

                    :

                    <Typography variant="body1">{emptyText}</Typography>}


            </React.Fragment>


        );
    }
}

export default (StatisticCardArea);