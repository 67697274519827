import {CHANGE_ACTIVE_POLL, CHANGE_CURRENT_RESTAURANT} from "../../constants/action-types";

export const change_active_poll = (poll) => {
    return ({
        type: CHANGE_ACTIVE_POLL,
        actualPoll: poll
    });
};

export const change_current_restaurant = (restaurantUuid) => {
    return ({
        type: CHANGE_CURRENT_RESTAURANT,
        currentRestaurant: restaurantUuid
    });
};
