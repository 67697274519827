import moment from "moment";
import 'moment/locale/es';

export function formatDate(date){

    const dateObject = new Date(date);
    //Formateamos la fecha
    return moment(dateObject).locale('es').format("LL");
}

export function formatReservationResumeDate(date){

    const dateObject = new Date(date);

    //Formateamos la fecha
    return moment(dateObject).locale('es').format("L");
}

export function getFormattedSchedule(schedule) {
    let time = new Date(schedule);
    return  getDoubleDigitHours(time) +  ':' + getDoubleDigitMinutes(time) + 'h';
}

export function getFormattedReservationHours(schedule) {
    let time = new Date(schedule);
    return  getDoubleDigitHours(time) +  ':' + getDoubleDigitMinutes(time);
}

export function getDoubleDigitHours(date){
    let hours = date.getHours();
    if (hours < 10){
        hours = '0' + hours;
    }
    return hours;
}

export function getDoubleDigitMinutes(date){
    let minutes = date.getMinutes();
    if (minutes < 10){
        minutes += '0';
    }
    return minutes;
}

//This function returns Minutes.Seconds between procured startDate and endDate
export function getMinutesDifference(startDate, endDate){

    let diff = Math.abs(endDate - startDate);
    let minutes = parseFloat((diff/1000)/60).toFixed(2);

    return minutes;
}

export function getMyDateFormat(date){
    return date.getFullYear()+'-'+ ('0' + (date.getMonth() + 1)).slice(-2) +'-' + date.getDate();
}


