import {ADD_MENU} from "../../constants/action-types";


const initialState = {
    menus : [],
};

const menusManagerReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADD_MENU:
        default:
            return state;
    }
};


export default menusManagerReducer;