import React from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import importedStyles from "../menusManagerStyle";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import Button from "@material-ui/core/es/Button/Button";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/es/FormControl/FormControl";


class DishSectionModals extends React.Component {
    render() {
        const classes = this.props.classes;

        return (
            <div>
                {/*MODAL REMOVE DISH FROM SECTION*/}
                <Dialog
                    fullWidth
                    maxWidth={'sm'}
                    open={this.props.removeDishFromSectionModal}
                    onClose={this.props.handleRemoveDishFromSectionModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Eliminar</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar este producto?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleRemoveDishFromSectionModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.props.deleteDishFromSection} variant="contained" color="secondary" autoFocus
                                disabled={this.props.sendingData}>
                            Eliminar
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* MODAL SELECT DISH */}
                <Dialog
                    open={this.props.addDishToSectionModal}
                    onClose={this.props.closeAddDishToSectionModal}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth={'sm'}
                >
                    <DialogTitle>Añadir un producto a esta sección</DialogTitle>
                    <IconButton color="inherit" onClick={this.props.closeAddDishToSectionModal} aria-label="Close"
                                    style={{position: 'absolute', right: 2, top: 2}}>
                            <CloseIcon/>
                        </IconButton>
                    <DialogContent style={{marginTop: '1rem', marginBottom: '2rem'}}>
                        <form>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="age-required">Producto: </InputLabel>
                                <Select
                                    fullWidth
                                    value={this.props.selectDish}
                                    onChange={this.props.handleInputChange}
                                    name="selectDish"
                                    className={classes.selectEmpty}
                                >
                                    {this.props.renderDishesItem()}
                                </Select>
                                {/*{this.props.renderSelectPrice()}*/}
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => this.props.setDish()}
                            disabled={this.props.selectDish.name === '' || this.props.sendingData}
                        >
                            Añadir
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />
                            }
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>);
    }
}
DishSectionModals.propTypes = {
    classes: PropTypes.object,
    sendingData: PropTypes.bool.isRequired,
    handleInputChange: PropTypes.func.isRequired,

    //Add dish section
    addDishToSectionModal: PropTypes.bool.isRequired,
    closeAddDishToSectionModal: PropTypes.func.isRequired,
    renderDishesItem: PropTypes.func.isRequired,
    renderSelectPrice: PropTypes.func.isRequired,
    setDish: PropTypes.func.isRequired,
    selectDish: PropTypes.string.isRequired,

    //Remove dish from section
    removeDishFromSectionModal: PropTypes.bool.isRequired,
    handleRemoveDishFromSectionModal: PropTypes.func.isRequired,
    deleteDishFromSection: PropTypes.func.isRequired,
};

export default withStyles(importedStyles())(DishSectionModals);
