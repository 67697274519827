import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme();

export default function importedStyles() {
    return {
        layout: {
            display: 'flex',
            marginTop: 25,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'column',
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
                width: 600,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            //alignItems: 'center',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            minWidth: 700,
            maxWidth: 700,
            minHeight: 450,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            },
        },
        chip: {
            margin: theme.spacing(1),
        },
        avatar: {
            width: 150,
            height: 150
        },
        chipsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: "wrap",
            justifyContent: 'space-evenly'
        },
        answerBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly'
        },
        gettingDataAnimation: {
            position: 'absolute',
            top: '40%'
        },
        error404: {
            position: 'absolute',
            top: '20%',
            textAlign: 'center'
        },
        error404text: {
            fontWeight: 'bold',
            fontSize: 240,
            color: '#79a8ff'
        }
    }
}
