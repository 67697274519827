import {RESET_FILTER_STATE, SET_FILTER_STATE} from "../../../../constants/action-types";

const initialState = {
    filterActive : false,
    searchedRestaurant: '',
    searchedKitchenType: '',
    searchedCity: '',
};

const searchReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_FILTER_STATE:
            return {
                ...state,
                filterActive: action.filterActive,
                searchedRestaurant: action.searchedRestaurant,
                searchedKitchenType: action.searchedKitchenType,
                searchedCity: action.searchedCity,
            };
        case RESET_FILTER_STATE:
            return {
                ...state,
                filterActive: false,
                searchedRestaurant: '',
                searchedKitchenType: '',
                searchedCity: '',
            };
        default:
            return state;
    }
};


export default searchReducer;