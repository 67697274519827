import { createMuiTheme } from '@material-ui/core/styles';
import red from "@material-ui/core/colors/red";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    }});

export default function importedStyles() {
    return {
        card: {
            //maxWidth: 400,
        },
        actions: {
            display: 'flex',
        },
        expand: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
            marginLeft: 'auto',
            [theme.breakpoints.up('sm')]: {
                marginRight: -8,
            },
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
            width: 60,
            height: 60,
        },
        cardHeader: {
            width: '100%',
            '&:hover': {
                cursor: 'pointer',
                //backgroundColor: '#fcffcc'
            }
        },
        cardHeaderAssociation: {
            width: '100%',
            '&:hover': {
                cursor: 'default',
            }
        },
        cardContent: {
            flex: 1,
            flexDirection: 'column'
        },
        infoListContainer: {
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap'
        }
    }
}