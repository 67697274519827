import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

export default function importedStyles() {
    return {
        navIconHide: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        toolbar: theme.mixins.toolbar,
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 50,
        },
        chargingContent: {
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 300,
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
        },
        chargingAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -10,
        },
        timePickersBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly'
        },
        contentCard: {
            backgroundColor: '#f3f7f8',
            marginTop: 15
        },
        scheduleContainer: {
            alignItems: 'center',
            marginTop: 15,
            backgroundColor: '#eaeaea',
            borderRadius: 15,
            paddingLeft: 10,
            paddingTop: 5,
            paddingBottom: 5,
        },
        scheduleSubContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minHeight: 175,
        },
        listItem: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            margin: 5
        },
        list: {
            width: '95%'
        },
        daysBox: {
            margin: 50
        },
        parentBox: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%'
        },
        confirmedReservationBox: {
            width: '100%',
            backgroundColor: '#e1e4ff',
            borderRadius: 25,
        },
        rejectedReservationBox: {
            width: '100%',
            backgroundColor: 'rgba(239,233,255,0.37)',
            filter: 'opacity(90%)',
            borderRadius: 25,
        },
        pendingReservationBox: {
            width: '100%',
            backgroundColor: '#ffe0e7',
            borderRadius: 25,
        },
        cardActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: 10
        },
        datePicker: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginTop: 15
        },
        confirmedRejectedBox: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        tabsContainer: {
            display: 'flex',
            justifyContent: 'center !important',
            marginTop: '0.5em'
        },
        iframeInfo:{
            backgroundColor: "lightgrey",
            padding: "1.4rem",
        },
        iframeText:{
            marginLeft: "1rem",

        },
        iframeLink:{
            color: "black",
            marginRight:"3px",
            marginLeft: "3px"
        },
        iframeInfoWrapper:{
            position: "sticky",
            bottom: 0,
            left: 0,
            marginLeft: "1.5rem",
            marginRight: "1.5rem",
            paddingBottom: "0.5rem",

        },
        iframeIframeText:{
            marginTop: "1rem",
            wordBreak: "break-all",
            background: "white",
            border: "0.7px solid",
            padding: "2px",
            borderRadius: "4px",
            wordWrap: "break-word"
        }
    }
}