function baseUrl() {

    // Develop
    //return 'http://localhost:3000/';

    //Production
     //return 'https://testingemenu.gihsoft.com/';
    return 'https://quierocomeren.com/';
}

export default baseUrl();