import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import history from '../../helpers/history';
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarsIcon from '@material-ui/icons/Stars';
import WarningIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import MoodBadIcon from '@material-ui/icons/MoodBadOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import ReactChartkick from 'react-chartkick'
import Chart from 'chart.js'
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import MenuCard from "./components/menuCardOffer";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Slide from "@material-ui/core/Slide/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import importedStyles from './offersStyle';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Snack from "../../components/Snack/Snack";
import Fab from "@material-ui/core/es/Fab/Fab";

ReactChartkick.addAdapter(Chart);

class offersScreen extends React.Component {

    state = {
        expanded: null,
        gettingData: true,
        newOfferModal: false,
        modalDeleteConfirmation: false,
        offers: {},
        menus: {},
        newOfferTitle: '',
        newOfferFinalPrice: undefined,
        newOfferMinQuantity: undefined,
        selectedMenu: 0,
        selectedDays: [],
        selectedDishes: [],
        selectedDishesChecks: {},
        offerResume: false,
        selectedOffer: null,
        sendingData: false,
        sendingStateData: false,
    };

    constructor(props) {
        super(props);
        this.createNewOffer = this.createNewOffer.bind(this);
        this.deleteOffer = this.deleteOffer.bind(this);
        this.disableOffer = this.disableOffer.bind(this);
        this.activateOffer = this.activateOffer.bind(this);
    }

    componentDidMount() {
        if (!this.props.restaurantPermissions.offers) {
            history.push('/forbiddenScreen');
        } else {
            this.getOffers(this.props.idRestaurant);
            this.getMenusRestaurant(this.props.idRestaurant);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.restaurantPermissions.offers) {
            history.push('/forbiddenScreen');
        } else if (this.props.idRestaurant !== nextProps.idRestaurant){
            //reset selectedOffer when changing between restaurants
            this.setState({selectedOffer: null});
            this.getOffers(nextProps.idRestaurant);
            this.getMenusRestaurant(nextProps.idRestaurant);
        }

    }


    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };
    handleSelectChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleInputChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleCheckbox = (e) => {

        let selectedDishes = this.state.selectedDishes;
        let index = selectedDishes.indexOf(e.target.value);

        let selectedDishesChecks = this.state.selectedDishesChecks;
        selectedDishesChecks[e.target.name] = e.target.checked;

        if (e.target.checked) {
            if (index === -1) {
                selectedDishes.push(e.target.value)
            }
        } else {
            selectedDishes.splice(index, 1);
        }

        this.setState({selectedDishes, selectedDishesChecks});

    };

    handleDaysCheckbox = (e) => {

        let selectedDays = this.state.selectedDays;
        let index = selectedDays.indexOf(e.target.value);

        if (e.target.checked) {
            if (index === -1) {
                selectedDays.push(e.target.value)
            }
        } else {
            selectedDays.splice(index, 1);
        }

        this.setState({selectedDays});

    };


    getOffers(idRestaurant) {

        this.setState({gettingData: true});

        fetch(apiBaseUrl + 'restaurants/getRestaurantOffers', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: idRestaurant
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error){
                throw new Error(response.errorMessage);
            } else {
                this.setState({offers: response.offers, gettingData: false})
            }
        }).catch((error) => {
            this.setState({gettingData: false});
            console.log(error);
        });
    }

    getMenusRestaurant(idRestaurant) {
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'restaurants/getMenus', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error){
                this.setState({menus: response.menus,  gettingData: false});
            } else {
                throw new Error('Error: ' + response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.setState({gettingData: false});
        });

    }

    renderOffers() {

        const {classes} = this.props;
        const {expanded} = this.state;
        let offers = this.state.offers;

        if (offers.length > 0) {
            let listItems = [];
            offers = [].slice.call(offers).sort((a, b) => a.position - b.position);

            Object.keys(offers).forEach((offer) => {

                listItems.push(
                    <ExpansionPanel
                        expanded={expanded === 'panel' + offers[offer].uuid}
                        onChange={this.handleChange('panel' + offers[offer].uuid)}
                        onClick={() => this.setState({selectedOffer: offers[offer]})}
                        key={offers[offer].uuid}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography className={classes.heading}>{offers[offer].title}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                size={"small"}
                                onClick={()=>this.setState({modalDeleteConfirmation: true})}
                            >
                                Eliminar
                                <DeleteIcon style={{marginLeft: 10, marginBottom: 2}}/>
                            </Button>
                            {offers[offer].active ?
                                <Button variant={"text"} color={"secondary"} size={"small"} onClick={this.disableOffer} disabled={this.state.sendingStateData}>
                                    Desactivar
                                    <RemoveIcon style={{marginLeft: 10, marginBottom: 2}}/>
                                    {this.state.sendingStateData &&
                                    <CircularProgress
                                        size={25}
                                        className={classes.chargingAnimation}
                                    />}
                                </Button>
                                :
                                <Button variant={"text"} color={"primary"} size={"small"} onClick={this.activateOffer} disabled={this.state.sendingStateData}>
                                    Activar
                                    <CheckIcon style={{marginLeft: 10, marginBottom: 5}}/>
                                    {this.state.sendingStateData &&
                                    <CircularProgress
                                        size={25}
                                        className={classes.chargingAnimation}
                                    />}
                                </Button>
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );

            });

            return (
                <div>
                    <Typography gutterBottom variant="headline" component="h2">
                        Mis ofertas
                    </Typography>
                    <List>{listItems}</List>
                </div>)
        } else {

            return (
                <div>
                    <Typography gutterBottom variant="headline" component="h2">
                        Sin ofertas
                    </Typography>
                    <Typography component="p">
                        Todavia no tienes ninguna oferta, cuando crees una podrás verla aquí.
                    </Typography>
                </div>
            )
        }


    }

    deleteOffer() {

        let offerId = this.state.selectedOffer.uuid;
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'offers/deleteOffer', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    idOffer: offerId,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);

            if (!response.error) {
                this.getOffers(this.props.idRestaurant);
                this.setState({modalDeleteConfirmation: false, sendingData: false});
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            }else{
                throw new Error();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido eliminar', snackTypes.error));
        });

    }
    disableOffer() {

        let offerId = this.state.selectedOffer.uuid;
        this.setState({sendingStateData: true});
        fetch(apiBaseUrl + 'offers/disableOffer', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    idOffer: offerId,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            console.log(response);

            response = JSON.parse(response);

            if (!response.error) {
                this.getOffers(this.props.idRestaurant);
                this.setState({sendingStateData: false});
                this.props.dispatch(showSnack('Oferta desactivada', snackTypes.success));
            }else{
                throw new Error();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingStateData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido desactivar', snackTypes.error));
        });

    }
    activateOffer() {
        let offerId = this.state.selectedOffer.uuid;
        this.setState({sendingStateData: true});
        fetch(apiBaseUrl + 'offers/activateOffer', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    idOffer: offerId,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.getOffers(this.props.idRestaurant);
                this.setState({sendingStateData: false});
                this.props.dispatch(showSnack('Oferta activada', snackTypes.success));
            }else{
                throw new Error();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingStateData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido activar', snackTypes.error));
        });
    }

    createNewOffer() {
        let idRestaurant = this.props.idRestaurant;
        let newOfferTitle = this.state.newOfferTitle;
        let newOfferMinQuantity = this.state.newOfferMinQuantity;
        let newOfferFinalPrice = this.state.newOfferFinalPrice;
        let selectedDishes = this.state.selectedDishes;
        let selectedDays = this.state.selectedDays;

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'offers/createNewOffer', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: idRestaurant,
                    title: newOfferTitle,
                    minQuantity: newOfferMinQuantity,
                    finalPrice: newOfferFinalPrice,
                    dishes: selectedDishes,
                    days: selectedDays,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);

            if (!response.error) {
                this.getOffers(this.props.idRestaurant);
                this.setState({newOfferModal: false, sendingData: false});
                this.props.dispatch(showSnack('Oferta añadida con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido crear', snackTypes.error));
        });

    }

    renderMenus() {
        const menus = this.state.menus;
        let menusCards = [];

        Object.keys(menus).forEach((menuId) => {

            let menuHaveDishes = false;
            const sections = menus[menuId].menu_sections;

            Object.keys(sections).forEach((sectionId) => {
                if (sections[sectionId].dishes.length > 0) {
                    menuHaveDishes = true;
                }
            });

            if (menuHaveDishes){

                menusCards.push(
                    <MenuCard
                        key={menus[menuId].uuid}
                        id={menus[menuId].uuid}
                        name={menus[menuId].name}
                        sections={this.renderSections(sections)}
                        style={{backgroundColor: "blue", color: 'red'}}
                    />
                )
            }
        });

        if (menusCards.length === 0) {
            return (
                <React.Fragment>

                    <FormHelperText
                        style={{
                            color: 'red',
                            fontStyle: 'italic',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        {/*
                        <MoodBadIcon style={{marginBottom: 15}}/>
                        */}
                        <WarningIcon style={{marginBottom: 15}}/>
                        Vaya, parece que no tienes ningún menú todavía.
                    </FormHelperText>
                    <FormHelperText
                        style={{
                            color: 'red',
                            fontStyle: 'italic',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        Para poder seleccionar platos, primero deberás
                        <Button
                            variant={"text"}
                            color={"primary"}
                            size={"small"}
                            onClick={() => history.push('/menus')}
                        >
                            Crear un menú
                        </Button>
                    </FormHelperText>
                </React.Fragment>
            );
        } else {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap'
                    }}
                >
                    {menusCards}
                </div>
            );
        }
    }

    renderSections(sections) {

        let sectionItem = [];
        Object.keys(sections).forEach((sectionId) => {

            sectionItem.push(
                <ExpansionPanel key={sectionId}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon onClick={() => {
                        this.setState({
                            expansionPanelOpen: !this.state.expansionPanelOpen
                        });
                    }}/>}>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>

                            {sections[sectionId].name}

                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {
                            this.renderSectionDishes(sections[sectionId].dishes)
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )
        });

        return sectionItem;
    }

    renderSectionDishes(dishes) {

        const {classes} = this.props;

        if (dishes.length === 0) {
            return (<p style={{margin: 20}}>Todavia no tienes platos en esta sección...</p>);
        } else {
            return (
                <List style={{width: '100%'}}>
                    {Object.keys(dishes).map(value => (
                        <ListItem
                            key={value}
                            role={undefined}
                            dense
                            className={classes.dishContainer}
                        >

                            <Checkbox
                                checked={this.state.selectedDishesChecks[dishes[value].uuid]}
                                name={dishes[value].name}
                                onChange={this.handleCheckbox}
                                value={dishes[value].uuid}
                                color="primary"
                            />
                            <ListItemText primary={dishes[value].name}/>

                        </ListItem>
                    ))}
                </List>
            );
        }
    }

    renderResumeDishes() {

        const {classes} = this.props;
        let dishes = [];

        Object.keys(this.state.selectedDishesChecks).forEach((dish) => {
            if (this.state.selectedDishesChecks[dish]) {
                dishes.push(dish)
            }
        });

        if (dishes.length > 0) {

            return (
                <List>
                    {Object.keys(dishes).map(dish => (

                            <ListItem
                                key={dish}
                                role={undefined}
                                dense
                                className={classes.nested}
                            >
                                <ListItemText primary={dishes[dish]}/>

                            </ListItem>
                        )
                    )}
                </List>
            );
        } else {
            return (
                <ListItemText
                    secondary={'Debes seleccionar al menos un plato'}
                    style={{color: 'red', fontStyle: 'italic'}}
                />
            )
        }
    }

    renderMenusSelect() {

        let menus = this.state.menus;
        let menuItems = [];

        if (menus) {
            Object.keys(menus).forEach((menu) => {

                menuItems.push(
                    <MenuItem value={menus[menu].uuid}>
                        <ListItemIcon>
                            <StarsIcon/>
                        </ListItemIcon>
                        {menus[menu].title}
                    </MenuItem>
                );

            });
        }

        return (
            <React.Fragment>
                <Select
                    value={this.state.selectedMenu}
                    onChange={this.handleSelectChange}
                    name='selectedMenu'
                    disabled={!menus}
                    fullWidth
                >
                    <MenuItem value={0}>
                        -- Escoge un menú --
                    </MenuItem>
                    {menuItems}
                </Select>
                {!menus ?
                    <FormHelperText style={{
                        color: 'red',
                        fontStyle: 'italic',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <MoodBadIcon/>
                        Vaya, parece que no tienes ningún menú todavía.
                    </FormHelperText>
                    : null
                }

            </React.Fragment>


        );

    }

    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    renderDays() {

        let days = {
            '1': 'Lunes',
            '2': 'Martes',
            '3': 'Miercoles',
            '4': 'Jueves',
            '5': 'Viernes',
            '6': 'Sábado',
            '7': 'Domingo',
        };
        let dayChecks = [];

        Object.keys(days).forEach((day) => {

            dayChecks.push(
                <FormControlLabel
                    key={day}
                    control={
                        <Checkbox
                            checked={this.state.selectedDays.indexOf(day) !== -1}
                            onChange={this.handleDaysCheckbox}
                            value={day}
                            color="primary"
                        />
                    }
                    label={days[day]}
                />
            )
        });

        return (
            <div style={{display: 'flex', alignSelf: 'center', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                {dayChecks}
            </div>
        )
    }

    renderResumeDays() {
        let days = {
            '1': 'Lunes',
            '2': 'Martes',
            '3': 'Miercoles',
            '4': 'Jueves',
            '5': 'Viernes',
            '6': 'Sábado',
            '7': 'Domingo',
        };
        let daysString = '';
        let selectedDays = this.state.selectedDays.sort((a, b) => a - b);

        if (selectedDays.length !== 0) {
            for (let i = 0; i < selectedDays.length; i++) {
                if (i === selectedDays.length - 1) {
                    daysString += '' + days[selectedDays[i]];
                } else {
                    daysString += '' + days[selectedDays[i]] + ', ';
                }
            }
        } else {
            daysString = 'Debes seleccionar al menos un dia aplicable a esta oferta'
            return (
                <ListItemText secondary={daysString}/>
            )
        }

        return (
            <ListItemText primary={daysString}/>
        )
    }

    renderNewOfferModalContent() {

        return (
            <React.Fragment>
                <AppBar style={{position: 'relative', marginBottom: 15}}>
                    <Toolbar>
                        <Typography variant="title" color="inherit" style={{flex: 1, marginLeft: 10}}>
                            Nueva oferta
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={() => this.setState({newOfferModal: false, offerResume: true})}
                            aria-label="Close"
                            style={{padding: 0}}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    style={{
                        padding: '0px 45px 24px'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'space-evenly'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <DialogContentText>
                                Escoge un nombre descriptivo para tu oferta:
                            </DialogContentText>
                            <TextField
                                autoFocus
                                value={this.state.newOfferTitle}
                                name="newOfferTitle"
                                margin="normal"
                                variant={"outlined"}
                                placeholder={"Ej: Nuevo 2x1 los CapriFindes"}
                                type="text"
                                inputProps={{maxLength: 50}}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <DialogContentText>
                                Cantidad mínima de artículos para aplicar la oferta:
                            </DialogContentText>
                            <TextField
                                value={this.state.newOfferMinQuantity}
                                margin="normal"
                                name="newOfferMinQuantity"
                                variant={"outlined"}
                                placeholder={"Ej: 3"}
                                type="number"
                                inputProps={{maxLength: 11}}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <DialogContentText>
                                Precio final con oferta aplicada :
                            </DialogContentText>
                            <TextField
                                value={this.state.newOfferFinalPrice}
                                name="newOfferFinalPrice"
                                margin="normal"
                                variant={"outlined"}
                                placeholder={"Ej: 15"}
                                type="number"
                                inputProps={{maxLength: 6}}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
                            <DialogContentText>
                                ¿Que días estará disponible esta oferta?
                            </DialogContentText>
                            {this.renderDays()}
                        </div>
                    </div>
                    <DialogContentText style={{marginTop: 15}}>
                        Selecciona los platos incluidos en la oferta:
                    </DialogContentText>

                    {this.renderMenus()}

                </DialogContent>
                <DialogActions style={{padding: 10}}>
                    <Button onClick={() => this.setState({newOfferModal: false, offerResume: false})} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={() => this.setState({offerResume: true})} variant={"contained"} color="primary">
                        Listo
                    </Button>
                </DialogActions>
            </React.Fragment>
        )

    }

    renderOfferResume() {

        const {classes} = this.props;
        return (
            <React.Fragment>
                <AppBar style={{position: 'relative', marginBottom: 15}}>
                    <Toolbar>
                        <Typography variant="title" color="inherit" style={{flex: 1, marginLeft: 10}}>
                            Resumen de la nueva oferta
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={() => this.setState({newOfferModal: false, offerResume: true})}
                            aria-label="Close"
                            style={{padding: 0}}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                   className={classes.offerResumeContent}
                >
                    <List>
                        <ListSubheader component="div">Título de la oferta</ListSubheader>
                        <ListItem className={classes.nested}>
                            {
                                this.state.newOfferMinQuantity ?
                                    <ListItemText primary={this.state.newOfferTitle}/>
                                    :
                                    <ListItemText
                                        secondary={'Debes elegir un título para la oferta'}
                                        style={{color: 'red', fontStyle: 'italic'}}
                                    />
                            }

                        </ListItem>
                        <ListSubheader component="div">Cantidad mínima para aplicar la oferta</ListSubheader>
                        <ListItem className={classes.nested}>
                            {
                                this.state.newOfferMinQuantity ?
                                    <ListItemText primary={this.state.newOfferMinQuantity}/>
                                    :
                                    <ListItemText
                                        secondary={'Debes asignar una cantidad mínima para continuar'}
                                        style={{color: 'red', fontStyle: 'italic'}}
                                    />
                            }
                        </ListItem>
                        <ListSubheader component="div">Precio final con oferta aplicada</ListSubheader>
                        <ListItem className={classes.nested}>
                            {
                                this.state.newOfferFinalPrice ?
                                    <ListItemText primary={this.state.newOfferFinalPrice + '€'}/>
                                    :
                                    <ListItemText
                                        secondary={'Debes asignar una precio final para continuar'}
                                        style={{color: 'red', fontStyle: 'italic'}}
                                    />
                            }

                        </ListItem>
                    </List>
                    <List>
                        <ListSubheader component="div">Días en los que la oferta será válida</ListSubheader>
                        <ListItem className={classes.nested}>
                            {this.renderResumeDays()}
                        </ListItem>
                        <ListSubheader component="div">Platos ofertados</ListSubheader>
                        <ListItem className={classes.nested}>
                            {this.renderResumeDishes()}
                        </ListItem>

                    </List>


                </DialogContent>
                <DialogActions style={{padding: 10}}>
                    <Button onClick={() => this.setState({offerResume: false})} color="secondary">

                        <ArrowBackIcon style={{marginLeft: 10}}/> Volver para editar oferta
                    </Button>
                    <Button
                        onClick={this.createNewOffer}
                        variant={"contained"}
                        color="primary"
                        disabled={this.state.sendingData || !(this.state.newOfferTitle && this.state.newOfferMinQuantity > 0 && this.state.newOfferFinalPrice > 0 && this.state.selectedDays.length > 0 && this.state.selectedDishes.length > 0)}
                    >
                        Crear oferta
                        {this.state.sendingData &&
                        <CircularProgress
                            size={25}
                           className={classes.chargingAnimation}
                        />
                        }
                    </Button>
                </DialogActions>
            </React.Fragment>

        )
    }

    renderOfferDetails() {
        const {classes} = this.props;
        const offer = this.state.selectedOffer;

        if (offer) {

            let daysString = '';
            for (let i = 0; i < offer.days.length; i++) {
                if (i !== offer.days.length - 1) {
                    daysString += offer.days[i].dayName + ', ';
                } else {
                    daysString += offer.days[i].dayName;
                }
            }

            let dishes = [];
            for (let i = 0; i < offer.dishes.length; i++) {
                dishes.push(
                    <ListItem className={classes.nested} key={i}>
                        <ListItemText secondary={offer.dishes[i].name}/>
                    </ListItem>
                );
            }

            return (
                <List>
                    <ListItem>
                        <ListItemText primary={'Cantidad mínima para aplicar oferta'}/>
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <ListItemText secondary={offer.minQuantity}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Precio final con oferta aplicada'}/>
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <ListItemText secondary={offer.finalPrice + '€'}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Días aplicables'}/>
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <ListItemText secondary={daysString}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Platos incluidos'}/>
                    </ListItem>
                    {dishes}
                    <ListItem>
                        <ListItemText primary={'¿Oferta vigente?'}/>
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <ListItemText secondary={offer.active ? 'Sí' : 'No'}/>
                    </ListItem>
                </List>
            );
        } else {
            return (
                <Typography> Haz click en la oferta que desees y podrás ver sus detalles aquí.</Typography>
            );
        }


    }


    render() {
        const classes = this.props.classes;

        return (
            <div style={{display: 'flex'}}>
                <MiniDrawer title={"Ofertas"} idRestaurant={this.props.idRestaurant}/>

                {this.state.gettingData ? <div className={classes.chargingContent}><CircularProgress/></div> :
                    <div className={classes.content}>

                        <Grid container
                              direction="row"
                              justify="space-evenly"
                              spacing={8}
                              alignItems="stretch"
                              style={{marginTop: 15}}
                        >
                            <Grid item xs={12} md={4}>
                                <Card style={{backgroundColor: '#f3f7f8'}}>
                                    <CardContent>
                                        {this.renderOffers()}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Card style={{backgroundColor: '#f3f7f8'}}>
                                    <CardContent>
                                        {this.renderOfferDetails()}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Fab variant="extended"
                                aria-label="Add Offer"
                                size="large" color={"primary"}
                                className={classes.button}
                                onClick={() => this.setState({newOfferModal: true})}
                        >
                            <AddIcon style={{marginRight: 5}}/>
                            Nueva oferta
                        </Fab>


                        {/* MODAL delete Offer */}
                        <Dialog
                            open={this.state.modalDeleteConfirmation}
                            onClose={() => this.setState({modalDeleteConfirmation: false})}
                            aria-labelledby="form-dialog-title"
                            scroll={"paper"}
                        >

                            <DialogTitle id="form-dialog-title">Eliminación de oferta</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    Si eliminas esta oferta no podrás recuperarla más tarde, ¿estás seguro?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    variant="raised"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => this.setState({modalDeleteConfirmation: false})}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    variant="raised"
                                    color="secondary"
                                    className={classes.submit}
                                    disabled={this.state.sendingData}
                                    onClick={() => this.deleteOffer()}
                                >
                                    Eliminar
                                    {this.state.sendingData &&
                                        <CircularProgress
                                            size={25}
                                            className={classes.chargingAnimation}
                                        />
                                    }
                                </Button>
                            </DialogActions>
                        </Dialog>


                        {/* MODAL NEW Offer */}
                        <Dialog
                            fullScreen
                            open={this.state.newOfferModal}
                            onClose={() => this.setState({newOfferModal: false, offerResume: true})}
                            aria-labelledby="form-dialog-title"
                            scroll={"paper"}
                            TransitionComponent={this.Transition}
                        >

                            {this.state.offerResume ? this.renderOfferResume() : this.renderNewOfferModalContent()}

                        </Dialog>
                        <Snack/>
                    </div>
                }
            </div>
        );
    }
}

offersScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        user: authReducer.credentials[0],
        idRestaurant: restaurantReducer.idRestaurant,
        nameRestaurant: restaurantReducer.nameRestaurant,
        restaurantPermissions: restaurantReducer.permissions
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles())(offersScreen));


