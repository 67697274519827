import React from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import importedStyles from "../menusManagerStyle";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import Typography from "@material-ui/core/es/Typography/Typography";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import TextField from "@material-ui/core/es/TextField/TextField";
import Button from "@material-ui/core/es/Button/Button";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import importedStylesSass from "./MenuModals.module.sass";

class MenuModals extends React.Component {
    render(){
        const classes = this.props.classes;
        const fullScreenModals = window.matchMedia("(max-width: 768px)").matches;

        return(
            <div>
                {/* MODAL CREAR MENU */}
                <Dialog
                    fullScreen={fullScreenModals}
                    fullWidth
                    maxWidth={'sm'}
                    open={this.props.newMenuModal}
                    onClose={this.props.closeNewMenuModal}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>
                        <Typography variant="title" color="inherit" className={classes.flex}>
                            Nueva Carta
                        </Typography>
                        <IconButton color="inherit" onClick={this.props.closeNewMenuModal}
                                    aria-label="Close"
                                    className={importedStylesSass.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.editMenuModalContent}>
                        <TextField
                            autoFocus
                            margin="normal"
                            name="newMenuName"
                            label="Nombre"
                            onChange={this.props.handleInputChange}
                            fullWidth
                            inputProps={{maxLength: 50}}
                        />

                        <TextField
                            margin="normal"
                            name="newMenuPrice"
                            label="Precio"
                            helperText="Opcional"
                            onChange={this.props.handleInputChange}
                            fullWidth
                            inputProps={{maxLength: 6}}
                            type={"number"}
                            style={{marginTop: 5, marginBottom: 5}}
                        />

                        {this.props.events.length > 0 ? this.props.renderEventsSelect() : null}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => this.props.addMenu()}
                            disabled={this.props.newMenuName === '' || this.props.sendingData}
                        >
                            Crear
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />
                            }
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* MODAL Editar Menú */}
                <Dialog
                    fullScreen={fullScreenModals}
                    fullWidth
                    maxWidth={'sm'}
                    open={this.props.editMenuModal}
                    onClose={this.props.handleEditMenuModal}
                    style={{display: 'flex', flexDirection: 'column'}}
                >
                    <DialogTitle>
                        <Typography variant="title" color="inherit" className={classes.flex}>
                            Editar menú
                        </Typography>
                        <IconButton color="inherit" onClick={this.props.handleEditMenuModal}
                                    aria-label="Close"
                                    className={importedStylesSass.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={importedStylesSass.dialogContent}>
                        <TextField
                            margin="normal"
                            value={this.props.editMenuName}
                            name="editMenuName"
                            label="Nuevo nombre"
                            inputProps={{maxLength: 50}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                        <TextField
                            margin="normal"
                            value={this.props.editMenuPrice}
                            name="editMenuPrice"
                            label="Nuevo precio"
                            helperText="Opcional"
                            inputProps={{maxLength: 50}}
                            onChange={this.props.handleInputChange}
                            type={'number'}
                            fullWidth
                        />
                        {this.props.events.length > 0 ? this.props.renderEditEventsSelect() : null}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => this.props.updateMenu()}
                            disabled={this.props.editMenuName === '' || this.props.sendingData}
                        >
                            Modificar datos
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />
                            }
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*REMOVE MENU MODAL*/}
                <Dialog
                    open={this.props.removeMenuModal}
                    onClose={this.props.handleRemoveMenuModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar este menú?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleRemoveMenuModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.props.deleteMenu} variant="contained" color="secondary" autoFocus
                                disabled={this.props.sendingData}>
                            Eliminar
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}
MenuModals.propTypes = {
    classes: PropTypes.object,
    sendingData: PropTypes.bool.isRequired,
    handleInputChange: PropTypes.func.isRequired,

    //Add menu
    newMenuModal: PropTypes.bool.isRequired,
    closeNewMenuModal: PropTypes.func.isRequired,
    events: PropTypes.array,
    renderEventsSelect: PropTypes.func.isRequired,
    newMenuName: PropTypes.string,
    addMenu: PropTypes.func.isRequired,

    //Edit menu
    editMenuModal: PropTypes.bool.isRequired,
    handleEditMenuModal: PropTypes.func.isRequired,
    editMenuActive: PropTypes.func,
    sendingStateData: PropTypes.bool.isRequired,
    editMenuName: PropTypes.string.isRequired,
    editMenuPrice: PropTypes.number,
    renderEditEventsSelect:  PropTypes.func.isRequired,
    updateMenu:  PropTypes.func.isRequired,

    //Remove menu
    removeMenuModal: PropTypes.bool.isRequired,
    handleRemoveMenuModal: PropTypes.func.isRequired,
    deleteMenu: PropTypes.func.isRequired
};

export default withStyles(importedStyles())(MenuModals);


//Original code, delete when tested
//
// {/*/!* MODAL CREAR MENU *!/*/}
// {/*<Dialog*/}
// {/*fullScreen*/}
// {/*open={this.state.newMenuModal}*/}
// {/*onClose={this.closeNewMenuModal}*/}
// {/*aria-labelledby="form-dialog-title"*/}
// {/*>*/}
// {/*<AppBar className={classes.appBar}>*/}
// {/*<Toolbar>*/}
// {/*<Typography variant="title" color="inherit" className={classes.flex}>*/}
// {/*Editar menú*/}
// {/*</Typography>*/}
// {/*<IconButton color="inherit" onClick={this.closeNewMenuModal} aria-label="Close"*/}
// {/*style={{position: 'absolute', right: 5}}>*/}
// {/*<CloseIcon/>*/}
// {/*</IconButton>*/}
// {/*</Toolbar>*/}
// {/*</AppBar>*/}
// {/*<DialogContent className={classes.editMenuModalContent}>*/}
// {/*<TextField*/}
// {/*autoFocus*/}
// {/*name="newMenuName"*/}
// {/*label="Nombre"*/}
// {/*onChange={this.handleInputChange}*/}
// {/*fullWidth*/}
// {/*inputProps={{maxLength: 50}}*/}
// {/*/>*/}
//
// {/*<TextField*/}
// {/*name="newMenuPrice"*/}
// {/*label="Precio"*/}
// {/*helperText="Opcional"*/}
// {/*onChange={this.handleInputChange}*/}
// {/*fullWidth*/}
// {/*inputProps={{maxLength: 6}}*/}
// {/*type={"number"}*/}
// {/*style={{marginTop: 5, marginBottom: 5}}*/}
// {/*/>*/}
//
// {/*{this.state.events.length > 0 ? this.renderEventsSelect() : null}*/}
//
// {/*</DialogContent>*/}
// {/*<DialogActions>*/}
// {/*<Button*/}
// {/*type="submit"*/}
// {/*fullWidth*/}
// {/*variant="contained"*/}
// {/*color="primary"*/}
// {/*className={classes.submit}*/}
// {/*onClick={() => this.addMenu()}*/}
// {/*disabled={this.state.newMenuName === '' || this.state.sendingData}*/}
// {/*>*/}
// {/*Crear*/}
// {/*{this.state.sendingData &&*/}
// {/*<CircularProgress*/}
// {/*size={25}*/}
// {/*className={classes.sendingDataAnimation}*/}
// {/*/>*/}
// {/*}*/}
// {/*</Button>*/}
// {/*</DialogActions>*/}
// {/*</Dialog>*/}
// {/*/!* MODAL Editar Menú *!/*/}
// {/*<Dialog*/}
// {/*fullScreen*/}
// {/*open={this.state.editMenuModal}*/}
// {/*onClose={this.handleEditMenuModal}*/}
// {/*style={{display: 'flex', flexDirection: 'column'}}*/}
// {/*>*/}
// {/*<AppBar className={classes.appBar}>*/}
// {/*<Toolbar>*/}
// {/*<Typography variant="title" color="inherit" className={classes.flex}>*/}
// {/*Editar menú*/}
// {/*</Typography>*/}
// {/*<IconButton color="inherit" onClick={this.handleEditMenuModal} aria-label="Close"*/}
// {/*style={{position: 'absolute', right: 5}}>*/}
// {/*<CloseIcon/>*/}
// {/*</IconButton>*/}
// {/*</Toolbar>*/}
// {/*</AppBar>*/}
// {/*<DialogContent className={classes.editMenuModalContent}>*/}
// {/*{*/}
// {/*this.state.editMenuActive ?*/}
// {/*<Button*/}
// {/*onClick={() => this.disableMenu()}*/}
// {/*variant={"contained"}*/}
// {/*size={"small"}*/}
// {/*color={"secondary"}*/}
// {/*disabled={this.state.sendingStateData}*/}
// {/*className={classes.menuStateButton}*/}
// {/*>*/}
// {/*Deshabilitar menú*/}
// {/*<DisabledIcon className={classes.menuStateIcon}/>*/}
// {/*{this.state.sendingStateData &&*/}
// {/*<CircularProgress*/}
// {/*size={25}*/}
// {/*className={classes.sendingDataAnimation}*/}
// {/*/>}*/}
// {/*</Button>*/}
// {/*:*/}
// {/*<Button*/}
// {/*onClick={() => this.enableMenu()}*/}
// {/*variant={"contained"}*/}
// {/*size={"small"}*/}
// {/*disabled={this.state.sendingStateData}*/}
// {/*className={classes.menuStateButton2}*/}
// {/*>*/}
// {/*Habilitar menú*/}
// {/*<CheckIcon className={classes.menuStateIcon}/>*/}
// {/*{this.state.sendingStateData &&*/}
// {/*<CircularProgress*/}
// {/*size={25}*/}
// {/*className={classes.sendingDataAnimation}*/}
// {/*/>}*/}
// {/*</Button>*/}
// {/*}*/}
// {/*<TextField*/}
// {/*autoFocus*/}
// {/*value={this.state.editMenuName}*/}
// {/*name="editMenuName"*/}
// {/*label="Nuevo nombre"*/}
// {/*inputProps={{maxLength: 50}}*/}
// {/*onChange={this.handleInputChange}*/}
// {/*fullWidth*/}
// {/*/>*/}
// {/*<TextField*/}
// {/*autoFocus*/}
// {/*value={this.state.editMenuPrice}*/}
// {/*name="editMenuPrice"*/}
// {/*label="Nuevo precio"*/}
// {/*helperText="Opcional"*/}
// {/*inputProps={{maxLength: 50}}*/}
// {/*onChange={this.handleInputChange}*/}
// {/*type={'number'}*/}
// {/*fullWidth*/}
// {/*/>*/}
// {/*{this.state.events.length > 0 ? this.renderEditEventsSelect() : null}*/}
//
// {/*</DialogContent>*/}
// {/*<DialogActions>*/}
// {/*<Button*/}
// {/*type="submit"*/}
// {/*fullWidth*/}
// {/*variant="contained"*/}
// {/*color="primary"*/}
// {/*className={classes.submit}*/}
// {/*onClick={() => this.updateMenu()}*/}
// {/*disabled={this.state.editMenuName === '' || this.state.editMenuPrice === '' || this.state.sendingData}*/}
// {/*>*/}
// {/*Modificar datos*/}
// {/*{this.state.sendingData &&*/}
// {/*<CircularProgress*/}
// {/*size={25}*/}
// {/*className={classes.sendingDataAnimation}*/}
// {/*/>*/}
// {/*}*/}
// {/*</Button>*/}
// {/*</DialogActions>*/}
// {/*</Dialog>*/}
// {/*/!*REMOVE MENU MODAL*!/*/}
// {/*<Dialog*/}
// {/*open={this.state.removeMenuModal}*/}
// {/*onClose={this.handleRemoveMenuModal}*/}
// {/*aria-labelledby="alert-dialog-title"*/}
// {/*aria-describedby="alert-dialog-description"*/}
// {/*>*/}
// {/*<DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>*/}
// {/*<DialogContent>*/}
// {/*<DialogContentText id="alert-dialog-description">*/}
// {/*¿Seguro que desea eliminar este menú?*/}
// {/*</DialogContentText>*/}
// {/*</DialogContent>*/}
// {/*<DialogActions>*/}
// {/*<Button onClick={this.handleRemoveMenuModal} color="primary">*/}
// {/*Cancelar*/}
// {/*</Button>*/}
// {/*<Button onClick={this.deleteMenu} variant="contained" color="secondary" autoFocus*/}
// {/*disabled={this.state.sendingData}>*/}
// {/*Eliminar*/}
// {/*{this.state.sendingData &&*/}
// {/*<CircularProgress*/}
// {/*size={25}*/}
// {/*className={classes.sendingDataAnimation}*/}
// {/*/>}*/}
// {/*</Button>*/}
// {/*</DialogActions>*/}
// {/*</Dialog>*/}
