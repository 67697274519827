import Card from "@material-ui/core/Card"
import React from 'react'
import CardActions from "@material-ui/core/CardActions";
import {CardContent} from "@material-ui/core";
import {BlockPicker, CompactPicker, HuePicker, SketchPicker, SliderPicker} from 'react-color'
import importedStylesSass from "./ColorPicker.module.sass";
import reactCSS from 'reactcss'
import Typography from "@material-ui/core/Typography";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import connect from "react-redux/lib/connect/connect";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import CardHeader from "@material-ui/core/CardHeader";
import BrushIcon from '@material-ui/icons/Brush';
import ConfirmIcon from '@material-ui/icons/Check';
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import PropTypes from "prop-types";
import {darken, hexToRgbA, lighten} from "../../../helpers/manageColors";

class ColorPicker extends React.Component {
    state = {
        displayColorPickerPrimary: false,
        displayColorPickerSecondary: false,
        primaryColor: '#ffffff',
        secondaryColor: '#ffffff',
        sendingData: false,
        editingData: false,
        gettingData: false,
        receivedColors: false
    };

    componentDidMount() {
        this.getStyles()
    }

    handleClickPrimary = () => {
        this.setState({displayColorPickerPrimary: !this.state.displayColorPickerPrimary});
    };
    handleClickSecondary = () => {

        this.setState({displayColorPickerSecondary: !this.state.displayColorPickerSecondary});
    };

    handleClosePrimary = () => {
        this.setState({displayColorPickerPrimary: false})
    };
    handleCloseSecondary = () => {
        this.setState({displayColorPickerSecondary: false})
    };

    handleChangePrimary = (color) => {
        if (!this.state.editingData) {
            this.setState({editingData: true});
        }
        this.setState({primaryColor: color.hex})
    };
    handleChangeSecondary = (color) => {
        if (!this.state.editingData) {
            this.setState({editingData: true});
        }
        this.setState({secondaryColor: color.hex});
    };

    renderDemoComponent = () => {

        let customStyles = {
            primaryText: {color: this.state.primaryColor},
            secondaryText: {color: this.state.secondaryColor},
        }
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Card style={{
                    backgroundColor: hexToRgbA(this.state.secondaryColor ? this.state.secondaryColor : '#fff', 10),
                    width: '80%',
                    marginBottom: '1rem'
                }}>
                    <CardContent>
                        {/*<ListItem>*/}
                        {/*    <ListItemText*/}
                        {/*        primary={<span*/}
                        {/*            style={{color: darken(this.state.primaryColor, 20)}}>Este será tu color principal</span>}*/}
                        {/*        secondary={<small style={{color: this.state.secondaryColor}}>Y este el*/}
                        {/*            secundario</small>}*/}
                        {/*        classes={{*/}
                        {/*            primary: customStyles.primaryText,*/}
                        {/*            secondary: customStyles.secondaryText*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</ListItem>*/}
                        <div
                            style={{borderRadius: '15px' ,backgroundImage: "linear-gradient(87deg, " + this.state.primaryColor + ", " + lighten(this.state.primaryColor, 20) + " 66%)"}}>

                            <ListItem style={{textAlign: 'center'}}>
                                <ListItemText

                                    primary={<small style={{color: this.state.secondaryColor}}>Color secundario</small>}
                                    classes={{
                                        primary: customStyles.primaryText,
                                        secondary: customStyles.secondaryText
                                    }}
                                />

                            </ListItem>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }


    updateStyles() {
        this.setState({sendingData: true});

        fetch(apiBaseUrl + 'restaurants/styles/update', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: this.props.idRestaurant,
                    primaryColor: this.state.primaryColor,
                    secondaryColor: this.state.secondaryColor
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.error) {
                throw new Error(response.error)
            } else {
                this.setState({sendingData: false, editingData: false});
                this.props.dispatch(showSnack('Datos editados con éxito', snackTypes.success));
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    getStyles() {
        this.setState({gettingData: true});

        fetch(apiBaseUrl + 'restaurants/styles/getStyles', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: this.props.idRestaurant,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.error || !response.data) {
                this.setState({
                    primaryColor: '#ffffff',
                    secondaryColor: '#ffffff',
                    receivedColors: false,
                    gettingData: false
                });
            } else {
                this.setState({

                    primaryColor: response.data.primaryColor ? response.data.primaryColor : '#ffffff',
                    secondaryColor: response.data.secondaryColor ? response.data.secondaryColor : '#ffffff',
                    receivedColors: true,
                    gettingData: false
                });
            }
        }).catch((error) => {
            this.setState({gettingData: false});
            console.error('Error: ' + error);
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    renderApplyChangesButton = () => {
        return (
            <Button variant="contained" color="primary" aria-label="Confirmar"
                    style={{position: 'relative'}}
                    onClick={() => this.updateStyles()}
                    disabled={this.state.sendingData}>
                Aplicar nuevos colores <ConfirmIcon style={{marginLeft: 10}}/>
                {this.state.sendingData &&
                <CircularProgress size={25} className={importedStylesSass.sendingDataAnimation}/>}
            </Button>
        );
    }


    render() {
        const styles = reactCSS({
            'default': {
                primaryColor: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.primaryColor
                },
                secondaryColor: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.secondaryColor

                },
                swatch: {
                    marginLeft: '3px',
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },

        });

        return (
            <Card className={importedStylesSass.root}>
                <CardHeader title="Colores personalizados de la carta" avatar={<BrushIcon/>}>
                </CardHeader>
                <CardContent className={importedStylesSass.cardContent}>
                    {this.state.gettingData ? <CircularProgress/> :
                        <React.Fragment>
                            <div className={importedStylesSass.item}>
                                <Typography className={importedStylesSass.typography} variant={"body2"}>Color
                                    principal: </Typography>
                                <div style={styles.swatch}
                                     onClick={this.handleClickPrimary}>
                                    <div style={styles.primaryColor}/>
                                </div>
                                {this.state.displayColorPickerPrimary &&
                                <div style={styles.popover}>
                                    <div style={styles.cover} onClick={this.handleClosePrimary}/>
                                    <div className={importedStylesSass.pickerContainer}>
                                        <div className={importedStylesSass.shadowFix}>
                                            <CompactPicker color={this.state.primaryColor} onChange={this.handleChangePrimary} className={importedStylesSass.compactPicker}/>
                                        </div>
                                        <HuePicker color={this.state.primaryColor} onChange={this.handleChangePrimary} className={importedStylesSass.huePicker}/>
                                    </div>
                                </div>}
                            </div>

                            <div className={importedStylesSass.item}>

                                <Typography variant={"body2"}>Color secundario: </Typography>
                                <div style={styles.swatch}
                                     onClick={this.handleClickSecondary}>
                                    <div style={styles.secondaryColor}/>
                                </div>
                                {this.state.displayColorPickerSecondary &&
                                <div style={styles.popover}>
                                    <div style={styles.cover} onClick={this.handleCloseSecondary}/>
                                    <div className={importedStylesSass.pickerContainer}>
                                        <div className={importedStylesSass.shadowFix}>
                                            <CompactPicker color={this.state.secondaryColor} onChange={this.handleChangeSecondary} className={importedStylesSass.compactPicker}/>
                                        </div>
                                        <HuePicker color={this.state.secondaryColor} onChange={this.handleChangeSecondary} className={importedStylesSass.huePicker}/>
                                    </div>
                                </div>
                                }
                            </div>
                        </React.Fragment>}

                </CardContent>
                {
                    this.state.gettingData ? <React.Fragment/> :
                        (this.props.withDemo && (this.state.primaryColor !== '#ffffff' || this.state.secondaryColor !== '#ffffff') ?
                            this.renderDemoComponent() :
                            <Typography variant={"body2"}
                                        style={{color: '#737373', paddingLeft: '2rem', width: '100%', display: 'flex'}}>
                                ¡Personaliza más tu carta seleccionando unos colores!
                            </Typography>)

                }


                <CardActions className={importedStylesSass.buttonContainer}>
                    {this.state.editingData && this.renderApplyChangesButton()}
                </CardActions>

            </Card>
        )
    }
}

ColorPicker.propTypes = {
    withDemo: PropTypes.bool,
};

ColorPicker.defaultProps = {
    withDemo: false,
}

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        idRestaurant: restaurantReducer.idRestaurant,
    });
};
export default connect(mapStateToProps)(ColorPicker);