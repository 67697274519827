import React from "react";
import PropTypes from 'prop-types';
import importedStyles from './BillingDataStyle';
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import Snack from "../../components/Snack/Snack";
import {withStyles} from "@material-ui/core/styles/index";
import List from "@material-ui/core/List/List";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ListItem from "@material-ui/core/ListItem/ListItem";
import Grid from "@material-ui/core/Grid/Grid";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField/TextField";
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PhoneIcon from '@material-ui/icons/Phone';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton/IconButton";
import PersonIcon from '@material-ui/icons/Person';
import TodayIcon from '@material-ui/icons/Today';
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ReceiptIcon from '@material-ui/icons/Receipt';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import connect from "react-redux/es/connect/connect";
import apiBaseUrl from './../../helpers/apiBaseUrl';
import DetailCard from "../Restaurant/components/DetailCard";
import axios from 'axios'
import moment from 'moment';
import {Typography} from "@material-ui/core";

class BillingDataScreen extends React.Component {
    state = {
        menus: [],
        idRestaurant: this.props.idRestaurant,
        name: "",
        cif: null,
        phone: null,
        billingAddress:null,
        contactPerson: null,
        day: null,
        editedBillingName: '',
        editedBillingCif: '',
        editedBillingPhone: '',
        editedBillingAddress:'',
        editedBillingContactPerson: '',
        editedBillingDay: '',

        editing: false,
        sendingData: false,
        receivingData: true,
        bills: []
    };

    componentDidMount() {
        this.getBillingData(this.state.idRestaurant);
        this.getBills(this.state.idRestaurant);
    }

    handleChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };

    renderActions(functionClick) {
        const classes = this.props.classes;

        return (
            <IconButton className={classes.editButton} aria-label="Editar" onClick={functionClick}>
                <EditIcon/>
            </IconButton>
        )
    }

    async getBills() {

        await fetch(apiBaseUrl + 'restaurants/getRestaurantBills', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    bills: response.data,
                    receivingData: false,
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    }
    getBillingData() {

        fetch(apiBaseUrl + 'restaurants/getBillingData', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    name: response.billingData.fiscal_name,
                    cif: response.billingData.cif,
                    phone: response.billingData.contact_phone,
                    billingAddress: response.billingData.billing_address,
                    contactPerson: response.billingData.contact_person,
                    day: response.billingData.preferred_billing_day
                },()=> console.info(this.state.name))
            } else {
                this.setState({loadingRestaurant: false});
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({loadingRestaurant: false});
            console.error(error);
        });

    }

    generateBillPdf(id) {
        axios.request({
            method: 'post',
            url: apiBaseUrl + 'restaurants/addBillPdf',
            data: {
                id: id,
            },
            responseType: 'blob'
        }).then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {type: "application/pdf"});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            })
            .catch((error) => {
                console.log(error);
                this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
            });
    }

    catch(error) {
        return {error};
    }

    async updateBillingData(newData) {

        this.setState({sendingData: true});
        await fetch(apiBaseUrl + 'restaurants/updateBillingData', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(newData),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then(async (res) => {
            let response = JSON.parse(res);
            response = JSON.parse(res);
            if (response.updateError) {
                this.setState({updateError: true, sendingData: false});
            } else {
                await
                    this.getBillingData();
                this.props.dispatch(showSnack('Datos editados con éxito', snackTypes.success));
                this.setState({sendingData: false});

            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    generateResume() {
        return (
            <React.Fragment>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <TextFormatIcon/>
                        </ListItemIcon>
                        <ListItemText primary={this.state.name} secondary="Nombre legal del restaurante"/>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <TextFormatIcon/>
                        </ListItemIcon>
                        <ListItemText primary={this.state.cif} secondary="CIF"/>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <PhoneIcon/>
                        </ListItemIcon>
                        <ListItemText primary={this.state.phone} secondary="Teléfono"/>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <PhoneIcon/>
                        </ListItemIcon>
                        <ListItemText primary={this.state.billingAddress} secondary="Dirección de facturación"/>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <PersonIcon/>
                        </ListItemIcon>
                        <ListItemText primary={this.state.contactPerson} secondary="Persona de contacto"/>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <TodayIcon/>
                        </ListItemIcon>
                        <ListItemText primary={this.state.day} secondary="Día preferente de facturación"/>
                    </ListItem>
                </List>
                {this.renderActions(() => this.setState({editing: true}))}
            </React.Fragment>
        )
    }

    editableResume() {
        return (
            <div>
                <List>
                    <form>

                        <ListItem>
                            <ListItemIcon>
                                <TextFormatIcon/>
                            </ListItemIcon>
                            <TextField
                                autoFocus
                                name='editedBillingName'
                                label="Nombre"
                                defaultValue={this.state.name}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth={true}
                                variant="outlined"
                                inputProps={{maxLength: 50}}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TextFormatIcon/>
                            </ListItemIcon>
                            <TextField
                                name='editedBillingCif'
                                label="CIF"
                                defaultValue={this.state.cif}
                                placeholder="A-00000000"
                                onChange={this.handleChange}
                                helperText="A-00000000"
                                margin="normal"
                                fullWidth={true}
                                variant="outlined"
                                inputProps={{maxLength: 10}}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon/>
                            </ListItemIcon>
                            <TextField
                                name='editedBillingPhone'
                                label="Teléfono"
                                defaultValue={this.state.phone}
                                fullWidth={true}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                inputProps={{maxLength: 50}}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon/>
                            </ListItemIcon>
                            <TextField
                                name='editedBillingAddress'
                                label="Dirección de facturación"
                                defaultValue={this.state.billingAddress}
                                fullWidth={true}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                inputProps={{maxLength: 50}}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon/>
                            </ListItemIcon>
                            <TextField
                                name='editedBillingContactPerson'
                                label="Persona de contacto"
                                defaultValue={this.state.contactPerson}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth={true}
                                variant="outlined"
                                inputProps={{maxLength: 50}}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TodayIcon/>
                            </ListItemIcon>
                            <TextField
                                name='editedBillingDay'
                                label="Día de facturación"
                                defaultValue={this.state.day}
                                onChange={this.handleChange}
                                type={'number'}
                                helperText="Valor entre 1 y 31"
                                margin="normal"
                                fullWidth={true}
                                variant="outlined"
                                InputProps={

                                    {
                                        inputProps: {
                                            max: 31,
                                            min: 1,
                                        },
                                        startAdornment: <InputAdornment position="start">Día: </InputAdornment>
                                    }}
                            />
                        </ListItem>
                    </form>
                    <div className={this.props.classes.actionButtonsBox}>
                        <Button
                            variant='contained'
                            color={"secondary"}
                            className={this.props.classes.cancelEditionButton}
                            onClick={() => this.setState({editing: false})}
                        >
                            Cancelar edición
                        </Button>
                        <Button
                            variant='contained'
                            color={"primary"}
                            onClick={() => this.saveEditedResume()}
                            disabled={this.state.sendingData}
                        >
                            Modificar datos
                            {this.state.sendingData &&
                            <CircularProgress
                                size={25}
                                className={[]}
                            />
                            }
                        </Button>
                    </div>
                </List>

            </div>
        )
    }


    saveEditedResume() {
        let editedResume = {};
        let edited = false;
        const id = this.props.idRestaurant;

        // Comprobaciones para ver si se ha modificado el campo "x"
        if (this.state.editedBillingName) {
            editedResume.fiscal_name = this.state.editedBillingName;
            edited = true;
        }
        if (this.state.editedBillingCif) {
            editedResume.cif = this.state.editedBillingCif;
            edited = true;
        }
        if (this.state.editedBillingPhone) {
            editedResume.contact_phone = this.state.editedBillingPhone;
            edited = true;
        }
        if (this.state.editedBillingAddress) {
            editedResume.billing_address = this.state.editedBillingAddress;
            edited = true;
        }
        if (this.state.editedBillingContactPerson) {
            editedResume.contact_person = this.state.editedBillingContactPerson;
            edited = true;
        }
        if (this.state.editedBillingDay) {
            editedResume.preferred_billing_day = this.state.editedBillingDay;
            edited = true;
        }


        // Comprobamos si algún campo ha sido editado y en consecuencia lanzamos la acción de update o no.
        if (edited) {
            //asignamos el id del restaurante a modificar
            editedResume.uuidRestaurant = id;
            this.updateBillingData(editedResume).then(() => {
                if (this.state.updateError) {
                    /* console.log('no cierro, hay errores al modificar'); */
                } else {
                    //si no hay errores dejamos de editar el resume
                    /* console.log("NO HAY ERROR"); */
                    this.setState({editingResume: false, editing: false})
                }
            });
        } else {
            this.setState({editingResume: false, editing: false})
        }
    }

    generateBillsfromArray = () => (
        <ListItem>

            {this.state.bills.length != 0 ? <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Concepto</TableCell>
                                <TableCell align="center">Total</TableCell>
                                <TableCell align="center">Fecha</TableCell>
                                <TableCell align="center">Pagado</TableCell>
                                <TableCell align="center">Ver</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {this.state.bills.map((bill) => (
                                <TableRow key={bill.date}>

                                    <TableCell align="center">{bill.concept}</TableCell>
                                    <TableCell align="center">{bill.amount}</TableCell>
                                    <TableCell align="center">
                                        {moment(bill.date).format('DD-MM-YYYY')}
                                    </TableCell>
                                    <TableCell align="center">{bill.paid ?
                                        <DoneOutlineIcon className={this.props.classes.tickIcon}/>
                                        : <ClearIcon className={this.props.classes.notPaidIcon}/>}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant='contained'
                                            color={"primary"}
                                            className={this.props.classes.cancelEditionButton}
                                            onClick={() => {
                                                this.generateBillPdf(bill.id);
                                            }}
                                        >
                                            VER
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                : <h3 className={this.props.classes.labelBills}>No existen facturas</h3>}
        </ListItem>
    )

    renderListBills = () => (

        <DetailCard
            title="Facturas"
            avatar={<ReceiptIcon/>}
            data={!this.state.receivingData ? this.generateBillsfromArray() : <CircularProgress className={this.props.classes.receivingDataProgress} size={50}/>}
        />


    );

    renderEditingCard() {
        return (
            <DetailCard
                title="Editar datos"
                avatar={<ReceiptIcon/>}
                data={this.editableResume()}
            />
        );
    }


    renderBillingCard() {
        return (
            <DetailCard
                title="Datos"
                avatar={<ReceiptIcon/>}
                data={!this.state.receivingData ? this.generateResume() :

                    <CircularProgress
                        className={this.props.classes.receivingDataProgress}

                        size={50}

                    />}
            />
        );
    }


    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.root}>
                <MiniDrawer title={'Información de facturación'} noRefresh={true}/>
                <Grid item xs={12} s={6} m={6} l={6}>
                    {!this.state.editing ? this.renderBillingCard() : this.renderEditingCard()}
                    {this.renderListBills()}
                </Grid>
                <Snack/>
            </div>
        )
    }
}

BillingDataScreen.propTypes = {
    user: PropTypes.array.isRequired,
    idRestaurant: PropTypes.string.isRequired,
    nameRestaurant: PropTypes.string.isRequired,
};
const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        user: authReducer.credentials[0],
        idRestaurant: restaurantReducer.idRestaurant,
        nameRestaurant: restaurantReducer.nameRestaurant
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles())(BillingDataScreen));

