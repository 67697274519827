import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import RemoveIcon from "@material-ui/icons/Delete";
import {Badge, Container, ListItemSecondaryAction, Tooltip, Typography} from "@material-ui/core";
import List from "@material-ui/core/List";
import {add_dish_to_cart, delete_whole_cart, remove_dish_from_cart, subtract_one_dish_from_cart} from "./cartActions";
import IconButton from "@material-ui/core/IconButton";
import importedStyles from "./Cart.module.sass";
import MinusIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"
import CartIcon from "@material-ui/icons/ShoppingCartOutlined"
import VerifiedIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import {addDishEvent} from "../../../../helpers/addDishEvent";


class Cart extends React.Component {

    state = {
        deleteWholeCartConfirmationDialog: false,
        finishOrderConfirmationDialog: false,
    };

    componentDidMount() {

    }

    removeItemFromCart = (dishUuid) => () => {
        this.props.dispatch(remove_dish_from_cart(dishUuid));
    };

    handleAddDishToCart = (dish) => () => {
        this.props.dispatch(add_dish_to_cart(dish));
        addDishEvent(dish.uuid);
    };

    handleSubstract = (dish) => () =>{
        this.props.dispatch(subtract_one_dish_from_cart(dish.uuid));
    };

    handleClearCart = () => {
        this.props.dispatch(delete_whole_cart());
        this.handleDeleteWholeCartConfirmationDialog(); //close delete confirmation modal
        window.location.reload(); // Reload page
    };
    handleDeleteWholeCartConfirmationDialog = () => {
        this.setState((prevState, props) => ({
            deleteWholeCartConfirmationDialog: !prevState.deleteWholeCartConfirmationDialog
        }));
    };

    handleFinishOrderConfirmationDialog = () => {
        this.setState((prevState, props) => ({
            finishOrderConfirmationDialog: !prevState.finishOrderConfirmationDialog
        }));
    };

    renderDishAvatar = dish => {
        let avatar = <Avatar style={{width: 65, height: 65}}>{dish.name.substr(0, 1)}</Avatar>;
        if (dish.photo && dish.photo.length > 0){
            avatar = <Avatar style={{width: 65, height: 65}} src={dish.photo}/>
        }
        return (
            <React.Fragment>{avatar}</React.Fragment>
        );
    };

    renderCartItems = () => {
        let currentCart = this.props.cart;
        let cartItems = [];
        let cartItemsAlreadyAdded = [];
        Object.keys(currentCart).forEach((key) => {
            let dish = currentCart[key];
            let numberOfEqualDishesOnCart = currentCart.filter(item => (item.uuid === dish.uuid)).length;
            let alreadyOnCart = cartItemsAlreadyAdded.filter(item => (item.uuid === dish.uuid)).length > 0;

            if (!alreadyOnCart) {
                cartItemsAlreadyAdded.push(dish);
                cartItems.push(
                    <React.Fragment>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar style={{marginRight: 10}}>
                                {this.renderDishAvatar(dish)}
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography variant={"h5"}>{dish.name}</Typography>}
                                secondary={<Typography variant={"subtitle1"} style={{color: 'rgba(0, 0, 0, 0.54)'}}>{numberOfEqualDishesOnCart > 0 ? (numberOfEqualDishesOnCart + ' x ' + dish.price + '€') : (dish.price + '€')}</Typography>}
                            />
                            <ListItemSecondaryAction>
                                <IconButton onClick={this.handleSubstract(dish)}>
                                    <MinusIcon style={{fontSize: 16}}/>
                                </IconButton>
                                <Badge badgeContent={numberOfEqualDishesOnCart} color="primary">
                                    <CartIcon onClick={this.handleAddDishToCart(dish)}/>
                                </Badge>
                                <IconButton onClick={this.handleAddDishToCart(dish)}>
                                    <AddIcon style={{fontSize: 16}}/>
                                </IconButton>
                                <Tooltip title={'Eliminar del pedido'} placement={"bottom"}>
                                    <IconButton onClick={this.removeItemFromCart(dish.uuid)}>
                                        <RemoveIcon className={importedStyles.removeIcon}/>
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li"/>
                    </React.Fragment>
                );
            }

        });

        return (
            <React.Fragment>
                {cartItems}
            </React.Fragment>
        );
    };

    renderEmptyCartMessage = () => {
        return (
          <ListItem>
              <ListItemText primary={'Todavía no tienes ningún plato en tu pedido'}/>
          </ListItem>
        );
    };



    render() {
        return (
            <React.Fragment>
                <Dialog open={this.props.cartOpened} onClose={this.props.onClose} maxWidth="md" fullWidth>
                <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close" style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}>
                    <CloseIcon/>
                </IconButton>
                <DialogTitle id="cart-title">Mi pedido</DialogTitle>
                <DialogContent style={{minHeight: '60vh'}}>
                    <DialogContentText id="alert-dialog-description" className={importedStyles.dialogContentText}>
                        Aquí puedes guardar los diferentes platos y/o bebidas que vas a pedir para poder recordarlos y
                        enseñarselo al camarero cuando te atienda.
                    </DialogContentText>
                    <Container className={importedStyles.cartItemsContainer}>
                        <List dense>
                            {this.props.cart.length > 0 ? this.renderCartItems() : this.renderEmptyCartMessage()}
                        </List>
                    </Container>
                </DialogContent>
                <DialogActions className={importedStyles.dialogActions}>
                    <Button variant={"outlined"} color="secondary" onClick={this.handleDeleteWholeCartConfirmationDialog}>
                        Borrar pedido
                    </Button>
                    <Button variant={"contained"} color="primary" onClick={this.handleFinishOrderConfirmationDialog}>
                        <VerifiedIcon style={{marginRight: 5}}/>
                        Confirmar pedido
                    </Button>
                </DialogActions>
            </Dialog>

                {/*DELETE WHOLE CART CONFIRMATION DIALOG */}
                <Dialog open={this.state.deleteWholeCartConfirmationDialog} onClose={this.handleDeleteWholeCartConfirmationDialog} maxWidth="sm" fullWidth >
                    <DialogTitle id="cart-title">Confirmar borrado</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={importedStyles.dialogContentText}>
                            Esta acción eliminará todos los platos/artículos del pedido <br/> ¿Estás seguro?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={importedStyles.dialogActions}>
                        <Button variant={"outlined"} color="secondary" onClick={this.handleDeleteWholeCartConfirmationDialog}>
                            Cancelar
                        </Button>
                        <Button variant={"contained"} color="primary" onClick={this.handleClearCart}>
                            Confirmar borrado
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* FINISH ORDER CONFIRMATION DIALOG */}
                <Dialog open={this.state.finishOrderConfirmationDialog} onClose={this.handleFinishOrderConfirmationDialog} maxWidth="sm" fullWidth >
                    <IconButton color="inherit" onClose={this.handleFinishOrderConfirmationDialog}  aria-label="Close" style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle id="cart-title">Confirmar pedido</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={importedStyles.dialogContentText}>
                            ¡GENIAL! <br/> Ahora puedes avisar al camarero/a más cercano para que tome nota de tu pedido.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color="primary" fullWidth onClick={this.handleFinishOrderConfirmationDialog}>
                            Entendido
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

Cart.propTypes = {
    cartOpened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(Cart);

