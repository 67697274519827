import { createMuiTheme } from '@material-ui/core/styles';
import red from "@material-ui/core/colors/red";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    }});
const drawerWidth = 240;

export default function importedStyles() {
    return {
        root: {
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '98%',
            },
        },
        appBar: {
            position: 'absolute',
            marginLeft: drawerWidth,
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
            },
        },
        navIconHide: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        drawerPaper: {
            width: drawerWidth,
            [theme.breakpoints.up('md')]: {
                position: 'relative',
            },
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(2),
            marginTop: 50,
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3),
                paddingRight: 0,
            },
        },
        card: {
            //maxWidth: 400,
        },
        media: {
            objectFit: 'cover',
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        actions: {
            display: 'flex',
        },
        expand: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
            marginLeft: 'auto',
            [theme.breakpoints.up('sm')]: {
                marginRight: -8,
            },
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },
        avatar: {
            backgroundColor: red[500],
            width: 150,
            height: 150,
        },
        miniAvatar: {
            width: 50,
            height: 50,
            marginRight: 10,
        },
        img: {
            height: 300,
            borderRadius: 5
        },
        imageCard: {
            margin: 10,
            overflow: 'unset'
        },
        imageCardActions: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        imgContainer: {
            display: 'flex',
            width: '100%',
            overflow: 'auto',
            backgroundColor: '#c1c1c169',
            borderRadius: '0.5em'
        },
        noImgContainer: {
            display: 'flex',
            justifyContent: 'flex-start'
        },
        menuCard: {
            marginLeft: 10,
            background: '#eef1f5',
            minWidth: 300
        },
        menuContainer: {
            display: 'flex',
            padding: 20,
            overflowX: 'scroll',
            justifyContent: 'flex-start'
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        chargingAnimationContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: window.innerHeight - 200
        },
        resumeCard: {
            minHeight: 500,
            maxHeight: 500
        },
        resumeContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',

        },
        chargingAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -10,
        },
        mapList: {
            marginTop: 30,
            width: '100%'
        },
        gridSubContainer: {
            display: 'flex'
        },
        autocompleteCityInput: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        iconRight: {
            marginLeft: 5
        },
        iconLeft: {
            marginRight: 10
        },
        addingImageContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                justifyContent: 'center',
                flexWrap: 'wrap',
            },
        },
        selectFileBox: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                flexWrap: 'wrap',
            },
        },
        logoContainer:{
            justifyContent: 'center'
        },
        statisticsContainer:{
            display: 'flex',
            justifyContent: 'center'
        },
        fileUploadButton: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '1em'
            },
        },
        deleteRestaurantBox: {
            backgroundColor: '#c1c1c138',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            overflow: 'auto',
            margin: 10,
        },
        deleteRestaurantCardContent: {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center'
        },
        sendingDataAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -10,
        },
        selectFileActions: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 25
        },
        restaurantLogo: {
            backgroundColor: red[500],
            width: 150,
            height: 150,
            alignSelf: 'center',
            marginBottom: 15
        },
        statisticsCard:{
            padding: '0.25rem',
        }
    }
}