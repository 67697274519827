import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import Container from "@material-ui/core/Container";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";
import IngredientsIcon from "@material-ui/icons/Layers";
import AllergensIcon from "@material-ui/icons/Warning";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import Grid from "@material-ui/core/Grid";
import importedStyles from "./SectionContentBox.module.sass";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {Badge, Chip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {add_dish_to_cart, subtract_one_dish_from_cart, undo_last_cart_addition} from "../Cart/cartActions";
import {addDishEvent} from "../../../../helpers/addDishEvent";


class SectionContentBox extends React.Component {

    state = {
        expanded: null,
    };


    componentDidMount() {
        if (this.props.currentDish && this.props.currentDish.length > 0) {
            this.setState({expanded: this.props.currentDish});
            let currentPanel = document.getElementById(this.props.currentDish);

            if (currentPanel) { //Security check to avoid 'null' exception behavior
                setTimeout(() => {
                    window.scrollTo({top: currentPanel.offsetTop - 150, behavior: 'smooth', block: 'end'});//moves the newly opened panel up offset
                }, 250);
            }


        }
    }

    renderDishAllergens = (allergens) => {
        let items = [];
        Object.keys(allergens).forEach((key) => {
            let allergen = allergens[key];
            items.push(
                <React.Fragment>
                    <Chip className={importedStyles.allergenChip}
                          avatar={<Avatar style={{height: 50, width: 50}} alt={allergen.name} src={allergen.logo}/>}
                          label={allergen.name}/>
                </React.Fragment>
            );
        });

        return (<React.Fragment>{items}</React.Fragment>);
    };

    renderAllergenSection = (allergens) => {
        return (
            <React.Fragment>
                <Divider variant="inset" component="li"/>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="allergens icon">
                            <AllergensIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"h5"}>Alérgenos</Typography>}
                        secondary={this.renderDishAllergens(allergens)}
                    />
                </ListItem>
            </React.Fragment>
        );
    };

    showAddedToCartSnack = (dishName) => {
        this.setState({dishToAdd: dishName, addedToCartSnack: true});
    };

    handleAddDishToCart = (dish, event) => {
        event.stopPropagation(); //Prevent expansion panel to expand

        if (this.props.firstAdditionToCart) { //show 'tuto' dialog if first addition to cart and turn flag to false
            this.props.handleFirstAdditionToCart();
        }


        this.props.dispatch(add_dish_to_cart(dish));
        // this.showAddedToCartSnack(dish.name);
    };

    handleSubstract = (dish, event) => {
        event.stopPropagation(); //Prevent expansion panel to expand
        this.props.dispatch(subtract_one_dish_from_cart(dish.uuid));
    };
    undoCartAddition = () => {
        this.props.dispatch(undo_last_cart_addition());
        this.setState({addedToCartSnack: false});
    };

    handleExpansionPanelChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
        let currentPanel = document.getElementById(panel);

        if (expanded && currentPanel) { //Security check for currentPanel to avoid 'null' exception behavior
            setTimeout(() => {
                window.scrollTo({top: currentPanel.offsetTop - 150, behavior: 'smooth', block: 'end'});//moves the newly opened panel up offset
            }, 250);
        }
    };

    renderDishes = () => {

        let items = [];
        let dishes = this.props.selectedSection.dishes;
        const {expanded} = this.state;

        Object.keys(dishes).forEach((key) => {
            let dish = dishes[key];
            let numberOfEqualDishesOnCart = this.props.cart.filter(item => (item.uuid === dish.uuid)).length;

            items.push(
                <ExpansionPanel key={dish.uuid} id={dish.uuid} expanded={expanded === dish.uuid}
                                onChange={this.handleExpansionPanelChange(dish.uuid)}>
                    <ExpansionPanelSummary className={importedStyles.expansionPanelSummary}
                                           expandIcon={<ExpandMoreIcon className={importedStyles.expandIcon}/>}>
                        <Typography variant={"h4"}>{dish.name}</Typography>
                        <Typography variant={"subtitle2"}
                                    className={importedStyles.dishPrice}>{dish.price}€</Typography>

                        <IconButton onClick={this.handleSubstract.bind(this, dish)}>
                            <MinusIcon style={{fontSize: 16}}/>
                        </IconButton>
                        <Badge badgeContent={numberOfEqualDishesOnCart} color="primary">
                            <CartIcon onClick={this.handleAddDishToCart.bind(this, dish)}/>
                        </Badge>
                        <IconButton onClick={this.handleAddDishToCart.bind(this, dish)} style={{marginRight: '1em'}}>
                            <AddIcon style={{fontSize: 16}}/>
                        </IconButton>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={6} style={{maxHeight: '18em', display: 'flex', justifyContent: 'center'}}>
                                <img
                                    className={importedStyles.dishPhoto}
                                    src={dish.photo ? dish.photo : require('../../../../rsc/img/no-image.jpg')}
                                    alt={dish.name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <List>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt="description icon">
                                                <DescriptionIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant={"h5"}>Descripción</Typography>}
                                            secondary={<span
                                                className={importedStyles.secondaryListItemText}>{dish.description}</span>}
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt="ingredients icon">
                                                <IngredientsIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant={"h5"}>Ingredientes</Typography>}
                                            secondary={<span
                                                className={importedStyles.secondaryListItemText}>{dish.ingredients}</span>}
                                        />
                                    </ListItem>
                                    {dish.allergens && dish.allergens.length > 0 && this.renderAllergenSection(dish.allergens)}
                                </List>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        });

        return (<React.Fragment>{items}</React.Fragment>);
    };


    renderNextSectionsLinks = () => {
        let nextSections = this.props.sections.filter((item) => {
            return item.uuid !== this.props.selectedSection.uuid
        });

        let nextSectionsLinks = [];

        Object.keys(nextSections).forEach((key) => {
            let nextSection = nextSections[key];
            nextSectionsLinks.push(
                <ListItem button onClick={this.props.handleSectionChange(nextSection)}>
                    <ListItemText primary={'Ver "' + nextSection.name + '"'} style={{fontStyle: 'oblique'}}/>
                </ListItem>
            );
        });

        return (
            <List>
                {nextSectionsLinks}
            </List>
        );
    };


    render() {


        return (
            <Container maxWidth="lg">
                {this.renderDishes()}
                {this.renderNextSectionsLinks()}
                {/*<Snackbar
                    open={this.state.addedToCartSnack}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    autoHideDuration={6000}
                    onClose={() => this.setState({addedToCartSnack: false})}
                    message={'"' + this.state.dishToAdd + '" ha sido añadido al pedido'}
                    action={<Button variant={'outlined'} key="undo" color="secondary" size="small"
                                    onClick={this.undoCartAddition}><span>Deshacer</span></Button>}
                />*/}
            </Container>
        );
    }
}

SectionContentBox.propTypes = {
    sections: PropTypes.array.isRequired,
    selectedSection: PropTypes.object.isRequired,
    handleSectionChange: PropTypes.func.isRequired,
    handleFirstAdditionToCart: PropTypes.func.isRequired,
    firstAdditionToCart: PropTypes.bool.isRequired,
    currentDish: PropTypes.string.isRequired
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        currentMenu: digitalMenuReducer.actualMenu,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(SectionContentBox);

