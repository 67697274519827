import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import imageDefault from "../../../../../src/rsc/img/image-default.png"
import importedStyles from "./ProductDetail.module.sass";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import IngredientsIcon from "@material-ui/icons/Layers";
import AllergensIcon from "@material-ui/icons/Warning";
import DescriptionIcon from "@material-ui/icons/Description";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import Avatar from "@material-ui/core/Avatar";
import {Badge, Chip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {
    add_dish_to_cart,
    subtract_one_dish_from_cart,
    undo_last_cart_addition
} from "../../../DigitalMenuMobile/components/Cart/cartActions";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import {handleImgLoadError} from "../../../../helpers/utils";
import Snack from "../../../../components/Snack/Snack";
import {addDishEvent} from "../../../../helpers/addDishEvent";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {ThemeProvider} from "@material-ui/styles";
import {darken, hexToRgbA, lighten} from "../../../../helpers/manageColors";

class ProductDetail extends React.Component {

    state = {};

    componentDidMount() {
        console.info(this.props.product.allergens)
    }


    renderDishAllergens = (allergens) => {
        let items = [];
        Object.keys(allergens).forEach((key) => {
            let allergen = allergens[key];
            items.push(
                <React.Fragment>
                    <Chip className={importedStyles.allergenChip}
                          avatar={<Avatar style={{height: 35, width: 35}} alt={allergen.name} src={allergen.logo}
                                          onError={handleImgLoadError}/>}
                          label={allergen.name}/>
                </React.Fragment>
            );
        });

        return (<React.Fragment>{items}</React.Fragment>);
    };

    renderDescription = () => {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.props.primaryColor ? this.props.primaryColor : '#008EEF',
                    // main:'#FF0000',
                },
                secondary: {
                    main: this.props.secondaryColor ? this.props.secondaryColor : '#000',
                },
            },
        });
        if (this.props.product.description && this.props.product.description !== 'null') {
            return (
                <React.Fragment>
                    <ThemeProvider theme={theme}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar
                                className={importedStyles.dishInfoIconContainer}>
                                <DescriptionIcon color={"primary"} className={importedStyles.dishInfoIcons}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography variant={"h5"}>Descripción</Typography>}
                                secondary={<span
                                    className={importedStyles.secondaryListItemText}>{this.props.product.description}</span>}
                            />
                        </ListItem>
                        < Divider variant="inset" component="li"/>
                    </ThemeProvider>
                </React.Fragment>
            );
        }
    };
    renderIngredients = () => {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.props.primaryColor ? this.props.primaryColor : '#008EEF',
                    // main:'#FF0000',
                },
                secondary: {
                    main: this.props.secondaryColor ? this.props.secondaryColor : '#000',
                },
            },
        });
        if (this.props.product.ingredients && this.props.product.ingredients !== 'null') {
            return (
                <ThemeProvider theme={theme}>

                    <ListItem alignItems="flex-start">
                        <ListItemAvatar className={importedStyles.dishInfoIconContainer}>
                            <IngredientsIcon color={"primary"} className={importedStyles.dishInfoIcons}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant={"h5"}>Ingredientes</Typography>}
                            secondary={<span
                                className={importedStyles.secondaryListItemText}>{this.props.product.ingredients}</span>}
                        />
                    </ListItem>
                </ThemeProvider>
            );
        }
    };
    renderAllergenSection = (allergens) => {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.props.primaryColor ? this.props.primaryColor : '#008EEF',
                    // main:'#FF0000',
                },
                secondary: {
                    main: this.props.secondaryColor ? this.props.secondaryColor : '#000',
                },
            },
        });
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>

                    <Divider variant="inset" component="li"/>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar className={importedStyles.dishInfoIconContainer}>
                            <AllergensIcon color={"primary"} className={importedStyles.dishInfoIcons}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant={"h5"}>Alérgenos</Typography>}
                            secondary={this.renderDishAllergens(allergens)}
                        />
                    </ListItem>
                </ThemeProvider>
            </React.Fragment>
        );
    };

    renderNoInfoAlert = () => {
        let product = this.props.product;
        if ((!product.description || product.description === 'null') && (!product.ingredients || product.ingredients === 'null') && product.allergens.length < 1) {
            return (
                <React.Fragment>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar className={importedStyles.dishInfoIconContainer}>
                            <AllergensIcon className={importedStyles.dishInfoIcons}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant={"h5"}>No se ha proporcionado información detallada para este
                                producto</Typography>}
                        />
                    </ListItem>
                </React.Fragment>
            )
        }
    };

    showAddedToCartSnack = (dishName) => {
        this.setState({dishToAdd: dishName, addedToCartSnack: true});
    };

    handleAddDishToCart = (dish) => () => {

        if (this.props.firstAdditionToCart) { //show 'tuto' dialog if first addition to cart and turn flag to false
            this.props.handleFirstAdditionToCart();
        }
        addDishEvent(dish.uuid);
        this.props.dispatch(add_dish_to_cart(dish));
        // this.showAddedToCartSnack(dish.name);
    };

    handleSubstract = (dish) => () => {
        this.props.dispatch(subtract_one_dish_from_cart(dish.uuid));
    };
    undoCartAddition = () => {
        this.props.dispatch(undo_last_cart_addition());
        this.setState({addedToCartSnack: false});
    };


    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.props.primaryColor ? this.props.primaryColor : '#008EEF',
                },
                secondary: {
                    main: this.props.secondaryColor ? this.props.secondaryColor : '#ffffff',
                },
            },
        });
        let numberOfEqualDishesOnCart = this.props.cart.filter(item => (item.uuid === this.props.product.uuid)).length;
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <Grow
                        in={true}
                        style={{transformOrigin: '0 0 0'}}
                        {...{timeout: 1000}}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className={importedStyles.mainContainer}>
                            <div style={{position: 'relative'}}>
                                <img
                                    alt="product"
                                    src={this.props.product.photo ? this.props.product.photo : imageDefault}
                                    className={importedStyles.productImage}
                                    onError={handleImgLoadError}
                                />
                                <span style={{background: this.props.primaryColor}}
                                      className={importedStyles.productPrice}>
                                    <span style={{color: lighten(this.props.secondaryColor,20)}}>{this.props.product.price}€</span>
                                </span>
                            </div>
                            <List className={importedStyles.productDetails}>
                                {this.renderNoInfoAlert()}
                                {this.renderDescription()}
                                {this.renderIngredients()}
                                {this.props.product.allergens && this.props.product.allergens.length > 0 && this.renderAllergenSection(this.props.product.allergens)}
                            </List>

                        </div>
                    </Grow>
                    <Fade in={true} timeout={800}>
                        <div style={{backgroundColor: this.props.primaryColor, boxShadow: `6px 7px 31px -5px ${hexToRgbA((this.props.primaryColor),50)}`}}
                             className={importedStyles.productActions}>
                            <IconButton onClick={this.handleSubstract(this.props.product)} style={{ color: this.props.secondaryColor}}>
                                <MinusIcon style={{ color: this.props.secondaryColor }}/>
                            </IconButton>
                            <Badge badgeContent={numberOfEqualDishesOnCart}
                                   style={{color: lighten(this.props.secondaryColor,20)}}
                                   color={"secondary"}
                                   // classes={{badge: importedStyles.dishQuantityBadge}}
                            >
                                <CartIcon style={{ color: lighten(this.props.secondaryColor,20) }}/>
                            </Badge>
                            <IconButton onClick={this.handleAddDishToCart(this.props.product)} style={{color: this.props.secondaryColor}}>
                                <AddIcon style={{ color: lighten(this.props.secondaryColor,20) }}/>
                            </IconButton>
                        </div>
                    </Fade>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}

ProductDetail.propTypes = {
    product: PropTypes.object.isRequired,
    handleFirstAdditionToCart: PropTypes.func.isRequired,
    firstAdditionToCart: PropTypes.bool.isRequired,
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        currentMenu: digitalMenuReducer.actualMenu,
        currentProduct: digitalMenuReducer.currentProduct,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(ProductDetail);

