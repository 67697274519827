import React from 'react';
import importedStyles from "./ReservationScreen.module.sass";
import Button from "@material-ui/core/Button/Button";
import history from "../../../helpers/history"
import NavbarLanding from "../Landing/NavbarLanding/NavbarLanding";
import connect from "react-redux/es/connect/connect";
import Footer from "../../../components/Footer/Footer";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import {DatePicker, TimePicker} from "material-ui-pickers";
import {getMyDateFormat} from "../../../helpers/dateUtils";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Snack from "../../../components/Snack/Snack";
import moment from "moment";


class ReservationScreen extends React.Component {

    state = {
        loadingRestaurant: true,
        formError: false,
        emailError: false,
        sendingData: false,
        reservationEmail: '',
        reservationName: '',
        reservationNote: '',
        reservationPhone: '',
        reservationAdults: 1,
        reservationKids: 0,
        reservationHour: new Date(),
        reservationDate: getMyDateFormat(new Date()),
    };

    componentDidMount() {
        if (this.props) {
            this.setState({id: this.props.match.params.id});
        }
        this.getInfo();
    }

    getInfo() {
        fetch(apiBaseUrl + 'restaurants/getRestaurant', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.match.params.id
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    restaurant: response.restaurant,
                    loadingRestaurant: false
                })
            } else {
                this.setState({loadingRestaurant: false});
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({loadingRestaurant: false});
            console.error(error);
        });

    }


    handleInputChange = (e) => {

        let alphaRegex = new RegExp("^[a-zA-Z ]*$");
        let emailRegex = new RegExp("^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");

        switch (e.target.name) {
            case 'reservationName':
                if (alphaRegex.test(e.target.value)) {
                    this.setState({[e.target.name]: e.target.value});
                }
                break;
            case 'reservationAdults':
                if (e.target.value <= 50) {
                    this.setState({[e.target.name]: e.target.value});
                }
                break;
            case 'reservationKids':
                if (e.target.value <= 50) {
                    this.setState({[e.target.name]: e.target.value});
                }
                break;
            case 'reservationPhone':
                if (e.target.value.length <= 9) {
                    this.setState({[e.target.name]: e.target.value});
                }
                break;
            case 'reservationEmail':
                if (emailRegex.test(e.target.value)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailError: false
                    })
                } else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailError: true
                    });
                }
                break;
            default:
                this.setState({[e.target.name]: e.target.value});
                break;
        }
    };
    handleInputDateChange = (date) => {
        let newValue = {reservationDate: new Date(date)};
        this.setState(newValue);
    };
    handleInputHourChange = (date) => {
        let change = {reservationHour: date};
        this.setState(change);
    };

    makeReservation = () => {
        this.setState({sendingData: true});
        console.log(this.state.reservationDate);
        fetch(apiBaseUrl + 'reservations/createReservation', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.match.params.id,
                email: this.state.reservationEmail,
                name: this.state.reservationName,
                phone: this.state.reservationPhone,
                date: moment(this.state.reservationDate).format('Y-MM-DD'),
                hour: moment(this.state.reservationHour).locale('es').format('LT'),
                adults: this.state.reservationAdults,
                childs: this.state.reservationKids,
                comment: this.state.reservationNote
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({sendingData: false});
                //history.push('/comerEn/confirmedReservation');
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.props.dispatch(showSnack('Ha ocurrido un error al intentar confirmar tu reserva, inténtalo en unos minutos', snackTypes.error));
            this.setState({sendingData: false});
            console.error(error);
        });
    };

    renderDetail() {
        if (this.state.restaurant) {
            return (
                <div className={importedStyles.formContainer}>
                    <h1>Reserva en {this.state.restaurant.name}</h1>
                    <form className={importedStyles.form}>
                            <div className={importedStyles.pickersContainer}>
                                <DatePicker
                                    autoFocus
                                    format={'DD/MM/YYYY'}
                                    label="Fecha"
                                    variant="outlined"
                                    value={this.state.reservationDate}
                                    disablePast
                                    onChange={this.handleInputDateChange}
                                    name="reservationDate"
                                    okLabel={'OK'}
                                    cancelLabel={'CANCELAR'}
                                    animateYearScrolling
                                    InputLabelProps={{
                                        className: importedStyles.pickerDate
                                    }}
                                />
                                <TimePicker
                                    margin="normal"
                                    label="Hora"
                                    variant="outlined"
                                    name="reservationHour"
                                    ampm={false}
                                    value={this.state.reservationHour}
                                    onChange={this.handleInputHourChange}
                                />
                            </div>
                        <TextField
                            error={this.state.formError || this.state.reservationName.length <= 0}
                            required
                            margin="normal"
                            name="reservationName"
                            label="Nombre"
                            type="text"
                            variant="outlined"
                            value={this.state.reservationName}
                            onChange={this.handleInputChange}
                            fullWidth
                            inputProps={{maxLength: 50}}
                        />
                        <TextField
                            margin="normal"
                            variant="outlined"
                            error={this.state.formError || this.state.reservationAdults.length <= 0}
                            name="reservationAdults"
                            label="Adultos"
                            value={this.state.reservationAdults}
                            type="number"
                            onChange={this.handleInputChange}
                            fullWidth
                        />
                        <TextField
                            margin="normal"
                            variant="outlined"
                            error={this.state.formError || this.state.reservationKids.length <= 0}
                            name="reservationKids"
                            value={this.state.reservationKids}
                            label="Niños"
                            type="number"
                            onChange={this.handleInputChange}
                            fullWidth
                        />

                        <TextField
                            margin="normal"
                            error={this.state.emailError || this.state.reservationEmail.length <= 0}
                            variant="outlined"
                            name="reservationEmail"
                            label="Correo de contacto"
                            type="email"
                            value={this.state.reservationEmail}
                            onChange={this.handleInputChange}
                            fullWidth
                            inputProps={{maxLength: 50}}
                        />
                        <TextField
                            margin="normal"
                            error={this.state.formError || this.state.reservationPhone.length < 9}
                            name="reservationPhone"
                            label="Teléfono"
                            variant="outlined"
                            type="number"
                            value={this.state.reservationPhone}
                            onChange={this.handleInputChange}
                            inputProps={{minLength: 9}}

                            fullWidth
                        />
                        <TextField
                            margin="normal"
                            name="reservationNote"
                            label="Comentario"
                            type="text"
                            variant="outlined"
                            value={this.state.reservationNote}
                            onChange={this.handleInputChange}
                            fullWidth
                            helperText="Añade un comentario sobre la reserva de manera opcional"
                            multiline
                            inputProps={{maxLength: 150}}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.makeReservation}
                            className={importedStyles.reservationButton}
                            disabled={
                                this.state.sendingData ||
                                !this.state.reservationName.length > 0 || !this.state.reservationEmail.length > 0 ||
                                !this.state.reservationPhone.length > 0 || !this.state.reservationAdults > 0 || this.state.emailError
                            }
                        >
                            {this.state.sendingData ? <CircularProgress size={25}/> : 'Reservar'}
                        </Button>
                    </form>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <NavbarLanding style={{marginBottom: 10}}/>
                <div className={importedStyles.reservation}>
                    <Button className={importedStyles.buttonBack}
                            onClick={() => history.push('/comerEn/restaurante/' + this.state.id)}>
                        <ArrowBack className={importedStyles.buttonBackIcon}/> Volver al restaurante
                    </Button>
                    {this.state.loadingRestaurant ? <CircularProgress className={importedStyles.circularProgress}
                                                                      size={120}/> : this.renderDetail()}
                </div>
                <Footer/>
                <Snack/>
            </div>
        );
    }
}

const mapStateToProps = ({searchReducer}) => {
    return ({
        // filterActive: searchReducer.filterActive,
    });
};

export default connect(mapStateToProps)(ReservationScreen);