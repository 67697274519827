import React from 'react';
import importedStyles from "./LandingManagerScreen.module.sass";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/Navbar/NavBar";
import history from '../../helpers/history';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Grid from "@material-ui/core/Grid";
import Image from "react-image-webp";
import fondoContact from "../../rsc/img/contact-background.png";
import supportsWebP from "supports-webp";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import StatsIcon from '../../rsc/icons/medir-el-exito.svg'
import ResponsiveIcon from '../../rsc/icons/responsive-icon.svg'
import AIIcon from '../../rsc/icons/ai.svg'
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Lottie from "react-lottie";
import success from "../../rsc/animation/success";
import {CircularProgress} from "@material-ui/core";


class LandingManagerScreen extends React.Component {

    state = {
        height: window.innerHeight,
        phone: '',
        contactSuccess: false,
        openPromoBanner: true,
    };

    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        //Fallback for non Webp format supported web browsers
        supportsWebP.then(supported => {
            if (!supported) {
                document.getElementById('contact').style.backgroundImage = `url(${fondoContact})`;
            }
        });

        if (this.props.logged) {
            history.push('/myRestaurants');
        }

        window.scrollTo(0, 0);
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    handleInputChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: window.innerHeight});
    }

    renderSendPhoneForm = () => {
        return (
            <div className={importedStyles.phoneFieldContainer}>
                <TextField
                    id="phone"
                    label="Teléfono"
                    name={'phone'}
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                />
                <IconButton className={importedStyles.submitPhoneButton}>
                    <SendIcon onClick={this.sendPhone}/>
                    {this.state.sendingContact &&
                    <CircularProgress size={25} className={importedStyles.sendingDataAnimation}/>}
                </IconButton>
            </div>
        )
    };

    renderPhoneSendedConfirmation = () => {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: success,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div style={{marginBottom: '2rem'}}>
                <Lottie options={defaultOptions}
                        height={150}
                        width={150}
                />
                <small>Gracias por contactar con nosotros</small>
            </div>
        )
    };

    sendPhone = () => {
        if (this.state.phone.length >= 9) {
            this.setState({sendingContact: true});

            fetch(apiBaseUrl + 'utils/contactFromLanding', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    contactNumber: this.state.phone,
                })
            }).then((responseJSON) => {
                return responseJSON.text();
            }).then((recurso) => {
                let response = JSON.parse(recurso);
                if (!response.error) {
                    this.setState({phone: '', contactSuccess: true, sendingContact: false});
                    this.props.dispatch(showSnack('Hemos recibido tu solititud, pronto nos pondremos en contacto contigo, gracias.', snackTypes.success));
                } else {
                    //ha fallado algo, no enviado
                    throw new Error(response.errorMessage);
                }
            }).catch((error) => {
                this.setState({sendingContact: false});
                console.error(error);
                this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
            });
        } else {
            alert('Introduce un número de 9 digitos válido');
        }
    };

    renderView() {
        return (
            <div className={importedStyles.content}>
                <Grid item xs={12} id={'header'} className={importedStyles.headerContainer}
                      style={{height: this.state.height}}>
                    <div className={importedStyles.headerSubcontainer}>
                        <h1>ACERCA LA TECNOLOGÍA A TU RESTAURANTE</h1>
                        <h3>Mejora la experiencia de tus clientes digitalizando tu negocio</h3>
                        <div className={importedStyles.buttonsContainer}>
                            <AnchorLink
                                offset={() => 100}
                                className={importedStyles.callToAction}
                                href={'#contact'}
                            >
                                Me interesa
                            </AnchorLink>
                            <AnchorLink
                                offset={() => 100}
                                className={importedStyles.callToAction2}
                                href={'#services'}
                            >
                                Servicios
                            </AnchorLink>
                        </div>
                    </div>
                </Grid>
                <Grid id={'customers'} item xs={12} className={importedStyles.customerContainer}>
                    <h2 className={importedStyles.sectionTitle}>Clientes satisfechos</h2>
                    <img
                        className={importedStyles.customersImg}
                        src={require('../../rsc/img/customersComeren.png')}
                        alt={'customers'}
                    />
                </Grid>
                <div id={'features'} className={importedStyles.featuresSection}>
                    <h2 className={importedStyles.sectionTitle}>Ventajas</h2>
                    <Grid container spacing={4} className={importedStyles.featuresContainer}>
                        <Grid item xs={12} md={4}>
                            <Card className={importedStyles.card}>
                                <img alt={'stats'} className={importedStyles.cardLogo}
                                     src={StatsIcon}
                                />
                                <CardActions className={importedStyles.cardAction}>
                                    <h4>Mide tus estadísticas</h4>
                                    <p>Analiza todo lo que ocurre en tu restaurante con números y gráficas</p>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={importedStyles.card}>
                                <img alt={'responsive'} className={importedStyles.cardLogo}
                                     src={ResponsiveIcon}
                                />
                                <CardActions className={importedStyles.cardAction}>
                                    <h4>Desde donde quieras</h4>
                                    <p>Controla todo lo que está pasando en tu restaurante estés dónde estés</p>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={importedStyles.card}>
                                <img alt={'AI'} className={importedStyles.cardLogo}
                                     src={AIIcon}
                                />
                                <CardActions className={importedStyles.cardAction}>
                                    <h4>Digitalízate</h4>
                                    <p>Ofrece a tus clientes un servicio del siglo XXI. ¡La competencia no podrá
                                        contigo!</p>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                <Grid item xs={12} className={importedStyles.menusContainer}>
                    <div className={importedStyles.menusTextContainer}>
                        <h2 className={importedStyles.sectionTitle}>Carta digital</h2>
                        <p>Nuestra joya de la corona, tu carta siempre accesible para tus clientes. <br/>En la web,
                            tablet o en su propio móvil mediante códigos QR.</p>
                    </div>
                    <div className={importedStyles.menusMockContainer}>
                        <Image
                            src={require('../../rsc/img/mockMenus.png')}
                            webp={require('../../rsc/img/mockMenus.webp')}
                            alt={'mockup'}
                        />
                        {/*       <Image
                            className={importedStyles.mockDigitalMenu}
                            src={require('../../rsc/img/mockCartaDigital.png')}
                            webp={require('../../rsc/img/mockCartaDigital.webp')}
                            alt={'mockup menu digital'}
                        />*/}
                        <Image
                            className={importedStyles.mockDigitalMenuMobile}
                            src={require('../../rsc/img/sectionDetail.png')}
                            webp={require('../../rsc/img/sectionDetail.webp')}
                            alt={'mockup menu digital'}
                        />
                        <Image
                            className={importedStyles.mockDigitalMenuMobileCart}
                            src={require('../../rsc/img/cartDetail.png')}
                            webp={require('../../rsc/img/cartDetail.webp')}
                            alt={'mockup menu digital'}
                        />
                        <div className={importedStyles.decorationMenus}>
                            <div className={importedStyles.decorationTriangleLeft}/>
                            <div className={importedStyles.decorationTriangleTop}/>
                            <div className={importedStyles.decorationTriangleRight}/>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} id={'services'} className={importedStyles.reservationContainer}>
                    <div className={importedStyles.reservationTextContainer}>
                        <h2 className={importedStyles.sectionTitle}>Reservas</h2>
                        <p>Controla tus reservas y permite a tus clientes reservar en tu restaurante de forma
                            rápida.</p>
                    </div>
                    <div className={importedStyles.reservationMockContainer}>
                        <Image
                            src={require('../../rsc/img/mockReservation.png')}
                            webp={require('../../rsc/img/mockReservation.webp')}
                            alt={'mock reservas'}
                        />
                        <div className={importedStyles.decorationReservation}>
                            <div className={importedStyles.decorationTriangleLeft}/>
                            <div className={importedStyles.decorationTriangleTop}/>
                            <div className={importedStyles.decorationTriangleRight}/>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} className={importedStyles.pollsContainer}>
                    <div className={importedStyles.pollsTextContainer}>
                        <h2 className={importedStyles.sectionTitle}>Encuestas</h2>
                        <p>Mejora tus servicios conociendo las opiniones de tus clientes. Descubre que piensan sobre tus
                            empleados, tus platos o la limpieza de tu local.</p>
                    </div>
                    <div className={importedStyles.pollsMockContainer}>
                        <Image
                            src={require('../../rsc/img/mockPoll.png')}
                            webp={require('../../rsc/img/mockPoll.webp')}
                            alt={'mock'}
                        />
                        <Image
                            className={importedStyles.mockMobile}
                            src={require('../../rsc/img/mockPollsStat.png')}
                            webp={require('../../rsc/img/mockPollsStat.webp')}
                            alt={'mockup'}
                        />
                        <div className={importedStyles.decorationPolls}>
                            <div className={importedStyles.decorationTriangleLeft}/>
                            <div className={importedStyles.decorationTriangleTop}/>
                            <div className={importedStyles.decorationTriangleRight}/>
                        </div>
                    </div>
                </Grid>
                {/*<div id={'prices'} className={importedStyles.priceSection}>
                    <h2 className={importedStyles.sectionTitle}>Elige tu plan</h2>
                    <Grid container spacing={4} className={importedStyles.priceContainer}>
                        <Grid item xs={12} md={3}>
                            <Card className={importedStyles.priceCard}>
                                <div className={importedStyles.priceTitle}>
                                    <h2>RESERVAS</h2>
                                </div>
                                <CardContent>
                                    <p>Instalación</p>
                                    <h2>150€</h2>
                                    <hr/>
                                    <p>CUOTA ANUAL</p>
                                    <h2>350€</h2>
                                    <p>RESERVAS ILIMITADAS</p>
                                    <br/>
                                    <h3>250€</h3>
                                    <p>Límite de uso 150 reservas/mes</p>
                                </CardContent>
                                <CardActions className={importedStyles.cardAction}>
                                    <Button className={importedStyles.priceButton} href={'#contact'}>Me
                                        interesa</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card className={importedStyles.priceCard}>
                                <div className={importedStyles.priceTitle}>
                                    <h2>ENCUESTAS</h2>
                                </div>
                                <CardContent>
                                    <p>Instalación</p>
                                    <h2>150€</h2>
                                    <hr/>
                                    <p>CUOTA ANUAL</p>
                                    <h2>150€</h2>
                                    <p>+60€ informes mensuales</p>
                                    <br/>
                                    <h3>80€</h3>
                                    <p>Por cada tablet extra adquirida</p>
                                </CardContent>
                                <CardActions className={importedStyles.cardAction}>
                                    <Button className={importedStyles.priceButton} href={'#contact'}>Me
                                        interesa</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card className={importedStyles.priceCard}>
                                <div className={importedStyles.priceTitle}>
                                    <h2>CARTA DIGITAL</h2>
                                </div>
                                <CardContent>
                                    <p>Instalación</p>
                                    <h2>150€</h2>
                                    <hr/>
                                    <p>CUOTA ANUAL</p>
                                    <h2>150€</h2>
                                    <p>Todas tus cartas o menús</p>
                                    <br/>
                                    <h3>80€</h3>
                                    <p>Por cada tablet extra adquirida</p>
                                </CardContent>
                                <CardActions className={importedStyles.cardAction}>
                                    <Button className={importedStyles.priceButton} href={'#contact'}>Me
                                        interesa</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card className={importedStyles.priceCard}>
                                <div className={importedStyles.priceTitleGold}>
                                    <h2>PACK COMPLETO</h2>
                                </div>
                                <CardContent>
                                    <p>Instalación</p>
                                    <h2>300€</h2>
                                    <hr/>
                                    <p>CUOTA ANUAL</p>
                                    <h2>500€</h2>
                                    <p>RESERVAS ILIMITADAS</p>
                                    <br/>
                                    <h3>400€</h3>
                                    <p>Límite de uso 150 reservas/mes</p>
                                </CardContent>
                                <CardActions className={importedStyles.cardAction}>
                                    <Button className={importedStyles.priceButton} href={'#contact'}>Me
                                        interesa</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>*/}
                <Grid item xs={12} id={'contact'} className={importedStyles.contactUsSection}>
                    <div className={importedStyles.contactUsContainer}>
                        <h1>¿TE INTERESA?</h1>
                        <h3>Deja tu teléfono y te llamamos</h3>
                        {!this.state.contactSuccess && this.renderSendPhoneForm()}
                        {this.state.contactSuccess && this.renderPhoneSendedConfirmation()}

                        <p>Si lo prefieres, escríbenos a <a href="mailto:direccion@sevisl.com">direccion@sevisl.com</a>
                        </p>
                    </div>
                    <img
                        className={importedStyles.imageContactUs}
                        src={require('../../rsc/img/contactUs.png')}
                        alt={'CallUs'}
                    />
                </Grid>

            </div>
        );
    }

    renderFooter() {
        return (
            <div className={importedStyles.footerContainer}>
                <p>ComerEn ® 2020. Producto desarrollado por </p>
                <a href={'https://sevisl.com'}>SEVI Systems</a>
                <div className={importedStyles.lawsContainer}>
                    <p onClick={() => history.push('/law')}>Aviso legal</p>
                    <p>y</p>
                    <p onClick={() => history.push('/privacy')}>Política de privacidad</p>
                </div>
            </div>
        );
    }

    closeBanner = () => {
        //this.setState({openPromoBanner: false});
        document.getElementById('banner').style.display = "none";
    };

    openRegister = () => {
        this.setState({openRegister: true});
        this.closeBanner();
    };

    renderPromoBanner = () => {
        return (
            <div id={'banner'} className={importedStyles.banner}>
                <span>Regístrate ahora y disfruta de tu PRIMER MES GRATIS</span>
                <Button variant={"contained"} size={"small"} className={importedStyles.bannerCTA}
                        onClick={this.openRegister}>
                    LO QUIERO
                </Button>
                <IconButton className={importedStyles.bannerCloseIcon} onClick={this.closeBanner}>
                    <CloseIcon/>
                </IconButton>
            </div>
        );
    };

    render() {
        return (
            <div>
                {/*{this.renderPromoBanner()}*/}
                <NavBar registerOpen={this.state.openRegister}/>
                <div className={importedStyles.landingManager}>
                    {this.renderView()}
                    {this.renderFooter()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({searchReducer, authReducer}) => {
    return ({
        // filterActive: searchReducer.filterActive,
        logged: authReducer.auth,
    });
};

export default connect(mapStateToProps)(LandingManagerScreen);