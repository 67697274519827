import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RedoIcon from '@material-ui/icons/Redo';
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarsIcon from '@material-ui/icons/Stars';
import BallotIcon from '@material-ui/icons/Ballot';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import ReactChartkick, {PieChart} from 'react-chartkick'
import Chart from 'chart.js'
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Clipboard from "simple-react-clipboard";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import baseUrl from "../../helpers/baseUrl";
import ChipInput from "../../components/ChipInput/ChipInput";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import importedStyles from "./pollsStyle";
import Snack from "../../components/Snack/Snack";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Fab from "@material-ui/core/es/Fab/Fab";
import history from "../../helpers/history";
import importedStylesSass from "./pollsScreen.module.sass";
import {DatePicker} from "material-ui-pickers";
import Switch from "@material-ui/core/Switch";
import RateQuestion from "./QuestionTypes/RateQuestion";
import BooleanQuestion from "./QuestionTypes/BooleanQuestion";
import NumericRangeQuestion from "./QuestionTypes/NumericRangeQuestion";
import ChipsQuestion from "./QuestionTypes/ChipsQuestion";
import ListSubheader from "@material-ui/core/ListSubheader";
import {formatDate} from "../../helpers/dateUtils";
import numericQuestionIcon from "../../rsc/img/numericQuestionIcon.png";
import booleanQuestionIcon from "../../rsc/img/booleanQuestionIcon.png";
import VisibilityIcon from '@material-ui/icons/Visibility';
ReactChartkick.addAdapter(Chart);

class pollsScreen extends React.Component {

    state = {
        newPollModal: false,
        newQuestionModal: false,
        polls: {},
        selectedPoll: {},
        newPollTitle: '',
        expanded: null,
        newQuestions: [],
        fixedQuestions: {},
        newFixedQuestion: '',
        newQuestion: '',
        newQuestionType: '',
        questionTypes: {},
        modalType: 'addPoll',
        questionChips: [],
        pickerMinValue: '',
        pickerMaxValue: '',
        newQuestionId: 1,
        gettingData: true,
        downloadingData: false,
        sharePollSnack: false,
        skipableQuestion: false,
        modalDeleteConfirmation: false,
        timesStarted: 0,
        timesFinished: 0,
        timeSpend: null,
        selectedBeginDay: new Date(),
        selectedFinishDay: new Date(),
        isFilterActive: false,
    };

    componentDidMount() {
        if (!this.props.restaurantPermissions.polls) {
            history.push('/forbiddenScreen');
        } else {
            this.getPolls(this.props.idRestaurant);
            this.getFixedQuestions();
            this.getQuestionTypes();
            this.setDefaultStatisticsFilterDates();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.restaurantPermissions.polls) {
            history.push('/forbiddenScreen');
        } else if (this.props.idRestaurant !== nextProps.idRestaurant) {
            this.getPolls(nextProps.idRestaurant);
            this.getFixedQuestions();
            this.getQuestionTypes();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedPoll.uuid) {
            this.refreshStatistics(prevState);
        }
    }

    handleBeginDate = date => {
        let d = new Date(date);
        d.setHours(0, 0, 0);

        this.setState({selectedBeginDay: new Date(d)});
    };

    handleFinishDate = date => {
        let d = new Date(date);
        d.setHours(23, 59, 59);

        this.setState({selectedFinishDay: new Date(d)});
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };
    handleSelectChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleInputChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    onChipsChange = chips => {
        this.setState({questionChips: chips});
    };

    handleDeleteQuestion(id) {

        let questions = this.state.newQuestions;
        let filteredArray = questions.filter(function (item) {
            return item.uuid !== id;
        });
        //questions = questions.splice(index);
        this.setState({newQuestions: filteredArray});
    }

    getPolls(uuidRestaurant) {

        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'restaurants/getRestaurantPolls', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: uuidRestaurant
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({polls: response.polls, gettingData: false});
            } else {
                throw new Error(response.errorMessage)
            }
        }).catch((error) => {
            console.log(error);
            this.setState({gettingData: false});
        });
    }

    openPollPreview = () => {
        window.open(baseUrl + 'frontPolls/' + this.props.slugRestaurant, '_blank');
    };

    openFrontPollsScreen = (selectedPoll) => {
        this.setState({
            //sharePollSnack: true,
            selectedPoll: selectedPoll
        });
        window.open(baseUrl + 'app/encuesta/' + this.props.slugRestaurant + '/' + selectedPoll.url_slug, '_blank');
    };


    renderPolls() {

        const {classes} = this.props;
        const {expanded} = this.state;
        let polls = this.state.polls;

        if (polls.length > 0) {
            let listItems = [];
            polls = [].slice.call(polls).sort((a, b) => a.position - b.position);

            Object.keys(polls).forEach((poll) => {

                listItems.push(
                    <ExpansionPanel
                        expanded={expanded === 'panel' + polls[poll].uuid}
                        onChange={this.handleChange('panel' + polls[poll].uuid)}
                        onClick={() => {
                            this.setState({selectedPoll: polls[poll]});
                            this.getPollStatistics(polls[poll]);
                        }}
                        key={polls[poll].uuid}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            <div className={importedStylesSass.expansionPanelSummaryContent}>
                                <Typography className={classes.heading}>{polls[poll].title}</Typography>
                                <IconButton onClick={() => this.openFrontPollsScreen(polls[poll])}>
                                    <LaunchIcon/>
                                </IconButton>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={importedStylesSass.expansionPanelDetails}>

                            {this.renderQuestions(polls[poll].questions)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );

            });

            return (
                <div>
                    <Typography gutterBottom variant="headline" component="h2">
                        Mis encuestas
                        <IconButton onClick={() => this.openPollPreview()}>
                            <VisibilityIcon/>
                        </IconButton>
                    </Typography>
                    <List>{listItems}</List>
                </div>)
        } else {

            return (
                <div>
                    <Typography gutterBottom variant="headline" component="h2">
                        Sin encuestas
                    </Typography>
                    <Typography component="p">
                        Todavía no tienes ninguna encuesta, cuando crees una podrás verla aquí.
                    </Typography>
                </div>
            )
        }


    }

    deletePoll() {

        let idRestaurant = this.props.idRestaurant;
        let pollId = this.state.selectedPoll.uuid;
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'polls/deletePoll', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: idRestaurant,
                    idPoll: pollId,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.error) {
                throw new Error(response.errorMessage)
            } else {
                this.getPolls(this.props.idRestaurant);
                this.setState({modalDeleteConfirmation: false, sendingData: false});
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            }
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido eliminar', snackTypes.error));

        });

    }

    getQuestionTypes() {

        fetch(apiBaseUrl + 'questiontypes', {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            }
        ).then((responseJSON) => {

            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);
            this.setState({questionTypes: response})

        }).catch((error) => {
            console.log(error);
        });
    }

    closeNewPollModal() {
        this.setState({
            newPollModal: false,
            newPollTitle: '',
            newQuestions: [],
            newFixedQuestion: '',
            newQuestion: '',
            newQuestionType: '',
            questionChips: [],
            pickerMinValue: '',
            pickerMaxValue: '',
            newQuestionId: 1,
        })
    }


    renderPollTypeIcon(type) {

        switch (parseInt(type, 0)) {
            case 1:
                return <StarsIcon/>;
            case 2:
                return <img src={booleanQuestionIcon} alt="boolean question"
                            className={importedStylesSass.booleanQuestionIcon}/>;
            case 3:
                return <img src={numericQuestionIcon} alt="numeric range question"
                            className={importedStylesSass.numericQuestionIcon}/>;
            case 4:
                return <BallotIcon/>;
            default:
                return 'undefined'
        }

    }

    renderQuestions(questions) {

        let listItems = [];

        if (questions.length > 0) {

            Object.keys(questions).forEach((question) => {

                listItems.push(
                    <ListItem key={questions[question].uuid} style={{alignItems: 'space-between'}}>
                        <ListItemIcon>
                            {this.renderPollTypeIcon(questions[question].type)}
                        </ListItemIcon>
                        <ListItemText
                            primary={questions[question].title}
                        />
                        {/* <ListItemSecondaryAction>
                            <IconButton aria-label="Delete">
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>*/}

                    </ListItem>
                );
            });

        } else {

            listItems.push(
                <ListItem key={0}>
                    <ListItemText
                        secondary='Esta encuesta no tiene ninguna pregunta todavía'
                    />
                </ListItem>
            )
        }


        return (
            <List style={{display: 'flex', flexDirection: 'column'}}>
                {listItems}
                <Button
                    onClick={() =>
                        this.setState({
                            modalDeleteConfirmation: true
                        })
                    }
                    fullWidth
                    color={"secondary"}
                    variant={"contained"}
                    style={{marginTop: 15}}>
                    Eliminar encuesta <DeleteIcon style={{marginLeft: 5}}/>
                </Button>
            </List>)

    }

    getFixedQuestions() {

        fetch(apiBaseUrl + 'fixedquestions', {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            }
        ).then((responseJSON) => {

            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);
            this.setState({fixedQuestions: response})

        }).catch((error) => {
            console.log(error);
        });
    }

    renderPredefinedQuestions() {

        const {classes} = this.props;
        let questions = this.state.fixedQuestions;
        let menuItems = [];

        Object.keys(questions).forEach((question) => {
            menuItems.push(
                <MenuItem value={questions[question]} key={question} style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.menuItem}>
                        <ListItemIcon className={classes.icon}>
                            {this.renderPollTypeIcon(questions[question].type)}
                        </ListItemIcon>
                        <ListItemText primary={questions[question].title}/>
                    </div>
                </MenuItem>
            );

        });

        return (
            <Select
                value={this.state.newFixedQuestion}
                onChange={this.handleSelectChange}
                name='newFixedQuestion'
                fullWidth
            >
                {menuItems}
            </Select>
        );

    }

    renderNewQuestions() {

        let listItems = [];
        let questions = this.state.newQuestions;

        //console.log('NEW Questions: ' + JSON.stringify(questions));

        if (questions.length > 0) {

            Object.keys(questions).forEach((question) => {

                listItems.push(
                    <ListItem key={questions[question].index} style={{alignItems: 'space-between'}}>
                        <ListItemIcon>
                            {this.renderPollTypeIcon(questions[question].type)}
                        </ListItemIcon>
                        <ListItemText
                            primary={questions[question].title}
                        />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Delete" onClick={() =>
                                this.handleDeleteQuestion(questions[question].uuid)
                            }>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });

        } else {

            listItems.push(
                <ListItem key={1}>
                    <ListItemText
                        secondary='Esta encuesta no tiene ninguna pregunta todavía'
                    />
                </ListItem>
            )
        }


        return (
            <List>
                {listItems}
            </List>
        )

    }

    renderNewQuestionInput(questionType) {

        switch (parseInt(questionType, 0)) {

            case 3:
                return (
                    <div>
                        <TextField
                            name="pickerMinValue"
                            onChange={this.handleInputChange}
                            label="Valor mínimo"
                            value={this.state.pickerMinValue}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                        />
                        <TextField
                            style={{marginLeft: 20}}
                            name="pickerMaxValue"
                            onChange={this.handleInputChange}
                            label="Valor máximo"
                            value={this.state.pickerMaxValue}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                        />
                    </div>

                );

            case 4:
                return (
                    <div>
                        <p>
                            <InputLabel>Introduce las posibles respuestas (Máx. 4)</InputLabel>
                        </p>
                        <ChipInput
                            value={this.state.questionChips}
                            helperText={'Introduce cada respuesta separada por comas'}
                            chipKeys={[',', '.']}
                            chipLimit={4}
                            chipLimitAlert={() => this.props.dispatch(showSnack('No puedes añadir más respuestas (Máx. 4)', snackTypes.warning))
                            }
                            onChange={(chips) => this.onChipsChange(chips)}
                        />
                    </div>

                );

            default:
            // render nothing

        }
    }

    renderQuestionTypes() {

        let types = this.state.questionTypes;
        let menuItems = [];
        const {classes} = this.props;

        Object.keys(types).forEach((type) => {

            switch (parseInt(types[type].uuid, 0)) {
                case 1:
                    menuItems.push(
                        <MenuItem value={types[type].uuid} key={type} style={{display: 'flex', flexDirection: 'row'}}>
                            <div className={classes.menuItem}>
                                <ListItemIcon className={classes.icon}>
                                    {this.renderPollTypeIcon(types[type].uuid)}
                                </ListItemIcon>
                                <ListItemText>
                                    Valoración (5 estrellas)
                                </ListItemText>
                            </div>
                        </MenuItem>
                    );
                    break;

                case 2:
                    menuItems.push(
                        <MenuItem value={types[type].uuid} key={type}>
                            <div className={classes.menuItem}>
                                <ListItemIcon className={classes.icon}>
                                    {this.renderPollTypeIcon(types[type].uuid)}
                                </ListItemIcon>
                                <ListItemText>
                                    Sí / No
                                </ListItemText>
                            </div>
                        </MenuItem>
                    );
                    break;

                case 3:
                    menuItems.push(
                        <MenuItem value={types[type].uuid} key={type}>
                            <div className={classes.menuItem}>
                                <ListItemIcon className={classes.icon}>
                                    {this.renderPollTypeIcon(types[type].uuid)}
                                </ListItemIcon>
                                <ListItemText>
                                    Rango numérico
                                </ListItemText>
                            </div>
                        </MenuItem>
                    );
                    break;

                case 4:
                    menuItems.push(
                        <MenuItem value={types[type].uuid} key={type}>
                            <div className={classes.menuItem}>
                                <ListItemIcon className={classes.icon}>
                                    {this.renderPollTypeIcon(types[type].uuid)}
                                </ListItemIcon>
                                <ListItemText>
                                    Respuestas predefinidas
                                </ListItemText>
                            </div>
                        </MenuItem>
                    );
                    break;
                default:
                    break;
            }

        });

        return (
            <Select
                value={this.state.newQuestionType}
                onChange={this.handleSelectChange}
                name='newQuestionType'
                fullWidth
            >
                {menuItems}
            </Select>
        );

    }

    addCustomQuestion(questionType) {

            let newQuestionId = this.state.newQuestionId + 1;
            let newQuestions = this.state.newQuestions;
            if (this.state.newQuestion) {
                let question = {
                    uuid: newQuestionId,
                    index: newQuestions.length,
                    title: this.state.newQuestion,
                    type: questionType,
                    possibleAnswers: [],
                    skippable: this.state.skipableQuestion
                };
                if (questionType === 3) {
                    question['minVal'] = this.state.pickerMinValue;
                    question['maxVal'] = this.state.pickerMaxValue;
                }
                if (questionType === 4) {
                    question['possibleAnswers'] = this.state.questionChips;
                }

                newQuestions.push(question);
            }

            this.setState({
                newQuestions: newQuestions,
                newQuestion: '',
                modalType: 'addPoll',
                newQuestionId: newQuestionId,
                questionChips: [],
                pickerMinValue: '',
                pickerMaxValue: '',
                skipableQuestion: false,
            });
    }

    renderAddQuestionButton(questionType) {
        const classes = this.props.classes;
        switch (parseInt(questionType, 0)) {

            case 1:
                return (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => this.addCustomQuestion(parseInt(questionType, 0))}
                        disabled={this.state.newQuestion === ''}
                    >
                        Añadir
                    </Button>
                );

            case 2:
                return (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => this.addCustomQuestion(parseInt(questionType, 0))}
                        disabled={this.state.newQuestion === ''}
                    >
                        Añadir
                    </Button>
                );

            case 3:
                return (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => this.addCustomQuestion(parseInt(questionType, 0))}
                        disabled={this.state.newQuestion === '' || this.state.pickerMinValue === '' || !this.state.pickerMaxValue === ''}>
                        Añadir
                    </Button>

                );

            case 4:
                return (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => this.addCustomQuestion(parseInt(questionType, 0))}
                        disabled={this.state.newQuestion === '' || this.state.questionChips.length < 2}
                    >
                        Añadir
                    </Button>

                );

            default:
                return (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={true}
                    >
                        Añadir
                    </Button>
                );
        }
    }

    addFixedQuestionToPoll() {
        if (this.state.newQuestions.length >= 10) {
            this.props.dispatch(showSnack('No puedes añadir más preguntas (Máx. 10)', snackTypes.warning));

        } else {

            let newQuestions = this.state.newQuestions;
            let question = this.state.newFixedQuestion;
            let alreadyAdded = false;

            //if it is the first insertion don't need to check if already exist on newQuestions array
            if (newQuestions.length !== 0) {
                for (let i = 0; i < newQuestions.length; i++) {
                    if (newQuestions[i].uuid === question.uuid) {
                        alreadyAdded = true;
                    }
                }
            }
            if (!alreadyAdded) {
                if (question.possibleAnswers == null || question.possibleAnswers.length === 0 ) {
                    question['possibleAnswers'] = [];
                } else {
                    question.possibleAnswers = question.possibleAnswers.split(', ');
                }

                newQuestions.push(question);

                this.setState({
                    newQuestions: newQuestions,
                    newFixedQuestion: '',
                    modalType: 'addPoll'
                });
            } else {
                this.props.dispatch(showSnack('Ya has añadido esta pregunta', snackTypes.warning));
            }
        }
    }

    renderModalContent(contentType) {

        const classes = this.props.classes;
        switch (contentType) {

            case 'addPoll':
                return (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Nueva encuesta</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={8} direction={"column"}>
                                <Grid item xs={12}>
                                    <div className={classes.newPollTitleContainer}>
                                        <p>
                                            <InputLabel>Título de la encuesta</InputLabel>
                                        </p>
                                        <TextField
                                            autoFocus
                                            value={this.state.newPollTitle}
                                            margin="dense"
                                            name="newPollTitle"
                                            placeholder="Ej: Encuesta consumidores marzo"
                                            inputProps={{maxLength: 50}}
                                            onChange={this.handleInputChange}
                                            fullWidth
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={8} direction={"row"} justify={"space-around"}
                                          alignItems={"center"}>
                                        <Grid item xs={12} md={4} className={classes.addQuestionsBox}>
                                            <Grid item xs={12}>
                                                <p>
                                                    <InputLabel>Elige una pregunta predefinida</InputLabel>
                                                </p>
                                                {this.renderPredefinedQuestions()}
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                    onClick={() => this.addFixedQuestionToPoll()}
                                                    disabled={this.state.newFixedQuestion === '' && this.state.newQuestions.length <= 0}
                                                    style={{marginTop: 25}}
                                                >
                                                    Añadir
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12} style={{marginTop: 55}}>
                                                <Typography variant="subheading" gutterBottom>
                                                    ¿No encuentras la pregunta que necesitas?
                                                </Typography>
                                                <Button variant='outlined' fullWidth color={"primary"}
                                                        style={{marginTop: 20}}
                                                        onClick={() => this.setState({modalType: 'newQuestion'})}>
                                                    Crea una nueva pregunta personalizada
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.redoIconBox}>
                                            <RedoIcon className={classes.redoIcon}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputLabel>
                                                Preguntas (Máx. 10)
                                            </InputLabel>
                                            <Grid item xs={12} className={classes.newQuestionsContainer}>
                                                <div className={classes.newQuestionsBox}>
                                                    {this.renderNewQuestions()}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => this.createNewPoll()}
                                disabled={this.state.newPollTitle === '' || this.state.newQuestions.length === 0 || this.state.sendingData}
                            >
                                Crear Encuesta
                                {this.state.sendingData &&
                                <CircularProgress
                                    size={25}
                                    className={importedStylesSass.progressAnimation}
                                />
                                }
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                );

            case 'newQuestion':
                return (
                    <React.Fragment>
                        <div>
                            <IconButton onClick={() => this.setState({modalType: 'addPoll'})}>
                                <ArrowBackIcon/>
                            </IconButton>
                        </div>
                        <DialogTitle id="form-dialog-title">Nueva pregunta personalizada</DialogTitle>
                        <DialogContent>
                            <p>
                                <InputLabel>Elige que tipo de pregunta deseas</InputLabel>
                            </p>
                            {this.renderQuestionTypes()}
                            <div style={{marginTop: 30}}>
                                <InputLabel>Título de la pregunta</InputLabel>
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    name="newQuestion"
                                    value={this.state.newQuestion}
                                    placeholder="Ej: ¿Volverías a comer aqui?"
                                    onChange={this.handleInputChange}
                                    fullWidth
                                />
                                {this.renderNewQuestionInput(this.state.newQuestionType)}
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.skipableQuestion}
                                        onChange={() => this.setState({skipableQuestion: !this.state.skipableQuestion})}
                                        value="skipable"
                                        color="primary"
                                    />
                                }
                                label="Los usuarios pueden saltar la pregunta"
                            />
                        </DialogContent>
                        <DialogActions>
                            {this.renderAddQuestionButton(this.state.newQuestionType)}
                        </DialogActions>
                    </React.Fragment>
                );

            default:
                break;
        }
    }

    createNewPoll() {
        if (this.state.newQuestions.length >= 10) {
            this.props.dispatch(showSnack('No puedes añadir más de 10 preguntas, por favor, borra alguna', snackTypes.warning));

        } else {
            let idRestaurant = this.props.idRestaurant;
            let newPollTitle = this.state.newPollTitle;
            let newQuestions = this.state.newQuestions;

            this.setState({sendingData: true});
            fetch(apiBaseUrl + 'polls/insertNewPoll', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        uuidRestaurant: idRestaurant,
                        title: newPollTitle,
                        questions: newQuestions
                    }),
                }
            ).then((responseJSON) => {
                return responseJSON.text();
            }).then((response) => {
                response = JSON.parse(response);
                if (response.error) {
                    throw new Error(response.errorMessage);
                } else {
                    this.getPolls(this.props.idRestaurant);
                    this.setState({
                        newPollModal: false,
                        sendingData: false,
                        newPollTitle: '',
                        newQuestions: [],
                        newFixedQuestion: '',
                        newQuestion: '',
                        newQuestionType: '',
                        questionChips: [],
                        pickerMinValue: '',
                        pickerMaxValue: '',
                        newQuestionId: 1,
                    });
                    this.props.dispatch(showSnack('Encuesta creada con éxito', snackTypes.success));
                }

            }).catch((error) => {
                console.log(error);
                this.setState({sendingData: false});
                this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido crear', snackTypes.error));
            });
        }


    }


    /* POLL STATISTICS */

    refreshStatistics = (prevState) => {

        //Set a flag to check if any date selection has changed
        let selectDateChanged = prevState.selectedBeginDay !== this.state.selectedBeginDay || prevState.selectedFinishDay !== this.state.selectedFinishDay;

        //Set a flag to check if filter state has changed
        let filterStateChanged = prevState.isFilterActive !== this.state.isFilterActive;

        //If date or filter status has changed then force to re-render statistics
        if (selectDateChanged || filterStateChanged) {
            this.getPollStatistics(this.state.selectedPoll);
            this.renderQuestionsStatistics();
        }
    };

    setDefaultStatisticsFilterDates() {
        let beginDate = new Date();
        beginDate.setMonth(beginDate.getMonth() - 1);
        beginDate.setHours(0, 0, 0);

        let finishDate = new Date();
        finishDate.setHours(23, 59, 59);

        this.setState({selectedBeginDay: beginDate, selectedFinishDay: finishDate});
    }

    getPollStatistics(poll) {
        let startedPollsRecords = poll.started_polls;
        let timesFinished = 0;
        let totalTimeSpend = 0;

        //If the date filter is applied
        if (this.state.isFilterActive) {
            startedPollsRecords = this.filterStartedPollsRecords(startedPollsRecords);
        }
        let timesStarted = startedPollsRecords.length;

        Object.keys(startedPollsRecords).forEach((key) => {
            let record = startedPollsRecords[key];
            if (record.endDate) {
                timesFinished++;
                totalTimeSpend = totalTimeSpend + parseFloat(record.timeSpend);
            }
        });

        //let timeSpendInSeconds = totalTimeSpend.toString().split('.').reverse().reduce((prev, curr, i) => prev + curr*Math.pow(60, i), 0);
        let timeSpendInSeconds = (totalTimeSpend / timesFinished).toFixed(2);
        let averageTimeSpend = timeSpendInSeconds;

        if (timeSpendInSeconds >= 0.60) {
            averageTimeSpend = ((timeSpendInSeconds * 100) / 60).toFixed(2);
        }

        this.setState({
            timesStarted: timesStarted,
            timesFinished: timesFinished,
            timeSpend: averageTimeSpend,
            hasStatistics: timesStarted > 0
        });
    }

    filterStartedPollsRecords(records) {
        return records.filter((record) => {
            let startDate = new Date(record.startDate).getTime();

            return (startDate >= this.state.selectedBeginDay.getTime()) && (startDate <= this.state.selectedFinishDay.getTime());
        });
    }

    filterAnswersByDate(answers) {
        return answers.filter((answer) => {
            let answerDate = new Date(answer.answerDate).getTime();

            return (answerDate >= this.state.selectedBeginDay.getTime()) && (answerDate <= this.state.selectedFinishDay.getTime());
        });
    }

    renderStatistics() {
        return (
            <Grid container spacing={8}>
                {this.renderDatePicker()}
                {this.renderGeneralStatistics()}
                {this.renderQuestionsStatistics()}
                {/*{this.renderDownloadAnswersButton()}*/}
            </Grid>
        )
    }

    renderDatePicker() {
        return (
            <Grid item xs={12} className={importedStylesSass.datePickerContainer}>
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        <DatePicker
                            keyboard
                            variant="outlined"
                            placeholder={'Ej: 20/02/2019'}
                            format={'DD/MM/YYYY'}
                            invalidDateMessage={'Formato de fecha inválido'}
                            maxDate={this.state.selectedFinishDay}
                            maxDateMessage={'Fecha fuera de rango'}
                            minDateMessage={'Fecha fuera de rango'}
                            label="Fecha inicio"
                            showTodayButton={true}
                            todayLabel={'Hoy'}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                            value={this.state.selectedBeginDay}
                            onChange={(date) => this.handleBeginDate(date)}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            keyboard
                            variant="outlined"
                            placeholder={'Ej: 20/02/2019'}
                            format={'DD/MM/YYYY'}
                            invalidDateMessage={'Formato de fecha inválido'}
                            minDate={this.state.selectedBeginDay}
                            maxDateMessage={'Fecha fuera de rango'}
                            minDateMessage={'Fecha fuera de rango'}
                            label="Fecha límite"
                            showTodayButton={true}
                            todayLabel={'Hoy'}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                            value={this.state.selectedFinishDay}
                            onChange={(date) => this.handleFinishDate(date)}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        />
                    </Grid>
                    <Grid item xs={12} className={importedStylesSass.datePickerActions}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.isFilterActive}
                                    onChange={() => this.setState((prevState, props) => ({isFilterActive: !prevState.isFilterActive}))}
                                    value="isFilterActive"
                                    color="primary"
                                />
                            }
                            label={'Filtrar'}
                        />

                    </Grid>
                </Grid>
            </Grid>
        )
    }

    renderNoPollSelectedMessage() {
        return (
            <Typography variant={"subtitle1"}>
                Selecciona una encuesta para ver sus estadisticas aquí
            </Typography>
        )
    }

    renderGeneralStatistics() {
        const classes = this.props.classes;

        let timeSpendSplited = this.state.timeSpend > 0 ? this.state.timeSpend.toString().split('.') : false;
        let timeSpendString = 'Sin datos';
        if (timeSpendSplited) {
            timeSpendString = timeSpendSplited[0] + ' min y ' + timeSpendSplited[1] + ' sec';
        }


        if (this.state.hasStatistics) {
            return (
                <React.Fragment>
                    <Grid item xs={8}>
                        <PieChart
                            data={[["Empezadas", this.state.timesStarted], ["Acabadas", this.state.timesFinished]]}/>
                    </Grid>
                    <Grid item xs={4} className={classes.timeSpendBox}>
                        <Typography variant={"subtitle1"} className={importedStylesSass.timeSpendClaim}>
                            Tiempo medio invertido
                        </Typography>
                        <br/>
                        <Typography variant={"subtitle1"}>
                            <i>{timeSpendString}</i>
                        </Typography>
                    </Grid>
                </React.Fragment>
            )
        } else {
            return (
                <Typography variant={"subtitle1"}>
                    Todavia no hay estadisticas disponibles para esta encuesta
                </Typography>
            )
        }
    }

    handleAnswersDownload = () => {
        this.setState({downloadingData: true});

        let beginDate = this.state.isFilterActive ? this.state.selectedBeginDay : null;
        let finishDate = this.state.isFilterActive ? this.state.selectedFinishDay : null;

        fetch(apiBaseUrl + 'answers/exportAnswersToExcel', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    restaurantUuid: this.props.idRestaurant,
                    pollUuid: this.state.selectedPoll.uuid,
                    beginDate: beginDate,
                    endDate: finishDate
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                //Open new tab with the file url automatic trigger download
                window.open(response.fileUrl, '_blank');
                this.setState({downloadingData: false})
            } else {
                throw new Error(response.errorMessage)
            }
        }).catch((error) => {
            console.error(error);
            this.setState({downloadingData: false});
        });
    };

    renderDownloadAnswersButton() {
        return (
            <Grid item xs={12} className={importedStylesSass.downloadAnswersContainer}>
                <Button variant={"outlined"} color={'primary'} onClick={this.handleAnswersDownload}
                        disabled={this.state.downloadingData}>
                    Descargar respuestas {this.state.downloadingData &&
                <CircularProgress size={25} color={"primary"} className={importedStylesSass.progressAnimation}/>}
                </Button>
                {this.state.isFilterActive &&
                <Typography variant={"caption"} style={{fontStyle: 'italic', textAlign: 'center', marginTop: '0.5em'}}>
                    De {formatDate(this.state.selectedBeginDay)} a {formatDate(this.state.selectedFinishDay)}
                </Typography>
                }
            </Grid>
        )
    }

    renderRateQuestionStatistics(questionObject) {
        let question = new RateQuestion(questionObject);
        //If filter is active, then set the answers property os question instance to the filtered ones
        if (this.state.isFilterActive) {
            question.answers = this.filterAnswersByDate(questionObject.answers);
        }
        let statistics = question.getAnswersStatistics();

        return (
            <React.Fragment key={questionObject.uuid}>
                <ListItem>
                    <ListSubheader className={importedStylesSass.statisticsQuestionTitle}>
                        <StarsIcon className={importedStylesSass.questionTitleIcon}/> {question.title}
                    </ListSubheader>
                </ListItem>
                <ListItem className={importedStylesSass.nestedStatisticsItem}>
                    <Grid container spacing={8} className={importedStylesSass.answersStatisticsRateContainer}>
                        <Grid item xs={5} className={importedStylesSass.answerStatisticsItem}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={
                                              <span className={importedStylesSass.averageRateText}>
                                                 <StarsIcon
                                                     className={importedStylesSass.averageRateIcon}/> {statistics.averageRate}
                                              </span>
                                          }
                                          secondary={'Valoración media'}/>
                        </Grid>
                        <Grid item xs={5} className={importedStylesSass.answerStatisticsItem}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.totalAnswers} secondary={'Votos totales'}/>
                        </Grid>
                    </Grid>
                </ListItem>
            </React.Fragment>
        )
    }

    renderBooleanQuestionStatistics(questionObject) {
        let question = new BooleanQuestion(questionObject);

        //If filter is active, then set the answers property os question instance to the filtered ones
        if (this.state.isFilterActive) {
            question.answers = this.filterAnswersByDate(questionObject.answers);
        }

        let statistics = question.getAnswersStatistics();

        return (
            <React.Fragment key={questionObject.uuid}>
                <ListItem>
                    <ListSubheader className={importedStylesSass.statisticsQuestionTitle}>
                        <img src={booleanQuestionIcon} alt="boolean question"
                             className={importedStylesSass.booleanQuestionStatisticsIcon}/>{question.title}
                    </ListSubheader>
                </ListItem>
                <ListItem className={importedStylesSass.nestedStatisticsItem}>
                    <Grid container spacing={8} className={importedStylesSass.answersStatisticsContainer}>
                        <Grid item xs={6} className={importedStylesSass.answerStatisticsItemYes}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.yesAnswers} secondary={'Sí'}/>
                        </Grid>
                        <Grid item xs={6} className={importedStylesSass.answerStatisticsItemNo}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.noAnswers} secondary={'No'}/>
                        </Grid>
                        <Grid item xs={6} className={importedStylesSass.answerStatisticsTotalVotesItem}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.totalAnswers} secondary={'Votos totales'}/>
                        </Grid>
                    </Grid>
                </ListItem>
            </React.Fragment>
        )
    }

    renderNumericRangeQuestionStatistics(questionObject) {
        let question = new NumericRangeQuestion(questionObject);

        //If filter is active, then set the answers property os question instance to the filtered ones
        if (this.state.isFilterActive) {
            question.answers = this.filterAnswersByDate(questionObject.answers);
        }

        let statistics = question.getAnswersStatistics();

        return (
            <React.Fragment key={questionObject.uuid}>
                <ListItem>
                    <ListSubheader className={importedStylesSass.statisticsQuestionTitle}>
                        <img src={numericQuestionIcon} alt="numeric question"
                             className={importedStylesSass.numericQuestionStatisticsIcon}/> {question.title}
                    </ListSubheader>
                </ListItem>
                <ListItem className={importedStylesSass.nestedStatisticsItem}>
                    <Grid container spacing={8} className={importedStylesSass.answersStatisticsContainer}>
                        <Grid item xs={6} className={importedStylesSass.answerStatisticsItemFirst}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.minValueProvided} secondary={'Respuesta más baja'}/>
                        </Grid>
                        <Grid item xs={6} className={importedStylesSass.answerStatisticsItemSecond}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.maxValueProvided} secondary={'Respuesta más alta'}/>
                        </Grid>
                        <Grid item xs={12} className={importedStylesSass.answerStatisticsItem}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.averageValue} secondary={'Media'}/>
                        </Grid>
                        <Grid item xs={6} className={importedStylesSass.answerStatisticsTotalVotesItem}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.totalAnswers} secondary={'Votos totales'}/>
                        </Grid>
                    </Grid>
                </ListItem>
            </React.Fragment>
        )
    }

    renderChipAnswersTotalVotes(statistics) {

        let gridItems = [];
        let totalVotes = 0;
        let answerTitle = '';
        let stylesClass = importedStylesSass.answerStatisticsItemChip;
        let gridItemSize = 6;

        let totalAnswers = statistics.possibleAnswers.length;

        for (let i = 0; i < totalAnswers; i++) {

            switch (i) {
                case 0:
                    totalVotes = statistics.answer1TotalVotes;
                    answerTitle = statistics.possibleAnswers[0];
                    stylesClass = importedStylesSass.answerStatisticsItemFirst;
                    break;
                case 1:
                    totalVotes = statistics.answer2TotalVotes;
                    answerTitle = statistics.possibleAnswers[1];
                    stylesClass = importedStylesSass.answerStatisticsItemSecond;
                    break;
                case 2:
                    totalVotes = statistics.answer3TotalVotes;
                    answerTitle = statistics.possibleAnswers[2];
                    stylesClass = importedStylesSass.answerStatisticsItemChip;
                    if (totalAnswers < 4) {
                        gridItemSize = 12;
                    }
                    break;
                case 3:
                    totalVotes = statistics.answer4TotalVotes;
                    answerTitle = statistics.possibleAnswers[3];
                    stylesClass = importedStylesSass.answerStatisticsItemChip;
                    break;
                default:
                    // do nothing
                    break;
            }

            gridItems.push(
                <Grid item xs={gridItemSize} className={stylesClass}>
                    <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                  primary={totalVotes}
                                  secondary={answerTitle}/>
                </Grid>
            );
        }

        return (
            <Grid container item xs={12}>
                {gridItems}
            </Grid>
        );
    }

    renderChipsQuestionStatistics(questionObject) {
        let question = new ChipsQuestion(questionObject);
        //If filter is active, then set the answers property os question instance to the filtered ones
        if (this.state.isFilterActive) {
            question.answers = this.filterAnswersByDate(questionObject.answers);
        }
        let statistics = question.getAnswersStatistics();


        return (
            <React.Fragment key={questionObject.uuid}>
                <ListItem>
                    <ListSubheader className={importedStylesSass.statisticsQuestionTitle}>
                        <BallotIcon className={importedStylesSass.questionTitleIcon}/>{question.title}
                    </ListSubheader>
                </ListItem>
                <ListItem className={importedStylesSass.nestedStatisticsItem}>
                    <Grid container spacing={8} className={importedStylesSass.answersStatisticsContainer}>
                        {this.renderChipAnswersTotalVotes(statistics)}
                        <Grid item xs={6} className={importedStylesSass.answerStatisticsTotalVotesItem}>
                            <ListItemText className={importedStylesSass.fixListItemTextPadding}
                                          primary={statistics.totalAnswers} secondary={'Votos totales'}/>
                        </Grid>
                    </Grid>
                </ListItem>
            </React.Fragment>
        )
    }

    renderQuestionsStatistics() {
        const questions = this.state.selectedPoll.questions;
        let questionsStatistics = [];

        Object.keys(questions).forEach((key) => {
            let questionObject = questions[key];

            switch (parseInt(questionObject.type)) {
                case 1:
                    questionsStatistics.push(this.renderRateQuestionStatistics(questionObject));
                    break;
                case 2:
                    questionsStatistics.push(this.renderBooleanQuestionStatistics(questionObject));
                    break;
                case 3:
                    questionsStatistics.push(this.renderNumericRangeQuestionStatistics(questionObject));
                    break;
                case 4:
                    questionsStatistics.push(this.renderChipsQuestionStatistics(questionObject));
                    break;
                default:
                    //do nothing
                    break;
            }
        });


        return (
            <Grid item xs={12} className={importedStylesSass.questionsStatisticsContainer}>
                <Typography variant={"subtitle2"}>
                    Estadísticas por pregunta:
                </Typography>
                <List>
                    {questionsStatistics}
                </List>
            </Grid>
        );

    }

    //////////////////// END STATISTICS //////////////////////

    getIframeValue() {
        let iframeValue = '';
        if (this.state.selectedPoll.uuid) {
            iframeValue = '<iframe src="' + baseUrl + 'poll/' + this.props.slugRestaurant + '/' + this.state.selectedPoll.url_slug + '/>';
        }
        return iframeValue;
    }

    render() {
        const classes = this.props.classes;

        return (
            <div style={{display: 'flex'}}>
                {/* <NavBar iconLeft={iconLeft} title={this.props.nameRestaurant}/> */}
                <MiniDrawer title={"Encuestas"}/>

                {this.state.gettingData ? <CircularProgress className={importedStylesSass.progressAnimation}/> :
                    <div className={classes.content}>
                        <Grid container
                              direction="row"
                              justify="space-evenly"

                              alignItems="stretch"
                              className={importedStylesSass.parentContainer}
                        >
                            <Grid item xs={12} md={4}>
                                <Card className={importedStylesSass.contentCard}>
                                    <CardContent>
                                        {this.renderPolls()}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card className={importedStylesSass.contentCard}>
                                    <CardContent>
                                        {this.state.selectedPoll.uuid ? this.renderStatistics() : this.renderNoPollSelectedMessage()}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Fab variant="extended"
                             aria-label="Add Poll"
                             size="large" color={"primary"}
                             className={classes.button}
                             onClick={() => this.setState({newPollModal: true})}
                        >
                            <AddIcon style={{marginRight: 5}}/>
                            Nueva Encuesta
                        </Fab>

                        {/* LINK TO POLL SNACKBAR */}
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={this.state.sharePollSnack}
                            //autoHideDuration={50000}
                            onClose={() => this.setState({sharePollSnack: !this.state.sharePollSnack})}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={
                                <div>
                                    <p id="copyPollIframeMessage">Pulsa en el icono para copiar el siguiente código en
                                        tu portapapeles. Insértalo en tu web para mostrar esta encuesta: </p>

                                    <OutlinedInput
                                        className={classes.textField}
                                        margin="normal"
                                        variant="filled"
                                        fullWidth
                                        style={{backgroundColor: '#e0e0e0'}}
                                        value={this.getIframeValue()}
                                        labelWidth={0}
                                    />
                                </div>

                            }
                            action={[
                                <Tooltip title="Copiar" placement="left">
                                    <IconButton
                                        key="copy"
                                        aria-label="copyCode"
                                        color="inherit"
                                        onClick={() => this.setState({sharePollSnack: false})}
                                    >
                                        <Clipboard
                                            text={this.getIframeValue()}
                                            render={({copy}) => <FileCopyIcon onClick={copy}/>}
                                        />
                                    </IconButton>
                                </Tooltip>,
                            ]}
                        />

                        {/* MODAL NEW POLL */}
                        <Dialog
                            className={classes.dialog}
                            open={this.state.modalDeleteConfirmation}
                            onClose={() => this.setState({modalDeleteConfirmation: false})}
                            aria-labelledby="form-dialog-title"
                            scroll={"paper"}
                        >

                            <DialogTitle id="form-dialog-title">Eliminación de encuesta</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    Si eliminas esta encuesta no podrás recuperarla más tarde, estas seguro?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => this.setState({modalDeleteConfirmation: false})}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}
                                    disabled={this.state.sendingData}
                                    onClick={() => this.deletePoll()}
                                >
                                    Eliminar
                                </Button>
                            </DialogActions>
                        </Dialog>


                        {/* MODAL NEW POLL */}
                        <Dialog
                            fullScreen
                            className={classes.dialog}
                            open={this.state.newPollModal}
                            onClose={() => this.setState({newPollModal: false, modalType: 'addPoll'})}
                            aria-labelledby="form-dialog-title"
                            scroll={"paper"}
                        >
                            {this.state.modalType !== 'newQuestion' &&
                            <IconButton
                                color="inherit"
                                onClick={() => this.closeNewPollModal()}
                                aria-label="Close"
                                className={importedStylesSass.closeModalIcon}
                            >
                                <CloseIcon/>
                            </IconButton>
                            }

                            {this.renderModalContent(this.state.modalType)}

                        </Dialog>
                        <Snack/>
                    </div>
                }
            </div>
        );
    }
}

pollsScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        user: authReducer.credentials[0],
        idRestaurant: restaurantReducer.idRestaurant,
        nameRestaurant: restaurantReducer.nameRestaurant,
        slugRestaurant: restaurantReducer.slugRestaurant,
        restaurantPermissions: restaurantReducer.permissions
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles())(pollsScreen));


