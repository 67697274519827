import React from 'react';
import {Typography} from "@material-ui/core";
import {Pie, PieChart, ResponsiveContainer, Tooltip, Cell, Legend} from "recharts";

import {Hidden} from '@material-ui/core';
import importedStyles from "./Statistics.module.sass";
import Box from "@material-ui/core/Box";


class StatisticCardPie extends React.Component {
    state = {
        statistics: [],
        COLORS: ['#FFD54F', '#16A085', '#7FB3D5', '#F1948A', '#D7DBDD', '#239B56', '#3DCC91', '#2874A6', '#873600', '#C0392B']
    }


    componentDidMount() {
    }


    render() {

        let isMobile = window.matchMedia("(max-width: 600px)").matches;

        const {title, statistics, color, emptyText, showText} = this.props;


        const renderCustomizedLabel = ({
                                           cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, count, fill
                                       }) => {
            const RADIAN = Math.PI / 180;
            const radius = 25 + innerRadius + (outerRadius - innerRadius);
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);


            return (
                <text
                    x={x}
                    y={y}
                    fill={fill}
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central"
                >
                    {name.substring(0, 15) + '...'}
                </text>
            );
        };
        const partialStatistics = statistics.slice(0, 10);
        return (
            <React.Fragment>


                <Typography variant="h5" className={importedStyles.cardTitle}>{title}</Typography>
                {(statistics.length != 0) ?

                    <React.Fragment>
                        <Box className={importedStyles.cardPieBot}>

                            <Hidden mdUp>
                                <ResponsiveContainer aspect={isMobile ? 2 : 3} width={'100%'} height={'100%'}>
                                <PieChart>
                                        <Pie className={importedStyles.graphicsPie}
                                             dataKey="count"
                                             isAnimationActive={false}
                                             data={partialStatistics}
                                             outerRadius={this.props.width}
                                             fill={color}
                                        >
                                            {
                                                partialStatistics.map((entry, index) => <Cell
                                                    fill={this.state.COLORS[index % this.state.COLORS.length]}/>)
                                            }
                                        </Pie>
                                        <Tooltip/>
                                    </PieChart>

                                    {/*{this.props.reduced && <Legend className={importedStyles.legend}/>}*/}
                                </ResponsiveContainer>
                                <ResponsiveContainer className={importedStyles.responsiveContainer}
                                                     aspect={isMobile ? 2 : 3} width={'100%'} >
                                <PieChart>
                                    <Pie style={{display: 'none'}}
                                         dataKey="count"
                                         isAnimationActive={false}
                                         data={partialStatistics}
                                         outerRadius={this.props.width}
                                         fill={color}
                                    >
                                        {
                                            partialStatistics.map((entry, index) => <Cell
                                                fill={this.state.COLORS[index % this.state.COLORS.length]}/>)
                                        }
                                    </Pie>
                                    <Legend className={importedStyles.legend}/>
                                    <Tooltip/>
                                </PieChart>
                                </ResponsiveContainer>
                            </Hidden>


                            <Hidden smDown>
                                <ResponsiveContainer className={importedStyles.responsiveContainer}
                                                     aspect={3} width={'100%'} height={'100%'}>
                                    <PieChart margin={{ top: 10, right: 10, left: 10, bottom: 10 }} width={this.props.width} height={this.props.height}>
                                        <Pie className={importedStyles.graphicsPie}
                                             dataKey="count"
                                             isAnimationActive={false}
                                             data={partialStatistics}
                                            innerRadius={this.props.height*0.6}
                                             fill={color}
                                             label={!this.props.reduced && renderCustomizedLabel}
                                        >
                                            {
                                                partialStatistics.map((entry, index) => <Cell
                                                    label={{value: "HOLA", position: 'outside'}}
                                                    fill={this.state.COLORS[index % this.state.COLORS.length]}/>)
                                            }
                                        </Pie>
                                        <Tooltip/>
                                        {this.props.reduced && <Legend className={importedStyles.legend}/>}
                                    </PieChart>
                                </ResponsiveContainer>
                                {/*<ResponsiveContainer className={importedStyles.responsiveContainer}
                                                     aspect={3} width={'100%'} height={'100%'}>
                                    <PieChart width={this.props.width} height={this.props.height} className={'hiddenChart'}>
                                        <Pie style={{display: 'none'}}
                                             dataKey="count"
                                             isAnimationActive={false}
                                             data={partialStatistics}
                                             innerRadius={this.props.height*0.6}
                                             fill={color}
                                        >
                                            {
                                                partialStatistics.map((entry, index) => <Cell
                                                    label={{value: "HOLA", position: 'outside'}}
                                                    fill={this.state.COLORS[index % this.state.COLORS.length]}/>)
                                            }
                                        </Pie>
                                        <Tooltip/>
                                        {<Legend className={importedStyles.legendPc}/>}
                                    </PieChart>
                                </ResponsiveContainer>*/}
                            </Hidden>


                        </Box>
                    </React.Fragment>
                    :
                    <Typography className={importedStyles.labelInfo} variant="body1">{emptyText}</Typography>}
            </React.Fragment>


        );
    }
}

export default (StatisticCardPie);