//import Reactotron from 'reactotron-react-js';
import {applyMiddleware, createStore} from 'redux';
import rootReducer from "../reducers/index";
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

 /*  USE THIS TO BE ABLE TO SEE REDUX ON REACTOTRON
  let store = Reactotron.createStore(
        (persistedReducer),
        applyMiddleware(thunkMiddleware)
    );*/

let store = createStore(
    (persistedReducer),
    applyMiddleware(thunkMiddleware),

);

    let persistor = persistStore(store);

    export {store, persistor};





