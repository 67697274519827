import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    }});

export default function importedStyles() {
    return {
        button: {
            margin: theme.spacing(3),
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            margin: theme.spacing(3),
            marginTop: 50,
        },
        root: {
            display: 'flex',
        },
        chargingAnimationContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: window.innerHeight - 200
        },
        chargingAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -10,
            height: window.innerHeight - 200
        },
        textAsociation: {
            marginBottom: 5,
        },
        selectFileBox: {
            border: '1px solid lightgrey',
            display: 'flex',
            alignItems: 'center',
            padding: 10,
            borderRadius: 5,
            minHeight: 80
        },
        fileUploadButton: {
            marginLeft: 10
        },
        iconLeft: {
            marginRight: 10
        },
        selectFileLabel: {
            position: 'relative',
            top: -39,
            padding: '0px 5px',
            backgroundColor: '#fff',
            color: 'grey',
            fontWeight: 300,
            fontSize: '0.75rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
        },
        selectFileActions: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: -35
        }
    }
}