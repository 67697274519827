import React from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import importedStyles from "../menusManagerStyle";
import importedStylesSass from "./DishModals.module.sass";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import Typography from "@material-ui/core/es/Typography/Typography";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import TextField from "@material-ui/core/es/TextField/TextField";
import Button from "@material-ui/core/es/Button/Button";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Create';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/es/Input/Input";
import FormControl from '@material-ui/core/FormControl';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";


// Import React FilePond
import {FilePond, registerPlugin} from 'react-filepond';
// Import FilePond styles
 import "filepond/dist/filepond.min.css";
 import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
 import FilePondPluginImagePreview from "filepond-plugin-image-preview";
 import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
 registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


////



class DishModals extends React.Component {

    state = {
        editingDishImage: false
    };

    componentDidMount() {

    }

    renderAllergensSelector = () => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        return (

            <Select
                multiple
                value={this.props.newDishAllergens}
                onChange={this.props.handleAllergensChange}
                input={<Input id="select-multiple-allergens-checkbox"/>}
                displayEmpty
                renderValue={selected => {
                    if (selected.length === 0) {
                        return <em style={{color: 'rgba(0, 0, 0, 0.54)'}}>Alérgenos</em>;
                    }
                    return selected.map(selected => selected.name + ', ');
                }}
                MenuProps={MenuProps}
            >
                {this.props.allergens.map((allergen) => (
                    <MenuItem key={allergen.uuid} value={allergen}>
                        <Checkbox
                            checked={this.props.newDishAllergens ? this.props.newDishAllergens.findIndex(obj => obj.uuid === allergen.uuid) > -1 : false}/>
                        <ListItemText primary={allergen.name}/>
                    </MenuItem>
                ))}

            </Select>

        );
    };

    renderEditAllergensSelector = () => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        return (
            <FormControl>
                <Select
                    multiple
                    value={this.props.editDishAllergens}
                    onChange={this.props.handleEditAllergensChange}
                    input={<Input id="select-multiple-allergens-edit-checkbox"/>}
                    displayEmpty
                    renderValue={selected => {
                        if (selected.length === 0) {
                            return <em>Alérgenos</em>;
                        }
                        return selected.map(selected => selected.name + ', ');
                    }}
                    MenuProps={MenuProps}
                >
                    {this.props.allergens.map((allergen) => (
                        <MenuItem key={allergen.uuid} value={allergen} selected={this.props.editDishAllergens.findIndex(obj => obj.uuid === allergen.uuid) > -1}>
                            <Checkbox
                                checked={this.props.editDishAllergens.findIndex(obj => obj.uuid === allergen.uuid) > -1}/>
                            <ListItemText primary={allergen.name}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    handleFilepondInit = () => {
        //registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
    };

    triggerProductImageEdit = () => {
        this.setState({editingDishImage: true});
    };

    renderCurrentImage = () => {
        return (
            <div className={importedStylesSass.currentProductImageContainer}>
                <img src={this.props.editDishImage} className={importedStylesSass.currentProductImage} alt={'current product'}/>
                <Tooltip title="Editar" placement="top">
                    <IconButton onClick={this.triggerProductImageEdit} className={importedStylesSass.editDishImageButton}><EditIcon/></IconButton>
                </Tooltip>
            </div>
        )
    };
    renderFilePond = () => {
        return (
            <FilePond
                maxFileSize={'20MB'}
                imagePreviewMaxFileSize={'20MB'}
                allowImagePreview={true}
                // className={importedStyles.filepond}
                allowImageExifOrientation={true}
                labelIdle={'Pulsa aquí para seleccionar una nueva imagen para tu producto2. También puedes arrastrarla directamente.'}
                labelFileTypeNotAllowed={'Lo sentimos, ese tipo de archivo no está permitido'}
                fileValidateTypeLabelExpectedTypes={'Lo sentimos, ese tipo de archivo no está permitido'}
                acceptedFileTypes={['image/*']}
                ref={ref => this.pond = ref}
                //files={files}
                allowImageResize={true}
                imageResizeUpscale={true}
                onupdatefiles={fileItems => this.props.handleEditDishImage(fileItems)}
            />
        );
    };

    closeEditModal = () => {
        this.setState({editingDishImage: false});
        this.props.handleEditDishModal();
    };

    render() {
        const classes = this.props.classes;
        const fullScreenModals = window.matchMedia("(max-width: 768px)").matches;

        return (
            <div>
                {/* MODAL CREAR PLATO */}
                <Dialog
                    disableEnforceFocus
                    fullScreen={fullScreenModals}
                    fullWidth
                    maxWidth={'sm'}
                    open={this.props.newDishModal}
                    onClose={this.props.closeNewDishModal}
                    style={{display: 'flex', flexDirection: 'column'}}
                >
                    <DialogTitle>
                        <Typography variant="title" color="inherit" className={classes.flex}>
                            Nuevo producto
                        </Typography>
                        <IconButton color="inherit" onClick={this.props.closeNewDishModal}
                                    aria-label="Close"
                                    className={importedStylesSass.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={importedStylesSass.dialogContent}>
                            <FilePond
                                maxFileSize={'20MB'}
                                imagePreviewMaxFileSize={'20MB'}
                                allowImagePreview={true}
                                labelIdle={'Pulsa aquí para seleccionar una imagen para tu producto. También puedes arrastrarla hasta aquí directamente.'}
                                labelFileTypeNotAllowed={'Lo sentimos, ese tipo de archivo no está permitido'}
                                fileValidateTypeLabelExpectedTypes={'Lo sentimos, ese tipo de archivo no está permitido'}
                                acceptedFileTypes={['image/*']}
                                ref={ref => this.pond = ref}
                                allowImageResize={true}
                                imageResizeUpscale={true}
                                onupdatefiles={fileItems => this.props.handleNewDishImage(fileItems)}
                                oninit={() => this.handleFilepondInit() }
                            />
                        <TextField
                            autoFocus
                            margin="normal"
                            value={this.props.newDishName}
                            name="newDishName"
                            label="Nombre"
                            inputProps={{maxLength: 50}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <InputLabel htmlFor="dish-size">Tamaño</InputLabel>
                            <Select
                                margin="normal"
                                name={"newDishSize"}
                                value={this.props.newDishSize}
                                onChange={this.props.handleInputChange}
                                input={<Input name="name" id="name-disabled"/>}
                            >
                                <MenuItem value="">
                                    <em>----</em>
                                </MenuItem>
                                <MenuItem value={'small'}>Pequeño (tapa)</MenuItem>
                                <MenuItem value={'normal'}>Mediano (Media ración)</MenuItem>
                                <MenuItem value={'big'}>Grande (ración entera)</MenuItem>
                            </Select>
                        </div>

                        <TextField
                            margin="normal"
                            value={this.props.newDishPrice}
                            name="newDishPrice"
                            label="Precio"
                            type="number"
                            inputProps={{maxLength: 6}}
                            onChange={this.props.handleInputChange}
                        />
                        <TextField
                            margin="normal"
                            name="newDishIngredients"
                            value={this.props.newDishIngredients}
                            label="Ingredientes"
                            rows={3}
                            helperText="Separa los ingredientes con una coma"
                            inputProps={{maxLength: 450}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                        {this.renderAllergensSelector()}
                        <TextField
                            margin="normal"
                            name="newDishDescription"
                            value={this.props.newDishDescription}
                            label="Descripción"
                            helperText="Opcional"
                            inputProps={{maxLength: 150}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                        {/* <div className={classes.dishComplementsBox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.dishHaveComplements}
                                        onChange={this.props.handleDishHaveComplements}
                                        value="dishHaveComplements"
                                        color="primary"
                                    />
                                }
                                label="Este plato tiene complementos"
                            />
                            {this.props.dishHaveComplements && this.props.renderComplementsSelect()}
                        </div>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => this.props.addDish()}
                            disabled={this.props.newDishName === '' || this.props.newDishPrice === '' || this.props.sendingData}
                        >
                            Crear
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />
                            }
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* MODAL EDITAR PLATO */}
                <Dialog
                    disableEnforceFocus
                    fullScreen={fullScreenModals}
                    fullWidth
                    maxWidth={'sm'}
                    open={this.props.editDishModal}
                    onClose={this.closeEditModal}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>
                            <Typography variant="title" color="inherit" className={classes.flex}>
                                Modificar producto
                            </Typography>
                            <IconButton color="inherit" onClick={this.closeEditModal}
                                        aria-label="Close"
                                        className={importedStylesSass.closeModalIcon}>
                                <CloseIcon/>
                            </IconButton>
                    </DialogTitle>
                    <DialogContent className={importedStylesSass.dialogContent}>
                        {(this.props.editDishImage && !this.state.editingDishImage) ? this.renderCurrentImage() : this.renderFilePond()}
                        <TextField
                            autoFocus
                            margin="normal"
                            value={this.props.editDishName}
                            name="editDishName"
                            label="Nombre"
                            inputProps={{maxLength: 50}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 5}}>
                            <InputLabel htmlFor="dish-size">Tamaño</InputLabel>
                            <Select
                                name={"editDishSize"}
                                value={this.props.editDishSize}
                                onChange={this.props.handleInputChange}
                                input={<Input name="name" id="name-disabled"/>}
                            >
                                <MenuItem value="">
                                    <em>----</em>
                                </MenuItem>
                                <MenuItem value={'small'}>Pequeño (tapa)</MenuItem>
                                <MenuItem value={'normal'}>Mediano (Media ración)</MenuItem>
                                <MenuItem value={'big'}>Grande (ración entera)</MenuItem>
                            </Select>
                        </div>
                        <TextField
                            margin="normal"
                            value={this.props.editDishPrice}
                            name="editDishPrice"
                            label="Precio"
                            type="number"
                            inputProps={{maxLength: 6}}
                            onChange={this.props.handleInputChange}
                        />
                        {/* <div className={classes.dishComplementsBox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.editDishHaveComplements}
                                        onChange={this.props.handleEditDishHaveComplements}
                                        value="editDishHaveComplements"
                                        color="primary"
                                    />
                                }
                                label="Este plato tiene complementos"
                            />
                            {this.props.editDishHaveComplements && this.props.renderEditComplementsSelect()}
                        </div>*/}

                        <TextField
                            margin="normal"
                            name="editDishIngredients"
                            value={this.props.editDishIngredients}
                            label="Ingredientes"
                            helperText="Separa los ingredientes con una coma"
                            inputProps={{maxLength: 450}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                        {this.renderEditAllergensSelector()}
                        <TextField
                            margin="normal"
                            name="editDishDescription"
                            value={this.props.editDishDescription}
                            label="Descripción"
                            helperText="Opcional"
                            inputProps={{maxLength: 150}}
                            onChange={this.props.handleInputChange}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => this.props.updateDish()}
                            disabled={this.props.editDishName === '' || this.props.editDishPrice === '' || this.props.sendingData}
                        >
                            Modificar
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />
                            }
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*MODAL REMOVE DISH*/}
                <Dialog
                    open={this.props.removeDishModal}
                    onClose={this.props.handleRemoveDishModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar este producto?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleRemoveDishModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.props.deleteDish} variant="contained" color="secondary"
                                disabled={this.props.sendingData}>
                            Eliminar
                            {this.props.sendingData &&
                            <CircularProgress
                                size={25}
                                className={classes.sendingDataAnimation}
                            />}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
}

DishModals.propTypes = {
    classes: PropTypes.object,
    sendingData: PropTypes.bool.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleAllergensChange: PropTypes.func.isRequired,
    allergens: PropTypes.array.isRequired,

    //Add dish
    newDishModal: PropTypes.bool.isRequired,
    closeNewDishModal: PropTypes.func.isRequired,
    newDishName: PropTypes.string.isRequired,
    newDishSize: PropTypes.string.isRequired,
    newDishPrice: PropTypes.number.isRequired,
    newDishIngredients: PropTypes.string.isRequired,
    newDishAllergens: PropTypes.array.isRequired,
    newDishDescription: PropTypes.string.isRequired,
    dishHaveComplements: PropTypes.bool.isRequired,
    renderComplementsSelect: PropTypes.func.isRequired,
    addDish: PropTypes.func.isRequired,
    handleDishHaveComplements: PropTypes.func.isRequired,

    //Edit dish
    editDishModal: PropTypes.bool.isRequired,
    handleEditDishModal: PropTypes.func.isRequired,
    editDishName: PropTypes.string.isRequired,
    editDishSize: PropTypes.string.isRequired,
    editDishPrice: PropTypes.number.isRequired,
    editDishImage: PropTypes.object.isRequired,
    editDishHaveComplements: PropTypes.bool.isRequired,
    handleEditDishHaveComplements: PropTypes.func.isRequired,
    renderEditComplementsSelect: PropTypes.func.isRequired,
    editDishIngredients: PropTypes.string.isRequired,
    editDishAllergens: PropTypes.array.isRequired,
    editDishDescription: PropTypes.string.isRequired,
    updateDish: PropTypes.func.isRequired,

    //Remove dish
    removeDishModal: PropTypes.bool.isRequired,
    handleRemoveDishModal: PropTypes.func.isRequired,
    deleteDish: PropTypes.func.isRequired,
};

export default withStyles(importedStyles())(DishModals);
