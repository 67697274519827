import {LOGIN_SUCCESS, LOGOUT, UPDATE_USER} from "../../constants/action-types";

export const login = user => {

    return ({
        type: LOGIN_SUCCESS,
        user: user
    });

};

export const logout = () => {

    return ({
        type: LOGOUT,
    });

};

export const updateUser = updatedUser => {

    return ({
        type: UPDATE_USER,
        updatedUser: updatedUser
    });

};
