import React from 'react';
import importedStyles from "./Footer.module.sass";
import history from '../../helpers/history'
import Logo from '../../rsc/img/logo-comerEn.png';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

class Footer extends React.Component {

    state = {
    };

    render() {

        return (
            <div className={importedStyles.footer}>
                <div className={importedStyles.footerContainer}>
                    <img src={Logo} alt={'logo'}/>
                    <ul className={importedStyles.list}>
                        <h2>Menú</h2>
                        <li className={importedStyles.listItem} onClick={()=> history.push('/')}>Inicio</li>
                        <li className={importedStyles.listItem} onClick={()=> history.push('/comerEn/restaurantes')}>Restaurantes</li>
                        <li className={importedStyles.listItem} onClick={()=> history.push('/comerEn/eventos')}>Eventos</li>
                        <li className={importedStyles.listItem} onClick={()=> history.push('/comerEn/contacto')}>Contacta</li>
                    </ul>
                    <ul className={importedStyles.contact}>
                        <h2>Contacto</h2>
                        <li className={importedStyles.contactItem}>
                            <EmailIcon/>
                            <a
                                href={'mailto: direccion@sevisl.com'}
                                target={'_blank'}
                                className={importedStyles.contactLink}
                            >
                                direccion@sevisl.com
                            </a>
                        </li>
                        <li className={importedStyles.contactItem}>
                            <PhoneIcon/>
                            <a
                                href={'tel: 976 061 979'}
                                target={'_blank'}
                                className={importedStyles.contactLink}
                            >
                                976 061 979
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={importedStyles.copyrightContainer}>
                    <p>ComerEn ® 2019. Producto desarrollado por <a href={'https://sevisl.com'}>SEVI Systems</a></p>
                    <div className={importedStyles.lawsContainer}>
                        <p onClick={()=> history.push('/law')}>Aviso legal</p>
                        <p>y</p>
                        <p onClick={()=>history.push('/privacy')}>Política de privacidad</p>
                    </div>
                </div>
            </div>);
    }
}

export default Footer;