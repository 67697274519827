export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const UPDATE_USER = "UPDATE_USER";


export const PROFILE_CHANGE_SUCCESS = "PROFILE_CHANGE_SUCCESS";
export const PROFILE_CHANGE_FAILED = "PROFILE_CHANGE_FAILED";


export const DETAIL_RTE = "DETAIL_RTE";

export const ADD_MENU = "ADD_MENU";
export const REMOVE_MENU = "ADD_MENU";
export const EDIT_MENU = "ADD_MENU";


export const SET_NEW_CART = "SET_NEW_CART";
export const ADD_DISH_TO_CART = "ADD_DISH_TO_CART";
export const REMOVE_DISH_FROM_CART = "REMOVE_DISH_FROM_CART";
export const UNDO_LAST_CART_ADDITION = "UNDO_LAST_CART_ADDITION";
export const SUBTRACT_ONE_DISH_FROM_CART = "SUBTRACT_ONE_DISH_FROM_CART";
export const DELETE_WHOLE_CART = "DELETE_WHOLE_CART";

export const CHANGE_ACTIVE_MENU = "CHANGE_ACTIVE_MENU";
export const CHANGE_CURRENT_SECTION = "CHANGE_CURRENT_SECTION";
export const CHANGE_CURRENT_PRODUCT = "CHANGE_CURRENT_PRODUCT";


export const CHANGE_ACTIVE_POLL = "CHANGE_ACTIVE_POLL";
export const CHANGE_CURRENT_RESTAURANT = "CHANGE_CURRENT_RESTAURANT";


export const DRAWER_OPENED = "DRAWER_OPENED";
export const SET_NESTED_COLLAPSABLES = "SET_NESTED_COLLAPSABLES";
export const SET_PARENT_COLLAPSABLE = "SET_PARENT_COLLAPSABLE";
export const SET_COLLAPSABLES = "SET_COLLAPSABLES";

export const SET_FILTER_STATE = "SET_FILTER_STATE";
export const RESET_FILTER_STATE = "RESET_FILTER_STATE";

export const SHOW_SNACK = "SHOW_SNACK";
