import React from 'react';
import importedStyles from "./Snack.module.sass";
import Snackbar from '@material-ui/core/Snackbar';
import connect from "react-redux/es/connect/connect";
import {showSnack} from '../../components/Snack/SnackActions';
import Button from "@material-ui/core/Button/Button";

class Snack extends React.Component {

    state = {
        snackData: {},
    };

    render() {
        const snackData = this.props.snackData;
        let style = importedStyles[snackData.snackType === '' ? snackData.oldSnackType : snackData.snackType];

        return (
            <div className={importedStyles.snackContainer}>
                <Snackbar
                    className={style}
                    className={importedStyles.test}
                    message={snackData.text}
                    open={snackData.show}
                    classes={{root: importedStyles.snackRoute}}
                    autoHideDuration={10000}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    onClose={() => this.props.dispatch(showSnack('', '', false))}
                    action={
                        <Button
                            size={"small"}
                            key="close"
                            aria-label="Close"
                            className={importedStyles.snackButton}
                            variant={"outlined"}
                            onClick={() => this.props.dispatch(showSnack('', '', false))}
                        >
                            Entendido
                        </Button>
                    }
                />
            </div>);
    }
}
const mapStateToProps = ({snackReducer}) => {
    return ({
        snackData: snackReducer.data,
    });
};

export default connect(mapStateToProps)(Snack);