import React from 'react';
import importedStyles from "./ContactScreen.module.sass";
import NavbarLanding from "../Landing/NavbarLanding/NavbarLanding";
import connect from "react-redux/es/connect/connect";
import Footer from "../../../components/Footer/Footer";

class ContactScreen extends React.Component {

    state = {
    };

    componentDidMount() {
        if(this.props){
            // this.setState({id : this.props.match.params.id});
        }
    }

    handleInputChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };

    renderView(){
            return(
                <div>
                    <div className={importedStyles.imgBackground}>
                    <div className={importedStyles.claimContainer}>
                        <h1>¿Quieres formar parte de la comunidad ComerEn?</h1>
                        <h3>Envíanos un correo a <a href="mailto:direccion@sevisl.com">direccion@sevisl.com</a></h3>
                    </div>
                    </div>
                </div>
            );
    }

    render() {
        return (
            <div>
                <NavbarLanding style={{marginBottom: 10}}/>
                <div className={importedStyles.contact}>
                    {this.renderView()}
                </div>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({searchReducer}) => {
    return ({
        // filterActive: searchReducer.filterActive,
    });
};

export default connect(mapStateToProps)(ContactScreen);