import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import importedStyles from "./LazyCardImage.module.sass";
import imageDefault from "../../../../../src/rsc/img/image-default.png"
import CardMedia from "@material-ui/core/CardMedia";
import {handleImgLoadError} from "../../../../helpers/utils";
import Skeleton from '@material-ui/lab/Skeleton';

class LazyCardImage extends React.Component {

    state = {
        imageLoaded: false,
    };

    handleImageLoad = () => {
        this.setState({imageLoaded: true})
    };

    renderImage = () => {
        return (
            <img
                //component={'img'}
                className={this.props.className}
                src={this.props.src}
                //title={this.props.imageTitle}
                alt={this.props.imageTitle}
                onLoad={this.handleImageLoad}
                onError={handleImgLoadError}
            />
        )
    };

    renderSkeleton = () => {
        return (
            <Skeleton variant="rect" className={this.props.skeletonClassName} />
        )
    };


    render() {
        return (
            <React.Fragment>
                {this.renderImage()}
                {!this.state.imageLoaded && this.renderSkeleton()}
            </React.Fragment>
        );
    }
}

LazyCardImage.propTypes = {
    imageTitle: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    skeletonClassName: PropTypes.string
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
    });
};

export default connect(mapStateToProps)(LazyCardImage);

