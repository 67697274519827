import {DETAIL_RTE} from "../../constants/action-types";

export const detail_rte = (id, name, logo, permissions,slug) => {

    return ({
        type: DETAIL_RTE,
        id: id,
        name: name,
        slug: slug,
        logo: logo,
        permissions: permissions,
    });

};

