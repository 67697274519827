import apiBaseUrl from "./apiBaseUrl";
import {showSnack, snackTypes} from "../components/Snack/SnackActions";

export function addDishEvent(uuidDish){
    fetch(apiBaseUrl + 'restaurants/statistics/dish/add', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            uuidDish: uuidDish,
        }),
    }).then((responseJSON) => {
        return responseJSON.text();

    }).then((res) => {
        let response = JSON.parse(res);
        if (!response.error) {
           //
        } else {
            throw new Error(response.errorMessage);
        }
    }).catch((error) => {
        // this.setState({sendingData: false});
        console.error('Error: ' + error);

        //this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
    });
}


