import React, {Component} from 'react';
import './App.css';
import {Provider} from 'react-redux';
import {persistor, store} from './store/index'
import Landing from './modules/comerEn/Landing/Landing';
import DetalleRestaurante from './modules/Restaurant/DetalleRestaurante/DetalleRestaurante';
import iframseSalvador from './iframe/SergioSalvador/iframe';
import {Route, Router, Switch} from 'react-router-dom';
import myRestaurantScreen from "./modules/Restaurant/myRestaurantScreen";
import history from './helpers/history.js';
import {PersistGate} from 'redux-persist/integration/react'
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import menuScreen from "./modules/MenusManager/menusManager";
import profileScreen from "./modules/Profile/profileScreen";
import pollsScreen from "./modules/Polls/pollsScreen";
import frontPollsScreen from "./modules/FrontPolls/frontPollsScreen";
import offersScreen from "./modules/Offers/offersScreen";
import LandingPageFeatures from "./modules/LandingPageFeatures/LandingPageFeatures";
import eventsScreen from "./modules/Events/eventsScreen";
import notFound from "./modules/notFound/notFound";
import reservationScreen from "./modules/Reservations/reservationScreen";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import scheduleScreen from "./modules/Schedule/scheduleScreen";
import AllRestaurantsScreen from "./modules/comerEn/AllRestaurants/AllRestaurantsScreen";
import DetailRestaurantScreen from "./modules/comerEn/detailRestaurant/DetailRestaurantScreen";
import ReservationScreen from "./modules/comerEn/Reservation/ReservationScreen";
import ConfirmedReservationScreen from "./modules/comerEn/Reservation/ConfirmedReservationScreen";
import ContactScreen from "./modules/comerEn/contact/ContactScreen";
import BillingDataScreen from './modules/BillingData/BillingDataScreen';
import LandingManagerScreen from "./modules/LandingManager/LandingManagerScreen";
import ForbiddenScreen from "./modules/ForbiddenScreen/forbiddenScreen";
import ReservationIframe from "./modules/comerEn/Reservation/ReservationIframe/ReservationIframe";
import RouterMiddlewareComponent from "./helpers/RouterMiddlewareComponent";
import Privacy from "./modules/Privacy/Privacy";
import Law from "./modules/Privacy/Law";
import digitalMenuScreen from "./modules/DigitalMenu/digitalMenuScreen";
import digitalMenuScreenMobile from "./modules/DigitalMenuMobile/digitalMenuScreenMobile";
import digitalMenuScreenMobileWithImages from "./modules/DigitalMenuMobileWithImages/digitalMenuScreenMobileWithImages";
import Statistics from "./modules/Statistics/Statistics";


class App extends Component {

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => {
                return response.json()
            })
            .then((meta) => {

                const latestVersion = meta.version;
                const currentVersion = process.env.REACT_APP_VERSION;
                console.log(latestVersion);
                console.log(currentVersion);

                const shouldForceRefresh = this.checkAppVersion(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    this.clearAppCache();
                    this.setState({loading: false, isLatestVersion: false});
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({loading: false, isLatestVersion: true});

                }
            });
    }

    clearAppCache = () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    };

    checkAppVersion = (versionA, versionB) => {
        const versionsA = versionA.split(/\./g);

        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());

            const b = Number(versionsB.shift());
            // eslint-disable-next-line no-continue
            if (a === b) continue;
            // eslint-disable-next-line no-restricted-globals
            return a > b || isNaN(b);
        }
        return false;
    };


    render() {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'} moment={moment}>
                <Provider store={store}>
                    <PersistGate loading={<CircularProgress size={50}/>} persistor={persistor}>
                        <Router history={history}>
                            <RouterMiddlewareComponent>
                                <Switch>
                                    <Route exact path="/prueba/:restaurantUuid/:menuUuid/:sectionUuid?" component={digitalMenuScreenMobile}/>

                                    <Route exact path="/comerEn" component={Landing}/>
                                    <Route exact path="/" component={LandingManagerScreen}/>
                                    <Route exact path="/myRestaurants" component={myRestaurantScreen}/>
                                    <Route exact path="/detalleRestaurante" component={DetalleRestaurante}/>
                                    <Route exact path="/sergioSalvador" component={iframseSalvador}/>
                                    <Route exact path="/menus" component={menuScreen}/>
                                    <Route exact path="/profile" component={profileScreen}/>
                                    <Route exact path="/polls" component={pollsScreen}/>
                                    <Route exact path="/frontPolls/:restaurantUuid" component={frontPollsScreen}/>
                                    <Route exact path="/poll/:restaurantUuid/:pollUuid" component={frontPollsScreen}/>
                                    <Route exact path="/app/encuesta/:restaurantUuid/:pollUuid" component={frontPollsScreen}/>
                                    <Route exact path="/offers" component={offersScreen}/>
                                    <Route exact path="/events" component={eventsScreen}/>
                                    <Route exact path="/schedule" component={scheduleScreen}/>
                                    <Route exact path="/billingData" component={BillingDataScreen}/>
                                    <Route exact path="/reservations" component={reservationScreen}/>
                                    <Route path="/comerEn/restaurantes/:searchedRestaurant?/:searchedKitchenType?/:searchedCity?" component={AllRestaurantsScreen}/>
                                    <Route exact path="/comerEn/restaurante/:id" component={DetailRestaurantScreen}/>
                                    <Route exact path="/comerEn/reserva/:id" component={ReservationScreen}/>
                                    <Route exact path="/app/reserva/:id" component={ReservationIframe}/>
                                    <Route exact path="/comerEn/reservationFrame/:id" component={ReservationIframe}/>
                                    <Route exact path="/comerEn/confirmedReservation" component={ConfirmedReservationScreen}/>
                                    <Route exact path="/statistics" component={Statistics}/>
                                    <Route exact path="/comerEn/cartaDigital/:restaurantUuid" component={digitalMenuScreen}/>
                                    <Route exact path="/comerEn/cartaDigital/:restaurantUuid/:menuUuid" component={digitalMenuScreen}/>
                                    <Route exact path="/comerEn/cartaDigitalMobile/:restaurantUuid" component={digitalMenuScreenMobile}/>
                                    <Route exact path="/menu/cartaDigitalMobile/:restaurantUuid" component={digitalMenuScreenMobile}/>
                                    <Route exact path="/comerEn/cartaDigitalMobile/:restaurantUuid/:menuUuid/:sectionUuid?" component={digitalMenuScreenMobile}/>
                                    <Route exact path="/comerEn/cartaDigitalMobileWithImages/:restaurantUuid/:menuUuid?/:sectionUuid?/:productUuid?" component={digitalMenuScreenMobileWithImages}/>
                                    <Route exact path="/app/menuPro/:restaurantUuid/:menuUuid?/:sectionUuid?/:productUuid?" component={digitalMenuScreenMobileWithImages}/>
                                    <Route exact path="/app/menu/:restaurantUuid/:menuUuid?/:sectionUuid?" component={digitalMenuScreenMobile}/>
                                    <Route exact path="/comerEn/contacto" component={ContactScreen}/>
                                    <Route exact path="/forbiddenScreen" component={ForbiddenScreen}/>
                                    <Route exact path="/privacy" component={Privacy}/>
                                    <Route exact path="/law" component={Law}/>
                                    <Route component={notFound}/>
                                    {/*<Route exact path="/landingPageFeatures" component={LandingPageFeatures}/>*/}
                                </Switch>
                            </RouterMiddlewareComponent>
                        </Router>
                    </PersistGate>
                </Provider>
            </MuiPickersUtilsProvider>
        );
    }
}

export default App;
