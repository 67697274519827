import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import MenuCard from "./components/menuCard";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField/TextField";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DragIcon from '@material-ui/icons/DragIndicator';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import FormControl from "@material-ui/core/FormControl/FormControl";
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import Typography from "@material-ui/core/Typography/Typography";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Clipboard from "simple-react-clipboard";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Grid from "@material-ui/core/Grid/Grid";
import importedStyles from "./menusManagerStyle";
import Snack from "../../components/Snack/Snack";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import MenuModals from './components/MenuModals';
import SectionModals from "./components/SectionModals";
import DishModals from "./components/DishModals";
import DishSectionModals from "./components/DishSectionModals";
import history from "../../helpers/history";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Avatar from "@material-ui/core/Avatar";
import {CardActions, Divider, ListItemAvatar} from "@material-ui/core";
import {differenceBy} from "lodash";
import ChevronRightIcon from "@material-ui/icons/ChevronRightRounded";
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import baseUrl from "../../helpers/baseUrl";
import QRCode from 'qrcode.react'
import importedStylesSass from "./menuManager.module.sass";
import Collapse from "@material-ui/core/Collapse";
import {FixedSizeList} from 'react-window';
import {Virtuoso} from 'react-virtuoso'
import {LazyLoadImage, trackWindowScroll} from "react-lazy-load-image-component";
import imageDefault from "../../rsc/img/image-default.png";
import {handleImgLoadError} from "../../helpers/utils";
import CardActionArea from "@material-ui/core/CardActionArea";
import 'react-lazy-load-image-component/src/effects/blur.css';

class menusManager extends React.Component {

    state = {
        menus: [],
        dishes: [],
        allergens: [],
        complements: [],
        productsListExpanded: false,
        allComplementsCheck: false,
        dishComplements: [],
        updateError: false,
        newMenuModal: false,
        expansionPanelOpen: false,
        selectDishModal: false,
        addDishToSectionModal: false,
        newSectionModal: false,
        newSectionImage: null,
        editSectionModal: false,
        editSectionImage: false,
        editSectionName: false,
        newDishModal: false,
        newMenuName: '',
        newMenuPrice: '',
        newSectionName: '',
        newDishIngredients: '',
        newDishDescription: '',
        newDishAllergens: [],
        newDishPrice: '',
        newDishSize: '',
        newDishImage: null,
        dishHaveComplements: false,
        newDishName: '',
        newComplementModal: false,
        newComplementName: '',
        newComplementPrice: '',
        idMenu: -1,
        idSection: -1,
        selectDish: -1,
        removeDishModal: false,
        removeSectionModal: false,
        removeMenuModal: false,
        editMenus: {
            0: false,
        },
        editSection: {
            0: false,
        },
        editDishModal: false,
        sectionSelectedId: '',
        sectionImageChanged:'',
        menuSelectedId: '',
        dishSelectedId: '',
        complementSelectedId: '',
        selectedDishDescription: '',
        selectedDishName: '',
        selectedDishPrice: '',
        editDishDescription: '',
        editDishIngredients: '',
        editDishAllergens: [],
        editDishName: '',
        editDishPrice: '',
        editDishImage: null,
        editDishSize: null,
        editDishHaveComplements: false,
        editDishComplements: [],
        removeDishFromSectionModal: false,
        newSectionDishPrice: undefined,
        editComplementModal: false,
        editComplementName: '',
        editComplementPrice: '',
        deleteComplementModal: '',
        events: [],
        selectedEvents: [],
        numberOfDataRetrieved: 0,
        sendingData: false,
        sendingStateData: false,
        editMenuModal: false,
        editMenuName: '',
        editMenuPrice: undefined,
        editMenuEvents: [],
        removeComplementModal: false,
    };

    constructor(props) {
        super(props);

        this.getMenusRestaurant = this.getMenusRestaurant.bind(this);
        this.getDishes = this.getDishes.bind(this);
        this.getComplements = this.getComplements.bind(this);
        this.updateComplement = this.updateComplement.bind(this);
        this.deleteComplement = this.deleteComplement.bind(this);
        this.renderSections = this.renderSections.bind(this);
        this.renderMenus = this.renderMenus.bind(this);
        this.deleteSection = this.deleteSection.bind(this);
        this.deleteMenu = this.deleteMenu.bind(this);
        this.updateDish = this.updateDish.bind(this);
        this.deleteDish = this.deleteDish.bind(this);
        this.disableMenu = this.disableMenu.bind(this);
        this.enableMenu = this.enableMenu.bind(this);
        this.deleteDishFromSection = this.deleteDishFromSection.bind(this);
    }

    componentDidMount() {
        if (!this.props.restaurantPermissions.menus) {
            history.push('/forbiddenScreen');
        } else {
            this.setState({numberOfDataRetrieved: 0});
            this.getMenusRestaurant(this.props.idRestaurant);
            this.getDishes(this.props.idRestaurant);
            this.getAllergens();
            this.getComplements(this.props.idRestaurant);
            this.getRestaurantAssociationsEvents(this.props.idRestaurant);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.restaurantPermissions.menus) {
            history.push('/forbiddenScreen');
        } else if (this.props.idRestaurant !== nextProps.idRestaurant) {
            this.setState({numberOfDataRetrieved: 0});
            this.getAllergens();
            this.getMenusRestaurant(nextProps.idRestaurant);
            this.getDishes(nextProps.idRestaurant);
            this.getComplements(this.props.idRestaurant);
            this.getRestaurantAssociationsEvents(nextProps.idRestaurant);
        }
    }


    handleEventChange = event => {
        this.setState({selectedEvents: event.target.value});
    };
    handleEditEventChange = event => {
        let events = event.target.value;
        let hashesFound = {};

        events.forEach((event) => {
            hashesFound[event.uuid] = event;
        });
        let results = Object.keys(hashesFound).map((k) => {
            return hashesFound[k];
        });

        this.setState({editMenuEvents: results});
    };

    ////////// MODAL FUNCTIONS //////////////////////
    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
    handleAllergensChange = (e) => {
        this.setState({newDishAllergens: e.target.value});
    };
    handleEditAllergensChange = (e) => {
        let newAllergens = e.target.value;
        this.setState({editDishAllergens: newAllergens});
    };
    closeNewMenuModal = () => {
        this.setState({
            newMenuModal: false,
            newMenuName: '',
            selectedEvents: [],
            newMenuPrice: '',
        });
    };
    openNewMenuModal = () => {
        this.setState({newMenuModal: true});
    };
    handleEditMenuModal = () => {
        this.setState({editMenuModal: !this.state.editMenuModal});
    };

    showEditMenuModal(menu) {
        this.setState({
            editMenuModal: true,
            menuSelectedId: menu.uuid,
            editMenuName: menu.name,
            editMenuPrice: menu.price,
            editMenuEvents: menu.events,
            editMenuActive: menu.active
        })
    }

    closeNewSectionModal = () => {
        this.setState({newSectionModal: false, newSectionName: ''});
    };
    closeEditSectionModal = () => {
        this.setState({editSectionModal: false, editSectionName: ''});
    };
    openNewSectionModal = (idMenu) => () => {
        this.setState({newSectionModal: true, idMenu: idMenu, newSectionImage:null});
    };
    closeNewDishModal = () => {
        this.setState({newDishModal: false, newDishName: '', dishComplements: []});
    };
    openNewDishModal = (idSection) => {
        this.setState({newDishModal: true, idSection: idSection});
    };
    closeSelectDishModal = () => {
        this.setState({selectDishModal: false});
    };
    openSelectDishModal = (idSection) => {
        this.setState({selectDishModal: true, idSection: idSection});
    };

    showModalEdition = dish => () => {
       /* let allComplements = this.state.complements;
        let dishComplements = dish.complements;
        let clonedComplements = [];
        Object.keys(dishComplements).forEach(dishComplement => {
            Object.keys(allComplements).forEach(complement => {
                if (dishComplements[dishComplement].uuid === allComplements[complement].uuid) {
                    clonedComplements.push(allComplements[complement]);
                }
            });
        });*/

        //Clone allergens
        let allAllergens = this.state.allergens;
        let dishAllergens = dish.allergens;
        let clonedAllergens = [];
        Object.keys(dishAllergens).forEach(dishAllergen => {
            Object.keys(allAllergens).forEach(allergen => {
                if (dishAllergens[dishAllergen].uuid === allAllergens[allergen].uuid) {
                    clonedAllergens.push(allAllergens[allergen]);
                }
            });
        });


        this.setState({
            editDishModal: true,
            dishSelectedId: dish.uuid,
            editDishImage: dish.photo,
            editDishDescription: dish.description,
            editDishName: dish.name,
            editDishPrice: dish.price,
            editDishSize: dish.size,
            editDishIngredients: dish.ingredients,
            editDishAllergens: clonedAllergens,
            editDishHaveComplements: dish.haveComplements,
            //editDishComplements: clonedComplements
        })
    };

    handleRemoveDishModal = dish => () => {
        if (dish){
            this.setState({dishSelectedId: dish.uuid});
        }
        this.setState({removeDishModal: !this.state.removeDishModal});
    };
    handleRemoveDishFromSectionModal = () => {
        this.setState({removeDishFromSectionModal: !this.state.removeDishFromSectionModal});
    };
    handleRemoveSectionModal = () => {
        this.setState({removeSectionModal: !this.state.removeSectionModal});
    };
    handleRemoveMenuModal = () => {
        this.setState({removeMenuModal: !this.state.removeMenuModal});
    };

    handleAddDishToSectionModal = (section) => (e) => {
        e.stopPropagation();
        this.setState({
            sectionSelectedId: section ? section.uuid : null,
            addDishToSectionModal: !this.state.addDishToSectionModal,
            currentSection: section
        });
    };

    handleRemoveComplementModal = () => {
        this.setState({removeComplementModal: !this.state.removeComplementModal});
    };

    handleAddComplementModal = () => {
        this.setState({newComplementModal: !this.state.newComplementModal});
    };

    handleEditComplementModal = () => {
        this.setState({editComplementModal: !this.state.editComplementModal});
    };

    handleDishHaveComplements = (e) => this.setState({dishHaveComplements: e.target.checked});

    handleEditDishHaveComplements = (e) => this.setState({editDishHaveComplements: e.target.checked});

    handleEditDishModal = () => {
        this.setState({editDishModal: !this.state.editDishModal});
    };

    closeAddDishToSectionModal = () => {
        this.setState({addDishToSectionModal: false, newSectionDishPrice: null})
    };

    showComplementEditionModal(complement) {
        this.setState({
            complementSelectedId: complement.uuid,
            editComplementName: complement.name,
            editComplementPrice: complement.price,
            editComplementModal: true,
        })
    }

    handleNewSectionImage = fileItems => {
        if (fileItems.length > 0) {
            const image = fileItems[0].file;
            this.setState({newSectionImage: image})
        } else {
            this.setState({newSectionImage: null})
        }
    };

    handleEditSectionImage = fileItems => {

        if(fileItems !== undefined){
            if (fileItems.length > 0) {
                const image = fileItems[0].file;
                this.setState({editSectionImage: image, sectionImageChanged: true})
            } else {
                this.setState({editSectionImage: null})
            }
        }else{
            this.setState({editSectionImage: null})
        }
    };

    handleNewDishImage = fileItems => {
        if (fileItems.length > 0) {
            const image = fileItems[0].file;
            this.setState({newDishImage: image})
        } else {
            this.setState({newDishImage: null})

        }
    };
    handleEditDishImage = fileItems => {
        if (fileItems.length > 0) {
            const image = fileItems[0].file;
            this.setState({editDishImage: image})
        } else {
            this.setState({editDishImage: null})
        }
    };

    ///////////////// END MODAL FUNCTIONS ///////////////////

    getAllergens = () => {
        fetch(apiBaseUrl + 'allergens', {
            method: 'GET'
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
            this.setState({allergens: response.allergens, numberOfDataRetrieved: addDataRetrieved});

        }).catch((error) => {
            console.error(error);
            let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
            this.setState({numberOfDataRetrieved: addDataRetrieved});
        });

    };

    getMenusRestaurant(idRestaurant) {
        fetch(apiBaseUrl + 'restaurants/getMenus', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);

            if (!response.error) {
                let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
                this.setState({menus: response.menus, numberOfDataRetrieved: addDataRetrieved});
            } else {
                throw new Error('Error: ' + response.errorMessage);
            }

        }).catch((error) => {
            console.error(error);
            let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
            this.setState({numberOfDataRetrieved: addDataRetrieved});
        });

    }

    getDishes(idRestaurant) {
        fetch(apiBaseUrl + 'restaurants/getDishes', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (!response.error) {
                let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
                this.setState({dishes: response.dishes, numberOfDataRetrieved: addDataRetrieved});
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
            this.setState({numberOfDataRetrieved: addDataRetrieved});
        });

    }

    renderMenus = () => {
        const menus = this.state.menus;
        let menusCards = [];

        Object.keys(menus).forEach((key) => {
            menusCards.push(
                <MenuCard
                    key={menus[key].uuid}
                    id={menus[key].uuid}
                    idRestaurant={this.props.idRestaurant}
                    name={this.state.editMenus[key] ?
                        (<TextField
                            name={'menu' + key + 'Name'}
                            label="Nombre de la carta"
                            defaultValue={menus[key].name}
                            onChange={this.handleInputChange}
                            margin="dense"
                            inputProps={{maxLength: 50}}
                            fullWidth
                            variant="outlined"
                        />) : menus[key].name}
                    events={menus[key].events}
                    price={menus[key].price}
                    sections={this.renderSections(menus[key].menu_sections, key)}
                    active={menus[key].active}
                    disableMenu={this.disableMenu}
                    enableMenu={this.enableMenu}
                    newSectionButton={(
                        <Button
                            color="primary"
                            variant={"outlined"}
                            aria-label="Add"
                            size={"small"}
                            className={importedStylesSass.addSectionButton}
                            onClick={this.openNewSectionModal(menus[key].uuid)}
                        >
                            Añadir sección +
                        </Button>
                    )}
                    actions={
                        this.renderActionsMenu(
                            () => this.showEditMenuModal(menus[key]),
                            () => this.setState({menuSelectedId: menus[key].uuid, copySnack: true}),
                            menus[key].uuid
                        )
                    }
                />
            )
        });

        if (menusCards.length === 0) {
            return (
                <Card>
                    <CardContent>
                        <p style={{margin: 20}}>Crea tu primera carta...</p>
                    </CardContent>
                </Card>
            );
        } else {
            return <div style={{textAlign: 'center'}}>{menusCards}</div>;
        }
    };

    addMenu = () => {

        let idRestaurant = this.props.idRestaurant;
        let newMenuName = this.state.newMenuName;
        let newMenuPrice = this.state.newMenuPrice ? this.state.newMenuPrice : null;
        let events = null;

        if (this.state.selectedEvents.length > 0) {
            const selectedEvents = this.state.selectedEvents;
            events = [];
            Object.keys(selectedEvents).forEach((event) => {
                events.push(selectedEvents[event].uuid);
            });
        }

        // console.log('IdRestaurant: ' + idRestaurant);
        // console.log('Nombre menú: ' + newMenuName);
        // console.log('Eventos seleccionados: ' + JSON.stringify(events));

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'menus/newMenu', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
                name: newMenuName,
                price: newMenuPrice,
                events: events
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (response.inserted) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.setState({
                    newMenuModal: false,
                    sendingData: false,
                    newMenuName: '',
                    selectedEvents: [],
                    newMenuPrice: '',
                });
                this.props.dispatch(showSnack('Creado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no insertado
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    updateMenu = () => {
        let menuId = this.state.menuSelectedId;
        let editedMenuName = this.state.editMenuName;
        let editedMenuPrice = this.state.editMenuPrice;
        let editedMenuEvents = this.state.editMenuEvents;

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'menus/updateMenu', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idMenu: menuId,
                name: editedMenuName,
                price: editedMenuPrice,
                events: editedMenuEvents
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (!response.updateError) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.setState({sendingData: false, editMenuModal: false});
                this.props.dispatch(showSnack('Modificado con éxito', snackTypes.success));
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    disableMenu = menuId => {
        this.setState({sendingStateData: true});
        fetch(apiBaseUrl + 'menus/disableMenu', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idMenu: menuId,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (!response.updateError) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.setState({sendingStateData: false, editMenuModal: false});
                this.props.dispatch(showSnack('Menú desactivado', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingStateData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    enableMenu = menuId => {
        this.setState({sendingStateData: true});
        fetch(apiBaseUrl + 'menus/enableMenu', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idMenu: menuId,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (!response.updateError) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.setState({sendingStateData: false, editMenuModal: false});
                this.props.dispatch(showSnack('Activado con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingStateData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    deleteMenu = () => {

        let idMenu = this.state.menuSelectedId;
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'menus/deleteMenu', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idMenu: idMenu,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);

            if (response.deleted) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.handleRemoveMenuModal();
                this.setState({sendingData: false});
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no eliminado
                throw new Error();
            }

        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    };


    /* DRAG LIST HANDLERS */
    onDragEnd(result, menuIndex) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let currentSections = [...this.state.menus[menuIndex].menu_sections];

        let reorderedSections = this.reorder(
            currentSections,
            result.source.index,
            result.destination.index
        );

        /* Filter re-ordered array to avoid undefined auto-filled indexes */
        reorderedSections = reorderedSections.filter(Boolean);

        //change position property by ordering array with the keys
        Object.keys(reorderedSections).forEach((key) => {
            reorderedSections[key].position = parseInt(key);
        });

        this.updateMenuSectionsPositions(reorderedSections);

        let modifiedMenus = [...this.state.menus];
        modifiedMenus[menuIndex].menu_sections = reorderedSections;

        this.setState({menus: modifiedMenus});
    }

    reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return [...result.map((_, i) => _)]
    };
    /*------------------*/

    updateMenuSectionsPositions = (reorderedSections) => {
        fetch(apiBaseUrl + 'menus/updateSectionsPositions', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                reorderedSections: reorderedSections,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);

            if (!response.error) {
                console.info('Section order updated succesfully')
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
        });

    };

    renderAvatar = (item, withDrag) => {
        let avatar = <Avatar style={{marginRight: 10}}>{item.name.substr(0, 1)}</Avatar>;
        if (item.photo && item.photo.length > 0) {
            avatar =  <LazyLoadImage
                className={importedStylesSass.productAvatar}
                src={item.photo}
                alt={item.name}
                // threshold={400} // amount of px before element that triggers image load
                scrollPosition={this.props.scrollPosition}
                effect={'blur'}
                onError={handleImgLoadError}
                placeholderSrc={imageDefault}
                wrapperClassName={importedStylesSass.productAvatar}
            />
        }
        return (
            <React.Fragment>
                {withDrag && <DragIcon style={{marginRight: 5}}/>}
                {avatar}
            </React.Fragment>
        );
    };

    handleSectionExpansion = section => () =>{
        this.setState({
            sectionSelectedId: section.uuid,
            currentSection: section
        })
    };
    renderSections(sections, menuIndex) {

        const {classes} = this.props;
        let sectionItems = [];
        Object.keys(sections).forEach((key) => {

            let section = sections[key];

            sectionItems.push(
                <Draggable key={key} draggableId={key} index={section.position}>
                    {(provided, snapshot) => (
                        <ExpansionPanel
                            TransitionProps={{unmountOnExit: true}}
                            key={key} classes={{root: classes.sectionExpansionPanel}}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <ExpansionPanelSummary
                                className={classes.sectionExpansionPanelSummary}
                                onClick={this.handleSectionExpansion(section)}
                                expandIcon={<ExpandMoreIcon/>}>
                                <div id={'sectionHeader' + key + ''} className={classes.sectionHeader}>
                                    {/*  <Avatar style={{marginRight: 10, backgroundColor: '#fff'}}>{section.position + 1}</Avatar>*/}
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {this.renderAvatar(section, true)}
                                        {section.name.length > 15 ? (section.name.substr(0, 15) + '...') : section.name}
                                        {this.renderActions(section)}
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={importedStylesSass.expansionPanelDetails}>
                                <Button color="primary" variant={"outlined"} size={'small'}
                                        className={importedStylesSass.addProductButton} onClick={this.handleAddDishToSectionModal(section)}>
                                    Añadir producto +
                                </Button>
                                {this.renderSectionDishes(section.dishes)}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )}
                </Draggable>
            )
        });

        return (
            <DragDropContext onDragEnd={(result) => this.onDragEnd(result, menuIndex)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            //style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {sectionItems}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }


    addSection = () => {

        let form = new FormData();
        form.append('uuidMenu', this.state.idMenu);
        form.append('name', this.state.newSectionName);
        form.append('photo', this.state.newSectionImage ? this.state.newSectionImage : null);


        this.setState({sendingData: true});

        fetch(apiBaseUrl + 'menus/newSection', {
            method: 'post',
            body: form
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);

            if (response.inserted) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.closeNewSectionModal();
                this.setState({sendingData: false});
                this.props.dispatch(showSnack('Creado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no insertado
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error(error);
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    };

    updateSection = (sectionId, newSectionName, sectionKey) => {

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'menus/updateSection', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                sectionId: sectionId,
                name: newSectionName,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);

            if (!response.updateError) {

                this.getMenusRestaurant(this.props.idRestaurant);

                let newState = {...this.state.editSection};
                newState[sectionKey] = false;
                this.setState({editSection: newState, sendingData: false});
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: true});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    editSection = section => {
        this.setState({sendingData: true});
        console.info("editSection");

        let form = new FormData();
        form.append('sectionId', section.uuid);
        form.append('name', this.state.editSectionName);
        this.state.editSectionImage && this.state.sectionImageChanged && form.append('photo', this.state.editSectionImage);

        fetch(apiBaseUrl + 'menus/updateSection', {
            method: 'post',
            body: form
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);

            if (!response.updateError) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.setState({sendingData: false, editSectionModal: false});
                this.props.dispatch(showSnack('Editado con éxito', snackTypes.success));
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    deleteSection = () => {

        let idSection = this.state.sectionSelectedId;
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'menus/deleteSection', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idSection: idSection,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (response.deleted) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.handleRemoveSectionModal();
                this.setState({sendingData: false});
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));

            } else {
                //ha fallado algo, no eliminado
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    };

    renderSectionDishes(dishes) {

        const {classes} = this.props;

        if (dishes.length === 0) {
            return (<p style={{margin: 20}}>Añade tu primer producto...</p>);
        } else {
            return (
                <List style={{width: '100%'}}>
                    {Object.keys(dishes).map(value => (
                        <ListItem
                            key={value}
                            role={undefined}
                            dense
                            className={classes.dishContainer}
                        >
                            {/*<ListItemAvatar>{this.renderAvatar(dishes[value], false)}</ListItemAvatar>*/}
                            <ListItemText primary={dishes[value].name}/>
                            <ListItemSecondaryAction>
                                <IconButton aria-label="Delete" onClick={() => {
                                    this.setState({
                                        dishSelectedId: dishes[value].uuid,
                                        sectionSelectedId: dishes[value].pivot.uuidMenuSections
                                    });
                                    this.handleRemoveDishFromSectionModal()
                                }}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            );
        }
    }

    setDish = () => {
        let dishPrice = this.state.newSectionDishPrice;
        let idDish = this.state.selectDish.uuid;
        let idSection = this.state.sectionSelectedId;

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'dishes/addDishToSection', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidMenuSections: idSection,
                uuidDishes: idDish,
                price: dishPrice
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);

            if (response.inserted) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.handleAddDishToSectionModal();
                this.setState({newSectionDishPrice: null, sendingData: false});
                this.props.dispatch(showSnack('Creado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no insertado
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    };

    handleProductsListExpansion = () => {
        this.setState((prevState, props) => {
            return {productsListExpanded: !prevState.productsListExpanded};
        });
    };


    renderDishes(dishes) {

        const {classes} = this.props;

        if (dishes.length === 0) {
            return (<span style={{margin: 20}}>Crea tu primer producto...</span>);
        } else {

            /*  TO BE USED WITH VIRTUOSO PACKAGE
            const GenerateItem = index => {
                 return (
                     <ListItem
                         index={index}
                         key={dishes[index].uuid}
                         role={undefined}
                         disableGutters
                         className={classes.dishContainer}
                     >
                         <ListItemAvatar>{this.renderAvatar(dishes[index], false)}</ListItemAvatar>
                         <ListItemText secondary={dishes[index].name} style={{maxWidth: '65%'}}/>
                         <ListItemSecondaryAction style={{right: -10}}>
                             <IconButton aria-label="Add" onClick={this.showModalEdition(dishes[index])}
                                         className={importedStylesSass.productActions}>
                                 <EditIcon/>
                             </IconButton>
                             <IconButton aria-label="Delete" onClick={() => {
                                 this.setState({dishSelectedId: dishes[index].uuid});
                                 this.handleRemoveDishModal()
                             }} className={importedStylesSass.productActions}>
                                 <DeleteIcon/>
                             </IconButton>
                         </ListItemSecondaryAction>
                     </ListItem>
                 )
             };*/
            /*<Virtuoso
                        totalCount={dishes.length}
                        item={GenerateItem}
                        //style={{ width: '300px', height: '400px' }}
                    />*/
            return (
                <React.Fragment>

                    <Collapse in={this.state.productsListExpanded} collapsedHeight="15rem">
                        <List className={importedStylesSass.productsList} disablePadding>
                            {Object.keys(dishes).map(index => (
                                <ListItem
                                    key={index}
                                    role={undefined}
                                    disableGutters
                                    className={classes.dishContainer}
                                >
                                    <ListItemAvatar>{this.renderAvatar(dishes[index], false)}</ListItemAvatar>

                                    <ListItemText secondary={dishes[index].name} style={{maxWidth: '65%'}}/>

                                    <ListItemSecondaryAction style={{right: -10}}>
                                        <IconButton aria-label="Add" onClick={this.showModalEdition(dishes[index])}
                                                    className={importedStylesSass.productActions}>
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton aria-label="Delete" onClick={this.handleRemoveDishModal(dishes[index])} className={importedStylesSass.productActions}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </React.Fragment>
            );
        }
    }

    addDish = () => {
        let form = new FormData();
        form.append('uuidRestaurant', this.props.idRestaurant);
        form.append('name', this.state.newDishName);
        form.append('ingredients', this.state.newDishIngredients);
        form.append('allergens', JSON.stringify(this.state.newDishAllergens));
        form.append('description', this.state.newDishDescription);
        form.append('price', this.state.newDishPrice);
        this.state.newDishImage && form.append('image', this.state.newDishImage);
        form.append('size', this.state.newDishSize);
        form.append('dishHaveComplements', this.state.dishHaveComplements);
        let dishComplements = this.state.dishComplements.map(complement => {
            return complement.uuid
        });
        form.append('complements', JSON.stringify(dishComplements));

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'dishes/newDish', {
            method: 'post',
            body: form
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);
            let dishes = response.dishes;

            if (response.inserted) {
                this.closeNewDishModal();
                this.setState({
                    dishes: dishes,
                    newDishName: '',
                    newDishPrice: '',
                    newDishDescription: '',
                    newDishIngredients: '',
                    newDishAllergens: [],
                    newDishSize: null,
                    dishComplements: [],
                    dishHaveComplements: false,
                    sendingData: false,
                });
                this.props.dispatch(showSnack('Creado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no insertado
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    updateDish = () => {

        let form = new FormData();
        form.append('uuidRestaurant', this.props.idRestaurant);
        form.append('idDish', this.state.dishSelectedId,);
        form.append('name', this.state.editDishName);
        form.append('ingredients', this.state.editDishIngredients);
        form.append('allergens', JSON.stringify(this.state.editDishAllergens));
        form.append('description', this.state.editDishDescription);
        form.append('price', this.state.editDishPrice);
        this.state.editDishImage && form.append('image', this.state.editDishImage);
        form.append('size', this.state.editDishSize);
        form.append('dishHaveComplements', this.state.editDishHaveComplements);
        let editedComplements = this.state.editDishComplements.map(complement => {
            return complement.uuid
        });
        form.append('complements', JSON.stringify(editedComplements));


        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'dishes/updateDish', {
            method: 'post',
            processData: false,
            body: form,
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);

            if (!response.updateError) {
                this.setState({
                    editDishModal: false,
                    dishes: response.dishes,
                    editDishSize: null,
                    editDishPrice: '',
                    editDishHaveComplements: false,
                    editDishComplements: [],
                    editDishIngredients: '',
                    editDishAllergens: [],
                    editDishDescription: '',
                    sendingData: false
                });
                this.props.dispatch(showSnack('Actualizado con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    deleteDish = () => {

        let idDish = this.state.dishSelectedId;
        let idRestaurant = this.props.idRestaurant;
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'dishes/deleteDish', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idDish: idDish,
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);

            if (response.deleted) {
                this.handleRemoveDishModal();
                this.setState({
                    removeDishModal: false,
                    dishes: response.dishes,
                    sendingData: false
                });
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no eliminado
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    };

    deleteDishFromSection = () => {

        let idDish = this.state.dishSelectedId;
        let idSection = this.state.sectionSelectedId;

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'dishes/deleteDishFromSection', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idDishes: idDish,
                idMenuSections: idSection
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);

            if (response.deleted) {
                this.getMenusRestaurant(this.props.idRestaurant);
                this.handleRemoveDishFromSectionModal();
                this.setState({removeDishFromSectionModal: false, sendingData: false});
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no eliminado
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    };

    renderDishesItem = () => {
        const alreadyAddedDishes = this.state.currentSection ? this.state.currentSection.dishes : [];
        const dishes = this.state.dishes;

        const filtered = differenceBy(dishes, alreadyAddedDishes, 'uuid');

        let dishesItems = [
            <MenuItem key={'000'} value={-1}>Selecciona un producto</MenuItem>
        ];

        Object.keys(filtered).forEach((key) => {
            let dish = filtered[key];
            dishesItems.push(
                <MenuItem key={key} value={dish}>{dish.name}</MenuItem>
            );
        });
        return dishesItems;

    };

    renderSelectPrice = () => {

        if (this.state.selectDish >= 0) {

            if (this.state.newSectionDishPrice === null) {
                this.setState({newSectionDishPrice: this.state.selectDish.price});
            }

            return (<TextField
                autoFocus
                margin="dense"
                value={this.state.newSectionDishPrice}
                name="newSectionDishPrice"
                label="Precio"
                type="number"
                fullWidth
                onChange={this.handleInputChange}
            />);

        } else {
            //nothing
        }
    };

    getComplements(idRestaurant) {
        fetch(apiBaseUrl + 'restaurants/getComplements', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const complements = JSON.parse(recurso);
            let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
            this.setState({complements: complements, numberOfDataRetrieved: addDataRetrieved});

        }).catch((error) => {
            console.error(error);
            let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
            this.setState({numberOfDataRetrieved: addDataRetrieved});
        });

    }

    renderComplements(complements) {
        const {classes} = this.props;

        if (complements.length === 0) {
            return (<span style={{margin: 20}}>Crea tu primer complemento...</span>);
        } else {
            return (
                <List style={{width: '100%'}}>
                    {Object.keys(complements).map(value => (
                        <ListItem
                            key={value}
                            role={undefined}
                            dense
                            className={classes.dishContainer}
                        >
                            {/*Added a div with a 75% off original width to avoid breaking overflow when text so long*/}
                            <div style={{width: '75%'}}>
                                <ListItemText secondary={complements[value].name}/>
                            </div>
                            <ListItemSecondaryAction>
                                <IconButton aria-label="Add" onClick={() => {
                                    this.showComplementEditionModal(complements[value]);
                                }}>
                                    <EditIcon/>
                                </IconButton>
                                <IconButton aria-label="Delete" onClick={() => {
                                    this.setState({complementSelectedId: complements[value].uuid});
                                    this.handleRemoveComplementModal();
                                }}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            );
        }
    }

    addComplement() {

        let idRestaurant = this.props.idRestaurant;
        let newComplementName = this.state.newComplementName;
        let newComplementPrice = this.state.newComplementPrice;

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'complements/newComplement', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
                complementName: newComplementName,
                complementPrice: newComplementPrice,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            let response = JSON.parse(recurso);
            if (response.inserted) {
                this.setState({
                    complements: response.complements,
                    newComplementModal: false,
                    newComplementName: '',
                    newComplementPrice: '',
                    sendingData: false
                });
                this.props.dispatch(showSnack('Creado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no insertado
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    updateComplement() {
        let idRestaurant = this.props.idRestaurant;
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'complements/updateComplement', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
                uuidComplement: this.state.complementSelectedId,
                complementName: this.state.editComplementName,
                complementPrice: this.state.editComplementPrice,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (!response.updateError) {
                this.setState({
                    editComplementModal: false,
                    complements: response.complements,
                    sendingData: false
                });
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    deleteComplement() {
        let uuidComplement = this.state.complementSelectedId;
        let idRestaurant = this.props.idRestaurant;
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'complements/deleteComplement', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidComplement: uuidComplement,
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (response.deleted) {
                this.handleRemoveComplementModal();
                this.setState({
                    removeComplementModal: false,
                    complements: response.complements,
                    sendingData: false
                });
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            } else {
                //ha fallado algo, no eliminado
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    }

    handleDishComplementsChange = event => {
        if (event.target.value !== 'all') {
            this.setState({dishComplements: event.target.value});
        }
    };
    handleEditDishComplementsChange = event => {
        if (event.target.value !== 'all') {
            this.setState({editDishComplements: event.target.value});
        }
    };
    checkAllComplements = event => {
        if (this.state.allComplementsCheck) {
            //If the check for all complements it's true we assume that we're trying to setting to false and clean all dishComplements
            this.setState({dishComplements: [], allComplementsCheck: false});
        } else {
            //if it's false, we turn it to true and add all complements
            let complements = this.state.complements;
            this.setState({dishComplements: complements, allComplementsCheck: true});
        }
    };
    editCheckAllComplements = event => {
        if (this.state.allComplementsCheck) {
            //If the check for all complements it's true we assume that we're trying to setting to false and clean all dishComplements
            this.setState({editDishComplements: [], allComplementsCheck: false});
        } else {
            //if it's false, we turn it to true and add all complements
            let complements = this.state.complements;
            this.setState({editDishComplements: complements, allComplementsCheck: true});
        }
    };

    renderComplementsSelect() {
        const {classes} = this.props;
        const complements = this.state.complements;
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        return (
            <FormControl variant={"outlined"} className={classes.formControl}>
                <InputLabel
                    ref={ref => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor="select-DishComplements"
                >
                    Complementos
                </InputLabel>
                <Select
                    multiple
                    value={this.state.dishComplements}
                    onChange={this.handleDishComplementsChange}
                    input={<OutlinedInput id="select-DishComplements" labelWidth={110}/>}
                    MenuProps={MenuProps}
                    renderValue={selected => selected.map(complement => {
                        return complement.name;
                    }).join(', ')}
                >
                    <MenuItem key={0} value={'all'}>
                        <Checkbox onChange={this.checkAllComplements} value={'allComplementsCheck'}
                                  checked={this.state.allComplementsCheck}/>
                        <ListItemText secondary={'Seleccionar todos los complementos'}/>
                    </MenuItem>
                    {complements.map(complement => (
                        <MenuItem key={complement.uuid} value={complement}>
                            <Checkbox checked={this.state.dishComplements.indexOf(complement) > -1}/>
                            <ListItemText primary={complement.name}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    renderEditComplementsSelect = () => {
        const {classes} = this.props;
        const complements = this.state.complements;
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        return (
            <FormControl variant={"outlined"} className={classes.formControl}>
                <InputLabel
                    ref={ref => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor="select-EditDishComplements"
                >
                    Complementos
                </InputLabel>
                <Select
                    multiple
                    value={this.state.editDishComplements}
                    onChange={this.handleEditDishComplementsChange}
                    input={<OutlinedInput id="select-EditDishComplements" labelWidth={110}/>}
                    MenuProps={MenuProps}
                    renderValue={selected => selected.map(complement => {
                        return complement.name
                    }).join(', ')}
                >
                    <MenuItem key={0} value={'all'}>
                        <Checkbox onChange={this.editCheckAllComplements} value={'allComplementsCheck'}
                                  checked={this.state.allComplementsCheck}/>
                        <ListItemText secondary={'Seleccionar todos los complementos'}/>
                    </MenuItem>
                    {complements.map(complement => (
                        <MenuItem key={complement.uuid} value={complement}>
                            <Checkbox checked={this.state.editDishComplements.indexOf(complement) > -1}/>
                            <ListItemText primary={complement.name}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    };

    handleEditSectionModal = section => {
        this.setState({
            editSectionModal: true,
            editSectionImage: section.photo,
            editSectionName: section.name,
            actualEditingSection: section
        });
    };

    renderActions = section => {
        return (
            <div style={{display: 'flex'}}>
                <IconButton aria-label="Editar" onClick={(e) => {
                    e.stopPropagation();
                    this.handleEditSectionModal(section);
                }}>
                    <EditIcon/>
                </IconButton>
                <IconButton aria-label="Delete" onClick={(e) => {
                    e.stopPropagation();
                    this.setState({sectionSelectedId: section.uuid});
                    this.handleRemoveSectionModal()
                }}>
                    <DeleteIcon/>
                </IconButton>
            </div>
        )
    };

    renderActionsMenu(functionClick, shareFunction, menuId) {
        return (
            <div>
                {/* <IconButton onClick={shareFunction}>
                    <LinkIcon/>
                </IconButton>*/}
                <IconButton aria-label="Editar" onClick={functionClick}>
                    <EditIcon/>
                </IconButton>
                <IconButton aria-label="Eliminar" onClick={() => {
                    this.setState({menuSelectedId: menuId});
                    this.handleRemoveMenuModal()
                }}>
                    <DeleteIcon/>
                </IconButton>
            </div>

        )
    }

    renderEditingActions(functionClick, functionConfirmEdit) {

        return (
            <div style={{display: 'flex'}}>
                <IconButton
                    color="secondary"
                    aria-label="Cancelar"
                    onClick={(e) => {
                        e.stopPropagation();
                        functionClick();
                    }}
                >
                    <CancelIcon/>
                </IconButton>
                <IconButton
                    color="primary"
                    aria-label="Confirmar"
                    onClick={(e) => {
                        e.stopPropagation();
                        functionConfirmEdit();
                    }}
                    disabled={this.state.sendingData}
                >
                    <CheckCircleIcon/>
                </IconButton>
            </div>
        )
    }

    getRestaurantAssociationsEvents(idRestaurant) {

        fetch(apiBaseUrl + 'restaurants/getRestaurantAssociationsEvents', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: idRestaurant
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
            if (!response.error) {
                this.setState({events: response.events, numberOfDataRetrieved: addDataRetrieved})
            } else {
                this.setState({numberOfDataRetrieved: addDataRetrieved})
            }

        }).catch((error) => {
            let addDataRetrieved = this.state.numberOfDataRetrieved + 1;
            this.setState({numberOfDataRetrieved: addDataRetrieved});
            console.log(error);
        });
    }

    getStyles(event, that) {
        return {
            marginBottom: 10,
            fontWeight:
                that.state.selectedEvents.indexOf(event) === -1
                    ? that.props.theme.typography.fontWeightRegular
                    : that.props.theme.typography.fontWeightMedium,
        };
    }

    renderEventsSelect = () => {

        const {classes} = this.props;
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };


        return (
            <div>
                <Typography
                    variant="subheading"
                    className={classes.flex}
                    style={{color: '#0095ff', marginTop: 20, marginBottom: 10}}
                >
                    Si esta carta es para un evento en concreto debes seleccionarlo aquí:
                </Typography>

                <Select
                    multiple
                    displayEmpty
                    variant={"outlined"}
                    margin="dense"
                    fullWidth
                    name="selectedEvents"
                    value={this.state.selectedEvents}
                    renderValue={selected => {
                        if (selected.length === 0) {
                            return <em style={{fontStyle: 'italic'}}>-- Eventos --</em>;
                        } else {
                            let selectedEventsNames = [];
                            this.state.selectedEvents.forEach(event => {
                                selectedEventsNames.push(event.name);
                            });
                            return selectedEventsNames.join(', ');
                        }
                    }}
                    onChange={this.handleEventChange}
                    input={<OutlinedInput labelWidth={0} id="select-multiple-events"/>}
                    MenuProps={MenuProps}
                    style={{marginBottom: 15}}
                >
                    <MenuItem disabled value="">
                        <em>-- Eventos --</em>
                    </MenuItem>

                    {this.state.events ?
                        this.state.events.map(event => (
                            <MenuItem key={event.uuid} value={event}
                                      style={this.getStyles(event.uuid, this)}>
                                <Checkbox checked={this.state.selectedEvents.indexOf(event) > -1}/>
                                <ListItemText primary={event.name} secondary={event.association}/>
                            </MenuItem>
                        ))
                        :
                        null
                    }
                </Select>
            </div>
        )

    };

    renderEditEventsSelect = () => {

        const {classes} = this.props;
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        let eventsList = [];

        Object.keys(this.state.events).forEach((event) => {
            let eventInMenu = false;
            Object.keys(this.state.editMenuEvents).forEach((menuEvent) => {
                if (this.state.events[event].uuid === this.state.editMenuEvents[menuEvent].uuid) {
                    eventInMenu = true;
                }
            });
            eventsList.push(
                <MenuItem
                    key={this.state.events[event].uuid}
                    value={this.state.events[event]}
                    style={this.getStyles(this.state.events[event].uuid, this)}
                >
                    <Checkbox checked={eventInMenu}/>
                    <ListItemText primary={this.state.events[event].name}
                                  secondary={this.state.events[event].association}/>
                </MenuItem>
            )
        });

        return (
            <div>
                <Typography
                    variant="subheading"
                    className={classes.flex}
                    style={{color: '#0095ff', marginTop: 20, marginBottom: 10}}
                >
                    Si esta carta es para un evento en concreto debes seleccionarlo aquí:
                </Typography>

                <Select
                    multiple
                    displayEmpty
                    variant={"outlined"}
                    fullWidth
                    name="editMenuEvents"
                    value={this.state.editMenuEvents}
                    renderValue={selected => {
                        if (selected.length === 0) {
                            return <em style={{fontStyle: 'italic'}}>-- Eventos --</em>;
                        } else {
                            let selectedEventsNames = [];
                            this.state.editMenuEvents.forEach(event => {
                                selectedEventsNames.push(event.name);
                            });
                            return selectedEventsNames.join(', ');
                        }
                    }}
                    onChange={this.handleEditEventChange}
                    input={<OutlinedInput labelWidth={0} id="select-multiple-events"/>}
                    MenuProps={MenuProps}
                    style={{marginBottom: 15}}
                >
                    <MenuItem disabled value="">
                        <em>-- Eventos --</em>
                    </MenuItem>
                    {this.state.events ? eventsList : null}
                </Select>
            </div>
        )
    };

    renderComplementsCard = () => {
        let classes = this.props.classes;
        return (
            <Grid item xs={12}>
                <div className={classes.header}>
                    <h2>Listado de complementos</h2>
                    <Button color='primary'
                            onClick={() => this.setState({newComplementModal: true})}>
                        Nuevo complemento
                    </Button>
                </div>
                <div>
                    <Card>
                        <CardContent>
                            {this.renderComplements(this.state.complements)}
                        </CardContent>
                    </Card>
                </div>
            </Grid>
        )
    };

    handleQrDownload = () => {
        let link = document.createElement('a');
        link.download = 'QRCarta.png';
        let qr = document.querySelector('#QrCode canvas');
        if (qr) {
            link.href = qr.toDataURL();
            link.click();
        } else {
            this.props.dispatch(showSnack('No se ha podido generar el QR, si el error persiste por favor ponte en contacto con el soporte técnico'))
        }

    };
    handleQrDownload2 = () => {
        let link = document.createElement('a');
        link.download = 'QRCartaConImagenes.png';
        let qr = document.querySelector('#QrCodeWithImages canvas');
        if (qr) {
            link.href = qr.toDataURL();
            link.click();
        } else {
            this.props.dispatch(showSnack('No se ha podido generar el QR, si el error persiste por favor ponte en contacto con el soporte técnico'))
        }

    };

    renderPreviewMenuWithImagesButton = () => {
        if (this.props.restaurantPermissions.menusWithImages) {
            return (
                <React.Fragment>
                    <Button variant={"contained"} className={importedStylesSass.previewMenusWithImagesButton}
                            href={baseUrl + 'app/menuPro/' + this.props.slugRestaurant}
                            target={'_blank'}>
                        <EyeIcon style={{marginRight: 10}}/>
                        Previsualizar carta con imágenes
                        <ChevronRightIcon style={{marginLeft: 5}}/>
                    </Button>
                    <Button variant={"contained"} size={"small"}
                            className={importedStylesSass.downloadMenusQrButton}
                            onClick={this.handleQrDownload2} target={'_blank'}>
                        Descargar QR carta con imágenes <DownloadIcon style={{marginLeft: 5}}/>
                    </Button>
                </React.Fragment>
            )
        }
    };


    render() {
        const classes = this.props.classes;
        const iframe = '<iframe src="' + apiBaseUrl + 'menus/getMenu/' + this.state.menuSelectedId + '"/>';
        return (
            <Grid container className={classes.content}>
                {/*<NavBar iconLeft={iconLeft} title={this.props.nameRestaurant}/> */}
                <Grid item xs={false} md={this.props.drawerOpen ? 3 : 1}>
                    <MiniDrawer title={"Modificar cartas"} idRestaurant={this.props.idRestaurant}/>
                </Grid>
                <Grid container item xs={this.props.drawerOpen ? 7 : 11} spacing={4}>

                    {this.state.numberOfDataRetrieved < 5 ?
                        <Grid item xs={12} className={classes.chargingAnimationContainer}>
                            <CircularProgress size={65}/>
                        </Grid>
                        :
                        <React.Fragment>
                            <Grid item xs={12} className={importedStylesSass.mainActions}>
                                <div className={importedStylesSass.mainActionsButtons}>
                                    <Button variant={"contained"} className={importedStylesSass.previewMenusButton}
                                            href={baseUrl + 'app/menu/' + this.props.slugRestaurant}
                                            target={'_blank'}>
                                        <EyeIcon style={{marginRight: 10}}/>
                                        Previsualizar carta básica
                                        <ChevronRightIcon style={{marginLeft: 5}}/>
                                    </Button>
                                    <Button variant={"contained"} size={"small"}
                                            className={importedStylesSass.downloadMenusQrButton}
                                            onClick={this.handleQrDownload} target={'_blank'}>
                                        Descargar QR carta básica <DownloadIcon style={{marginLeft: 5}}/>
                                    </Button>
                                </div>
                                <div className={importedStylesSass.mainActionsButtons}>
                                    {this.renderPreviewMenuWithImagesButton()}

                                </div>
                                <div className={classes.hiddenQR} id={'QrCode'}>
                                    <QRCode size={512}
                                            value={baseUrl + 'app/menu/' + this.props.slugRestaurant}/>
                                </div>
                                <div className={classes.hiddenQR} id={'QrCodeWithImages'}>
                                    <QRCode size={512}
                                            value={baseUrl + 'app/menuPro/' + this.props.slugRestaurant}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className={classes.header}>
                                    <h2>Listado de productos</h2>
                                    <Button color='primary' variant={"outlined"} size={"small"}
                                            onClick={this.openNewDishModal}> Nuevo
                                        producto</Button>
                                </div>
                                <Card>
                                    <CardContent style={{maxHeight: '25rem', overflowY: this.state.productsListExpanded ? 'scroll' : 'hidden'}}>
                                        {this.renderDishes(this.state.dishes)}
                                    </CardContent>
                                    <CardActions>
                                        <Button variant={'text'} onClick={this.handleProductsListExpansion}
                                                style={{textTransform: 'none', color: '#6c77ff'}}>
                                            {this.state.productsListExpanded ? 'Ver menos...' : 'Ver más...'}
                                        </Button>
                                    </CardActions>
                                </Card>
                                {/*{this.renderComplementsCard}*/}
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <div className={classes.header}>
                                    <h2>Listado de cartas</h2>
                                    <Button color='primary' variant={'outlined'} size={'small'}
                                            onClick={this.openNewMenuModal}>Nueva carta</Button>
                                </div>
                                {this.renderMenus()}
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
                <MenuModals
                    newMenuModal={this.state.newMenuModal}
                    closeNewMenuModal={this.closeNewMenuModal}
                    handleInputChange={this.handleInputChange}
                    events={this.state.events}
                    renderEventsSelect={this.renderEventsSelect}
                    newMenuName={this.state.newMenuName}
                    sendingData={this.state.sendingData}
                    addMenu={this.addMenu}
                    editMenuModal={this.state.editMenuModal}
                    handleEditMenuModal={this.handleEditMenuModal}
                    editMenuActive={this.state.editMenuActive}
                    sendingStateData={this.state.sendingStateData}
                    editMenuName={this.state.editMenuName}
                    editMenuPrice={this.state.editMenuPrice}
                    renderEditEventsSelect={this.renderEditEventsSelect}
                    updateMenu={this.updateMenu}
                    removeMenuModal={this.state.removeMenuModal}
                    handleRemoveMenuModal={this.handleRemoveMenuModal}
                    deleteMenu={this.deleteMenu}
                />
                <SectionModals
                    sendingData={this.state.sendingData}
                    handleInputChange={this.handleInputChange}
                    newSectionModal={this.state.newSectionModal}
                    closeNewSectionModal={this.closeNewSectionModal}
                    addSection={this.addSection}
                    handleNewSectionImage={this.handleNewSectionImage}
                    newSectionName={this.state.newSectionName}

                    actualEditingSection={this.state.actualEditingSection}
                    editSectionModal={this.state.editSectionModal}
                    closeEditSectionModal={this.closeEditSectionModal}
                    editSection={this.editSection}
                    handleEditSectionImage={this.handleEditSectionImage}
                    editSectionName={this.state.editSectionName}
                    editSectionImage={this.state.editSectionImage}
                    removeSectionModal={this.state.removeSectionModal}
                    handleRemoveSectionModal={this.handleRemoveSectionModal}
                    deleteSection={this.deleteSection}
                />

                <DishSectionModals
                    sendingData={this.state.sendingData}
                    handleInputChange={this.handleInputChange}
                    addDishToSectionModal={this.state.addDishToSectionModal}
                    closeAddDishToSectionModal={this.closeAddDishToSectionModal}
                    renderDishesItem={this.renderDishesItem}
                    renderSelectPrice={this.renderSelectPrice}
                    setDish={this.setDish}
                    selectDish={this.state.selectDish}
                    removeDishFromSectionModal={this.state.removeDishFromSectionModal}
                    handleRemoveDishFromSectionModal={this.handleRemoveDishFromSectionModal}
                    deleteDishFromSection={this.deleteDishFromSection}
                />

                <DishModals
                    allergens={this.state.allergens}
                    sendingData={this.state.sendingData}
                    handleInputChange={this.handleInputChange}
                    handleAllergensChange={this.handleAllergensChange}
                    handleNewDishImage={this.handleNewDishImage}
                    handleEditDishImage={this.handleEditDishImage}
                    newDishModal={this.state.newDishModal}
                    closeNewDishModal={this.closeNewDishModal}
                    newDishName={this.state.newDishName}
                    newDishSize={this.state.newDishSize}
                    newDishPrice={this.state.newDishPrice}
                    newDishDescription={this.state.newDishDescription}
                    newDishAllergens={this.state.newDishAllergens}
                    dishHaveComplements={this.state.dishHaveComplements}
                    renderComplementsSelect={() => this.renderComplementsSelect()}
                    addDish={this.addDish}
                    handleDishHaveComplements={this.handleDishHaveComplements}
                    editDishModal={this.state.editDishModal}
                    handleEditDishModal={this.handleEditDishModal}
                    editDishName={this.state.editDishName}
                    editDishSize={this.state.editDishSize}
                    editDishPrice={this.state.editDishPrice}
                    editDishImage={this.state.editDishImage}
                    editDishDescription={this.state.editDishDescription}
                    editDishAllergens={this.state.editDishAllergens}
                    editDishIngredients={this.state.editDishIngredients}
                    handleEditAllergensChange={this.handleEditAllergensChange}
                    editDishHaveComplements={this.state.editDishHaveComplements}
                    handleEditDishHaveComplements={this.handleEditDishComplementsChange}
                    renderEditComplementsSelect={this.renderEditComplementsSelect}
                    updateDish={this.updateDish}
                    removeDishModal={this.state.removeDishModal}
                    handleRemoveDishModal={this.handleRemoveDishModal}
                    deleteDish={this.deleteDish}
                />

                {/*<ComplementModals
                    sendingData={this.state.sendingData}
                    handleInputChange={this.handleInputChange}
                    newComplementModal={this.state.newComplementModal}
                    handleAddComplementModal={this.handleAddComplementModal}
                    addComplement={() => this.addComplement()}
                    newComplementName={this.state.newComplementName}
                    newComplementPrice={this.state.newComplementPrice}
                    editComplementModal={this.state.editComplementModal}
                    handleEditComplementModal={this.handleEditComplementModal}
                    updateComplement={() => this.updateComplement()}
                    editComplementName={this.state.editComplementName}
                    editComplementPrice={this.state.editComplementPrice}
                    removeComplementModal={this.state.removeComplementModal}
                    handleRemoveComplementModal={this.handleRemoveComplementModal}
                    deleteComplement={() => this.deleteComplement()}
                />*/}

                <Snack/>
                {/* SHARE MENU LINK SNACKBAR */}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.copySnack}
                    //autoHideDuration={50000}
                    onClose={() => this.setState({copySnack: !this.state.copySnack})}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <div>
                            <p id="copyMenuIframeMessage">Pulsa en el icono para copiar el siguiente código en tu
                                portapapeles. Insértalo en tu web para mostrar esta carta: </p>

                            <OutlinedInput
                                className={classes.textField}
                                variant="filled"
                                fullWidth
                                style={{backgroundColor: '#e0e0e0'}}
                                value={iframe}
                                labelWidth={0}
                            />
                        </div>

                    }
                    action={[
                        <Tooltip title="Copiar" placement="left">
                            <IconButton
                                key="copy"
                                aria-label="copyCode"
                                color="inherit"
                                onClick={() => {
                                    this.setState({copySnack: false});
                                    this.dispatch(showSnack('¡Link copiado al portapapeles!', snackTypes.warning));
                                }}
                            >
                                <Clipboard
                                    text={iframe}
                                    render={({copy}) => <FileCopyIcon onClick={copy}/>}
                                />
                            </IconButton>
                        </Tooltip>
                    ]}
                />

            </Grid>
        );
    }
}

menusManager.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer, MiniDrawerReducer}) => {
    return ({
        user: authReducer.credentials[0],
        drawerOpen: MiniDrawerReducer.drawerOpen,
        idRestaurant: restaurantReducer.idRestaurant,
        slugRestaurant: restaurantReducer.slugRestaurant,
        nameRestaurant: restaurantReducer.nameRestaurant,
        restaurantPermissions: restaurantReducer.permissions
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles(), {withTheme: true})(trackWindowScroll(menusManager)));


