import {CHANGE_ACTIVE_MENU, CHANGE_CURRENT_RESTAURANT, CHANGE_CURRENT_SECTION} from "../../constants/action-types";


const initialState = {
    actualMenu : {},
    currentRestaurant: null,
    currentSection: {},
};

const digitalMenuReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHANGE_ACTIVE_MENU:
            return {...state, actualMenu: action.actualMenu};
        case CHANGE_CURRENT_SECTION:
            return {...state, currentSection: action.currentSection};
        case CHANGE_CURRENT_RESTAURANT:
            return {...state, currentRestaurant: action.currentRestaurant};
        default:
            return state;
    }
};


export default digitalMenuReducer;