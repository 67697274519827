import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import CartIcon from "@material-ui/icons/ShoppingCart";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import importedStyles from "./DigitalMenuNavBarMobile.module.sass";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Cart from "../Cart/CartMobile";
import Badge from "@material-ui/core/Badge";
import Fade from "@material-ui/core/Fade";
import {darken, lighten} from "../../../../helpers/manageColors";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {ThemeProvider} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";


class DigitalMenuNavBarMobile extends React.Component {

    state = {
        cartOpened: false,
        totalCartItems: 0
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cart.length !== this.props.cart.length) {
            this.setState({totalCartItems: this.props.cart.length})
        }
    }

    renderSelectSectionItems = () => {

        if (this.props.currentMenu.menu_sections) {
            let sections = this.props.currentMenu.menu_sections;
            let items = [];
            Object.keys(sections).forEach((key) => {
                let section = sections[key];
                items.push(
                    <MenuItem value={section}>{section.name}</MenuItem>
                );
            });
            return (items);
        }
    };

    handleCart = () => {
        this.setState((prevState, props) => ({
            cartOpened: !prevState.cartOpened
        }));
    };

    renderBackButton = () => {

        return (
            <IconButton
                color="inherit"
                aria-label="back"
                className={this.props.hideBackButton ? importedStyles.hideBackButton : ''}
                onClick={this.props.handleBack}
            >
                <BackIcon
                    style={{color: this.props.secondaryColor}}
                    className={importedStyles.backIcon}/>
            </IconButton>
        )

    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: this.props.primaryColor ? this.props.primaryColor : '#008EEF',
                },
                secondary: {
                    main: this.props.secondaryColor ? this.props.secondaryColor : '#000',
                },
                customBadge: {
                    backgroundColor: "blue",
                    color: "red"
                }
            },

        });
        return (
            <ThemeProvider theme={theme}>


                <Fade in mountOnEnter>
                    <AppBar position="fixed" className={importedStyles.root}
                            style={{backgroundImage: "linear-gradient(87deg, " + this.props.primaryColor + ", " + lighten(this.props.primaryColor, 20) + " 66%)"}}>
                        <Grid container className={importedStyles.gridContainerNavBar}>
                            <Grid item xs={1} className={importedStyles.gridItem}>
                                {this.renderBackButton()}
                            </Grid>
                            <Grid item xs={9} className={importedStyles.gridItem} style={{color: this.props.secondaryColor,whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                <Typography style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word', width: '100%'}}>{this.props.navbarTitle}</Typography>
                            </Grid>
                            <Grid item xs={1} className={importedStyles.gridItem}>
                                <IconButton onClick={this.handleCart} className={importedStyles.cartAccessor}>
                                    <Badge badgeContent={this.props.cart.length > 0 ? this.props.cart.length : '0'}
                                           style={{color: this.props.secondaryColor}}
                                        color={"secondary"}
                                    >
                                        <CartIcon style={{color: lighten(this.props.secondaryColor,20)}} />
                                    </Badge>
                                </IconButton>
                            </Grid>

                            {/* <div id={'languageIcon'}>
                        <img style={{height: 25, position: 'absolute', bottom: '0.5rem', right: '0.9rem'}}
                             src={require('../../../../rsc/img/espana.png')} alt={'bandera española'}/>
                    </div>*/}
                        </Grid>
                        {/*    <div className={importedStyles.cartAccessor}>
                    <Badge badgeContent={this.props.cart.length} classes={{badge: importedStyles.badgeColor}}>
                        <IconButton onClick={this.handleCart} style={{padding: '0'}}><CartIcon/></IconButton>
                    </Badge>
                </div>*/}
                        <Cart primaryColor={this.props.primaryColor} secondaryColor={this.props.secondaryColor}  cartOpened={this.state.cartOpened} onClose={this.handleCart}/>
                    </AppBar>
                </Fade>
            </ThemeProvider>
        );
    }
}

DigitalMenuNavBarMobile.propTypes = {
    classes: PropTypes.object,
    restaurantLogo: PropTypes.string.isRequired,
    hideBackButton: PropTypes.bool,
    navbarTitle: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleSectionSelect: PropTypes.func.isRequired,
    currentSection: PropTypes.any.isRequired,
};

const mapStateToProps = ({cartReducer, digitalMenuReducer}) => {
    return ({
        cart: cartReducer.cart,
        selectedSection: digitalMenuReducer.selectedSection,
        currentMenu: digitalMenuReducer.actualMenu,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(DigitalMenuNavBarMobile);

