import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import importedStylesSass from "./DetalleRestaurante.module.sass";
import Clipboard from "simple-react-clipboard";
import LocationIcon from '@material-ui/icons/LocationOn';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LockIcon from '@material-ui/icons/Lock';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GroupIcon from '@material-ui/icons/Group';
import PhotoIcon from '@material-ui/icons/Photo';
import FolderIcon from '@material-ui/icons/FolderOpen';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import LabelIcon from '@material-ui/icons/LabelImportant';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CloseIcon from '@material-ui/icons/Close';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import PhoneIcon from '@material-ui/icons/Phone';
import {Assignment, Category, EuroSymbol, EventAvailable, Warning} from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import DetailCard from "../components/DetailCard";
import Grid from "@material-ui/core/Grid/Grid";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import PeopleIcon from '@material-ui/icons/People';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import CancelIcon from '@material-ui/icons/Cancel';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {Pie, PieChart,} from 'recharts';
// import {Pie} from 'react-chartjs-2'
import Avatar from "@material-ui/core/Avatar/Avatar";
import FastFood from '@material-ui/icons/Fastfood';
import history from '../../../helpers/history';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import GoogleMapReact from 'google-map-react';
import TextField from "@material-ui/core/TextField/TextField";
import connect from "react-redux/es/connect/connect";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CardActions from "@material-ui/core/CardActions/CardActions";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import MiniDrawer from "../../../components/MiniDrawer/MiniDrawer";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import PlacesAutocomplete from "react-places-autocomplete";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import importedStyles from './DetalleRestauranteStyle';
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Snack from "../../../components/Snack/Snack";
import Fab from "@material-ui/core/es/Fab/Fab";
import Geocode from "react-geocode";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab'

import StatisticCardPie from "../../Statistics/StatisticCardPie";
import ColorPicker from "../../MenusManager/components/ColorPicker";
import Paper from "@material-ui/core/Paper";
import {detail_rte} from "../restaurantActions";


class DetalleRestaurante extends React.Component {

    state = {
        open: false,
        address: "",
        city: "",
        removeModal: false,
        removeImgModal: false,
        uploadErrorSnack: false,
        uploadAnimation: false,
        restaurant: {},
        idRestaurant: '',
        menus: {},
        allFeatures: {},
        allAssociations: {},
        features: {},
        featureChecks: {},
        featuresList: '',
        associations: {},
        associationsChecks: {},
        statisticsCountData: [],
        statisticsCountDataMorning: [],
        statisticsCountDataNight: [],
        associationsList: '',
        editingResume: false,
        editingMap: false,
        editingOtherData: false,
        editingFeatures: false,
        editingAssociations: false,
        editedRestaurantName: '',
        editedRestaurantEmail: '',
        editedRestaurantPhone: '',
        editedRestaurantWeb: '',
        editedRestaurantSchedule: '',
        editedRestaurantDescription: '',
        editedRestaurantCateringBase: '',
        editedRestaurantCapacity: '',
        editedRestaurantPrice: '',
        editedRestaurantCity: '',
        editedRestaurantAddress: '',
        editedRestaurantSecurityPin: '',
        img: null,
        logo: null,
        newLogo: undefined,
        newLogoPreview: '',
        urlPicToDelete: '',
        copySnack: false,
        sendingData: false,
        documentFile: [],
        restaurantCoordinates: null,
        showPIN: false,
        seeingStatistics: false,
        dishes: [],
        dish: "",

    };

    componentDidMount() {
        if (this.props && this.props.idRestaurant) {
            this.setState({gettingData: true});
            this.getRestaurant(this.props.idRestaurant);
            this.getCoordinatesFromAddress();
            // this.getAllFeatures();
            this.props.restaurantPermissions.menus && this.getMenusRestaurant(this.props.idRestaurant);
            // this.getRestaurantImages(this.props.idRestaurant);
            this.setState({idRestaurant: this.props.idRestaurant});
            this.getStatistics();
            this.getDishes();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        //Check if new restaurant is selected, then retrieve all restaurant data
        if (this.props.idRestaurant !== nextProps.idRestaurant) {
            //Activate loading animation flag
            this.setState({gettingData: true});
            this.getRestaurant(nextProps.idRestaurant);
            this.getCoordinatesFromAddress();
            this.getAllFeatures();
            nextProps.restaurantPermissions.menus && this.getMenusRestaurant(nextProps.idRestaurant);
            this.getRestaurantImages(nextProps.idRestaurant);
            //After all API calls set loading animation flag to false
            this.setState({idRestaurant: nextProps.idRestaurant});
        }
    }

    handleRemoveModal = () => {
        this.setState({removeModal: !this.state.removeModal});
    };
    handleChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };

    handleFeatureChange = (e) => {
        let featureChecks = this.state.featureChecks;
        featureChecks[e.target.value] = e.target.checked;
        this.setState({featureChecks});
    };
    handleAssociationChange = (e) => {
        let associationsChecks = this.state.associationsChecks;
        associationsChecks[e.target.value] = e.target.checked;
        this.setState({associationsChecks});
    };

    getRestaurant(id) {
        fetch(apiBaseUrl + 'restaurants/getRestaurant', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: id,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            let response = JSON.parse(recurso);
            if (!response.error) {
                let restaurant = response.restaurant;
                this.getAllAssociations(restaurant.associations);
                this.setState({
                    restaurant: restaurant,
                    address: restaurant.address,
                    city: restaurant.city,
                    logo: restaurant.logo,
                    associations: restaurant.associations,
                    gettingData: false
                });
            } else {
                throw new Error(response.errorMessage)
            }
        }).catch((error) => {
            console.error(error);
            this.setState({gettingData: false});
        });

        fetch(apiBaseUrl + 'restaurants/restaurantFeatures', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: id,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            this.setState({features: JSON.parse(recurso)});
            this.generateFeatureList();
        }).catch((error) => {
            console.error(error);
        });
    }


    getAllFeatures() {

        fetch(apiBaseUrl + 'features', {
                method: 'get',
                headers: {'Content-Type': 'application/json'},
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            const allFeatures = JSON.parse(recurso);
            const actualFeatures = this.state.features;
            let featureChecks = this.state.featureChecks;

            Object.keys(allFeatures).forEach((feature) => {
                let checked = false;
                Object.keys(actualFeatures).forEach((actualFeature) => {
                    if (actualFeatures[actualFeature].uuid === allFeatures[feature].uuid) {
                        checked = true;
                    }
                });
                featureChecks[allFeatures[feature].uuid] = checked;
            });
            this.setState({featureChecks, allFeatures: allFeatures});
        }).catch((error) => {
            console.error(error);
        });
    }

    getAllAssociations(actualAssociations) {

        fetch(apiBaseUrl + 'associations', {
                method: 'get',
                headers: {'Content-Type': 'application/json'},
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            const allAssociations = JSON.parse(recurso);
            let associationsChecks = this.state.associationsChecks;

            Object.keys(allAssociations).forEach((association) => {
                let checked = false;
                Object.keys(actualAssociations).forEach((actualAssociation) => {
                    if (actualAssociations[actualAssociation].uuid === allAssociations[association].uuid) {
                        checked = true;
                    }
                });
                associationsChecks[allAssociations[association].uuid] = checked;
            });
            this.setState({associationsChecks, allAssociations: allAssociations});
        }).catch((error) => {
            console.error(error);
        });
    }

    generateShortFeatureList() {

        let featuresList = [];
        let features = this.state.features;

        if (features.length > 0) {

            // if there is more than 5 features, then crop the array, if not just render normally
            // if there is more than 5 features, then crop the array, if not just render normally
            if (features.length > 5) {
                features = this.state.features.slice(0, 5);
            }

            Object.keys(features).forEach((feature) => {
                featuresList.push(
                    <ListItem key={feature}>
                        <ListItemIcon>
                            <img alt={features[feature].description}
                                 src={"/featureIcons/" + features[feature].logo + ".svg"}
                                 className={importedStylesSass.featureIcon}/>
                        </ListItemIcon>
                        <ListItemText inset primary={features[feature].name}/>
                    </ListItem>
                )
            });
        } else {
            featuresList.push(
                <ListItem key={0}>
                    <ListItemText primary={"Tu restaurante todavía no tiene ningún servicio"}/>
                </ListItem>
            )
        }


        return (
            <List>
                {featuresList}
            </List>
        )
    }

    generateFeatureList() {

        let featuresList = [];
        const features = this.state.features;

        if (features.length > 0) {
            Object.keys(features).forEach((feature) => {
                featuresList.push(
                    <ListItem key={feature}>
                        <ListItemIcon>
                            <img alt={features[feature].description}
                                 src={"/featureIcons/" + features[feature].logo + ".svg"}
                                 className={importedStylesSass.featureIcon}/>
                        </ListItemIcon>
                        <ListItemText inset primary={features[feature].name}/>
                    </ListItem>
                )
            });
        } else {
            featuresList.push(
                <ListItem key={0}>
                    <ListItemText primary={"Tu restaurante todavía no tiene ningún servicio"}/>
                </ListItem>
            )
        }


        return (<List>{featuresList}</List>)
    }

    generateAssociationsList() {

        let associationsList = [];
        const associations = this.state.associations;

        if (associations.length > 0) {

            Object.keys(associations).forEach((association) => {
                associationsList.push(
                    <ListItem key={association}>
                        <ListItemIcon>
                            <LabelIcon/>
                        </ListItemIcon>
                        <ListItemText inset primary={associations[association].name}/>
                    </ListItem>
                )
            });

        } else {
            associationsList.push(
                <ListItem key={0}>
                    <ListItemIcon>
                        <LabelIcon/>
                    </ListItemIcon>
                    <ListItemText inset primary={"Este restaurante todavía no pertenece a ninguna asociación"}/>
                </ListItem>
            )
        }

        return (<List>{associationsList}</List>)
    }

    editableFeatures() {

        const features = this.state.allFeatures;
        const featuresList = [];

        Object.keys(features).forEach((feature) => {
            let featureId = features[feature].uuid;
            featuresList.push(
                <ListItem key={feature}>
                    <ListItemIcon>
                        <LabelIcon/>
                    </ListItemIcon>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.featureChecks[featureId]}
                                onChange={this.handleFeatureChange}
                                value={'' + features[feature].uuid}
                            />
                        }
                        label={features[feature].name}
                    />
                </ListItem>
            )
        });

        return (
            <List key={0}>
                <form>
                    {featuresList}
                </form>
            </List>
        )
    }

    editableAssociations() {

        const associations = this.state.allAssociations;
        //const actualAssociations = this.state.associations;
        const associationsList = [];

        if (associations.length > 0) {
            Object.keys(associations).forEach((association) => {
                let associationId = associations[association].uuid;
                associationsList.push(
                    <ListItem key={association}>
                        <ListItemIcon>
                            <LabelIcon/>
                        </ListItemIcon>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.associationsChecks[associationId]}
                                    onChange={this.handleAssociationChange}
                                    value={'' + associations[association].uuid}
                                />
                            }
                            label={associations[association].name}
                        />
                    </ListItem>
                )
            });
        } else {
            associationsList.push(
                <ListItem key={0}>
                    <ListItemText primary="No hay asociaciones disponibles"/>
                </ListItem>
            );
        }

        return (
            <List>
                <form>
                    {associationsList}
                </form>
            </List>
        )

    }

    generateResume() {

        const restaurant = this.state.restaurant;
        const classes = this.props.classes;

        return (
            <div className={classes.resumeContainer}>
                <Avatar className={classes.restaurantLogo}
                        src={this.state.logo}
                />
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <TextFormatIcon/>
                        </ListItemIcon>
                        <ListItemText inset primary={restaurant.name} secondary="Nombre"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PhoneIcon/>
                        </ListItemIcon>
                        <ListItemText inset primary={restaurant.phone} secondary="Teléfono"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <EmailIcon/>
                        </ListItemIcon>
                        <ListItemText inset primary={restaurant.email} secondary="Email"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LanguageIcon/>
                        </ListItemIcon>
                        <ListItemText inset primary={restaurant.website} secondary="Web"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ScheduleIcon/>
                        </ListItemIcon>
                        <ListItemText
                            inset
                            primary={
                                <Button size={"small"} variant={"outlined"} onClick={() => history.push('/schedule')}>
                                    Ver horario <OpenInNewIcon style={{marginLeft: 5}}/>
                                </Button>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ReceiptIcon/>
                        </ListItemIcon>
                        <ListItemText
                            inset
                            primary={
                                <Button size={"small"} variant={"outlined"}
                                        onClick={() => history.push('/billingData')}>
                                    Facturación <OpenInNewIcon style={{marginLeft: 5}}/>
                                </Button>
                                /* Si tiene información de facturación, mostrar un boton para un Dialog para mostrar la información. Si no, botón para introducir la información  */
                            }
                        />
                    </ListItem>
                    {/*<ListItem>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <LockIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText inset primary={this.state.showPIN ? restaurant.securityPin : '****'}*/}
                    {/*                  secondary="PIN de seguridad"/>*/}
                    {/*    <ListItemSecondaryAction>*/}
                    {/*        <Tooltip title={'Ver PIN'} placement={"top"}>*/}
                    {/*            <IconButton*/}
                    {/*                onMouseDown={() => this.setState({showPIN: true})}*/}
                    {/*                onTouchStart={() => this.setState({showPIN: true})}*/}
                    {/*                onMouseUp={() => this.setState({showPIN: false})}*/}
                    {/*                onMouseLeave={() => this.setState({showPIN: false})}*/}
                    {/*                onTouchEnd={() => this.setState({showPIN: false})}*/}
                    {/*            >*/}
                    {/*                <EyeIcon/>*/}
                    {/*            </IconButton>*/}
                    {/*        </Tooltip>*/}
                    {/*    </ListItemSecondaryAction>*/}
                    {/*</ListItem>*/}

                </List>
            </div>
        )
    }

    editableResume() {

        const restaurant = this.state.restaurant;
        const classes = this.props.classes;
        return (
            <List>
                <form>
                    <ListItem className={classes.logoContainer}>
                        {this.renderImageUploader()}
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <TextFormatIcon/>
                        </ListItemIcon>
                        <TextField
                            autoFocus
                            name='editedRestaurantName'
                            label="Nombre"
                            defaultValue={restaurant.name}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth={true}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PhoneIcon/>
                        </ListItemIcon>
                        <TextField
                            name='editedRestaurantPhone'
                            label="Teléfono"
                            defaultValue={restaurant.phone}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth={true}
                            variant="outlined"
                            type="number"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <EmailIcon/>
                        </ListItemIcon>
                        <TextField
                            name='editedRestaurantEmail'
                            label="Correo"
                            defaultValue={restaurant.email}
                            fullWidth={true}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LanguageIcon/>
                        </ListItemIcon>
                        <TextField
                            name='editedRestaurantWeb'
                            label="Web"
                            defaultValue={restaurant.website}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth={true}
                            variant="outlined"
                            inputProps={{maxLength: 500}}
                        />
                    </ListItem>
                    {/*<ListItem>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <LockIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <TextField*/}
                    {/*        name='editedRestaurantSecurityPin'*/}
                    {/*        label="PIN de seguridad"*/}
                    {/*        defaultValue={restaurant.securityPin}*/}
                    {/*        onChange={this.handleChange}*/}
                    {/*        type={'number'}*/}
                    {/*        margin="normal"*/}
                    {/*        fullWidth={true}*/}
                    {/*        variant="outlined"*/}
                    {/*        InputProps={{maxLength: 4}}*/}
                    {/*    />*/}
                    {/*</ListItem>*/}
                </form>
            </List>
        )
    }

    generateOtherData() {

        const restaurant = this.state.restaurant;

        return (
            <List>
                <ListItem>
                    <ListItemIcon>
                        <LabelIcon/>
                    </ListItemIcon>
                    <ListItemText inset primary={restaurant.description} secondary='Descripción del negocio'/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText inset primary={restaurant.capacity} secondary='Aforo'/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <EuroSymbol/>
                    </ListItemIcon>
                    <ListItemText inset primary={restaurant.price ? restaurant.price + '€' : 0 + '€'}
                                  secondary='Precio medio'/>
                </ListItem>
                {restaurant.cateringBase ? (
                    <ListItem>
                        <ListItemIcon>
                            <EuroSymbol/>
                        </ListItemIcon>
                        <ListItemText inset primary={restaurant.cateringBase + '€'} secondary='Precio base catering'/>
                    </ListItem>
                ) : null}
            </List>
        )
    }

    editableOtherData() {

        const restaurant = this.state.restaurant;
        return (
            <List>
                <form>
                    <ListItem>
                        <ListItemIcon>
                            <TextFormatIcon/>
                        </ListItemIcon>
                        <TextField
                            name='editedRestaurantDescription'
                            label="Descripción"
                            defaultValue={restaurant.description}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                            inputProps={{maxLength: 150}}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <TextField
                            name='editedRestaurantCapacity'
                            label="Aforo"
                            defaultValue={restaurant.capacity}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                            type="number"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <EuroSymbol/>
                        </ListItemIcon>
                        <TextField
                            label="Precio medio"
                            // disabled={true}
                            name='editedRestaurantPrice'
                            defaultValue={restaurant.price ? restaurant.price : 0}
                            margin="normal"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth={true}
                            type="number"
                            // helperText={'Este dato se genera automáticamente con la media de precios de tu carta'}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <EuroSymbol/>
                        </ListItemIcon>
                        <TextField
                            name='editedRestaurantCateringBase'
                            label="Precio base catering"
                            defaultValue={restaurant.cateringBase}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth={true}
                            variant="outlined"
                            helperText="Opcional"
                            type="number"
                        />
                    </ListItem>
                </form>
            </List>
        )
    }

    getMenusRestaurant(idRestaurant) {
        fetch(apiBaseUrl + 'restaurants/getMenus', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({menus: response.menus});
            } else {
                throw new Error('Error: ' + response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    generateMenus() {
        const classes = this.props.classes;
        const menus = this.state.menus;
        let menuCards = [];
        let sectionsString = '';

        if (menus.length > 0) {
            Object.keys(menus).forEach((menu) => {

                let sections = menus[menu].menu_sections;
                Object.keys(sections).forEach((section) => {
                    sectionsString += sections[section].name + ', ';
                });

                menuCards.push(
                    <Card className={importedStylesSass.menuCard} key={menu} onClick={() => {
                        this.props.dispatch(detail_rte(this.state.restaurant.uuid, this.state.restaurant.name, this.state.restaurant.logo, this.state.restaurant.restaurants_role, this.state.restaurant.url_slug));
                        history.push('/menus')}}
                          style={{cursor: 'pointer'}}>
                        <CardHeader
                            title={menus[menu].name}
                            subheader={sectionsString + '...'}
                        />
                        <CardActions>
                            <IconButton
                                onClick={() => this.setState({copySnack: true, selectedMenuId: menus[menu].uuid})}>
                                <LinkIcon/>
                            </IconButton>
                        </CardActions>
                    </Card>
                );
                sectionsString = '';
            });
        } else {
            menuCards.push(
                <List key={0}>
                    <ListItem key={0} button onClick={() => history.push('/menus')}>
                        <ListItemText primary={"Añade tu primer menú"}/>
                        <ListItemIcon>
                            <AddIcon/>
                        </ListItemIcon>
                    </ListItem>
                </List>
            );
        }
        return (
            <div className={menus.length > 0 ? classes.menuContainer : classes.noImgContainer}>
                {menuCards}
            </div>
        );
    }

    getCoordinatesFromAddress() {
        let address = this.state.restaurant.address + ', ' + this.state.restaurant.city;


        let coordinates = {
            latFromAddress: null,
            lngFromAddress: null,
        };

        Geocode.setApiKey("AIzaSyBDDhsUN4N2z7WHXDPhS12ap_-FH3DP8tc");
        Geocode.fromAddress(this.state.address + this.state.city).then(
            response => {
                const {lat, lng} = response.results[0].geometry.location;
                coordinates.latFromAddress = lat;
                coordinates.lngFromAddress = lng;
                this.setState({
                    restaurantCoordinates: {
                        latFromAddress: lat,
                        lngFromAddress: lng,
                    }
                });
            },
            error => {
                // console.error('Map error: ', error);
            }
        );

    }

    getMap() {
        const {classes} = this.props;

        //If there is no restaurant coordinates, then call to the method who generate it
        if (!this.state.restaurantCoordinates) {
            this.getCoordinatesFromAddress();
        }

        return (
            <React.Fragment>
                <div style={{width: '100%', height: 384}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: 'AIzaSyBDDhsUN4N2z7WHXDPhS12ap_-FH3DP8tc'}}
                        center={{
                            lat: this.state.restaurantCoordinates ? this.state.restaurantCoordinates.latFromAddress : 39.934671,
                            lng: this.state.restaurantCoordinates ? this.state.restaurantCoordinates.lngFromAddress : -0.097785
                        }}
                        defaultCenter={{
                            lat: 39.934671,
                            lng: -0.097785
                        }}
                        defaultZoom={16}
                    >
                        <LocationIcon
                            style={{marginTop: -32, marginLeft: -16}}
                            lat={this.state.restaurantCoordinates ? this.state.restaurantCoordinates.latFromAddress : 39.934671}
                            lng={this.state.restaurantCoordinates ? this.state.restaurantCoordinates.lngFromAddress : -0.097785}
                            color={'primary'}
                        />
                    </GoogleMapReact>
                </div>
                <div className={classes.mapList}>
                    <ListItem>
                        <ListItemIcon>
                            <LocationCityIcon/>
                        </ListItemIcon>
                        <ListItemText inset primary={this.state.restaurant.city} secondary='Ciudad'/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <MapIcon/>
                        </ListItemIcon>
                        <ListItemText inset primary={this.state.restaurant.address} secondary='Dirección'/>
                    </ListItem>
                </div>
            </React.Fragment>
        );
        // }

    }

    handleCityChange = city => {
        let comaPosition = city.indexOf(',');
        if (comaPosition !== -1) {
            city = city.substring(0, comaPosition);
        }
        this.setState({editedRestaurantCity: city});
    };

    renderAutocompleteCityInput() {

        const {classes} = this.props;

        const searchOptions = {
            componentRestrictions: {country: 'es'},
            types: ["(cities)"]
        };

        return (
            <PlacesAutocomplete
                defaultValue={this.state.restaurant.city}
                value={this.state.editedRestaurantCity}
                onChange={this.handleCityChange}
                searchOptions={searchOptions}
            >
                {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <div className={classes.autocompleteCityInput}>
                        <TextField
                            variant={"outlined"}
                            margin="normal"
                            name={"city"}
                            label="Ciudad"
                            type="text"
                            fullWidth
                            {...getInputProps({
                                autoComplete: "nope",
                                maxLength: 50
                            })}
                        />

                        <div>
                            {loading && <div>Generando sugerencias...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? {backgroundColor: '#c4c4c4', cursor: 'pointer', marginBottom: 5}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer', marginBottom: 5};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        )
    }

    editableMap() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <div style={{width: '100%', height: 384}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: 'AIzaSyBDDhsUN4N2z7WHXDPhS12ap_-FH3DP8tc'}}
                        defaultCenter={{
                            lat: 39.934671,
                            lng: -0.097785
                        }}
                        defaultZoom={20}
                    >
                        <LocationIcon
                            style={{marginTop: -32, marginLeft: -16}}
                            lat={39.934671}
                            lng={-0.097785}
                            color={'primary'}
                        />
                    </GoogleMapReact>
                </div>
                <div className={classes.mapList}>
                    <ListItem>
                        <ListItemIcon>
                            <LocationCityIcon/>
                        </ListItemIcon>
                        {this.renderAutocompleteCityInput()}
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <MapIcon/>
                        </ListItemIcon>
                        <TextField
                            name='editedRestaurantAddress'
                            label="Dirección"
                            defaultValue={this.state.restaurant.address}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                            inputProps={{maxLength: 50}}
                        />
                    </ListItem>
                </div>
            </React.Fragment>
        );
    }

    saveEditedMap() {

        let editedData = {
            'uuidRestaurant': this.props.idRestaurant,
            'city': this.state.editedRestaurantCity.length > 0 ? this.state.editedRestaurantCity : this.state.restaurant.city,
            'address': this.state.editedRestaurantAddress.length > 0 ? this.state.editedRestaurantAddress : this.state.restaurant.address,
        };
        this.updateRestaurant(editedData).then(() => {
            if (this.state.updateError) {
            } else {
                //si no hay errores dejamos de editar
                this.setState({editingMap: false})
            }
        });

    }

    deleteRte(idRestaurant) {
        fetch(apiBaseUrl + 'restaurants/deleteRestaurant', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: idRestaurant
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.removed) {
                this.props.dispatch(showSnack('Eliminado con éxito', snackTypes.success));
            } else {
                throw new Error();
            }
        }).catch((error) => {
            console.error(error);
            this.props.dispatch(showSnack('Ha ocurrido un error, no se ha podido eliminar', snackTypes.error));
        });
    }

    renderActions(functionClick) {
        return (
            <IconButton aria-label="Editar" onClick={functionClick}>
                <EditIcon/>
            </IconButton>
        )
    }

    renderEditingActions(functionCancel, functionConfirmEdit) {
        const {classes} = this.props;

        return (
            <div>
                <IconButton aria-label="Cancelar" onClick={functionCancel}>
                    <CancelIcon/>
                </IconButton>
                <Button variant="contained" color="primary" aria-label="Confirmar" onClick={functionConfirmEdit}
                        disabled={this.state.sendingData}>
                    Confirmar
                    {this.state.sendingData && <CircularProgress size={25} className={classes.sendingDataAnimation}/>}
                </Button>
            </div>
        )
    }

    saveEditedResume() {

        let editedResume = {};
        let edited = false;
        const id = this.props.idRestaurant;

        if (this.state.newLogo) {
            this.uploadLogo(this.props.idRestaurant);
        }

        // Comprobaciones para ver si se ha modificado el campo "x"
        if (this.state.editedRestaurantName) {
            editedResume.name = this.state.editedRestaurantName;
            edited = true;
        }
        if (this.state.editedRestaurantPhone) {
            editedResume.phone = this.state.editedRestaurantPhone;
            edited = true;
        }
        if (this.state.editedRestaurantEmail) {
            editedResume.email = this.state.editedRestaurantEmail;
            edited = true;
        }
        if (this.state.editedRestaurantName) {
            editedResume.name = this.state.editedRestaurantName;
            edited = true;
        }
        if (this.state.editedRestaurantWeb) {
            editedResume.website = this.state.editedRestaurantWeb;
            edited = true;
        }
        if (this.state.editedRestaurantSecurityPin) {
            editedResume.securityPin = this.state.editedRestaurantSecurityPin;
            edited = true;
        }
        // Comprobamos si algún campo ha sido editado y en consecuencia lanzamos la acción de update o no.
        if (edited) {
            //asignamos el id del restaurante a modificar
            editedResume.uuidRestaurant = id;
            this.updateRestaurant(editedResume).then(() => {
                if (this.state.updateError) {
                } else {
                    //si no hay errores dejamos de editar el resume
                    this.setState({editingResume: false})
                }
            });
        } else {
            this.setState({editingResume: false})
        }
    }

    saveEditedOtherData() {

        let editedOtherData = {};
        let edited = false;
        const id = this.props.idRestaurant;

        // Comprobaciones para ver si se ha modificado el campo "x"
        if (this.state.editedRestaurantDescription) {
            editedOtherData.description = this.state.editedRestaurantDescription;
            edited = true;
        }
        if (this.state.editedRestaurantAddress) {
            editedOtherData.address = this.state.editedRestaurantAddress;
            edited = true;
        }
        if (this.state.editedRestaurantCapacity) {
            editedOtherData.capacity = this.state.editedRestaurantCapacity;
            edited = true;
        }
        if (this.state.editedRestaurantPrice) {
            editedOtherData.price = this.state.editedRestaurantPrice;
            edited = true;
        }
        if (this.state.editedRestaurantCity) {
            editedOtherData.city = this.state.editedRestaurantCity;
            edited = true;
        }
        if (this.state.editedRestaurantCateringBase) {
            editedOtherData.cateringBase = this.state.editedRestaurantCateringBase;
            edited = true;
        }

        // Comprobamos si algún campo ha sido editado y en consecuencia lanzamos la acción de update o no.
        if (edited) {
            //asignamos el id del restaurante a modificar
            editedOtherData.uuidRestaurant = id;
            this.updateRestaurant(editedOtherData).then(() => {
                if (this.state.updateError) {
                } else {
                    //si no hay errores dejamos de editar
                    this.setState({editingOther: false})
                }
            });
        } else {
            this.setState({editingOther: false});
        }
    }

    saveEditedFeatures() {

        let featureChecks = this.state.featureChecks;
        const id = this.props.idRestaurant;
        let newFeatures = [];
        let featuresToRemove = [];

        Object.keys(featureChecks).forEach((feature) => {
            if (featureChecks[feature]) {
                newFeatures.push(feature);
            } else {
                featuresToRemove.push(feature);
            }
        });

        let editedFeatures = {
            'uuidRestaurant': id,
            'features': newFeatures,
            'featuresToRemove': featuresToRemove
        };
        this.updateFeatures(editedFeatures).then(() => {
            if (this.state.updateError) {
            } else {
                //si no hay errores dejamos de editar
                this.setState({editingFeatures: false})
            }
        });

    }

    saveEditedAssociations() {
        let associationsChecks = this.state.associationsChecks;
        const id = this.props.idRestaurant;
        let newAssociations = [];
        let associationsToRemove = [];

        Object.keys(associationsChecks).forEach((association) => {
            if (associationsChecks[association]) {
                newAssociations.push(association);
            } else {
                associationsToRemove.push(association);
            }
        });

        let editedAssociations = {
            'uuidRestaurant': id,
            'associations': newAssociations,
            'associationsToRemove': associationsToRemove
        };
        this.updateAssociations(editedAssociations).then(() => {
            if (this.state.updateError) {
            } else {
                //si no hay errores dejamos de editar
                this.setState({editingAssociations: false})
            }
        });
    }

    getDishes = () => {
        fetch(apiBaseUrl + 'restaurants/getDishes', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    dishes: response.dishes,
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    getStatistics = () => {
        // this.setState({gettingData: true});
        // this.setState({gettingStatistics: true});


        fetch(apiBaseUrl + 'restaurants/statistics/dish/getStatisticsCountByDish', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,

            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    statisticsCountData: response.data,
                    gettingData: false,

                }, () => {

                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({
                sendingData: false,
                gettingData: false,
            });
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    }

    updateFeatures(editedFeatures) {
        this.setState({sendingData: true});
        return new Promise((resolve, reject) => {
            fetch(apiBaseUrl + 'restaurant_feature/updateRestaurantFeatures', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(editedFeatures),
                }
            ).then((responseJSON) => {
                return responseJSON.text();
            }).then((response) => {
                response = JSON.parse(response);
                if (response.error) {
                    throw new Error(response.error)
                } else {
                    this.setState({features: response.updatedFeatures, sendingData: false});
                    this.props.dispatch(showSnack('Datos editados con éxito', snackTypes.success));
                    resolve();
                }
            }).catch((error) => {
                this.setState({sendingData: false});
                console.error('Error: ' + error);
                reject();
                this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
            });
        });

    }

    updateAssociations(editedAssociations) {
        this.setState({sendingData: true});
        return new Promise((resolve, reject) => {
            fetch(apiBaseUrl + 'restaurant_association/updateRestaurantAssociations', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(editedAssociations),
                }
            ).then((responseJSON) => {
                return responseJSON.text();
            }).then((response) => {
                response = JSON.parse(response);
                this.setState({
                    associations: response,
                    sendingData: false,
                    editingAssociations: false
                });
                this.props.dispatch(showSnack('Datos editados con éxito', snackTypes.success));
            }).catch((error) => {
                this.setState({sendingData: false, editingAssociations: false});
                console.error('Error: ' + error);
                this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
            });
        });
    }

    updateRestaurant(newData) {

        this.setState({sendingData: true});
        return new Promise((resolve, reject) => {
            fetch(apiBaseUrl + 'restaurants/updateRestaurant', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(newData),
                }
            ).then((responseJSON) => {
                return responseJSON.text();
            }).then((response) => {

                response = JSON.parse(response);
                if (response.updateError) {
                    this.setState({updateError: true, sendingData: false});
                    resolve();
                } else {
                    this.setState({
                        restaurant: response,
                        sendingData: false
                    });
                    resolve();
                    this.props.dispatch(showSnack('Datos editados con éxito', snackTypes.success));
                }
            }).catch((error) => {
                this.setState({sendingData: false});
                console.error('Error: ' + error);
                reject();
                this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
            });
        });

    }

    getRestaurantImages(uuidRestaurant) {

        let form = new FormData();
        form.append("uuidRestaurant", uuidRestaurant);

        fetch(apiBaseUrl + 'restaurants/getRestaurantImages', {
                method: 'post',
                body: form
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                if (response.imgUrls.length > 0) {
                    this.setState({imgUrls: response.imgUrls});
                } else {
                    this.setState({imgUrls: []});
                }
            } else {
                this.setState({imgUrls: []});
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
        });
    }


    generateStatistics() {
        const classes = this.props.classes;


        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={importedStylesSass.statisticsGridItem}>
                        <Card className={classes.statisticsCard}>
                            <StatisticCardPie
                                reduced={true}
                                emptyText="Aún no se han recogido datos de tus cartas"
                                showText="null" title="Platos más vistos"
                                statistics={this.state.statisticsCountData}/>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );


    }

    generatePicsRte() {
        const classes = this.props.classes;
        const images = this.state.imgUrls;
        const imageCollection = [];

        if (images && images.length > 0) {

            Object.keys(images).forEach((image) => {
                imageCollection.unshift(
                    <Card className={classes.imageCard} key={image}>
                        <CardContent>
                            <img src={images[image].image} className={classes.img} alt=' '/>
                        </CardContent>
                        <CardActions className={classes.imageCardActions}>
                            <Button
                                size={"small"}
                                variant={"contained"}
                                color={"secondary"}
                                onClick={() => this.setState({
                                    removeImgModal: true,
                                    urlPicToDelete: images[image].image
                                })}
                            >
                                <DeleteIcon className={classes.iconLeft}/> Eliminar
                            </Button>
                        </CardActions>
                    </Card>
                );
            });
            return (
                <div className={classes.imgContainer}>
                    {imageCollection}
                </div>
            );
        } else {
            imageCollection.unshift(
                <List key={0}>
                    <ListItem key={0}>
                        <ListItemIcon>
                            <LabelIcon/>
                        </ListItemIcon>
                        <ListItemText inset primary={"Aún no hay imágenes"}/>
                    </ListItem>
                </List>
            );
            return (
                <div className={classes.noImgContainer}>
                    {imageCollection}
                </div>
            );
        }


    }

    uploadImage(uuidRestaurant) {

        let form = new FormData();
        form.append('img', this.state.img);
        form.append('uuidRestaurant', uuidRestaurant);

        this.setState({uploadAnimation: true});

        fetch(apiBaseUrl + 'restaurants/saveRestaurantImageToS3', {
            method: 'POST',
            body: form
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    imgUrls: response.images,
                    addingImage: false,
                    uploadAnimation: false,
                    img: null
                });
                this.props.dispatch(showSnack('Imagen subida con éxito', snackTypes.success));
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
            this.setState({uploadAnimation: false});
            console.error('Error: ' + JSON.stringify(error));
        });
    };

    deletePic(picUrl) {
        let form = new FormData();
        form.append('img', picUrl);
        form.append('uuidRestaurant', this.props.idRestaurant);
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'restaurants/deleteRestaurantImage', {
            method: 'POST',
            body: form
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    imgUrls: response.images,
                    deleteConfirmationSnack: true,
                    removeImgModal: false,
                    sendingData: false
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({deleteErrorSnack: true, sendingData: false});
            console.error('Error: ' + JSON.stringify(error));
        });


    }


    renderAddingImage(handleImgFunction, uploadFunction, cancelFunction) {

        const {classes} = this.props;
        let uploadedImageName = '';
        if (this.state.img) {
            let fileName = this.state.img.name;
            uploadedImageName = fileName.substring(0, 6) + '... .' + fileName.substr((fileName.lastIndexOf('.') + 1));
        }

        return (
            <div className={classes.addingImageContainer}>
                <div className={classes.selectFileBox}>
                    <Button
                        variant="contained"
                        component="label"
                        size={"small"}
                    >
                        <FolderIcon className={classes.iconLeft}/>
                        Seleccionar Archivo
                        <input
                            type="file"
                            accept="image/*"
                            id='documentFile'
                            onChange={(e) => this.handleImg(e)}
                            style={{display: "none"}}
                        />
                    </Button>
                    {this.state.img &&
                    <List>
                        <ListItem className={classes.iconLeft}>
                            <ListItemAvatar>
                                <PhotoIcon/>
                            </ListItemAvatar>
                            <ListItemText primary={uploadedImageName}/>
                            <ListItemSecondaryAction className={classes.iconRight}>
                                <IconButton onClick={() => this.setState({img: null})}>
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    }
                </div>
                <div>
                    <Button variant="text" color="secondary" aria-label="Cancelar" onClick={cancelFunction}
                            disabled={this.state.uploadAnimation}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" aria-label="Confirmar" onClick={uploadFunction}
                            disabled={this.state.uploadAnimation || !this.state.img}>
                        Confirmar
                        {this.state.uploadAnimation &&
                        <CircularProgress size={25} className={classes.chargingAnimation}/>}
                    </Button>
                </div>
            </div>
        )
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handleFileChange = (event) => {

        let file = event.target.files[0];

        this.getBase64(file).then((data) => {
            let base64File = data;
            let fileObject = {
                name: file.name,
                base64: base64File
            };

            let newDocumentFiles = this.state.documentFile;
            newDocumentFiles.push(fileObject);
            this.setState({
                documentFiles: newDocumentFiles,
            });
            //clean file input value
            document.getElementById("documentFile").value = "";
        });
    };


    renderAddImageButton(toggleAddFunction) {
        return (
            <div>
                <Button variant="contained" color="primary" aria-label="addImg" onClick={toggleAddFunction}>
                    Añadir imagen
                </Button>
            </div>
        )
    }

    renderSeeStatisticsButton(toggleSeeingFunction) {
        return (
            <div>
                <Button variant="contained" color="primary" aria-label="addImg" onClick={toggleSeeingFunction}>
                    Ver estadísticas
                </Button>
            </div>
        )
    }


    handleImg(e) {
        let file = e.target.files[0];
        if (file) {
            this.setState({img: file});
        }
    };


    getRestaurantLogo(id) {

        let form = new FormData();
        form.append("idRestaurant", id);

        fetch(apiBaseUrl + 'restaurants/getRestaurantLogo', {
                method: 'post',
                body: form
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            recurso = JSON.parse(recurso);
            this.setState({logo: recurso.logo});
        }).catch((error) => {
            console.error(error);
        });
    }

    uploadLogo(idRestaurant) {

        let form = new FormData();
        form.append("img", this.state.newLogo);
        form.append("uuidRestaurant", idRestaurant);

        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'restaurants/saveRestaurantLogoToS3', {
                method: 'post',
                body: form
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({logo: response.image, sendingData: false});
                this.props.dispatch(showSnack('Imagen subida con éxito', snackTypes.success));
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    }

    handleLogo(e) {
        let file = e.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.onload = (ev) => {
                this.setState({newLogoPreview: ev.target.result});
            };
            reader.readAsDataURL(file);
            this.setState({newLogo: file});
        }
    };

    renderImageUploader() {
        const {classes} = this.props;
        let newLogoName = '';
        let avatar = (
            <Avatar
                className={classes.avatar}
                src={this.state.logo}
            />
        );
        if (this.state.newLogo) {
            let fileName = this.state.newLogo.name;
            newLogoName = fileName.substring(0, 6) + '... .' + fileName.substr((fileName.lastIndexOf('.') + 1));
            avatar = (
                <Avatar
                    className={classes.avatar}
                    src={this.state.newLogoPreview}
                />
            );
        }
        return (
            <React.Fragment>
                <div className={classes.selectFileBox}>
                    {avatar}
                    <div className={classes.selectFileActions}>
                        {this.state.newLogo &&
                        <List>
                            <ListItem className={classes.iconLeft}>
                                <ListItemAvatar>
                                    <PhotoIcon/>
                                </ListItemAvatar>
                                <ListItemText primary={newLogoName}/>
                                <ListItemSecondaryAction className={classes.iconRight}>
                                    <IconButton onClick={() => this.setState({newLogo: undefined})}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                        }
                        <Button
                            variant="contained"
                            component="label"
                            size={"small"}
                            className={classes.fileUploadButton}
                        >
                            <FolderIcon className={classes.iconLeft}/>
                            Cambiar Logo
                            <input
                                type="file"
                                accept="image/*"
                                id='documentFile'
                                onChange={(e) => this.handleLogo(e)}
                                style={{display: "none"}}
                            />
                        </Button>
                    </div>
                </div>

            </React.Fragment>
        )
    }


    render() {
        const classes = this.props.classes;
        const {restaurant} = this.state;
        const featuresList = this.generateFeatureList();
        const shortFeaturesList = this.generateShortFeatureList();
        const resume = this.generateResume();
        const editResume = this.editableResume();
        const editOtherData = this.editableOtherData();
        const editFeatures = this.editableFeatures();
        const editAssociations = this.editableAssociations();
        const statistics = this.generateStatistics();
        const pics = this.generatePicsRte();
        const menus = this.props.restaurantPermissions.menus ? this.generateMenus() : [];
        const associationsList = this.generateAssociationsList();
        const map = this.getMap();
        const editMap = this.editableMap();
        const otherData = this.generateOtherData();
        const iframe = '<iframe src="' + apiBaseUrl + 'menus/getMenu/' + this.state.selectedMenuId + '"/>';

        return (
            <div id='root2' className={classes.root}>
                {/* <NavBar iconLeft={iconLeft} title={restaurant.name}/> */}
                <MiniDrawer title={restaurant.name}/>
                <Grid container className={classes.content}>
                    {
                        this.state.gettingData ?
                            <Grid item xs={12} className={classes.chargingAnimationContainer}>
                                <CircularProgress size={65}/>
                            </Grid>
                            :
                            <React.Fragment>
                                <Grid item xs={12} container spacing={16}>
                                    <Grid item xs={12} md={6}>
                                        {this.state.editingResume ?
                                            <DetailCard title="Editar resumen"
                                                        avatar={<ListAltIcon/>}
                                                        data={editResume}
                                                        actions={this.renderEditingActions(
                                                            () => this.setState({editingResume: false, newLogo: null}),
                                                            () => this.saveEditedResume(),
                                                        )}
                                            />
                                            :
                                            <DetailCard title="Resumen"
                                                        avatar={<ListAltIcon/>}
                                                        data={resume}
                                                        actions={this.renderActions(() => this.setState({editingResume: true}))}
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {this.state.editingMap ?
                                            <DetailCard
                                                title="Ubicación"
                                                avatar={<LocationIcon/>}
                                                data={editMap}
                                                centered={true}
                                                actions={this.renderEditingActions(
                                                    () => this.setState({editingMap: false}),
                                                    () => this.saveEditedMap()
                                                )}
                                            />
                                            :
                                            <DetailCard
                                                title="Ubicación"
                                                avatar={<LocationIcon/>}
                                                data={map}
                                                centered={true}
                                                actions={this.renderActions(() => this.setState({editingMap: true}))}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={16}>
                                    <Grid item xs={12}>
                                        <DetailCard
                                            title="Estadísticas (beta)"
                                            avatar={<EqualizerIcon/>}
                                            data={this.state.gettingData ? <CircularProgress/> : statistics}
                                            centered={false}
                                            actions={
                                                this.renderSeeStatisticsButton(() => history.push('/statistics'))
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {/*<Grid item xs={12} container spacing={16}>*/}
                                {/*    <Grid item xs={12}>*/}
                                {/*        <DetailCard*/}
                                {/*            title="Fotos"*/}
                                {/*            avatar={<PhotoCameraIcon/>}*/}
                                {/*            data={pics}*/}
                                {/*            centered={false}*/}
                                {/*            actions={*/}
                                {/*                this.state.addingImage ?*/}
                                {/*                    this.renderAddingImage(*/}
                                {/*                        (e) => this.handleImg(e),*/}
                                {/*                        () => this.uploadImage(this.props.idRestaurant),*/}
                                {/*                        () => this.setState({addingImage: false})*/}
                                {/*                    )*/}
                                {/*                    :*/}
                                {/*                    this.renderAddImageButton(() => this.setState({addingImage: true}))*/}
                                {/*            }*/}
                                {/*        />*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                                {this.props.restaurantPermissions.menus &&
                                <Grid item xs={12} container spacing={16}>
                                    <Grid item xs={12}>
                                        <DetailCard
                                            title="Cartas"
                                            avatar={<FastFood/>}
                                            data={menus}
                                            actions={this.renderActions(() => {
                                                history.push('/menus');})}
                                        />
                                    </Grid>
                                </Grid>
                                }
                                <Grid item xs={12} container spacing={16}>
                                    {/*<Grid item xs={12} md={6}>*/}
                                    {/*    {this.state.editingFeatures ?*/}

                                    {/*        <DetailCard*/}
                                    {/*            title="Editar servicios"*/}
                                    {/*            avatar={<RoomServiceIcon/>}*/}
                                    {/*            data={*/}
                                    {/*                <Paper className={importedStylesSass.paperList}>*/}
                                    {/*                    {editFeatures}*/}
                                    {/*                </Paper>*/}
                                    {/*            }*/}
                                    {/*            actions={this.renderEditingActions(*/}
                                    {/*                () => this.setState({editingFeatures: false}),*/}
                                    {/*                () => this.saveEditedFeatures()*/}
                                    {/*            )}*/}
                                    {/*        />*/}

                                    {/*        :*/}
                                    {/*        <DetailCard*/}
                                    {/*            title="Servicios"*/}
                                    {/*            avatar={<RoomServiceIcon/>}*/}
                                    {/*            data={shortFeaturesList}*/}
                                    {/*            collapsedContent={featuresList}*/}
                                    {/*            collapsableCard={this.state.features.length > 5}*/}
                                    {/*            actions={this.renderActions(() => this.setState({editingFeatures: true}))}*/}
                                    {/*        />*/}
                                    {/*    }*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={12} md={6}>
                                        <ColorPicker withDemo/>
                                        {}
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        {this.state.editingOther ?
                                            <DetailCard title="Editar otros datos"
                                                        avatar={<ListAltIcon/>}
                                                        data={editOtherData}
                                                        actions={this.renderEditingActions(
                                                            () => this.setState({editingOther: false}),
                                                            () => this.saveEditedOtherData()
                                                        )}
                                            />
                                            :
                                            <DetailCard title="Otros Datos"
                                                        avatar={<ListAltIcon/>}
                                                        data={otherData}
                                                        actions={this.renderActions(() => this.setState({editingOther: true}))}
                                            />
                                        }
                                    </Grid>
                                    {(this.props.restaurantPermissions.reservations || this.props.restaurantPermissions.polls || this.props.restaurantPermissions.offers)
                                    &&
                                    <Grid item xs={12} md={6}>
                                        <DetailCard
                                            title="Más"
                                            avatar={<Category/>}
                                            data={<div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                height: 249
                                            }}>
                                                {this.props.restaurantPermissions.reservations &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <EventAvailable/>
                                                    <Button
                                                        onClick={() => history.push('/reservations')}>Reservas</Button>
                                                </div>
                                                }
                                                {this.props.restaurantPermissions.polls &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <Assignment/>
                                                    <Button
                                                        onClick={() => history.push('/polls')}>Encuestas</Button>
                                                </div>
                                                }
                                                {this.props.restaurantPermissions.offers &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <EuroSymbol/>
                                                    <Button onClick={() => history.push('/offers')}>Ofertas</Button>
                                                </div>
                                                }
                                            </div>
                                            }
                                        />
                                    </Grid>
                                    }

                                </Grid>
                                <Grid item xs={12} container spacing={16}>
                                    <Grid item xs={12}>
                                        <Card className={classes.deleteRestaurantBox}>
                                            <CardHeader
                                                title='¡Zona peligrosa!'
                                                titleTypographyProps={{color: 'secondary'}}
                                                avatar={<Warning color='secondary'/>}
                                            />
                                            <CardContent className={classes.deleteRestaurantCardContent}>
                                                <Fab color='secondary' variant="extended"
                                                     onClick={this.handleRemoveModal}>
                                                    <DeleteIcon className={classes.iconLeft}/>
                                                    Eliminar restaurante
                                                </Fab>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                    }

                    {/* UPLOAD CONFIRMATION SNACKBAR */}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.uploadConfirmationSnack}
                        autoHideDuration={2000}
                        onClose={() => this.setState({uploadConfirmationSnack: !this.state.uploadConfirmationSnack})}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Imagen subida con éxito</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => this.setState({uploadConfirmationSnack: false})}
                            >
                                <CloseIcon/>
                            </IconButton>,
                        ]}
                    />
                    <Snack/>

                    {/* COPY CONFIRMATION SNACKBAR */}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.copySnack}
                        //autoHideDuration={50000}
                        onClose={() => this.setState({copySnack: !this.state.copySnack})}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={
                            <div>
                                <p id="copyMenuIframeMessage">Pulsa en el icono para copiar el siguiente código en tu
                                    portapapeles. Insértalo en tu web para mostrar este menú: </p>

                                <OutlinedInput
                                    className={classes.textField}
                                    margin="normal"
                                    variant="filled"
                                    fullWidth
                                    labelWidth={0}
                                    style={{backgroundColor: '#e0e0e0'}}
                                    value={iframe}
                                />
                            </div>

                        }
                        action={[
                            <Tooltip title="Copiar" placement="left">
                                <IconButton
                                    key="copy"
                                    aria-label="copyCode"
                                    color="inherit"
                                    onClick={() => this.setState({copySnack: false})}
                                >
                                    <Clipboard
                                        text={iframe}
                                        render={({copy}) => <FileCopyIcon onClick={copy}/>}
                                    />
                                </IconButton>
                            </Tooltip>,
                        ]}
                    />

                    {/* REMOVE RESTAURANT CONFIRMATION MODAL */}
                    <Dialog
                        open={this.state.removeModal}
                        onClose={() => {

                            history.push('/myRestaurants');
                            this.handleRemoveModal();

                        }
                        }
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle
                            id="alert-dialog-title">{"¿Seguro que quieres eliminar este restaurante?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Si borras el restaurante no podrás recuperar sus datos más tarde, piensalo bien.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleRemoveModal} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={() => {
                                this.deleteRte(this.props.idRestaurant);
                                history.push("/myRestaurants");
                            }} variant="contained"
                                    color="secondary" autoFocus>
                                Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* REMOVE IMAGE CONFIRMATION MODAL */}
                    <Dialog
                        open={this.state.removeImgModal}
                        onClose={() => this.setState({removeImgModal: false})}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"¿Seguro que quieres eliminar esta imagen?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Si borras esta imagen no podrás recuperarla más tarde, piensalo bien.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({removeImgModal: false})} color="primary">
                                Cancelar
                            </Button>
                            <Button
                                onClick={() => this.deletePic(this.state.urlPicToDelete)}
                                variant="contained"
                                color="secondary"
                                autoFocus
                                disabled={this.state.sendingData}
                            >
                                Eliminar
                                {this.state.sendingData &&
                                <CircularProgress
                                    size={25}
                                    className={classes.chargingAnimation}
                                />
                                }
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </div>
        );
    }
}

DetalleRestaurante.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        user: authReducer.credentials[0],
        idRestaurant: restaurantReducer.idRestaurant,
        slug: restaurantReducer.slugRestaurant,
        restaurantPermissions: restaurantReducer.permissions
    });
};

export default connect(mapStateToProps)(withStyles(importedStyles())(DetalleRestaurante));


