import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DividerIcon from '@material-ui/icons/Remove';
import HelpIcon from '@material-ui/icons/Help';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Slide from "@material-ui/core/Slide/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TimeInput from 'material-ui-time-picker';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import List from "@material-ui/core/List/List";
import importedStyle from './scheduleStyle';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Snack from "../../components/Snack/Snack";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";


class scheduleScreen extends React.Component {

    state = {
        sendingData: false,
        gettingData: false,
        schedules: [],
        openMonday: false,
        mondaySchedule: {},
        mondayEditedTime: false,
        openTuesday: false,
        tuesdaySchedule: {},
        tuesdayEditedTime: false,
        openWednesday: false,
        wednesdaySchedule: {},
        wednesdayEditedTime: false,
        openThursday: false,
        thursdaySchedule: {},
        thursdayEditedTime: false,
        openFriday: false,
        fridaySchedule: {},
        fridayEditedTime: false,
        openSaturday: false,
        saturdaySchedule: {},
        saturdayEditedTime: false,
        openSunday: false,
        sundaySchedule: {},
        sundayEditedTime: false,
    };

    componentDidMount() {
        this.getRestaurantSchedule(this.props.idRestaurant);
    }

    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    getRestaurantSchedule(idRestaurant) {
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'restaurants/getRestaurantSchedules', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: idRestaurant,
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                let schedules = response.schedules;
                Object.keys(schedules).forEach((schedule) => {
                    switch (schedules[schedule].dayUuid) {
                        case '1':
                            this.setState({mondaySchedule: schedules[schedule]});
                            break;
                        case '2':
                            this.setState({tuesdaySchedule: schedules[schedule]});
                            break;
                        case '3':
                            this.setState({wednesdaySchedule: schedules[schedule]});
                            break;
                        case '4':
                            this.setState({thursdaySchedule: schedules[schedule]});
                            break;
                        case '5':
                            this.setState({fridaySchedule: schedules[schedule]});
                            break;
                        case '6':
                            this.setState({saturdaySchedule: schedules[schedule]});
                            break;
                        case '7':
                            this.setState({sundaySchedule: schedules[schedule]});
                            break;
                        default:
                            console.error('no schedules available to show');
                    }
                });
                this.setState({gettingData: false});
            } else {
                console.error('Error getting schedule: ', response.errorMessage);
                this.setState({gettingData: false});
            }
        }).catch((error) => {
            console.error(error);
            this.setState({gettingData: false});
        });

    }

    updateSchedule(editedScheduleName, editedFlag) {
        this.setState({sendingData: true});
        fetch(apiBaseUrl + 'schedules/updateSchedule', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                editedSchedule: this.state[editedScheduleName]
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    [editedScheduleName]: response.updatedSchedule,
                    [editedFlag]: false,
                    sendingData: false,
                });
                this.props.dispatch(showSnack('Horario actualizado con éxito', snackTypes.success));
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            this.setState({sendingData: false, createErrorSnack: true});
            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    handleTimeChange(daySchedule, editedFlag, foodTime, time) {
        let editedSchedule = this.state[daySchedule];
        editedSchedule[foodTime] = moment(time).format();
        this.setState({
            [editedFlag]: true,
            [daySchedule]: editedSchedule
        });
    }

    renderLunchTimePickers(schedule) {
        const {classes} = this.props;
        let scheduleName = '';
        let editedFlagName = '';

        switch (schedule.dayUuid) {
            case '1':
                scheduleName = 'mondaySchedule';
                editedFlagName = 'mondayEditedTime';
                break;
            case '2':
                scheduleName = 'tuesdaySchedule';
                editedFlagName = 'tuesdayEditedTime';
                break;
            case '3':
                scheduleName = 'wednesdaySchedule';
                editedFlagName = 'wednesdayEditedTime';
                break;
            case '4':
                scheduleName = 'thursdaySchedule';
                editedFlagName = 'thursdayEditedTime';
                break;
            case '5':
                scheduleName = 'fridaySchedule';
                editedFlagName = 'fridayEditedTime';
                break;
            case '6':
                scheduleName = 'saturdaySchedule';
                editedFlagName = 'saturdayEditedTime';
                break;
            case '7':
                scheduleName = 'sundaySchedule';
                editedFlagName = 'sundayEditedTime';
                break;
            default:
                break;
        }

        let formattedLunchStartTime = schedule.lunchStartTime ? new Date(schedule.lunchStartTime) : null;
        let formattedLunchEndTime = schedule.lunchEndTime ? new Date(schedule.lunchEndTime) : null;

        return (
            <React.Fragment>
                <FormControl>
                    <InputLabel>
                        Hora de inicio
                    </InputLabel>
                    <TimeInput
                        mode='24h'
                        autoOk={false}
                        cancelLabel={'Cancelar'}
                        value={formattedLunchStartTime}
                        onChange={(time) => this.handleTimeChange(scheduleName, editedFlagName, 'lunchStartTime', time)}
                    />
                </FormControl>
                <DividerIcon className={classes.dividerIcon}/>
                <FormControl>
                    <InputLabel>
                        Hora de finalización
                    </InputLabel>
                    <TimeInput
                        variant="outlined"
                        mode='24h'
                        autoOk={false}
                        cancelLabel={'Cancelar'}
                        value={formattedLunchEndTime}
                        onChange={(time) => this.handleTimeChange(scheduleName, editedFlagName, 'lunchEndTime', time)}
                    />
                </FormControl>
                <IconButton>
                    <CancelIcon onClick={() => {
                        let editedSchedule = this.state[scheduleName];
                        editedSchedule['lunchStartTime'] = null;
                        editedSchedule['lunchEndTime'] = null;
                        this.setState({
                            [editedFlagName]: true,
                            [scheduleName]: editedSchedule
                        });
                    }}/>
                </IconButton>
            </React.Fragment>
        )
    }

    handleReservationFrecuencyChange(event, schedule, dayName, dayEditedFlag,) {
        if (event.target.value > 60 || event.target.value <= 0) {
            this.props.dispatch(showSnack('El intervalo debe ser inferior a 60min y mayor que 0', 'warning'));
        } else {
            let editedSchedule = schedule;
            editedSchedule.reservationFrecuency = event.target.value;
            this.setState({
                [dayEditedFlag]: true,
                [dayName]: editedSchedule
            });
        }
    };

    renderReservationFrecuencySelector(schedule, dayName, dayEditedFlag) {
        return (
            <React.Fragment>
                <TextField
                    id={dayName + 'intervalInput'}
                    label="Intervalo entre reservas"
                    //helperText={'Este valor indica cada cuantos minutos se puede reservar en tu restaurante'}
                    value={this.state[dayName].reservationFrecuency}
                    onChange={(event) => this.handleReservationFrecuencyChange(event, schedule, dayName, dayEditedFlag)}
                    type="number"
                    InputLabelProps={{shrink: true,}}
                    margin="normal"
                    variant="outlined"
                />
                <Tooltip title="Este valor indica cada cuantos minutos se puede reservar en tu restaurante" placement="top">
                    <IconButton>
                        <HelpIcon/>
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        );
    }

    renderDinnerTimePickers(schedule) {
        const {classes} = this.props;
        let scheduleName = '';
        let editedFlagName = '';
        switch (schedule.dayUuid) {
            case '1':
                scheduleName = 'mondaySchedule';
                editedFlagName = 'mondayEditedTime';
                break;
            case '2':
                scheduleName = 'tuesdaySchedule';
                editedFlagName = 'tuesdayEditedTime';
                break;
            case '3':
                scheduleName = 'wednesdaySchedule';
                editedFlagName = 'wednesdayEditedTime';
                break;
            case '4':
                scheduleName = 'thursdaySchedule';
                editedFlagName = 'thursdayEditedTime';
                break;
            case '5':
                scheduleName = 'fridaySchedule';
                editedFlagName = 'fridayEditedTime';
                break;
            case '6':
                scheduleName = 'saturdaySchedule';
                editedFlagName = 'saturdayEditedTime';
                break;
            case '7':
                scheduleName = 'sundaySchedule';
                editedFlagName = 'sundayEditedTime';
                break;
            default:
                break;
        }

        return (
            <React.Fragment>
                <FormControl>
                    <InputLabel>
                        Hora de inicio
                    </InputLabel>
                    <TimeInput
                        mode='24h'
                        autoOk={false}
                        cancelLabel={'Cancelar'}
                        value={schedule.dinnerStartTime ? new Date(schedule.dinnerStartTime) : null}
                        onChange={(time) => this.handleTimeChange(scheduleName, editedFlagName, 'dinnerStartTime', time)}
                    />
                </FormControl>
                <DividerIcon className={classes.dividerIcon}/>
                <FormControl>
                    <InputLabel>
                        Hora de finalización
                    </InputLabel>
                    <TimeInput
                        variant="outlined"
                        mode='24h'
                        autoOk={false}
                        cancelLabel={'Cancelar'}
                        value={schedule.dinnerEndTime ? new Date(schedule.dinnerEndTime) : null}
                        onChange={(time) => this.handleTimeChange(scheduleName, editedFlagName, 'dinnerEndTime', time)}
                    />
                </FormControl>
                <IconButton>
                    <CancelIcon onClick={() => {
                        let editedSchedule = this.state[scheduleName];
                        editedSchedule['dinnerStartTime'] = null;
                        editedSchedule['dinnerEndTime'] = null;
                        this.setState({
                            [editedFlagName]: true,
                            [scheduleName]: editedSchedule
                        });
                    }}/>
                </IconButton>
            </React.Fragment>
        )
    }

    renderDaySchedule(schedule, dayName, dayEditedFlag) {
        const {classes} = this.props;
        let listItems = [];

        if (schedule) {
            listItems.push(
                <React.Fragment>
                    <ListItem className={classes.timePickersBox}>
                        {schedule.lunchStartTime ?
                            this.renderLunchTimePickers(schedule)
                            :
                            <Button
                                variant={"outlined"}
                                size={"medium"}
                                onClick={() => {
                                    let editedSchedule = schedule;
                                    editedSchedule['lunchStartTime'] = moment('2019-02-20T12:00:00').format();
                                    editedSchedule['lunchEndTime'] = moment('2019-02-20T16:00:00').format();
                                    this.setState({
                                        [dayEditedFlag]: true,
                                        [dayName]: editedSchedule
                                    });
                                }}
                            >
                                Añadir comida
                            </Button>
                        }
                    </ListItem>
                    <ListItem className={classes.timePickersBox}>

                        {schedule.dinnerEndTime ?
                            this.renderDinnerTimePickers(schedule)
                            :
                            <Button
                                variant={"outlined"}
                                size={"medium"}
                                onClick={() => {
                                    let editedSchedule = schedule;
                                    editedSchedule['dinnerStartTime'] = moment('2019-02-20T20:00:00').format();
                                    editedSchedule['dinnerEndTime'] = moment('2019-02-20T24:00:00').format();
                                    this.setState({
                                        [dayEditedFlag]: true,
                                        [dayName]: editedSchedule
                                    });
                                }}
                            >

                                Añadir cena
                            </Button>
                        }

                    </ListItem>
                </React.Fragment>
            );

        } else {

            listItems.push(
                <div className={classes.listItem} key={0}>
                    <ListItem className={classes.list}>
                        <ListItemText
                            secondary='Aún no hay un horario establecido'
                        />
                    </ListItem>
                </div>
            )
        }

        return (
            <List className={classes.list}>
                {listItems}
            </List>
        )

    }

    renderPanelSummaryHours(schedule) {

        /*const {classes} = this.props;

        let lunchStartTime = schedule.lunchStartTime ? new Date(schedule.lunchStartTime) : '';
        let lunchEndTime =  schedule.lunchEndTime ? new Date(schedule.lunchEndTime) : 'Cerrado';
        let dinnerStartTime =  schedule.dinnerStartTime ? new Date(schedule.dinnerStartTime) : '';
        let dinnerEndTime =  schedule.dinnerEndTime ? new Date(schedule.dinnerEndTime) : 'Cerrado';

        if (schedule.allDay) {
            return (
                <Typography variant={"subtitle1"}>
                    Abierto 24h
                </Typography>
            )
        } else {
            let lunchString = lunchStartTime.getHours() + ':' + lunchStartTime.getMinutes() + ' - '
                    + lunchEndTime.getHours() + ':' + lunchEndTime.getMinutes();
            let dinnerString = dinnerStartTime.getHours() + ':' + dinnerStartTime.getMinutes() + ' - '
                    + dinnerEndTime.getHours() + ':' + dinnerEndTime.getMinutes();

            return (
                <div className={classes.summaryHoursBox}>
                    <Typography variant={"subtitle1"}>
                        {lunchString}
                    </Typography>
                    <Typography variant={"subtitle1"}>
                        {dinnerString}
                    </Typography>
                </div>
            )
        }*/

    }

    daysPanel() {
        const {classes} = this.props;
        return (
            <List
                component="nav"
                // subheader={<ListSubheader component="div">Horario</ListSubheader>}
                className={classes.root}
            >
                {/* Monday */}
                <ExpansionPanel key={0} classes={{root: classes.dayExpansionPanel}}>
                    <ExpansionPanelSummary
                        className={classes.dayExpansionPanelSummary}
                        classes={{content: classes.dayExpansionPanelSummaryContent}}
                        expandIcon={<ExpandMoreIcon/>}
                        onClick={() => this.setState({openMonday: !this.state.openMonday})}
                    >
                        <Typography variant={"subtitle1"}>
                            Lunes
                        </Typography>
                        {this.renderPanelSummaryHours(this.state.mondaySchedule)}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={8} justify={"center"}>
                            <Grid item xs={12} md={6} justify={"center"} className={classes.timePickersBox}>
                                {
                                    this.state.mondaySchedule.allDay ?
                                        <ListItem className={classes.timePickersBox}>
                                            <Typography variant={"subtitle1"}>Abierto 24h</Typography>
                                        </ListItem>
                                        :
                                        this.renderDaySchedule(this.state.mondaySchedule, 'mondaySchedule', 'mondayEditedTime')
                                }
                            </Grid>
                            <Grid item xs={12} className={classes.reservationFrecuencyBox}>
                                {this.props.restaurantPermissions.reservations && this.renderReservationFrecuencySelector(this.state.mondaySchedule, 'mondaySchedule', 'mondayEditedTime')}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.mondaySchedule.allDay}
                                            onChange={(e) => {
                                                let editedSchedule = this.state.mondaySchedule;
                                                editedSchedule['allDay'] = e.target.checked;
                                                this.setState({
                                                    mondayEditedTime: true,
                                                    mondaySchedule: editedSchedule
                                                });
                                            }}
                                        />
                                    }
                                    label="Abierto 24h"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.mondayEditedTime &&
                                <Button variant={'outlined'} color={"primary"}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.updateSchedule('mondaySchedule', 'mondayEditedTime')}>
                                    Guardar cambios
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* Tuesday */}
                <ExpansionPanel key={1} classes={{root: classes.dayExpansionPanel}}>
                    <ExpansionPanelSummary
                        className={classes.dayExpansionPanelSummary}
                        classes={{content: classes.dayExpansionPanelSummaryContent}}
                        onClick={() => this.setState({openTuesday: !this.state.openTuesday})}
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant={"subtitle1"}>
                            Martes
                        </Typography>
                        {this.renderPanelSummaryHours(this.state.tuesdaySchedule)}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={8} justify={"center"}>
                            <Grid item xs={12} md={6} justify={"center"} className={classes.timePickersBox}>
                                {
                                    this.state.tuesdaySchedule.allDay ?
                                        <ListItem className={classes.timePickersBox}>
                                            <Typography variant={"subtitle1"}>Abierto 24h</Typography>
                                        </ListItem>
                                        :
                                        this.renderDaySchedule(this.state.tuesdaySchedule, 'tuesdaySchedule', 'tuesdayEditedTime')
                                }
                            </Grid>
                            <Grid item xs={12} className={classes.reservationFrecuencyBox}>
                                {this.props.restaurantPermissions.reservations && this.renderReservationFrecuencySelector(this.state.tuesdaySchedule, 'tuesdaySchedule', 'tuesdayEditedTime')}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.tuesdaySchedule.allDay}
                                            onChange={(e) => {
                                                let editedSchedule = this.state.tuesdaySchedule;
                                                editedSchedule['allDay'] = e.target.checked;
                                                this.setState({
                                                    tuesdaySchedule: editedSchedule,
                                                    tuesdayEditedTime: true
                                                });
                                            }}
                                        />
                                    }
                                    label="Abierto 24h"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.tuesdayEditedTime &&
                                <Button variant={'outlined'} color={"primary"}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.updateSchedule('tuesdaySchedule', 'tuesdayEditedTime')}>
                                    Guardar cambios
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* Wednesday */}
                <ExpansionPanel key={2} classes={{root: classes.dayExpansionPanel}}>
                    <ExpansionPanelSummary
                        classes={{content: classes.dayExpansionPanelSummaryContent}}
                        className={classes.dayExpansionPanelSummary}
                        onClick={() => this.setState({openWednesday: !this.state.openWednesday})}
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant={"subtitle1"}>
                            Miércoles
                        </Typography>
                        {this.renderPanelSummaryHours(this.state.wednesdaySchedule)}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={8} justify={"center"}>
                            <Grid item xs={12} md={6} justify={"center"} className={classes.timePickersBox}>
                                {
                                    this.state.wednesdaySchedule.allDay ?
                                        <ListItem className={classes.timePickersBox}>
                                            <Typography variant={"subtitle1"}>Abierto 24h</Typography>
                                        </ListItem>
                                        :
                                        this.renderDaySchedule(this.state.wednesdaySchedule, 'wednesdaySchedule', 'wednesdayEditedTime')
                                }
                            </Grid>
                            <Grid item xs={12} className={classes.reservationFrecuencyBox}>
                                {this.props.restaurantPermissions.reservations && this.renderReservationFrecuencySelector(this.state.wednesdaySchedule, 'wednesdaySchedule', 'wednesdayEditedTime')}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.wednesdaySchedule.allDay}
                                            onChange={(e) => {
                                                let editedSchedule = this.state.wednesdaySchedule;
                                                editedSchedule['allDay'] = e.target.checked;
                                                this.setState({
                                                    wednesdaySchedule: editedSchedule,
                                                    wednesdayEditedTime: true
                                                });
                                            }}
                                        />
                                    }
                                    label="Abierto 24h"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.wednesdayEditedTime &&
                                <Button variant={'outlined'} color={"primary"}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.updateSchedule('wednesdaySchedule', 'wednesdayEditedTime')}>
                                    Guardar cambios
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* Thursday */}
                <ExpansionPanel key={3} classes={{root: classes.dayExpansionPanel}}>
                    <ExpansionPanelSummary
                        className={classes.dayExpansionPanelSummary}
                        classes={{content: classes.dayExpansionPanelSummaryContent}}
                        onClick={() => this.setState({openThursday: !this.state.openThursday})}
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant={"subtitle1"}>
                            Jueves
                        </Typography>
                        {this.renderPanelSummaryHours(this.state.thursdaySchedule)}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={8} justify={"center"}>
                            <Grid item xs={12} md={6} justify={"center"} className={classes.timePickersBox}>
                                {
                                    this.state.thursdaySchedule.allDay ?
                                        <ListItem className={classes.timePickersBox}>
                                            <Typography variant={"subtitle1"}>Abierto 24h</Typography>
                                        </ListItem>
                                        :
                                        this.renderDaySchedule(this.state.thursdaySchedule, 'thursdaySchedule', 'thursdayEditedTime')
                                }
                            </Grid>
                            <Grid item xs={12} className={classes.reservationFrecuencyBox}>
                                {this.props.restaurantPermissions.reservations && this.renderReservationFrecuencySelector(this.state.wednesdaySchedule, 'wednesdaySchedule', 'wednesdayEditedTime')}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.thursdaySchedule.allDay}
                                            onChange={(e) => {
                                                let editedSchedule = this.state.thursdaySchedule;
                                                editedSchedule['allDay'] = e.target.checked;
                                                this.setState({
                                                    thursdayEditedTime: true,
                                                    thursdaySchedule: editedSchedule
                                                });
                                            }}
                                        />
                                    }
                                    label="Abierto 24h"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.thursdayEditedTime &&
                                <Button variant={'outlined'} color={"primary"}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.updateSchedule('thursdaySchedule', 'thursdayEditedTime')}>
                                    Guardar cambios
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* Friday */}
                <ExpansionPanel key={4} classes={{root: classes.dayExpansionPanel}}>
                    <ExpansionPanelSummary
                        className={classes.dayExpansionPanelSummary}
                        classes={{content: classes.dayExpansionPanelSummaryContent}}
                        onClick={() => this.setState({openFriday: !this.state.openFriday})}
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant={"subtitle1"}>
                            Viernes
                        </Typography>
                        {this.renderPanelSummaryHours(this.state.fridaySchedule)}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={8} justify={"center"}>
                            <Grid item xs={12} md={6} justify={"center"} className={classes.timePickersBox}>
                                {
                                    this.state.fridaySchedule.allDay ?
                                        <ListItem className={classes.timePickersBox}>
                                            <Typography variant={"subtitle1"}>Abierto 24h</Typography>
                                        </ListItem>
                                        :
                                        this.renderDaySchedule(this.state.fridaySchedule, 'fridaySchedule', 'fridayEditedTime')
                                }
                            </Grid>
                            <Grid item xs={12} className={classes.reservationFrecuencyBox}>
                                {this.props.restaurantPermissions.reservations && this.renderReservationFrecuencySelector(this.state.fridaySchedule, 'fridaySchedule', 'fridayEditedTime')}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.fridaySchedule.allDay}
                                            onChange={(e) => {
                                                let editedSchedule = this.state.fridaySchedule;
                                                editedSchedule['allDay'] = e.target.checked;
                                                this.setState({
                                                    fridayEditedTime: true,
                                                    fridaySchedule: editedSchedule
                                                });
                                            }}
                                        />
                                    }
                                    label="Abierto 24h"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.fridayEditedTime &&
                                <Button variant={'outlined'} color={"primary"}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.updateSchedule('fridaySchedule', 'fridayEditedTime')}>
                                    Guardar cambios
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* Saturday */}
                <ExpansionPanel key={5} classes={{root: classes.dayExpansionPanel}}>
                    <ExpansionPanelSummary
                        className={classes.dayExpansionPanelSummary}
                        classes={{content: classes.dayExpansionPanelSummaryContent}}
                        onClick={() => this.setState({openSaturday: !this.state.openSaturday})}
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant={"subtitle1"}>
                            Sábado
                        </Typography>
                        {this.props.restaurantPermissions.reservations && this.renderPanelSummaryHours(this.state.saturdaySchedule)}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={8} justify={"center"}>
                            <Grid item xs={12} md={6} justify={"center"} className={classes.timePickersBox}>
                                {
                                    this.state.saturdaySchedule.allDay ?
                                        <ListItem className={classes.timePickersBox}>
                                            <Typography variant={"subtitle1"}>Abierto 24h</Typography>
                                        </ListItem>
                                        :
                                        this.renderDaySchedule(this.state.saturdaySchedule, 'saturdaySchedule', 'saturdayEditedTime')
                                }
                            </Grid>
                            <Grid item xs={12} className={classes.reservationFrecuencyBox}>
                                {this.props.restaurantPermissions.reservations && this.renderReservationFrecuencySelector(this.state.saturdaySchedule, 'saturdaySchedule', 'saturdayEditedTime')}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.saturdaySchedule.allDay}
                                            onChange={(e) => {
                                                let editedSchedule = this.state.saturdaySchedule;
                                                editedSchedule['allDay'] = e.target.checked;
                                                this.setState({
                                                    saturdayEditedTime: true,
                                                    saturdaySchedule: editedSchedule
                                                });
                                            }}
                                        />
                                    }
                                    label="Abierto 24h"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.saturdayEditedTime &&
                                <Button variant={'outlined'} color={"primary"}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.updateSchedule('saturdaySchedule', 'saturdayEditedTime')}>
                                    Guardar cambios
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* Sunday */}
                <ExpansionPanel key={6} classes={{root: classes.dayExpansionPanel}}>
                    <ExpansionPanelSummary
                        className={classes.dayExpansionPanelSummary}
                        classes={{content: classes.dayExpansionPanelSummaryContent}}
                        onClick={() => this.setState({openSunday: !this.state.openSunday})}
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant={"subtitle1"}>
                            Domingo
                        </Typography>
                        {this.renderPanelSummaryHours(this.state.sundaySchedule)}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={8} justify={"center"}>
                            <Grid item xs={12} md={6} justify={"center"} className={classes.timePickersBox}>
                                {
                                    this.state.sundaySchedule.allDay ?
                                        <ListItem className={classes.timePickersBox}>
                                            <Typography variant={"subtitle1"}>Abierto 24h</Typography>
                                        </ListItem>
                                        :
                                        this.renderDaySchedule(this.state.sundaySchedule, 'sundaySchedule', 'sundayEditedTime')
                                }
                            </Grid>
                            <Grid item xs={12} className={classes.reservationFrecuencyBox}>
                                {this.props.restaurantPermissions.reservations && this.renderReservationFrecuencySelector(this.state.sundaySchedule, 'sundaySchedule', 'sundayEditedTime')}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.sundaySchedule.allDay}
                                            onChange={(e) => {
                                                let editedSchedule = this.state.sundaySchedule;
                                                editedSchedule['allDay'] = e.target.checked;
                                                this.setState({
                                                    sundayEditedTime: true,
                                                    sundaySchedule: editedSchedule
                                                });
                                            }}
                                        />
                                    }
                                    label="Abierto 24h"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.sundayEditedTime &&
                                <Button variant={'outlined'} color={"primary"}
                                        disabled={this.state.sendingData}
                                        onClick={() => this.updateSchedule('sundaySchedule', 'sundayEditedTime')}>
                                    Guardar cambios
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </List>
        );
    }

    render() {
        const classes = this.props.classes;

        return (
            <div style={{display: 'flex'}}>
                <MiniDrawer title={"Horario"} idRestaurant={this.props.idRestaurant}/>

                {this.state.gettingData ?
                    <div className={classes.chargingContent}><CircularProgress/></div>
                    :
                    <div className={classes.content}>
                        <Grid container
                              direction="row"
                              justify="space-evenly"
                              spacing={8}
                              alignItems="stretch"
                              style={{marginTop: 15}}
                        >
                            <Grid item xs={12} className={classes.editorBox}>
                                {this.daysPanel()}
                            </Grid>
                        </Grid>

                        <Snack/>
                    </div>
                }
            </div>
        );
    }
}

scheduleScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({authReducer, restaurantReducer}) => {
    return ({
        user: authReducer.credentials[0],
        idRestaurant: restaurantReducer.idRestaurant,
        nameRestaurant: restaurantReducer.nameRestaurant,
        restaurantPermissions: restaurantReducer.permissions
    });
};

export default connect(mapStateToProps)(withStyles(importedStyle(), {withTheme: true})(scheduleScreen));


