export default class NumericRangeQuestion {

    constructor(question) {
        this.title = question.title;
        this.answers = question.answers;
        this.type = question.type;
        this.position = question.position;
        this.skippable = question.skippable;
        this.minVal = question.minVal;
        this.maxVal = question.maxVal;
    }

    getAnswersStatistics() {
        let answers = this.answers;
        let totalAnswers = answers.length;
        let maxValueProvided = 0;
        let minValueProvided = 0;
        let allValuesTotal = 0;

        Object.keys(answers).forEach((key) => {
            let answerObject = answers[key];
            let valueProvided = parseInt(answerObject.answer);

            if (valueProvided > maxValueProvided){
                maxValueProvided = valueProvided;
            }
            if (valueProvided < minValueProvided){
                minValueProvided = valueProvided;
            }

            allValuesTotal = allValuesTotal + valueProvided;
        });

        let averageValue = (allValuesTotal / totalAnswers).toFixed(1);

        let answerStatistics = {
            totalAnswers: totalAnswers,
            averageValue: averageValue,
            minValueProvided: minValueProvided,
            maxValueProvided: maxValueProvided,
        };

        return answerStatistics;
    }
}