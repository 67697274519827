import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";

const styles = ({
   footer: {
       width: '98%',
       padding: '1%',
       height: '10%',
       display: 'flex',
       justifyContent: 'space-around',
       background: '#0f1a26',
       bottom: 0,
       position: 'absolute'
   },
   header: {
       width: '96%',
       padding: '2%',
       height: '10%',
       display: 'flex',
       justifyContent: 'space-around',
   },
   content: {
     padding: '3%'
   },
   textTotal: {
       color: '#bf8f45',
       fontSize: '40px',
   },
   textPeople: {
        color: 'white',
   }
});

// const idUsuarioSergio = 0;

class Landing extends React.Component {

    state = {
        dish: {},
    };

    componentDidMount() {
        // this.getRestaurants();
    }

    getDish(Menu, idRestaurant) {
        fetch('http://localhost:8000/api/users/listRestaurants', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                owner: "1",
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {

            this.setState({restaurants: JSON.parse(recurso)})

        }).catch((error) => {
            console.error(error);
        });

    }


    renderDish() {
    }

    renderFooter() {
        return <div style={styles.footer}>
            <p style={styles.textPeople}>2</p>
            <p style={styles.textTotal}>120€</p>
            <button>Contacta</button>
        </div>;
    }

    render() {
        // const {classes} = this.props;

        return (
            <div>
                <div style={styles.header}><h1>Configura tu menú</h1></div>
                <div style={styles.content}>
                    <div style={{flex: 1, flexDirection: 'column', alignContent: 'space-around'}}>
                        <h3>Aperitivos</h3>
                        <h3>Entrantes</h3>
                        <h3>Platos principales</h3>
                    </div>
                </div>
                {this.renderFooter()}
            </div>
        );
    }
}

Landing.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Landing);






