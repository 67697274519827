import {DETAIL_RTE} from "../../constants/action-types";


const initialState = {
    restaurant : {},
    nameRestaurant: '',
    restaurantLogo: '',
    idRestaurant: -1,
    permissions: [],
    slugRestaurant: -1,
};

const restaurantReducer = (state = initialState, action) => {

    switch (action.type) {
        case DETAIL_RTE:
            return {state, idRestaurant: action.id, nameRestaurant: action.name, restaurantLogo: action.logo, permissions: action.permissions, slugRestaurant: action.slug};
        default:
            return state;
    }
};


export default restaurantReducer;