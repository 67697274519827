import React from 'react'
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DateFnsUtils from "@date-io/date-fns";
import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import connect from "react-redux/lib/connect/connect";
import MiniDrawer from "../../components/MiniDrawer/MiniDrawer";
import Grid from "@material-ui/core/Grid";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import DetailCard from "../Restaurant/components/DetailCard";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import importedStyles from "./Statistics.module.sass";
import StatisticCardPie from "./StatisticCardPie";
import StatisticsCardArea from "./StatisticsCardArea";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
// import useTheme from "@material-ui/core/styles/useTheme";
import {useMediaQuery} from "@material-ui/core";


class Statistics extends React.Component {

    state = {
        restaurant: {},
        gettingStatistics: true,
        statisticsCountData: [],
        statisticsCountDataMorning: [],
        statisticsCountDataNight: [],
        statisticsCountTimestampData: [],
        statisticsCountTimestampDataByDish: [],
        productStatisticsData: [],
        startDate: null,
        endDate: null,
        minorPrice: null,
        majorPrice: null,
        dish: "",
        dishes: [],
        tags: [],
        dishName: "",

    }

    componentDidMount() {

        // this.getStatistics()
        this.setState({gettingStatistics: true})
        this.getDishes()
        this.getCountEventsByRestaurantTimestamp()
        this.getCountEventsByRestaurant()
        this.getCountEventsByRestaurantMorning()
        this.getCountEventsByRestaurantNight()
        this.getStatisticsByProductTimestamp()


    }

    onTagsChange = (event, values) => {
        this.setState({
            tags: values
        }, () => {
            // This will output an array of objects
            // given by Autocompelte options property.
        });
    }
    handleChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };

    handleChangeDish = (event) => {
        this.setState({dish: event.target.value});
    };

    getDishes = () => {

        fetch(apiBaseUrl + 'restaurants/getDishes', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    dishes: response.dishes,
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            // this.setState({sendingData: false});
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    getCountEventsByRestaurant() {
        fetch(apiBaseUrl + 'restaurants/statistics/dish/getStatisticsCountByDish', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                minorPrice: this.state.minorPrice,
                majorPrice: this.state.majorPrice,
            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    statisticsCountData: response.data,


                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    getCountEventsByRestaurantNight() {
        fetch(apiBaseUrl + 'restaurants/statistics/dish/getStatisticsCountByDishNight', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                minorPrice: this.state.minorPrice,
                majorPrice: this.state.majorPrice,

            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    statisticsCountDataNight: response.data,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    minorPrice: this.state.minorPrice,
                    majorPrice: this.state.majorPrice,

                }, () => {
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    getCountEventsByRestaurantMorning() {
        fetch(apiBaseUrl + 'restaurants/statistics/dish/getStatisticsCountByDishMorning', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                minorPrice: this.state.minorPrice,
                majorPrice: this.state.majorPrice,

            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    statisticsCountDataMorning: response.data,

                }, () => {
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    getCountEventsByRestaurantTimestamp() {

        fetch(apiBaseUrl + 'restaurants/statistics/dish/getStatisticsCountByTimestamp', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                minorPrice: this.state.minorPrice,
                majorPrice: this.state.majorPrice,

            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    statisticsCountTimestampData: response.data,

                }, () => {
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });

    }

    updateStatistics = () => {
        this.setState({gettingStatistics: true})
        this.getCountEventsByRestaurant();
        this.getCountEventsByRestaurantMorning();
        this.getCountEventsByRestaurantNight();
        this.getCountEventsByRestaurantTimestamp();
        this.getStatisticsByProductTimestamp();
    }

    getStatisticsByProductTimestamp() {
        fetch(apiBaseUrl + 'restaurants/statistics/dish/getStatisticsByProductTimestamp', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: this.props.idRestaurant,
                uuidDish: this.state.dish ? this.state.dish : null,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                minorPrice: this.state.minorPrice,
                majorPrice: this.state.majorPrice,
            }),
        }).then((responseJSON) => {
            return responseJSON.text();

        }).then((res) => {
            let response = JSON.parse(res);
            if (!response.error) {
                this.setState({
                    productStatisticsData: response.data,
                    dishName: response.firstName,
                    gettingStatistics: false,
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({sendingData: false});
            console.error('Error: ' + error);

            this.props.dispatch(showSnack('Ha ocurrido un error, vuelva a intentarlo', snackTypes.error));
        });
    }

    renderStatisticsFilter() {
        return (
            <Card

                variant="outlined">


                <CardContent>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Typography className={importedStyles.filterTitle} variant="h6">Filtrar: </Typography>

                        <TextField
                            className={importedStyles.textFieldLong}
                            id="date"
                            name="startDate"
                            label="Fecha inicio"
                            type="date"
                            value={this.state.startDate}
                            onChange={this.handleChange}

                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            className={importedStyles.textFieldLong}

                            id="date"
                            name="endDate"

                            label="Fecha fin"
                            type="date"
                            value={this.state.endDate}
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            className={importedStyles.textFieldShort}

                            id="date"
                            name="minorPrice"
                            label="Precio menor"
                            type="number"
                            onChange={this.handleChange}

                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            className={importedStyles.textFieldShort}

                            id="date"
                            name="majorPrice"
                            label="Precio mayor"
                            type="number"
                            onChange={this.handleChange}

                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {/*<div className={importedStyles.select}>*/}
                        {/*    <InputLabel className={importedStyles.labelSelect} id="dish">Plato</InputLabel>*/}
                        {/*    <Select*/}
                        {/*        labelId="dish"*/}
                        {/*        id="dish"*/}
                        {/*        label=""*/}
                        {/*        value={this.state.dish.name}*/}
                        {/*        onChange={this.handleChangeDish}*/}
                        {/*    >*/}
                        {/*        <MenuItem value="null">*/}
                        {/*            <em>TODOS</em>*/}
                        {/*        </MenuItem>*/}
                        {/*        {this.state.dishes.map((dish) => (*/}
                        {/*            <MenuItem value={dish.uuid}>{dish.name}</MenuItem>*/}
                        {/*        ))}*/}

                        {/*    </Select>*/}
                        {/*</div>*/}


                    </MuiPickersUtilsProvider>
                    {/*<Autocomplete*/}
                    {/*    options={this.state.dishes}*/}
                    {/*    getOptionLavel={this.state.dishes.map(option=>option.name)}*/}

                    {/*    renderInput={(params) =>*/}
                    {/*        <TextField {...params}  label="With categories" variant="outlined"/>}>*/}
                    {/*</Autocomplete>*/}

                </CardContent>
                <CardActions>
                    {this.renderStatisticsActions(() => this.updateStatistics())}
                </CardActions>

            </Card>


        )
    }

    generateStatistics() {


        return (
            <React.Fragment>
                <React.Fragment>


                    <Grid className={importedStyles.container} container spacing={3}>
                        <Grid className={importedStyles.filters} item xs={12}>
                            {this.renderStatisticsFilter()}
                        </Grid>


                        <Grid className={importedStyles.item} item xs={12} lg={6}>

                            <Card className={importedStyles.card}>
                                <CardContent className={importedStyles.cardArea}>
                                    {this.state.gettingStatistics ?
                                        <CircularProgress size={70} className={importedStyles.circularProgress}/> :
                                        <StatisticCardPie showText={true} emptyText="Aún no se han recogido datos de tus cartas"
                                            title="Platos más vistos"
                                                          statistics={this.state.statisticsCountData}/>}
                                </CardContent>

                            </Card>


                        </Grid>
                        <Grid className={importedStyles.item} item xs={12} lg={6}>

                            <Card className={importedStyles.card}>
                                <CardContent className={importedStyles.cardArea}>
                                    {this.state.gettingStatistics ?
                                        <CircularProgress size={70} className={importedStyles.circularProgress}/> :

                                        <StatisticCardPie  showText={true} emptyText="Aún no se han recogido datos de tus cartas"
                                            title="Platos más vistos (comidas)"
                                                          statistics={this.state.statisticsCountDataMorning}/>}
                                </CardContent>

                            </Card>


                        </Grid>

                        <Grid className={importedStyles.item} item xs={12} lg={6}>

                            <Card className={importedStyles.card}>
                                <CardContent className={importedStyles.cardArea}>
                                    {this.state.gettingStatistics ?
                                        <CircularProgress size={70} className={importedStyles.circularProgress}/> :

                                        <StatisticCardPie showText={true} emptyText="Aún no se han recogido datos de tus cartas"
                                            title="Platos más vistos (cenas)"
                                                          statistics={this.state.statisticsCountDataNight}/>}
                                </CardContent>

                            </Card>

                        </Grid>

                        {/*<Grid className={importedStyles.item} item xs={12} md={6} lg={4}>*/}

                        {/*    <Card className={importedStyles.card}>*/}
                        {/*        <CardContent className={importedStyles.cardArea}>*/}
                        {/*            {this.state.gettingStatistics ?*/}
                        {/*                <CircularProgress size={70} className={importedStyles.circularProgress}/> :*/}

                        {/*                <StatisticsCardArea  emptyText="Aún no se han recogido datos de tus cartas"*/}
                        {/*                    title="Actividad del restaurante"*/}
                        {/*                                    statistics={this.state.statisticsCountTimestampData}/>}*/}
                        {/*        </CardContent>*/}

                        {/*    </Card>*/}


                        {/*</Grid>*/}

                        {/*<Grid className={importedStyles.item} item xs={12} md={6} lg={4}>*/}

                        {/*    <Card className={importedStyles.card}>*/}
                        {/*        <CardContent className={importedStyles.cardArea}>*/}
                        {/*            {this.state.gettingStatistics ?*/}
                        {/*                <CircularProgress size={70} className={importedStyles.circularProgress}/> :*/}

                        {/*                <StatisticsCardArea emptyText="Selecciona un plato"*/}
                        {/*                                    title="Actividad por plato"*/}
                        {/*                                    statistics={this.state.productStatisticsData}/>}*/}
                        {/*        </CardContent>*/}

                        {/*    </Card>*/}


                        {/*</Grid>*/}

                    </Grid>
                </React.Fragment>


            </React.Fragment>


        )
            ;
    }

    render() {


        const {restaurant} = this.state;

        return (

            <div className={importedStyles.root}>
                {/*console*/}
                <MiniDrawer title={"Estadísticas (beta)"}/>
                <Grid container className={importedStyles.content}>
                    {this.generateStatistics()}
                </Grid>


            </div>

        )
    }

    renderStatisticsActions(toggleStatisticsSearch) {

        return (
            <Button className={importedStyles.button} variant="contained" color="secondary"
                    onClick={toggleStatisticsSearch}>
                Buscar
            </Button>
        )

    }
}

const
    mapStateToProps = ({authReducer, restaurantReducer}) => {
        return ({
            user: authReducer.credentials[0],
            idRestaurant: restaurantReducer.idRestaurant,
        });
    };

export default connect(mapStateToProps)(Statistics);
