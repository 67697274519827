import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles/index";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import importedStyles from './menuCardOfferStyle';

class MenuCardOffer extends React.Component {
    state = {
        expanded: false,
        anchorEl: null,
        removeMenuModal: false,
        removeDishModal: false,
        removeSectionModal: false,
        removeConfirmationSnack: false,
        menuSelectedId: -1,
        dishSelectedId: -1,
        sectionSelectedId: -1,
    };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleRemoveModal = () => {
        this.setState({ removeModal: !this.state.removeModal });
    };

    handleRemoveMenuModal = () => {
        this.setState({ removeMenuModal: !this.state.removeMenuModal });
    };
    handleRemoveSectionModal = ()=> {
        this.setState({ removeSectionModal: !this.state.removeSectionModal });
    };
    handleRemoveDishModal = () => {
        this.setState({ removeDishModal: !this.state.removeDishModal });
    };

    renderSections(sections) {
        let sectionItem = [];

        Object.keys(sections).forEach((sectionId)=> {
            sectionItem.push(
                <ExpansionPanel key={sectionId}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon onClick={() => {
                        this.setState({
                            expansionPanelOpen: !this.state.expansionPanelOpen
                        });
                    }}/>}>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography> {sections[sectionId].name}</Typography>
                                {this.props.sectionActions}

                            </div>
                            <div>
                                <IconButton aria-label="Add" onClick={() => this.props.functionAddDish(sections[sectionId].uuid)}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton aria-label="Delete" onClick={() => {
                                    this.setState({sectionSelectedId: sections[sectionId].uuid});
                                    this.handleRemoveSectionModal()
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {this.renderDishes(sections[sectionId].dishes)}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                )
        });

        return sectionItem ;
    }

    renderDishes(dishes){
        const {classes } = this.props;

        return (
            <List style={{width:'100%'}}>
            {Object.keys(dishes).map(value => (
                <ListItem
                    key={value}
                    role={undefined}
                    dense
                    className={classes.dishContainer}
                >
                    <ListItemText primary={dishes[value].name} />
                    <ListItemSecondaryAction>
                        <IconButton aria-label="Delete" onClick={() => {
                            this.setState({dishSelectedId: dishes[value].uuid});
                            this.handleRemoveDishModal()
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                ))}
            </List>
            );

    }

    removeMenu = () =>{
        this.handleRemoveMenuModal()
    };
    removeSection = () =>{
        console.log('deleted: ' + this.state.sectionSelectedId);
        this.handleRemoveSectionModal()

    };
    removeDishFromSection =()=>{
        console.log('deleted: ' + this.state.menuSelectedId);
        this.handleRemoveDishModal()

    };

    render() {
        const {classes } = this.props;

        return (
            <div style={{margin: 10}}>
                <Card className={classes.card} style={{backgroundColor: "rgb(243, 247, 248)"}}>

                    <CardContent>
                        <div className={classes.fullDisplayFlex}>
                            <div className={classes.displayFlexCenter}>
                                <h3>{this.props.name}</h3>
                                {this.props.actions}

                            </div>
                            {this.props.newSectionButton}
                        </div>
                        {this.props.sections}
                    </CardContent>

                </Card>

                {/*REMOVE MENU MODAL*/}
                <Dialog
                    open={this.state.removeMenuModal}
                    onClose={this.handleRemoveMenuModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar este menú?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveMenuModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.removeMenu} variant="contained" color="secondary" autoFocus>
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*REMOVE SECTION MODAL*/}
                <Dialog
                    open={this.state.removeSectionModal}
                    onClose={this.handleRemoveSectionModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar esta sección?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveSectionModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.removeSection} variant="contained" color="secondary" autoFocus>
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*REMOVE DISH MODAL*/}
                <Dialog
                    open={this.state.removeDishModal}
                    onClose={this.handleRemoveDishModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Eliminar"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea eliminar este plato de la sección?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveDishModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.removeDishFromSection} variant="contained" color="secondary" autoFocus>
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

MenuCardOffer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(importedStyles())(MenuCardOffer);