import React from "react";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import KeyIcon from '@material-ui/icons/VpnKeyRounded';
import Chip from "@material-ui/core/Chip/Chip";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import importedStyles from "./digitalMenuScreen.module.sass";
import Snack from "../../components/Snack/Snack";
import {showSnack} from "../../components/Snack/SnackActions";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Box, Container, IconButton, Tooltip} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ReactCodeInput from "react-code-input";
import Grid from "@material-ui/core/Grid";
import screenfull from "screenfull";
import {change_active_menu, change_current_restaurant, change_current_section} from "./digitalMenuActions";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import DigitalMenuNavBar from "./components/DigitalMenuNavBar/DigitalMenuNavBar";
import SectionContentBox from "./components/SectionContentBox/SectionContentBox";
import DishCarousel from "./components/DishCarousel/DishCarousel";
import CloseIcon from "@material-ui/icons/Close";
import IdleTimer from 'react-idle-timer';
import Lottie from 'react-lottie';
import idleAnimation from '../../rsc/animation/idleAnimation';


class digitalMenuScreen extends React.Component {

    state = {
        restaurantMenus: [],
        restaurantLogo: '',
        restaurantName: '',
        menu: {},
        cart: [],
        selectedSection: '',
        existMenu: false,
        gettingData: false,
        isMenuSelected: false,
        securityPinModal: false,
        idleRefreshDialog: false,
        previewMode: false,
        firstAdditionToCart: true,
        firstAdditionToCartTutorialDialog: false,
        currentDish: null,
    };

    constructor(props) {
        super(props);
        this.idleTimer = null;
    }



    componentDidMount() {

        this.getRestaurantLogo();

        const menuUuid = this.props.match.params.menuUuid;
        //If there is a param menuUuid, you came from 'comerEnManager', so directly get that menu and set a ´preview mode´ flag
        if (menuUuid !== null && menuUuid !== undefined) {
            /*
                If there is an actual menu (check it by checking if uuid property exists) on redux and it's the same uuid as :menuUuid on url,
                 copy it on 'menu' object at state and set existMenu & isMenuSelected flags true
                 If there is not a menu on redux, or it's different from menuUuid at url, then fetch menu data
             */

            this.setState({previewMode: true});
            if (this.props.actualMenu && this.props.actualMenu.uuid && (this.props.actualMenu.uuid === this.props.match.params.menuUuid)) {
                this.getMenuData(this.props.actualMenu.uuid);
                if (this.props.currentSection) {
                    this.setState({selectedSection: this.props.currentSection});
                }
            } else {
                this.getMenuData(menuUuid);
            }

        } else {
            /*
                If there is an actual menu (check it by checking if uuid property exists) on redux, then copy it on 'menu' object at state
                and set existMenu & isMenuSelected flags to true
             */
            let restaurantChanged = this.checkIfRestaurantChanged();

            if (this.props.actualMenu && this.props.actualMenu.uuid && !restaurantChanged) {

                this.getMenuData(this.props.actualMenu.uuid); //Refresh menu data to get last changes

                if (this.props.currentSection) {
                    this.setState({selectedSection: this.props.currentSection});
                }
            } else {
                this.getRestaurantMenus();
            }
        }
    }

    handleFirstAdditionToCart = () => {
        this.setState((prevState, props) => ({
            firstAdditionToCartTutorialDialog: true,
            firstAdditionToCart: false
        }));
    };
    handleFirstAdditionToCartTutorialDialog = () => {
        this.setState((prevState, props) => ({
            firstAdditionToCartTutorialDialog: !prevState.firstAdditionToCartTutorialDialog,
        }));
    };

    handleSectionSelect = e => {
        this.props.dispatch(change_current_section(e.target.value)); //save currentSection to redux, if page refreshed then we can retrieve it from there
        this.setState({selectedSection: e.target.value});
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    handleSectionChange = nextSection => () => {
        this.props.dispatch(change_current_section(nextSection));
        this.setState({selectedSection: nextSection});
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    handleBackToMenu = () => {
        this.props.dispatch(change_current_section('')); //save currentSection to redux, if page refreshed then we can retrieve it from there
        this.setState({selectedSection: ''});
    };

    enableFullScreen() {
        if (screenfull.enabled) {
            screenfull.toggle().then((data) => {
                //
            }).catch(exception => {
                console.error('Error fullscreen: ', exception);
            });
        }
    }

    checkIfRestaurantChanged = () => {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        const lastRestaurantOnRedux = this.props.currentRestaurant;

        let restaurantChanged = lastRestaurantOnRedux !== restaurantUuid;

        if (restaurantChanged) {
            //If restaurant changed, then save current restaurant uuid on redux
            this.props.dispatch(change_current_restaurant(restaurantUuid));
        }

        return restaurantChanged;
    };


    /* FETCH RESTAURANT DATA */
    getRestaurantLogo() {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'restaurants/getRestaurantLogo', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidRestaurant: restaurantUuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    gettingData: false,
                    restaurantLogo: response.logo,
                    restaurantName: response.restaurantName
                });
            } else {
                throw new Error('Error retrieving logo from restaurant');
            }
        }).catch((error) => {
            this.setState({gettingData: false});
            console.log(error);
        });
    }

    getRestaurantMenus = () => {
        let restaurantUuid = this.props.match.params.restaurantUuid;
        fetch(apiBaseUrl + 'restaurants/getMenus', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                uuidRestaurant: restaurantUuid
            })
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({restaurantMenus: response.menus});
            } else {
                throw new Error('Error: ' + response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    getMenuData = menuUuid => {
        this.setState({gettingData: true});
        fetch(apiBaseUrl + 'menus/getMenu/' + menuUuid, {
            method: 'get',
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({menu: response, existMenu: true, isMenuSelected: true, gettingData: false});
                this.props.dispatch(change_active_menu(response));
            } else {
                throw new Error('Error: ' + response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            if (this.props.actualMenu) { //fallback if no connection or error, if there is an ancient menu on redux at least, charge it to state
                this.setState({menu: this.props.actualMenu, existMenu: true, isMenuSelected: true, gettingData: false})
            }
        });
    };
    /////////////////////////////

    /* Menu SELECTOR LOGICS*/
    handleMenuSelection = selectedMenu => () => {
        this.props.dispatch(change_active_menu(selectedMenu));
        this.setState({menu: selectedMenu, isMenuSelected: true, existMenu: true});
    };

    async checkPinIsValid() {
        const restaurantUuid = this.props.match.params.restaurantUuid;
        const introducedPin = this.state.introducedPin;
        let pinIsValid = false;

        try {
            this.setState({gettingData: true});
            const response = await fetch(apiBaseUrl + 'restaurants/checkPinIsValid', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        uuidRestaurant: restaurantUuid,
                        introducedPin: introducedPin,
                    }),
                }
            );
            const jsonData = await response.json();

            if (!jsonData.error) {
                this.setState({gettingData: false});
                pinIsValid = jsonData.pinIsValid
            } else {
                throw new Error('Error checking security pin');
            }
        } catch (error) {
            console.error(error);
            this.setState({gettingData: false});
        }

        //Should return false if pin is not valid
        return pinIsValid;
    };

    cleanReduxAndRefresh = () => {
        this.setState({introducedPin: ''});

        this.checkPinIsValid().then((pinIsValid) => {
            if (pinIsValid) {
                //Set menu object to empty one on redux and refresh page to show menu selector
                this.setState({securityPinModal: false});
                this.props.dispatch(change_active_menu({}));
                document.location.reload();
            } else {
                // Close pin input modal and show snack error
                this.setState({securityPinModal: false});
                this.props.dispatch(showSnack('El PIN introducido no es correcto', 'error'));
                console.error('PIN no válido');
            }
        });


    };

    //////////// RENDERS //////////////

    getSectionCols = sectionPosition => {
        switch (sectionPosition) {
            case 0:
                return 12;
            case 1:
            case 2:
                return 6;
            case 3:
            case 4:
            case 5:
                return 4;
            default:
                return 3;
        }
    };


    shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    };

    getRandomDishes = () => {
        let sections = this.state.menu.menu_sections;
        let dishes = [];
        Object.keys(sections).forEach((key) => {
            let section = sections[key];
            dishes.concat(section.dishes);
        });

        dishes = this.shuffleArray(dishes);
        return dishes;
    };

    handleGoToSectionTroughDish = (dish) =>{
        let dishParentSection = dish.pivot.uuidMenuSections; //get parent section from dish pivot properties
        let index = this.state.menu.menu_sections.findIndex(function(item, i){
            return item.uuid === dishParentSection //return index of parent section in menu_sections array
        });
        let selectedSection =  this.state.menu.menu_sections[index]; //get desired section object
        this.handleSectionChange(selectedSection).call(); // call handleSectionChange to change current section to desired one
        this.setState({currentDish: dish.uuid});
    };

    renderDishesCarousel = () => {
        return (
            <DishCarousel
                dishes={this.state.menu.menu_sections[0].dishes}
                //dishes={this.getRandomDishes}
                handleGoToSectionTroughDish={this.handleGoToSectionTroughDish}
                show={6}
            />);
    };

    renderSectionsTiles = sections => {
        let tiles = [];

        if (sections && sections.length > 0) {
            Object.keys(sections).forEach((key) => {
                let section = sections[key];

                if (parseInt(section.position) === 3) {
                    tiles.push(
                        <GridListTile key={'DishesCarousel'} cols={12}>
                            {this.renderDishesCarousel()}
                        </GridListTile>
                    );
                }

                tiles.push(
                    <GridListTile key={section.uuid} cols={this.getSectionCols(parseInt(section.position))}
                                  onClick={this.handleSectionChange(section)} style={{cursor: 'pointer'}}>
                        <img
                            src={section.photo ? section.photo : 'https://images.pexels.com/photos/1028637/pexels-photo-1028637.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}
                            alt={section.name}/>
                        <GridListTileBar className={importedStyles.tileInfoBar}
                                         title={<span>{section.name}</span>}
                            //subtitle={}
                            /*actionIcon={
                                <IconButton aria-label={`info about ${section.name}`}>
                                    <InfoIcon style={{color: '#fff'}}/>
                                </IconButton>
                            }*/
                        />
                    </GridListTile>
                );
            });
            return (<GridList cellHeight={180} className={importedStyles.mainGridList} cols={12}> {tiles}</GridList>)
        } else {
            return (<Box><Typography variant={"h5"}>{'Esta carta no tiene secciones que mostrar'}</Typography></Box>);
        }
    };

    renderMenuContent = () => {
        let sections = this.state.menu.menu_sections;
        // let sections2 = [
        //     {
        //         uuid: 0,
        //         name: 'Nuestra selección',
        //         cols: 12,
        //         image: 'https://images.pexels.com/photos/2696064/pexels-photo-2696064.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
        //     },
        //     {
        //         uuid: 0,
        //         name: 'Carnes',
        //         cols: 6,
        //         image: 'https://images.pexels.com/photos/1881336/pexels-photo-1881336.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
        //     }
        // ];
        return (
            <div className={importedStyles.gridGlobalContainer}>
                {this.renderSectionsTiles(sections)}
            </div>
        )
    };

    renderContent() {
        if (this.state.isMenuSelected) {
            if (this.state.selectedSection) {
                return (
                    <React.Fragment>
                        <SectionContentBox
                            selectedSection={this.state.selectedSection}
                            sections={this.state.menu.menu_sections}
                            handleSectionChange={this.handleSectionChange}
                            handleFirstAdditionToCart={this.handleFirstAdditionToCart}
                            firstAdditionToCart={this.state.firstAdditionToCart}
                            currentDish={this.state.currentDish}
                        />
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {this.renderMenuContent()}
                    </React.Fragment>
                );
            }
        } else {
            return (
                <React.Fragment>
                    {this.renderMenuSelector()}
                </React.Fragment>
            );
        }
    }

    renderPinInput = () => {
        return (
            <ReactCodeInput value={this.state.introducedPin} type='number' fields={4} autoFocus={true}
                            inputMode={'numeric'}
                            onChange={(value) => this.setState({introducedPin: value})}
                            className={importedStyles.pinInput}/>
        )
    };

    renderPreviewModeAlert = () => {
        return (
            <div className={importedStyles.previewModeAlertContainer}>
                <Typography variant="h5" gutterBottom>
                    Estás viendo este menú/carta en modo "Previsualización"
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    Así es como tus clientes verán tu carta
                </Typography>
            </div>
        );
    };

    renderFullScreenIconButton() {
        return (
            <React.Fragment>
                <Tooltip title={'Pantalla completa'}>
                    <IconButton onClick={this.enableFullScreen} style={{width: undefined}}>
                        <FullScreenIcon/>
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        )
    }

    renderMenuSelectorTriggerButton() {
        const menuUuid = this.props.match.params.menuUuid;

        // If there is a menuUuid on url, you came from 'comerEnManager' and this is a preview so not rendering the change menu button.
        if (menuUuid) {
            // do nothing

        } else {
            return (
                <div className={importedStyles.menuSelectorButton}>
                    <Tooltip title={'Cambiar menú'} placement={"left"}>
                        <IconButton onClick={() => this.setState({securityPinModal: true})}>
                            <KeyIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }

    }

    renderMenuSelector() {
        let menus = this.state.restaurantMenus;
        let listItems = [];
        Object.keys(menus).forEach((key) => {
            let menu = menus[key];
            listItems.push(
                <Chip key={key} label={menu.name} onClick={this.handleMenuSelection(menu)}
                      className={importedStyles.menuChip}/>
            );
        });

        return (
            <div>
                <Typography variant={'h6'} className={importedStyles.menuSelectorTitle}>
                    Elige que menú/carta deseas mostrar
                </Typography>
                <Card className={importedStyles.card}>
                    <CardContent className={importedStyles.menuSelectorCardContent}>
                        {listItems}
                    </CardContent>
                </Card>
            </div>
        )
    };

    renderNavBar = () => {
        return (
            <DigitalMenuNavBar
                handleBack={this.handleBackToMenu} restaurantLogo={this.state.restaurantLogo}
                restaurantName={this.state.restaurantName} currentSection={this.state.selectedSection}
                handleSectionSelect={this.handleSectionSelect}
            />
        )
    };


    onIdleRefreshContent = () => {
        this.setState({idleRefreshDialog: true}); //Open idle alert dialog

       setTimeout(()=>{
           if (this.state.idleRefreshDialog){ //Refresh only if dialog still open (no user touch)
               window.location.reload(); //Refresh page to retrieve fresh content when user is idle after 15s of idle alert
           }
       }, 15000);
    };

    renderIdleAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: idleAnimation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                //speed={0.7}
            />
        )
    };

    ////////

    render() {
        const {fullScreen} = this.props;

        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdleRefreshContent}
                    debounce={250}
                    timeout={1000 * 60 * 5} // Idle timeout in miliseconds (5min)
                />
                {this.state.isMenuSelected && this.renderNavBar()}
                <Grid container spacing={0} id={'container'} className={importedStyles.parentContainer}>
                    {this.state.previewMode && this.renderPreviewModeAlert()}
                    <Grid item xs={12} md={12} className={importedStyles.content}>
                        {this.state.gettingData ?
                            <Grid item xs={12} className={importedStyles.chargingContent}>
                                <CircularProgress color={"primary"} size={120}/>
                            </Grid>
                            :
                            this.renderContent()
                        }
                        {this.state.isMenuSelected && this.renderMenuSelectorTriggerButton()}
                        {/*{this.state.isMenuSelected && this.renderFullScreenIconButton()}*/}
                    </Grid>

                    <Snack/>

                    <Dialog
                        open={this.state.firstAdditionToCartTutorialDialog}
                        onClose={this.handleFirstAdditionToCartTutorialDialog}
                        aria-labelledby="first addition to cart tutorial"
                        disableBackdropClick
                    >
                        <IconButton color="inherit" onClick={this.handleFirstAdditionToCartTutorialDialog} aria-label="Close" style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}>
                            <CloseIcon/>
                        </IconButton>
                        <DialogTitle>Información de uso</DialogTitle>
                        <DialogContent>
                            <DialogContentText className={importedStyles.dialogContentText}>
                                Acabas de añadir tu primer plato al pedido,
                                recuerda que puedes quitar o añadir platos/articulos a la lista y que te ayudará a recordarlos a la hora de realizar tu pedido al camarero/a.
                                <br/><br/>
                                ¡Gracias!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"contained"} color={"primary"} onClick={this.handleFirstAdditionToCartTutorialDialog}>
                                Entendido
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullScreen={fullScreen}
                        open={this.state.securityPinModal}
                        onClose={() => this.setState({securityPinModal: false})}
                        aria-labelledby="security pin required"
                    >
                        <DialogTitle>Cambiar menú</DialogTitle>
                        <DialogContent className={importedStyles.securityPinModalContent}>
                            <DialogContentText>
                                Esta acción requiere la introducción del PIN de seguridad
                            </DialogContentText>
                            {this.renderPinInput()}
                        </DialogContent>
                        <DialogActions>
                            <Button size={"small"} color="secondary"
                                    onClick={() => this.setState({securityPinModal: false})}>
                                Cancelar
                            </Button>
                            <Button color="primary" variant={"contained"} onClick={this.cleanReduxAndRefresh} autoFocus>
                                Comprobar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullWidth
                        maxWidth={"md"}
                        open={this.state.idleRefreshDialog}
                        onClose={() => this.setState({idleRefreshDialog: false})}
                        aria-labelledby="idle cause data refresh"
                    >
                        <DialogTitle>Aviso de inactividad</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{fontSize: '1.35rem'}}>
                                Hemos detectado un periodo de inactividad prolongado, en 15s se borrará el contenido del pedido y se actualizará toda la información de la carta.
                                <br/><br/>
                                Si deseas continuar con el pedido pulsa el botón 'Continuar mi pedido'.
                            </DialogContentText>
                            {this.renderIdleAnimation()}
                            <Container maxWidth={"md"} style={{display: 'flex', justifyContent: 'center', paddingBottom: '2em'}}>
                                <Button color="primary" variant={"contained"} onClick={() => this.setState({idleRefreshDialog: false})}>
                                    Continuar mi pedido
                                </Button>
                            </Container>
                        </DialogContent>
                    </Dialog>

                </Grid>
            </React.Fragment>
        );
    }
}

digitalMenuScreen.propTypes = {};

const mapStateToProps = ({digitalMenuReducer}) => {
    return ({
        actualMenu: digitalMenuReducer.actualMenu,
        currentRestaurant: digitalMenuReducer.currentRestaurant,
    });
};

export default connect(mapStateToProps)(digitalMenuScreen);
