export default function importedStyles() {
    return {
        card: {},
        dishContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        fullDisplayFlex: {
            width: '100%', display: 'flex', justifyContent: 'space-between'
        },
        displayFlexCenter: {
            display: 'flex',
            alignItems: 'center'
        }
    }
}