import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    }});
const drawerWidth = 240;

export default function importedStyles() {
    return {
        root: {
            marginTop: '9vh',
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '100%',
            },
        },
        editButton: {
            
            display:'block',
            float:'right',
            marginRight:'0px',
            marginBottom: '2vh'
           
        },

        actionButtonsBox:{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: 15,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                flexWrap: 'wrap',
            },
        },
        cancelEditionButton:{
            [theme.breakpoints.down('sm')]: {
                marginTop: '1em'
            },
        },

        receivingDataProgress:{
            position: 'relative', 
            left: '45%', 
            
        },

        tickIcon:{
            color: 'green'
        },

        notPaidIcon:{
            color: 'red'
        },
        labelBills:{
            margin: 'auto'
        },
        button:{
            marginLeft: '1rem'
        }
    }
}