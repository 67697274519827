import React from 'react';
import PropTypes from 'prop-types';
import history from '../../helpers/history';
import logo from '../../rsc/img/logo-comerEn.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import {login, logout} from '../../modules/Auth/authActions';
import connect from "react-redux/es/connect/connect";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import importedStyles from "./NavBar.module.sass";
import Snack from "../Snack/Snack";
import {showSnack, snackTypes} from "../Snack/SnackActions";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuIcon from '@material-ui/icons/Menu';
import UserIcon from '@material-ui/icons/PermIdentity';
import * as qs from 'query-string';


class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openDrawer: false,
            anchorEl: null,
            loginOpen: false,
            registerOpen: false,
            userIsAssociation: false,
            email: '',
            password: '',
            registerName: '',
            registerEmail: '',
            registerPassword: '',
            registerSurname: '',
            registerPhone: '',
            associationName: '',
            associationEmail: '',
            associationPhone: '',
            authEmailError: false,
            authPasswordError: false,
            logoutModal: false,
            waitingLoginResponse: false,
            waitingRegisterResponse: false,
        };
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        console.log(window.location.search);
        const params = qs.parse(window.location.search);
        if (params.register){
            this.setState({registerOpen: true});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.registerOpen && this.state.registerOpen === false && prevProps.registerOpen === false){
            this.setState({registerOpen: true});
        }
    }


    handleInputChange = (e) => {
        let change = {[e.target.name]: e.target.value};
        this.setState(change);
    };
    toggleDrawer = (boolean) => () => {
        this.setState({openDrawer: boolean});
    };
    handleAssociationCheckbox = () => {

        this.setState({userIsAssociation: !this.state.userIsAssociation})
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };


    closeLoginModal = () => {
        this.setState({loginOpen: false});
    };

    openLoginModal = () => {
        this.setState({loginOpen: true, registerOpen: false});
    };

    closeRegisterModal = () => {
        this.setState({registerOpen: false});
    };

    openRegisterModal = () => {
        this.setState({loginOpen: false, registerOpen: true});
    };

    handleLogoutModal = () => {
        this.setState({logoutModal: !this.state.logoutModal});
    };

    handleLogin(fromRegister) {

        this.setState({
            authPasswordError: false,
            authEmailError: false,
            waitingLoginResponse: true
        });

        let email = this.state.email;
        let password = this.state.password;

        if (fromRegister) {
            email = this.state.registerEmail;
            password = this.state.registerPassword;
        }

        fetch(apiBaseUrl + 'users/userExists', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email, password: password})

        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
            
        })
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            loginOpen: false,
                            email: '',
                            password: '',
                            waitingLoginResponse: false,
                            waitingRegisterResponse: false
                        });
                        this.props.dispatch(login(response.user));
                        history.push('/myRestaurants');
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({authEmailError: true, waitingLoginResponse: false});
                                break;
                            case 'badPassword':
                                this.setState({authPasswordError: true, waitingLoginResponse: false});
                                break;
                            default:
                                this.setState({ waitingLoginResponse: false});
                                this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingLoginResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                }
            ).catch((error) =>{
                console.log('Error: ' + error);
                    this.setState({
                        waitingLoginResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
            })
    };

    handleRegister = () => {

        const registerName = this.state.registerName;
        const registerSurname = this.state.registerSurname;
        const registerEmail = this.state.registerEmail;
        const registerPassword = this.state.registerPassword;
        const registerPhone = this.state.registerPhone;
        const association = this.state.userIsAssociation;
        const associationName = this.state.associationName ? this.state.associationName : null;
        const associationEmail = this.state.associationEmail ? this.state.associationEmail : null;
        const associationPhone = this.state.associationPhone ? this.state.associationPhone : null;


        //console.log('User: ' + registerName + '-' + registerSurname + '-' + registerEmail + '-' + registerPhone);
        this.setState({waitingRegisterResponse: true});

        fetch(apiBaseUrl + 'users/registerUser', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: registerName,
                    surname: registerSurname,
                    password: registerPassword,
                    email: registerEmail,
                    phone: registerPhone,
                    association: association,
                    associationName: associationName,
                    associationEmail: associationEmail,
                    associationPhone: associationPhone
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {

            response = JSON.parse(response);
            if (!response.exist) {
                if (!response.error) {
                    this.props.dispatch(showSnack('Usuario registrado con éxito, ahora utiliza tus datos para acceder', snackTypes.success));
                    this.handleLogin(true);
                } else {
                    this.setState({waitingRegisterResponse: false});
                    console.log('Error: ' + response.errorMessage);
                }
            } else {
                // el usuario con ese email ya existe
                this.setState({waitingRegisterResponse: false});
                this.props.dispatch(showSnack('¡Ya existe un usuario con este correo!', snackTypes.error));
            }

        }).catch((error) => {
            console.log(error);
            this.setState({waitingRegisterResponse: false});
            this.props.dispatch(showSnack('Error en el registro, vuelva a intentarlo en unos minutos', snackTypes.error));
        });

    };

    handleLogout() {
        this.props.dispatch(logout());
        localStorage.removeItem('userIsAssociation');
        history.push('/');
        //cerramos modal de logout
        this.handleLogoutModal();
    }

    renderAssociationFields() {
        return (
            <React.Fragment>
                <Typography variant="title" gutterBottom>
                    Datos de la asociación
                </Typography>
                <TextField
                    margin="dense"
                    name="associationName"
                    label="Nombre de la asociación"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    name="associationEmail"
                    label="Correo de contacto"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    name="associationPhone"
                    label="Teléfono de contacto"
                    type="text"
                    onChange={this.handleInputChange}
                    fullWidth
                />
            </React.Fragment>
        )
    }

    renderDrawerContent = () => {
        const auth = this.props.auth;

        let drawerClasses = [
            !this.state.openDrawer ? importedStyles.drawerSlideIn : importedStyles.drawerSlideOut,
            importedStyles.drawerContainer
        ].join(' ');

            return (
                <div className={drawerClasses}
                     onClick={this.toggleDrawer(false)}
                >
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.toggleDrawer(false)}
                        className={importedStyles.handleButton}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <ListItem className={importedStyles.nestedList} button key={'login'}  onClick={auth ? () => {
                        history.push('/myRestaurants')
                    } : this.openLoginModal}>
                        <ListItemIcon> <UserIcon/> </ListItemIcon>
                        <a href={'#'}>Acceder</a>
                    </ListItem>
                    <ListItem className={importedStyles.nestedList} button
                              key={'landing'}>
                        <a href={'#services'}>Servicios</a>
                    </ListItem>
                    {/*<ListItem className={importedStyles.nestedList} button
                              key={'landing'}>
                        <a href={'#prices'}>Precios</a>
                    </ListItem>*/}
                    <ListItem className={importedStyles.nestedList}
                              key={'faq'}>
                        <a href={'#contact'}>Contacto</a>
                    </ListItem>
                    <div className={importedStyles.decoration}>
                        <div className={importedStyles.decorationTriangleLeft}/>
                        <div className={importedStyles.decorationTriangleTop}/>
                        <div className={importedStyles.decorationTriangleRight}/>
                    </div>
                </div>
            );
    };


    render() {
        const fullScreenModals = window.matchMedia("(max-width: 768px)").matches;
        const auth = this.props.auth;
        const {userIsAssociation} = this.state;

        return (
            <div className={importedStyles.root}>

                <AppBar position="static">
                    <Toolbar className={importedStyles.toolbar}>
                        {this.props.iconLeft && <IconButton className={importedStyles.menuButton} color="inherit" aria-label="Menu">
                            {this.props.iconLeft}
                        </IconButton>
                        }
                        <img className={importedStyles.logo} src={logo} alt={'comerEn'} onClick={() => history.push('/')}/>
                        <div className={importedStyles.buttonsContainer}>
                            <div className={importedStyles.sectionButtonsContainer}>
                                <Button
                                    variant="text"
                                    className={importedStyles.sectionBt}
                                    href={'#services'}>
                                    Servicios
                                </Button>
                                {/*<Button
                                    variant="text"
                                    className={importedStyles.sectionBt}
                                    href={'#prices'}>
                                    Precios
                                </Button>*/}
                                <Button
                                    variant="text"
                                    className={importedStyles.sectionBt}
                                    href={'#contact'}>
                                    Contacto
                                </Button>
                            </div>
                            <Button
                                variant="contained"
                                className={importedStyles.openLoginModalBt}
                                onClick={auth ? () => {
                                    history.push('/myRestaurants')
                                } : this.openLoginModal}
                            >
                                <AccountCircle/> Acceder
                            </Button>
                        </div>
                        <IconButton
                            aria-label="Open drawer"
                            onClick={this.toggleDrawer(true)}
                            className={importedStyles.menuButton}
                        >
                            <MenuIcon fontSize={"large"}/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {this.renderDrawerContent()}

                {/* ***************LOGIN MODAL ***************** */}
                <Dialog
                    open={this.state.loginOpen}
                    onClose={this.closeLoginModal}
                    aria-labelledby="form-dialog-title"
                    fullScreen={fullScreenModals}
                >
                    <Snack/>

                    <IconButton className={importedStyles.closeModalIcon} onClick={this.closeLoginModal}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle id="form-dialog-title">Acceso</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="normal"
                            name="email"
                            label="Email"
                            type="email"
                            onChange={this.handleInputChange}
                            fullWidth
                            inputProps={{maxLength: 50}}
                            onKeyPress={(e) => {
                                if (e.which === 13) {
                                    if (this.state.email !== '' && this.state.password !== '') {
                                        this.handleLogin(false);
                                    }
                                }
                            }}
                        />
                        <TextField
                            margin="normal"
                            name="password"
                            label="Contraseña"
                            type="password"
                            onChange={this.handleInputChange}
                            fullWidth
                            onKeyPress={(e) => {
                                if (e.which === 13) {
                                    if (this.state.email !== '' && this.state.password !== '') {
                                        this.handleLogin(false);
                                    }
                                }
                            }}
                        />
                        <div hidden={!this.state.authEmailError} className={importedStyles.errorBox}>
                            <Typography variant="subheading" color="secondary">
                                Error de autenticación, no existe ningún usuario con ese email
                            </Typography>
                        </div>
                        <div hidden={!this.state.authPasswordError} className={importedStyles.errorBox}>
                            <Typography variant="subheading" color="secondary">
                                Error de autenticación, contraseña erronea
                            </Typography>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={importedStyles.submit}
                            onClick={() => this.handleLogin(false)}
                            disabled={this.state.email === '' || this.state.password === '' || this.state.waitingLoginResponse}
                        >
                            Acceder
                            {this.state.waitingLoginResponse && <CircularProgress size={25} className={importedStyles.circularProgress}/>}
                        </Button>
                    </DialogActions>
                    <Typography variant="caption" gutterBottom align="center">
                        ¿No tienes cuenta?
                        <Button
                            color="primary"
                            size="small"
                            onClick={this.openRegisterModal}
                        >
                            Regístrate aquí
                        </Button>
                    </Typography>
                </Dialog>

                {/* END LOGIN MODAL */}

                {/* *************** REGISTER MODAL ***************** */}
                <Dialog
                    open={this.state.registerOpen}
                    onClose={this.closeRegisterModal}
                    aria-labelledby="form-dialog-title"
                    fullScreen={fullScreenModals}
                >
                    <Snack />

                    <IconButton className={importedStyles.closeModalIcon} onClick={this.closeRegisterModal}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle id="form-dialog-title">Registro</DialogTitle>
                    <DialogContent>
                        {/*<div id={'promoHurrify'} className={importedStyles.promoHurrify}>*/}
                        {/*    Registrate ahora y disfruta de tu PRIMER MES GRATIS*/}
                        {/*</div>*/}
                        <Typography variant={"subtitle1"}>
                            Datos del responsable
                        </Typography>
                        <TextField autoFocus margin="normal" name="registerName" label="Nombre"
                                   inputProps={{maxLength: 50}}
                                   onChange={this.handleInputChange}
                                   type="text" fullWidth/>
                        <TextField margin="normal" name="registerSurname" label="Apellidos"
                                   inputProps={{maxLength: 100}}
                                   onChange={this.handleInputChange}
                                   type="text" fullWidth/>
                        <TextField margin="normal" name="registerEmail" label="Correo" onChange={this.handleInputChange}
                                   inputProps={{maxLength: 50}}
                                   type="email" fullWidth/>
                        <TextField margin="normal" name="registerPassword" label="Contraseña" onChange={this.handleInputChange}
                                   type="password"
                                   fullWidth/>
                        <TextField margin="normal" name="registerPhone" label="Teléfono" onChange={this.handleInputChange}
                                   type="number"
                                   fullWidth/>
                        {/*<div className={importedStyles.asotiation}>
                            <span>Soy una asociación</span>
                            <Checkbox
                                checked={userIsAssociation}
                                onChange={this.handleAssociationCheckbox}
                                color="primary"
                            />
                        </div>*/}
                        {userIsAssociation ? this.renderAssociationFields() : null}
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleRegister}
                            disabled={this.state.waitingRegisterResponse || this.state.registerName.length <= 0 || this.state.registerEmail.length <= 0 || this.state.registerPassword.length <= 0 || this.state.registerSurname.length <= 0 || this.state.registerPhone.length <= 0}
                        >
                            Registrarme
                            {this.state.waitingRegisterResponse &&
                            <CircularProgress
                                size={25}
                                className={importedStyles.circularProgress}
                            />
                            }
                        </Button>
                    </DialogActions>
                    <Typography variant="caption" gutterBottom align="center">
                        ¿Ya tienes una cuenta? <Button color="primary" size="small"
                                                      onClick={this.openLoginModal}>Accede</Button>
                    </Typography>
                </Dialog>
                {/* END REGISTER MODAL */}

                {/* LOGOUT CONFIRMATION MODAL */}
                <Dialog
                    open={this.state.logoutModal}
                    onClose={this.handleLogoutModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Salir"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro desea salir?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleLogoutModal} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleLogout} variant="contained" color="secondary" autoFocus>
                            Salir
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

NavBar.propTypes = {
    classes: PropTypes.object,
};

NavBar.defaultProps = {
    registerOpen: false
};

const mapStateToProps = ({authReducer}) => ({auth: authReducer.auth});

export default connect(mapStateToProps)(NavBar);