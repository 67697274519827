import {
    DRAWER_OPENED,
    SET_COLLAPSABLES,
    SET_NESTED_COLLAPSABLES,
    SET_PARENT_COLLAPSABLE
} from "../../constants/action-types";


const initialState = {
    drawerOpen : false,
    collapsables: {},
    collapseOpen: false,
    collapseAllNested: true,
};

const miniDrawerReducer = (state = initialState, action) => {

    switch (action.type) {
        case DRAWER_OPENED:
            return { ...state, drawerOpen: action.drawerOpen};

        case SET_NESTED_COLLAPSABLES:
            return { ...state, collapseAllNested: action.collapseAllNested};

        case SET_PARENT_COLLAPSABLE:
            return { ...state, collapseOpen: action.collapseOpen};

            case SET_COLLAPSABLES:
            return { ...state, collapsables: action.collapsables};
        default:
            return state;
    }
};


export default miniDrawerReducer;