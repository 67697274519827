export default class RateQuestion {

    constructor(question) {
        this.title = question.title;
        this.answers = question.answers;
        this.type = question.type;
        this.position = question.position;
        this.skippable = question.skippable;
    }

    getAnswersStatistics() {
        let answers = this.answers;
        let totalAnswers = answers.length;
        let totalRate = 0;

        Object.keys(answers).forEach((key) => {
            let answerObject = answers[key];
            totalRate = totalRate + parseInt(answerObject.answer);
        });

        let averageRate = (totalRate / totalAnswers).toFixed(1);

        let answerStatistics = {
            averageRate: averageRate,
            totalAnswers: totalAnswers,
        };

        return answerStatistics;

    }

}