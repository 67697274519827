import { createMuiTheme } from '@material-ui/core/styles';
import red from "@material-ui/core/colors/red";

const theme = createMuiTheme();

export default function importedStyles() {
    return {
        avatar: {
            backgroundColor: red[500],
            width: 60,
            height: 60,
        },
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            marginTop: 50,
        },
        root: {
            display: 'flex',
        },
        cardActions: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        avatarContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-evenly',

        },
        chargingAnimation: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -10,
        },
        selectFileBox: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                flexWrap: 'wrap',
            },
        },
        actionButtonsBox:{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: 15,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                flexWrap: 'wrap',
            },
        },
        cancelEditionButton:{
            [theme.breakpoints.down('sm')]: {
                marginTop: '1em'
            },
        },
        fileUploadButton: {
            marginLeft: 10,
            marginTop: '1em'
        },
        iconLeft: {
            marginRight: 10
        },

    }
}