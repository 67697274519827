import {combineReducers} from "redux";
import authReducer from '../modules/Auth/authReducer';
import menusManagerReducer from '../modules/MenusManager/menusManagerReducer';
import restaurantReducer from '../modules/Restaurant/restaurantReducer';
import MiniDrawerReducer from "../components/MiniDrawer/MiniDrawerReducer";
import snackReducer from "../components/Snack/SnackReducer";
import searchReducer from "../modules/comerEn/Landing/SearchHeader/searchReducer";
import frontPollsReducer from "../modules/FrontPolls/frontPollsReducer";
import digitalMenuReducer from "../modules/DigitalMenu/digitalMenuReducer";
import cartReducer from "../modules/DigitalMenu/components/Cart/cartReducer";

const rootReducer = combineReducers({
    authReducer,
    restaurantReducer,
    menuReducer: menusManagerReducer,
    MiniDrawerReducer,
    snackReducer,
    searchReducer,
    frontPollsReducer,
    digitalMenuReducer,
    cartReducer
});

export default rootReducer;